import { authHeader } from "../helpers/authHeader";
import { authService } from "../services";
import { notifyError, notifySuccess } from "./../../utils/notifications";
import { fetchAccountContent } from "./general.action";

import { handleErrors } from "../helpers/errorHandlers";
import { methods } from "../helpers/HttpHelper";

import { setGlobalLoadingOff, setGlobalLoadingOn } from "./globalLoading.action";

export const actions = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",

  LOGOUT: "LOGOUT",

  CHECK_AUTHENTICATION_REQUEST: "CHECK_AUTHENTICATION_REQUEST",
  CHECK_AUTHENTICATION_SUCCESS: "CHECK_AUTHENTICATION_SUCCESS",
  CHECK_AUTHENTICATION_FAILURE: "CHECK_AUTHENTICATION_FAILURE",

  CHECK_REGISTRATION_REQUEST: "CHECK_REGISTRATION_REQUEST",
  CHECK_REGISTRATION_SUCCESS: "CHECK_REGISTRATION_SUCCESS",
  CHECK_REGISTRATION_FAILURE: "CHECK_REGISTRATION_FAILURE",

  CREATE_ACCOUNT_REQUEST: "CREATE_ACCOUNT_REQUEST",
  CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_FAILURE: "CREATE_ACCOUNT_FAILURE",

  SELECT_LASTEST_REPORT_REQUEST: "SELECT_LASTEST_REPORT_REQUEST",
  SELECT_LASTEST_REPORT_SUCCESS: "SELECT_LASTEST_REPORT_SUCCESS",
  SELECT_LASTEST_REPORT_FAILURE: "SELECT_LASTEST_REPORT_FAILURE",
};

export const authActions = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  changePassword,
  refreshToken,
  registerUserData,
  checkAuthentication,
  checkRegistration,
  getUserTokenForLoginAs,
};

export function getUserTokenForLoginAs(token) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_BACKOFFICE_API_URL}/External/Token?token=${token}`;

  const requestOptions = {
    method: methods.GET,
  };

  return fetch(apiUrl, requestOptions)
    .then((res) => res.json())
    .catch(() => {
      return null;
    });
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    authService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        dispatch(checkAuthentication());
        dispatch(selectLatestReport());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: actions.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: actions.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: actions.LOGIN_FAILURE, error };
  }
}

function logout(callback) {
  return (dispatch) => {
    authService.logout();

    dispatch({
      type: actions.LOGOUT,
    });

    // if any callback trigger is
    // this happen to redirect to some other page
    if (callback) callback();
  };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user.email));

    authService.register(user).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: actions.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: actions.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: actions.REGISTER_FAILURE, error };
  }
}

function forgotPassword(email) {
  return (dispatch) => {
    dispatch(request({ email }));

    authService
      .forgotPassword(email)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)));
  };

  function request(email) {
    return { type: actions.FORGOT_PASSWORD_REQUEST, email };
  }

  function success() {
    return { type: actions.FORGOT_PASSWORD_SUCCESS };
  }

  function failure(error) {
    return { type: actions.FORGOT_PASSWORD_FAILURE, error };
  }
}

function registerUserData(user) {
  return (dispatch) => {
    dispatch({ type: actions.REGISTER_USER_DATA, user });
  };
}

export function checkRegistration(user) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_AUTH_API_URL}/is-email-valid?email=${user.email}`;

  const requestOptions = {
    method: methods.GET,
  };

  return (dispatch) => {
    dispatch(checkRegistrationRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then(() => {
        dispatch(checkRegistrationSuccess());
      })
      .catch((error) => dispatch(checkRegistrationFailure(error)));
  };

  function checkRegistrationRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.CHECK_REGISTRATION_REQUEST,
      });
    };
  }
  function checkRegistrationSuccess() {
    return (dispatch) => {
      dispatch({
        type: actions.CHECK_REGISTRATION_SUCCESS,
        user,
      });
    };
  }
  function checkRegistrationFailure(error) {
    if (error === "Conflict") {
      error = "Your email is already registered";
    }

    return (dispatch) => {
      dispatch({
        type: actions.CHECK_REGISTRATION_FAILURE,
        payload: error,
      });
    };
  }
}

export function createAccount(email, password, code) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_AUTH_API_URL}/account/ConfirmInvitation`;

  const data = { email, password, code };
  const json = JSON.stringify(data);

  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    method: methods.PATCH,
    body: json,
  };

  return (dispatch) => {
    dispatch(createAccountRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(createAccountSuccess(json));
      })
      .catch((error) => dispatch(createAccountFailure(error)));
  };

  function createAccountRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_ACCOUNT_REQUEST,
      });
    };
  }
  function createAccountSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_ACCOUNT_SUCCESS,
        payload,
      });
    };
  }
  function createAccountFailure(error) {
    if (error === "Conflict") {
      error = "Your email is already registered";
    }

    return (dispatch) => {
      dispatch({
        type: actions.CREATE_ACCOUNT_FAILURE,
        payload: error,
      });
    };
  }
}

function resetPassword(userId, code, password, confirmPassword) {
  return (dispatch) => {
    dispatch(request({ userId, code, password, confirmPassword }));

    authService
      .resetPassword(userId, code, password, confirmPassword)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)));
  };

  function request(resetPassword) {
    return { type: actions.RESET_PASSWORD_REQUEST, resetPassword };
  }

  function success() {
    return { type: actions.RESET_PASSWORD_SUCCESS, resetPassword };
  }

  function failure(error) {
    return { type: actions.RESET_PASSWORD_FAILURE, error };
  }
}

function changePassword(current_password, new_password, confirm_new_password) {
  return (dispatch) => {
    dispatch(
      request({
        current_password,
        new_password,
        confirm_new_password,
      })
    );

    authService
      .changePassword(current_password, new_password, confirm_new_password)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)));
  };

  function request(changePassword) {
    return (dispatch) => {
      dispatch({
        type: actions.CHANGE_PASSWORD_REQUEST,
        changePassword,
      });
      dispatch(setGlobalLoadingOn());
    };
  }

  function success() {
    return (dispatch) => {
      dispatch({
        type: actions.CHANGE_PASSWORD_SUCCESS,
      });
      dispatch(setGlobalLoadingOff());
      notifySuccess("Password changed with success!");
    };
  }

  function failure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.CHANGE_PASSWORD_FAILURE,
        error,
      });
      dispatch(setGlobalLoadingOff());
      notifyError(error.message || error);
    };
  }
}

function refreshToken() {
  return (dispatch) => {
    dispatch(request());
    authService
      .refreshToken()
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)));
  };

  function request() {
    return (dispatch) => {
      dispatch({
        type: actions.REFRESH_TOKEN_REQUEST,
      });
    };
  }

  function success(user) {
    return (dispatch) => {
      dispatch({
        type: actions.REFRESH_TOKEN_SUCCESS,
        user,
      });
    };
  }

  function failure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.REFRESH_TOKEN_FAILURE,
        error,
      });
      dispatch(logout());
    };
  }
}

function checkAuthentication() {
  return (dispatch) => {
    dispatch(request());
    if (localStorage.getItem("user")) {
      dispatch(fetchAccountContent())
        .then(() =>
          localStorage.getItem("userId")
            ? dispatch(success())
            : dispatch(failure("User couldn't authenticate"))
        )
        .catch((error) => dispatch(failure(error)));
      return;
    }

    dispatch(failure(""));
  };

  function request() {
    return { type: actions.CHECK_AUTHENTICATION_REQUEST };
  }

  function success(user) {
    return { type: actions.CHECK_AUTHENTICATION_SUCCESS, user };
  }

  function failure(error) {
    return { type: actions.CHECK_AUTHENTICATION_FAILURE, error };
  }
}

function selectLatestReport() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/selected-latest`;

  const requestOptions = {
    method: methods.PUT,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(selectLatestRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then(() => {
        dispatch(selectLatestSuccess());
      })
      .catch((error) => dispatch(selectLatestFailure(error)));
  };

  function selectLatestRequest() {
    return {
      type: actions.SELECT_LASTEST_REPORT_REQUEST,
    };
  }
  function selectLatestSuccess(payload) {
    return {
      type: actions.SELECT_LASTEST_REPORT_SUCCESS,
      payload,
    };
  }
  function selectLatestFailure(error) {
    return {
      type: actions.SELECT_LASTEST_REPORT_FAILURE,
      error,
    };
  }
}
