import React from "react";
import { Switch, Redirect } from "react-router-dom";
import uniqid from "uniqid";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import routerConfig from "../routesConfig";

const renderRoute = (isPrivate, props) => {
  return isPrivate ? (
    <PrivateRoute key={uniqid()} {...props} />
  ) : (
      <PublicRoute key={uniqid()} {...props} />
    );
};

const Router = () => {
  return (
    <Switch>
      {routerConfig.map(({ isPrivate, ...props }) =>
        renderRoute(isPrivate, props)
      )}
      <Redirect to="/" />
    </Switch>
  );
};

export default Router;
