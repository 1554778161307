import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";

import useComparison from "../../utils/validateComparison";

import { compareComments } from "../../../../store/actions/benchmarking.action";
import { setNavigationAssets } from "../../../../store/actions/navigation.action";
import { fetchCommentTypes } from "../../../../store/actions/reports.action";

import Dropdown from "../../../../components/Dropdown";
import PageContent from "../../../../components/PageContent";
import PageLoading from "../../../../components/PageLoading";
import PageTitle from "../../../../components/PageTitle";
import TabbarV2 from "../../../../components/TabbarV2";
import { SECTIONS } from "../../../../constants/sections";
import { tabColors } from "../../../../constants/tabs";
import ReportsTabbar from "../../components/ReportsTabbar";

import { reportsData } from "./data";

import "./CommentsCompare.scss";

function CommentsCompare(props) {
  const {
    isPageLoading,
    isTeam360,
    reports,
    setNavigationAssets,
    compareComments,
    comparedComments,
    reportsToCompare,
    commentTypes,
    fetchCommentTypes,
  } = props;

  const [filter, setFilter] = React.useState(commentTypes[0]?.id);
  const [reportIndex, setReportIndex] = React.useState(0);

  const filteredComments = useMemo(() => {
    if (!comparedComments || !filter) {
      return [];
    }

    return (
      comparedComments[reportIndex]?.filter(
        (comment) => comment.commentTypeId === filter
      ) ?? []
    );
  }, [comparedComments, filter, reportIndex]);

  const [reportList, setReportList] = React.useState([]);

  const options = useMemo(
    () =>
      commentTypes
        .sort((a, b) => a.order - b.order)
        .map((commentType, index) => {
          return {
            id: commentType.id,
            title: commentType.type,
            onClick: () => setFilter(commentType.id),
            active: filter === commentType.id ? "active" : "",
            color: tabColors[index],
          };
        }),
    [commentTypes, filter]
  );

  useComparison();

  const selectReport = React.useCallback(
    (id) => {
      const index = reportList.findIndex((report) => report.id === Number(id));
      if (index !== -1) setReportIndex(index);
    },
    [reportList]
  );

  React.useEffect(() => {
    const navigation = {
      title: "METRIC REPORTS",
      subtitle: SECTIONS.COMMENTS,
      tab: "YOUR REPORTS",
    };
    setNavigationAssets(navigation);
    fetchCommentTypes();
  }, []);

  React.useEffect(() => {
    if (reportsToCompare.reportFrom && reportsToCompare.reportTo) {
      compareComments(
        reportsToCompare.reportFrom?.id,
        reportsToCompare.reportTo?.id
      );
    }
  }, [reportsToCompare.reportFrom, reportsToCompare.reportTo]);

  React.useEffect(() => {
    const filteredReports = reports.filter(
      (report) =>
        report.id === reportsToCompare.reportFrom?.id ||
        report.id === reportsToCompare.reportTo?.id
    );
    setReportList(filteredReports);
  }, [reports]);

  let firstParagraph = "";
  let secondParagraph = "";
  let paragraphs = [];

  if (isTeam360) {
    firstParagraph =
      "Within the questionnaire are three questions that ask the team for their comments about the team. We’ve asked them what they feel the team does well, what it can improve upon and anything else they feel would help the team develop further.";
    secondParagraph =
      "The comments are written verbatim as the platform does not edit or amend what’s been written by the team in any way. It may include errors, spelling mistakes and very occasionally, inappropriate language.";
    paragraphs.push(firstParagraph);
    paragraphs.push(secondParagraph);
  } else {
    firstParagraph =
      "Within the questionnaire are three questions that ask your stakeholders to comment on your team. We ask them what they feel the team does well, what it can improve upon and anything else they feel would help the team develop further.";
    secondParagraph =
      "The comments are written verbatim as the platform does not edit or amend what’s been written in any way. It may include errors, spelling mistakes and very occasionally, inappropriate language.";
    paragraphs.push(firstParagraph);
    paragraphs.push(secondParagraph);
  }

  return (
    <PageLoading
      isLoading={isPageLoading}
      render={() => (
        <Fragment>
          <PageTitle
            title="Comments"
            icon={
              <span className="paragraphs__icon">
                <i className="fa fa-comments" />
              </span>
            }
            paragraphs={paragraphs}
            paragraphsClassNames="parapgh-min-height"
          >
            {reports && reports.length > 0 && (
              <div className="header-container-select">
                <Dropdown
                  data={{
                    ...reportsData,
                    list: reportList,
                    value: reportList[reportIndex]?.id,
                  }}
                  onSelect={(_, id) => selectReport(id)}
                />
              </div>
            )}
          </PageTitle>
          <PageContent>
            <ReportsTabbar compare={true} />
            <div className="mt-4">
              <TabbarV2 options={options} />
            </div>
            <div className="comment-wrapper">
              {filteredComments.map((comment, index) => (
                <div className="comment" key={index}>
                  <span className="comment__text">{comment.answer}</span>
                </div>
              ))}
            </div>
          </PageContent>
        </Fragment>
      )}
    />
  );
}

CommentsCompare.propTypes = {
  setNavigationAssets: PropTypes.func,
  compareComments: PropTypes.func,
  isPageLoading: PropTypes.bool,
  comments: PropTypes.array,
  isTeam360: PropTypes.bool,
  reports: PropTypes.array,
  reportsToCompare: PropTypes.object,
  comparedComments: PropTypes.array,
  commentTypes: PropTypes.array,
  fetchCommentTypes: PropTypes.func,
};

const mapStateToProps = ({ reports, benchmarking }) => {
  const isPageLoading =
    reports.isLoadingCommentTypes ||
    reports.IsLoadingComments ||
    reports.isLoadingReports;
  const { comparedComments, reportsToCompare } = benchmarking;
  return {
    isPageLoading,
    comments: reports.comments,
    isTeam360:
      reports && reports.reportStatus && reports.reportStatus.is_team_360,
    reports: reports.reports,
    comparedComments,
    reportsToCompare,
    commentTypes: reports.commentTypes,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  compareComments,
  fetchCommentTypes,
})(CommentsCompare);
