export const actions = {
  SET_GLOBAL_LOADING_TIMEOUT: "SET_GLOBAL_LOADING_TIMEOUT",
  SET_GLOBAL_LOADING_ON: "SET_GLOBAL_LOADING_ON",
  SET_GLOBAL_LOADING_OFF: "SET_GLOBAL_LOADING_OFF"
};

/**
 * Will receive the timeout variable to set it in the state
 * this will allow the actual loading to only appear after 500ms
 * so that there is no flickering
 * @param {function} loadingTimeout
 */
function setGlobalLoadingTimeout(loadingTimeout) {
  return {
    type: actions.SET_GLOBAL_LOADING_TIMEOUT,
    payload: loadingTimeout
  };
}

/**
 * Setting global loading on will not activate it instantly
 * instead it will trigger after 500ms if the timeout is not cleared
 * by setting the global loading off
 */
export function setGlobalLoadingOn() {
  const TIMEOUT = 500;
  return (dispatch, getState) => {
    const state = getState();

    // clear the timeout if it exists already
    clearTimeout(state.globalLoading.timeout);

    const setLoadingOn = setTimeout(
      () =>
        dispatch({
          type: actions.SET_GLOBAL_LOADING_ON
        }),
      TIMEOUT
    );

    dispatch(setGlobalLoadingTimeout(setLoadingOn));
  };
}

/**
 * Set global loading off, and clear the timeout to set it on
 */
export function setGlobalLoadingOff() {
  return (dispatch, getState) => {
    const state = getState();

    clearTimeout(state.globalLoading.timeout);

    dispatch({
      type: actions.SET_GLOBAL_LOADING_OFF
    });
  };
}
