import React from 'react';
import Button from '../../../../../components/Button';
import PropTypes from 'prop-types';

const StepSizeSelectorBody = ({bodyProps}) => {
    const [rowAmount, setRowAmount] = React.useState("1");
    const handleInputChange = (e) => {
        setRowAmount(e.target.value);
        
    }
    const increment = () =>{
        setRowAmount((prevValue) => parseInt(prevValue) + 1)
    }
    const decrement = () =>{
        setRowAmount((prevValue) => parseInt(prevValue) - 1)
        if(rowAmount-1 < 1)
            setRowAmount(1)
    }
    

    return (
        <div className="step-size-selector-body">
            <div className='step-size-selector-body__amount__wrapper'>
                <input type="number" min="1" className="step-size-selector-body__amount" value={rowAmount} onChange={handleInputChange}/>
                <div className="step-size-selector-body__amount__buttons">
                    <button onClick={increment}><i className='fa fa-caret-up'/></button>
                    <button onClick={decrement}><i className='fa fa-caret-down'/></button>
                </div>
            </div>
            <Button icon="fa-check" color="green" onClick={() => bodyProps.onClick(rowAmount)}>{bodyProps.buttonText}</Button>
        </div>
    );
};

StepSizeSelectorBody.propTypes = {
    bodyProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        buttonText: PropTypes.string.isRequired
    }).isRequired
}

export default StepSizeSelectorBody;