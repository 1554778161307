import "./StepSizeSelector.scss"

import React from 'react';
import StepSizeSelectorBody from "./StepSizeSelectorBody";
import StepSizeSelectorHeader from "./StepSizeSelectorHeader/StepSizeSelectorHeader";
import PropTypes from 'prop-types';

const StepSizeSelector = ({headerProps, bodyProps}) => {
    return (
        <div className="step-size-selector">
            <StepSizeSelectorHeader headerProps={headerProps} />
            <StepSizeSelectorBody bodyProps={bodyProps}/>
        </div>
    );
};

StepSizeSelector.propTypes = {
    headerProps: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired
    }).isRequired,
    
    bodyProps:PropTypes.object
}



export default StepSizeSelector;