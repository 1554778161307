import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

const PublicRoute = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        Layout ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
            <Component {...props} />
          )
      }
    />
  );
};

PublicRoute.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any,
};

export default PublicRoute;
