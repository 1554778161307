export const actions = {
  SET_NAVIGATION_ASSETS: "SET_NAVIGATION_ASSETS",
  SET_BLURRED_CONTENT: "SET_BLURRED_CONTENT"
};

export function setNavigationAssets(navigation) {
  return {
    type: actions.SET_NAVIGATION_ASSETS,
    payload: navigation
  };
}

export function setBlurredContent(blur) {
  return {
    type: actions.SET_BLURRED_CONTENT,
    payload: blur
  };
}
