import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import clickOutside from "react-click-outside";

// actions
import { authActions } from "../../../../store/actions/auth.action";
import { fetchAccountContent } from "../../../../store/actions/general.action";
import { fetchReportStatus } from "../../../../store/actions/reports.action";

import "./User.scss";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleToggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleMenuOff = () => {
    this.setState({
      isOpen: false
    });
  };

  handleClickOutside() {
    this.setState({
      isOpen: false
    });
  }

  handleLogout = () => {
    this.props.logout(() => this.props.history.push("/login"));
  };

  handleHelp = () => {
    this.props.history.push("/explainer-videos");
  };

  render() {
    const { first_name, surname, progress } = this.props.account;

    return (
      <div className="navbar__user">
        <div className="user__container">
          <Link to="/settings/profile">
            <div className="user__name">
              <span>{`${first_name ? first_name : ""} ${
                surname ? surname : ""
              }`}</span>
            </div>
            <div className="user__progress">
              <span>{`Profile ${progress ? progress : ""}% Complete`}</span>
            </div>
          </Link>
        </div>
        <div className="settings__container" onClick={this.handleToggleMenu}>
          <i className="fa fa-cog" />
        </div>
        {this.state.isOpen && (
          <div className="user__menu">
            <ul>
              <li onClick={this.handleMenuOff}>
                <Link to="/settings/profile" onClick={this.handleMenuOff}>
                  <span>Profile</span>
                  <i className="fa fa-user" />
                </Link>
              </li>
              <li onClick={this.handleMenuOff}>
                <Link to="/settings/company" onClick={this.handleMenuOff}>
                  <span>Company</span>
                  <i className="fa fa-briefcase" />
                </Link>
              </li>
              <li onClick={this.handleMenuOff}>
                <Link to="/settings/password" onClick={this.handleMenuOff}>
                  <span>Password</span>
                  <i className="fa fa-lock" />
                </Link>
              </li>
            </ul>
            <div className="separator" />
            <ul>
              <li onClick={this.handleMenuOff}>
                <button className="logout" onClick={this.handleLogout}>
                  <span>Logout</span>
                  <i className="fa fa-sign-out" />
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.general.content
});

ProfileMenu.propTypes = {
  account: PropTypes.object,
  fetchAccountContent: PropTypes.func,
  fetchReportStatus: PropTypes.func,
  history: PropTypes.object,
  logout: PropTypes.func
};

export default withRouter(
  connect(mapStateToProps, {
    fetchAccountContent,
    fetchReportStatus,
    logout: callback => authActions.logout(callback)
  })(clickOutside(ProfileMenu))
);
