export const actions = {
  SET_MOTIVATOR_REPORT: "SET_MOTIVATOR_REPORT",
};

export function setMotivatorReport(payload) {
  return {
    type: actions.SET_MOTIVATOR_REPORT,
    payload,
  };
}
