import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class QuestionnaireSingleChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.response ? props.response.answer : "N/A",
    };
    this.yesOrNo = ["Yes", "No"];
    this.workplace = ["Fixed location", "Mobile", "Home based", "Client based"];
    this.schedule = ["Full time", "Part time"];
    this.monthly = [
      "<6 months",
      "6-12 months",
      "1-2 year",
      "2-5 year",
      "5-10 years",
      ">10 years",
    ];
  }

  componentDidUpdate() {
    const { response } = this.props;
    if (response) {
      if (this.state.selectedOption !== response.answer) {
        this.setState({
          selectedOption: response.answer,
        });
      }
    }
  }

  handleItemClick = (e) => {
    this.props.onItemChange(this.props.questionId, e.currentTarget.value);
    this.setState({
      selectedOption: e.currentTarget.value,
    });
  };

  handleOnBack = () => {
    this.setState({
      error: null,
    });
    this.props.onBack();
  };

  handleOnNext = () => {
    const { selectedOption } = this.state;
    if (selectedOption && selectedOption !== "N/A") {
      this.props.onNext(selectedOption);
      this.setState({
        selectedOption: "N/A",
        error: null,
      });
    } else {
      this.setState({
        error: "You need to answer this question in order to continue",
      });
    }
  };

  renderAnswerRadios = () => {
    switch (this.props.questionAnswerType) {
      case "yesOrNo":
        return this.yesOrNo;
      case "workplace":
        return this.workplace;
      case "schedule":
        return this.schedule;
      case "monthly":
        return this.monthly;
      default:
        return [];
    }
  };

  render() {
    const { error } = this.state;
    const {
      section,
      title,
      subTitle,
      question,
      explanation,
      number,
    } = this.props;
    const formattedQuestion = { __html: question };

    return (
      <Fragment>
        <div className="questionnaire-page__content-header">
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-title-main">
              {`${section ? `Section ${section}. ` : ""}${title}`}
            </span>
          </div>
          <div className="questionnaire-page__content-header-sub-title">
            <span className="questionnaire-page__content-header-sub-title">
              {subTitle}
            </span>
          </div>
        </div>
        <div className="questionnaire-page__content-body">
          <div className="questionnaire-page__content-body-question">
            <i className="fa fa-question-circle" />
            <span>{`Q${number}.  `}</span>
            <span dangerouslySetInnerHTML={formattedQuestion}></span>
          </div>
          <div className="questionnaire-page__content-body-question">
            <span>{explanation && `*${explanation}`}</span>
          </div>
          <div className="questionnaire-page__content-body-options">
            <span className="questionnaire-page__content-body-options-title">
              Please select an option:
            </span>
            <div className="questionnaire-page__content-body-options-container">
              {this.renderAnswerRadios().map((value, index) => {
                return (
                  <label
                    className="questionnaire-page__content-body-options-single-choice"
                    key={index}
                  >
                    {value}
                    <input
                      type="radio"
                      onChange={this.handleItemClick}
                      value={value}
                      checked={this.state.selectedOption === value}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                );
              })}
            </div>
          </div>
          <div className="questionnaire-page__content-body-actions">
            <button
              className="questionnaire-page__content-body-actions-back"
              onClick={() => this.handleOnBack()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" />
              <span>Back</span>
            </button>
            <div className="questionnaire-page__content-body-actions-separator">
              <hr />
            </div>
            <button
              className="questionnaire-page__content-body-actions-next"
              onClick={() => this.handleOnNext()}
            >
              <span>Next</span>
              <i className="fa fa-arrow-right" aria-hidden="true" />
            </button>
          </div>
          <div className="questionnaire-page__content-body-error">
            {error && error ? error : ""}
          </div>
        </div>
      </Fragment>
    );
  }
}

QuestionnaireSingleChoice.propTypes = {
  response: PropTypes.object,
  onBack: PropTypes.func,
  onItemClick: PropTypes.func,
  onNext: PropTypes.func,
  onItemChange: PropTypes.func,
  section: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  question: PropTypes.string,
  questionId: PropTypes.number,
  number: PropTypes.string,
  questionAnswerType: PropTypes.string,
  explanation: PropTypes.string,
};

export default QuestionnaireSingleChoice;
