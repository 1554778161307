import PageTitle from "../../components/PageTitle";
import PageLoading from "../../components/PageLoading";
import TeamWizardSteps from "./components/TeamWizardSteps";
import RegionAmountSelector from "./components/InnerComponents/Region/RegionAmountSelector/RegionAmountSelector";
import RegionCreation from "./components/InnerComponents/Region/RegionCreation";
import AreaAmountSelector from "./components/InnerComponents/Area/AreaAmountSelector/AreaAmountSelector";
import AreaCreation from "./components/InnerComponents/Area/AreaCreation/AreaCreation.jsx";
import TeamAmountSelector from "./components/InnerComponents/Team/TeamAmountSelector/TeamAmountSelector";
import TeamMemberCreation from "./components/InnerComponents/TeamMembers/TeamMemberCreation/TeamMemberCreation";
import TeamMemberAmountSelector from './components/InnerComponents/TeamMembers/TeamMemberAmountSelector/TeamMemberAmountSelector'
import {
  fetchCompanyId,
  saveRegionsAction,
  getRegionsAction,
  saveAreasAction,
  saveTeamsAction,
  saveTeamMemberAction,
  getAreasAction,
  getTeamsAction,
  handleAmounts,
  actions
} from "../../store/actions/wizard.action";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import {
  stepEnum,
  handleAreaParsing,
} from "../../store/helpers/teamWizardHelper";
import "./TeamWizard.scss";
import TeamCreation from "./components/InnerComponents/Team/TeamCreation/TeamCreation";
import { notifyError } from "../../utils/notifications";

const TeamWizard = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const companyId = useSelector((state) => state.teamWizard.companyId);
  const steps = useSelector((state) => state.teamWizard.steps);
  const currentStep = useSelector((state) => state.teamWizard.currentStep);
  const isLoading = useSelector((state) => state.teamWizard.isLoading);
  const regions = useSelector((state) => state.teamWizard.regions);
  const areas = useSelector((state) => state.teamWizard.areas);
  const [savedRegions, setSavedRegions] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [regionAmount, setRegionAmount] = useState("1");
  const [areaAmount, setAreaAmount] = useState("1");
  const [teamAmount, setTeamAmount] = useState("1");
  const [teamMemberAmount, setTeamMemberAmount] = useState("1");

  useEffect(() => {
    dispatch(fetchCompanyId());
  }, []);

  useEffect(() => {
    if (regions && regions.length > 0) {
      setSavedRegions(
        regions.map((region) => ({
          value: region.id,
          label: region.name,
        }))
      );
    }
  }, [regions]);

  const handleRegionsAmount = (rowAmount) => {
    dispatch(handleAmounts(setRegionAmount, rowAmount, stepEnum.RegionAmount));
  };

  const handleAreasAmount = (rowAmount) => {
    dispatch(getRegionsAction(companyId));
    dispatch(handleAmounts(setAreaAmount, rowAmount, stepEnum.AreaAmount));
  };

  const handleTeamsAmount = (rowAmount) => {
    dispatch(getAreasAction(companyId));
    dispatch(handleAmounts(setTeamAmount, rowAmount, stepEnum.TeamsAmount));
  };

  const handleTeamMembersAmount = (rowAmount) => {
    dispatch(getTeamsAction(companyId));
    dispatch(handleAmounts(setTeamMemberAmount, rowAmount, stepEnum.TeamMembersAmount));
  };

  const handleBackStepClick = (step) => {
    switch (step) {
      case stepEnum.AreaCreation:
        dispatch({ type: actions.BACK_REGIONS_CREATION });
        break;
      case stepEnum.TeamsCreation:
        dispatch({ type: actions.BACK_AREAS_CREATION });
        break;
      case stepEnum.TeamMembersCreation:
        dispatch({ type: actions.BACK_TEAMS_CREATION });
        break;
      default:
        break;
    }

  }

  const handleHasDuplicates = (array) => {
    const mapResult = array.map(element => element.name)
    const removeEmptyStrings = mapResult.filter(element => element !== '')
    const removeDuplicates = new Set(removeEmptyStrings)
    const hasDuplicates = removeDuplicates.size < removeEmptyStrings.length
    return {
      hasDuplicates,
      removeEmptyStrings
    }
  }


  const saveRegions = (regionData) => {
    const result = handleHasDuplicates(regionData)
    const hasDuplicates = result.hasDuplicates
    const removeEmptyStrings = result.removeEmptyStrings
    if (hasDuplicates) {
      notifyError('You cannot have regions with the same name')
      return
    }
    if (!removeEmptyStrings.length) {
      notifyError('You should add at least one Region')
      return
    }
    if (!hasDuplicates && removeEmptyStrings.length >= 1) {
      const saveRegionsRequest = {
        regions: removeEmptyStrings,
        companyId: companyId,
      };
      dispatch(saveRegionsAction(saveRegionsRequest));
      setRegionData([])
    }
  }

  const saveAreas = (areaData) => {
    const result = handleHasDuplicates(areaData)
    const hasDuplicates = result.hasDuplicates
    const removeEmptyStrings = result.removeEmptyStrings
    if (hasDuplicates) {
      notifyError('You cannot have areas with the same name')
      return
    }
    if (!removeEmptyStrings.length) {
      notifyError('You should add at least one Area')
      return
    }

    if (!hasDuplicates && removeEmptyStrings.length >= 1) {
      const saveAreasRequest = areaData.filter(area => area.name !== '').map((area) => {
        if (!area.region && area.name !== '') {
          notifyError('You should select a Region')
          return
        }
        return {
          name: area.name,
          regionid: area.region,
        }
      });
      dispatch(saveAreasAction(saveAreasRequest));
      setAreaData([])
    }
  }

  const saveTeams = async (teamData) => {
    const result = handleHasDuplicates(teamData)
    const hasDuplicates = result.hasDuplicates
    const removeEmptyStrings = result.removeEmptyStrings
    if (hasDuplicates) {
      notifyError('You cannot have teams with the same name')
      return
    }
    if (!removeEmptyStrings.length) {
      notifyError('You should add at least one Team')
      return
    }
    if (!hasDuplicates && removeEmptyStrings.length >= 1) {
      const userId = localStorage.getItem("userId");
      const saveTeamsRequest = teamData.filter(team => team.name !== '').map((team) => {
        if (!team.area) {
          notifyError('You should select an Area')
          return
        }

        return {
          name: team.name,
          companyId: companyId,
          areaId: team.area,
          ownerUserId: userId,
          active: true
        }
      }

      );
      dispatch(saveTeamsAction(saveTeamsRequest));
      setTeamData([])
    }
  };

  const saveTeamMembers = async (teamMemberData) => {
    const result = handleHasDuplicates(teamMemberData)
    const hasDuplicates = result.hasDuplicates
    const removeEmptyStrings = result.removeEmptyStrings

    if (hasDuplicates) {
      notifyError('You cannot have team members with the same name')
      return
    }

    if (!removeEmptyStrings.length) {
      notifyError('You should add at least one Team Member')
      return
    }

    if (!hasDuplicates && removeEmptyStrings.length >= 1) {
      const saveTeamMemberRequest = teamMemberData.filter(teamMember => teamMember.name !== '').map((teamMember) => {
        if (!teamMember.team) {
          notifyError('You should select a Team')
          return
        }
        return {
          name: teamMember.name,
          email: `${teamMember.email}@company.com`,
          team_id: teamMember.team
        }
      });

      dispatch(saveTeamMemberAction(saveTeamMemberRequest));
      setTeamMemberData([])
      setTimeout(() => {
        history.push('/team-review')
      }, 300)
    }
  };

  return (
    <PageLoading
      isLoading={isLoading}
      render={() => (
        <div className="team-wizard">
          <PageTitle
            title="Create your Teams"
            subtitle="Let's start by defining your Regions, Areas and Teams, and then you can add team members"
          />
          <TeamWizardSteps steps={steps} />
          {currentStep === stepEnum.RegionAmount && (
            <RegionAmountSelector
              onSubmit={handleRegionsAmount}
              regionAmount={regionAmount}
            />
          )}
          {currentStep === stepEnum.RegionCreation && (
            <RegionCreation
              regionAmount={regionAmount}
              onChange={setRegionData}
              data={regionData}
              onNextStepClick={() => saveRegions(regionData)}
            />
          )}
          {currentStep === stepEnum.AreaAmount && (
            <AreaAmountSelector
              onSubmit={handleAreasAmount}
              areaAmount={areaAmount}
            />
          )}
          {currentStep === stepEnum.AreaCreation && (
            <AreaCreation
              areaAmount={areaAmount}
              onChange={setAreaData}
              data={areaData}
              regions={savedRegions}
              onNextStepClick={() => saveAreas(areaData)}
              onBackStepClick={(step) => handleBackStepClick(step)}
            />
          )}
          {currentStep === stepEnum.TeamsAmount && (
            <TeamAmountSelector
              onSubmit={handleTeamsAmount}
              teamAmount={teamAmount}
            />
          )}
          {currentStep === stepEnum.TeamsCreation && (
            <TeamCreation
              teamAmount={teamAmount}
              data={teamData}
              onChange={setTeamData}
              areas={handleAreaParsing(areas, regions)}
              onNextStepClick={() => saveTeams(teamData)}
              onBackStepClick={(step) => handleBackStepClick(step)}
            />
          )}
          {currentStep === stepEnum.TeamMembersAmount && (
            <TeamMemberAmountSelector
              onSubmit={handleTeamMembersAmount}
              teamMemberAmount={teamMemberAmount}
            />
          )}
          {currentStep === stepEnum.TeamMembersCreation && (
            <TeamMemberCreation
              teamMemberAmount={teamMemberAmount}
              onChange={setTeamMemberData}
              data={teamMemberData}
              onNextStepClick={() => saveTeamMembers(teamMemberData)}
              onBackStepClick={(step) => handleBackStepClick(step)}
            />
          )}
        </div>
      )}
    />
  );
};

export default TeamWizard;
