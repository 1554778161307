import { actions } from "../actions/wizard.action";
import { stepEnum, updateActiveStep } from "../helpers/teamWizardHelper";

const initialState = {
  companyId : null,
  isLoading: false,
  steps: [
    { name: 'Regions', active: true, icon: 'globe' },
    { name: 'Areas', active: false, icon: 'map' },
    { name: 'Teams', active: false, icon: 'users' },
    { name: 'Team Members', active: false, icon: 'user' },
  ],
  currentStep : stepEnum.RegionAmount,
  regions : [],
  areas: [],
  teams: [],
};

export default function wizardReducer(state = initialState, action) {
  switch (action.type) {
    case action.FETCH_COMPANYID_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_COMPANYID_SUCCESS:
      return {
        ...state,
        companyId: action.payload,
        isLoading: false,
      };
    case actions.AMOUNT_REGIONS_SET:
      return {
        ...state,
        currentStep: stepEnum.RegionCreation,
      };
    case actions.SAVE_REGIONS_SUCCESS:
      return {
        ...state,
        currentStep: stepEnum.AreaAmount,
        steps: updateActiveStep('Areas', state.steps),
      };
    case actions.SAVE_REGIONS_FAIL:
      return {
        ...state,
        currentStep: stepEnum.RegionCreation,
      };
    case actions.AMOUNT_AREAS_SET:
      return {
        ...state,
        currentStep: stepEnum.AreaCreation,
      };
    case actions.FETCH_REGIONS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload,
        isLoading: false,
      };
    case actions.SAVE_AREAS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_AREAS_SUCCESS:
      return {
        ...state,
        currentStep: stepEnum.TeamsAmount,
        steps: updateActiveStep('Teams', state.steps),
        isLoading: false,
      };
    case actions.SAVE_AREAS_FAIL:
      return {
        ...state,
        currentStep: stepEnum.AreaCreation,
        isLoading: false,
      };
    case actions.FETCH_AREAS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload,
        isLoading: false,
      };
    case actions.AMOUNT_TEAMS_SET:
      return {
        ...state,
        currentStep: stepEnum.TeamsCreation,
      };
    case actions.SAVE_TEAMS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_TEAMS_SUCCESS:
      return {
        ...state,
        currentStep: stepEnum.TeamMembersAmount,
        steps: updateActiveStep('Team Members', state.steps),
        isLoading: false,
      };
    case actions.SAVE_TEAMS_FAIL:
      return {
        ...state,
        currentStep: stepEnum.TeamsCreation,
        isLoading: false,
      };
    case actions.FETCH_TEAMS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
        isLoading: false,
      };
    case actions.AMOUNT_TEAM_MEMBER_SET:
      return {
        ...state,
        currentStep: stepEnum.TeamMembersCreation,
      };
    case actions.SAVE_TEAM_MEMBER_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SAVE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        currentStep: stepEnum.TeamMembersCreation,
        steps: updateActiveStep('Team Members', state.steps),
        isLoading: false,
      };
    case actions.SAVE_TEAM_MEMBER_FAIL:
      return {
        ...state,
        currentStep: stepEnum.TeamMembersCreation,
        isLoading: false,
      };
    case actions.FETCH_TEAM_MEMBER_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        teamMembers: action.payload,
        isLoading: false,
      };
    case actions.BACK_REGIONS_CREATION:
      return {
        ...state,
        currentStep: stepEnum.RegionCreation,
      };
    case actions.BACK_AREAS_CREATION:
      return {
        ...state,
        currentStep: stepEnum.AreaCreation,
      };
    case actions.BACK_TEAMS_CREATION:
      return {
        ...state,
        currentStep: stepEnum.TeamsCreation,
      };
    case actions.GO_BACK_TEAM_MEMBER_WIZARD:
      return {
        ...state,
        currentStep: stepEnum.TeamMembersCreation,
      };
    case actions.DELETE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actions.DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DELETE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
