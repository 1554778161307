import React from "react";

//scss
import "./ScoreLabel.scss";

const ScoreLabel = () => {
  const scores = [
    {
      grade: "0",
      description: "Strongly Disagree",
    },
    {
      grade: "2",
      description: "Disagree",
    },
    {
      grade: "4",
      description: "Slightly Disagree",
    },
    {
      grade: "6",
      description: "Slightly Agree",
    },
    {
      grade: "8",
      description: "Agree",
    },
    {
      grade: "10",
      description: "Strongly Agree",
    },
  ];

  return (
    <div className="score-label">
      {scores.map((score) => {
        return (
          <div key={score.grade} className="score-label__value">
            <div className="score-label__value-grade">{score.grade}</div>
            <div className="score-label__value-description">
              {score.description}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScoreLabel;
