import React from 'react';
import PropTypes from "prop-types";

import WizardFooter from '../../../WizardFooter';
import WizardFormTable from '../../../WizardFormTable/WizardFormTable';

import "./RegionCreation.scss"

const RegionCreation = ({ regionAmount, onChange, data, onNextStepClick }) => {


    return (
        <div className="region-creation">
            <WizardFormTable
                initialRows={Number(regionAmount)}
                columns={[
                    { hidden: true },
                    {
                        hidden: false,
                        icon: "fa-globe",
                        name: "Region Name",
                        type: "textInput",
                        property: "name",
                        validate: (value) => {
                            return value.length > 2;
                        }
                    },
                ]}
                data={data}
                onChange={(e) => {
                    onChange([...e])
                }} />
            <WizardFooter
                className="region-creation__footer"
                title="Once you've added all your teams' Regions, move on to Areas."
                buttonTitle="Add Areas"
                buttonIcon="fa-map"
                buttonColor="green"
                isFirstStep
                onClick={onNextStepClick} />
        </div>
    );
};

RegionCreation.propTypes = {
    regionAmount: PropTypes.any,
    onChange: PropTypes.func,
    data: PropTypes.array,
    onNextStepClick: PropTypes.func,
};

export default RegionCreation;