export const oldPasswordData = {
  id: 1,
  value: '',
  name: 'current_password',
  label: 'Old password',
  type: 'password',
  required: true,
  validate: true,
  validations: [
    {
      validationName: 'required',
      priority: 1,
      message: 'This is required field',
    },
    {
      validationName: 'minLength',
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`,
    },
    {
      validationName: 'maxLength',
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`,
    },
    {
      validationName: 'regex',
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
      priority: 4,
      message:
        'Password must contain upper and lowercase letters and a special character (except _ ).',
    },
  ],
};

export const newPasswordData = {
  id: 2,
  value: '',
  name: 'new_password',
  label: 'New password',
  type: 'password',
  required: true,
  validate: true,
  validations: [
    {
      validationName: 'required',
      priority: 1,
      message: 'This is required field',
    },
    {
      validationName: 'minLength',
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`,
    },
    {
      validationName: 'maxLength',
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`,
    },
    {
      validationName: 'regex',
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
      priority: 4,
      message:
        'Password must contain upper and lowercase letters and a special character (except _ ).',
    },
  ],
};

export const confirmPasswordData = {
  id: 3,
  value: '',
  name: 'confirm_new_password',
  label: 'Confirm new password',
  type: 'password',
  required: true,
  validate: true,
  validations: [
    {
      validationName: 'required',
      priority: 1,
      message: 'This is required field',
    },
    {
      validationName: 'minLength',
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`,
    },
    {
      validationName: 'maxLength',
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`,
    },
    {
      validationName: 'regex',
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
      priority: 4,
      message:
        'Password must contain upper and lowercase letters and a special character (except _ ).',
    },
  ],
};
