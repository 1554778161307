/*eslint no-magic-numbers: 0*/
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// scss
import "../../Questionnaire.scss";

class QuestionnaireOpenText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.response ? props.response.answer : "",
    };
  }

  componentDidUpdate() {
    const { response } = this.props;
    if (response) {
      if (this.state.text !== response.answer) {
        this.setState({
          text: response.answer,
        });
      }
    }
  }

  handleChange = (e) => {
    this.props.onTextChange(this.props.questionId, e.target.value);
    this.setState({ text: e.target.value });
  };

  handleOnBack = () => {
    this.setState({
      error: null,
    });
    this.props.onBack();
  };

  handleOnNext = () => {
    const { text } = this.state;
    if (text && text.length <= 1000 && text.length !== 0) {
      this.props.onNext();
      this.setState({
        text: "",
        error: null,
      });
      return;
    }
    if (text && text.length > 1000) {
      this.setState({
        error: "You exceeded the maximum number of characters.",
      });
      return;
    }
    this.setState({
      error: "You need to answer this question in order to continue.",
    });
  };

  renderTextAreaTip() {
    const { text } = this.state;
    return (
      <Fragment>
        {text && text.length >= 1000 ? (
          <div className="questionnaire-page__content-body-opentext-tip">
            <span style={{ color: "#ff4900" }}>Characters exceeded</span>
          </div>
        ) : text ? (
          <div className="questionnaire-page__content-body-opentext-tip">
            <span>{1000 - text.length} characters left</span>
          </div>
        ) : null}
      </Fragment>
    );
  }

  render() {
    const { text, error } = this.state;
    const { subTitle, question, explanation, title, number } = this.props;

    return (
      <Fragment>
        <div className="questionnaire-page__content-header">
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-title-main">
              {`${title}`}
            </span>
          </div>
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-sub-title">
              {subTitle}
            </span>
          </div>
        </div>
        <div className="questionnaire-page__content-body">
          <div className="questionnaire-page__content-body-question">
            <i className="fa fa-question-circle" />
            <span>{`Q${number}.  `}</span>
            <span>{question}</span>
          </div>
          <div className="questionnaire-page__content-body-question">
            <span>{explanation && `*${explanation}`}</span>
          </div>
          <div className="questionnaire-page__content-body-opentext">
            <textarea
              name="response"
              id="1"
              placeholder="Enter your response here"
              onChange={this.handleChange}
              value={text}
            />

            {this.renderTextAreaTip()}
          </div>
          <div className="questionnaire-page__content-body-actions">
            <button
              className="questionnaire-page__content-body-actions-back"
              onClick={() => this.handleOnBack()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" />
              <span>Back</span>
            </button>
            <div className="questionnaire-page__content-body-actions-separator">
              <hr />
            </div>
            <button
              className="questionnaire-page__content-body-actions-next"
              onClick={() => this.handleOnNext()}
            >
              <span>Next</span>
              <i className="fa fa-arrow-right" aria-hidden="true" />
            </button>
          </div>
          <div className="questionnaire-page__content-body-error">
            {error && error ? error : ""}
          </div>
          <div className="questionnaire-page__content-body-description">
            *Please note, your comments are completely confidential. However,
            the report includes exactly what you’ve written so please be mindful
            what you write and include.
          </div>
        </div>
      </Fragment>
    );
  }
}

QuestionnaireOpenText.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onTextChange: PropTypes.func,
  response: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  question: PropTypes.string,
  number: PropTypes.string,
  questionId: PropTypes.number,
  explanation: PropTypes.string,
};

export default QuestionnaireOpenText;
