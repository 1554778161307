import React, { useState, useCallback } from 'react'

import './Search.scss'
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';


const Search = ({ id, name, onChange, value }) => {
  const [localValue, setLocalValue] = useState(value || '');
  const onChangeDebounced = useCallback(debounce(onChange, 300), []);
  const handleInputChange = (event) => {
    const v = event.target.value.toLowerCase();
    setLocalValue(v);
    onChangeDebounced(v);
  }

  const handleClearClick = () => {
    setLocalValue('');
    onChange('');
  }

  return (
    <div className="team-review__search search">
      <input
        className="search__input"
        type="text"
        name={name}
        placeholder={'Search'}
        id={id || name}
        value={localValue}
        onChange={handleInputChange}
      />
      {localValue === ''
        ? <SearchIcon />
        : <ClearIcon onClick={handleClearClick} />}
    </div>

  )
}

Search.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default Search