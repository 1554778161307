/*eslint no-magic-numbers: 0*/
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import Dropdown from "../../../../../../components/Dropdown";
import PageLoading from "../../../../../../components/PageLoading";
import QuestionDetail from "../../../../components/QuestionDetail";
import ReportsFooter from "../../../../components/ReportsFooter";
import ScoreLabel from "../../../../components/ScoreLabel";

//actions
import { setNavigationAssets } from "../../../../../../store/actions/navigation.action";
import { fetchCategory } from "../../../../../../store/actions/reports.action";

//utils
import { getValueColor } from "../../../../../../store/helpers/uiHelper";

//scss
import PageContent from "../../../../../../components/PageContent";
import PageTitle from "../../../../../../components/PageTitle";
import "./CategoryDetail.scss";

class CategoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
    };
    this.orderList = [
      { id: "", description: "Number Order" },
      { id: "high", description: "High to Low" },
      { id: "low", description: "Low to High" },
    ];
  }

  componentDidMount() {
    const navigation = { title: "METRIC REPORTS", subtitle: "Team Synergy" };
    this.props.setNavigationAssets(navigation);
    this.props.fetchCategory(
      this.props.match.params.categoryId,
      this.state.order
    );
  }

  updateFormFieldValue = (_, fieldValue) => {
    this.setState({ order: fieldValue }, () => {
      this.props.fetchCategory(
        this.props.match.params.categoryId,
        this.state.order
      );
    });
  };

  render() {
    const { category, isLoadingCategory } = this.props;

    const paragraphs = [
      <span key={category && category.category_name}>
        Your six
        <>
          {category && (
            <span
              style={{
                fontFamily: "Omnes-bold, sans-serif, Montserrat",
                letterSpacing: "0.0em",
              }}
            >
              {" "}
              {category.category_name}{" "}
            </span>
          )}
        </>
        questions are shown below. Click any of the bars to reveal the disparity
        of how your team scored each of them. Scroll down to show the category
        explanation, observations, and suggested actions.
      </span>,
    ];

    if (category?.category_details_description) {
      paragraphs.push(
        <p key={category.category_details_description}>
          {category && category.category_details_description}
        </p>
      );
    }

    return (
      <PageLoading
        isLoading={isLoadingCategory}
        render={() => (
          <div className="category-details-page">
            <div className="category-details-page__header">
              <button
                onClick={() => this.props.history.goBack()}
                className="category-details-page__header-button"
              >
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span>Back to Summary</span>
              </button>
            </div>
            <PageTitle
              title={`Category: ${category && category.category_name}`}
              paragraphs={[paragraphs]}
            >
              <div className="header-select">
                <div className="category-details-page__content-score">
                  <div className="category-details-page__content-score-icon">
                    <img
                      src={category && category.icon_url}
                      alt={category && category.title}
                    />
                  </div>
                  <div
                    className={`category-details-page__content-score-value ${getValueColor(
                      category && category.average
                    )}`}
                  >
                    <span>{category && category.average.toFixed(1)}</span>
                  </div>
                </div>
                <Dropdown
                  data={{
                    list: this.orderList,
                    name: "order",
                    customClass:
                      "category-details-page__content-options-actions",
                    value: this.state.order,
                  }}
                  onSelect={this.updateFormFieldValue}
                />
              </div>
            </PageTitle>
            <PageContent>
              <ScoreLabel />
              {category &&
                category.questions.map((question) => (
                  <Fragment key={question.question_id}>
                    <QuestionDetail
                      key={question.question_id}
                      description={question.question_description}
                      average={question.average}
                      answers={question.answer_count}
                      answersTotal={question.total_answers}
                      number={question.question_number}
                    />
                  </Fragment>
                ))}
              <ReportsFooter />
            </PageContent>
          </div>
        )}
      />
    );
  }
}

CategoryDetail.propTypes = {
  category: PropTypes.object,
  fetchCategory: PropTypes.func,
  history: PropTypes.object,
  isLoadingCategory: PropTypes.bool,
  match: PropTypes.object,
  setNavigationAssets: PropTypes.func,
  isTeam360: PropTypes.bool,
};

const mapStateToProps = ({ reports }) => ({
  category: reports.category,
  isLoadingCategory: reports.isLoadingCategory,
  isTeam360:
    reports && reports.reportStatus && reports.reportStatus.is_team_360,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCategory: (id, order) => dispatch(fetchCategory(id, order)),
    setNavigationAssets: (navigation) =>
      dispatch(setNavigationAssets(navigation)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
