import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './TeamReviewTable.scss'
import MemberDialog from "../../../../components/MemberDialog";
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  getTeamMembersAction
} from '../../../../store/actions/teamReview.action'

const HEADER = [
  'Name',
  'Email',
  'Team',
  'Area',
  'Region'
]

const TeamReviewTable = ({ data }) => {
  const history = useHistory()
  const [ascendant, setAscendant] = useState(true)
  const dispatch = useDispatch()
  const companyId = useSelector((state) => state.teamWizard.companyId)
  const [isTeamMemberDialogOpen, setIsTeamMemberDialogOpen] = useState(false)
  const [teamMemberDialogOptions, setTeamMemberDialogOptions] = useState([])


  const handleSortAscByField = (array, field) => {
    setAscendant(true)
    array.sort(function (a, b) {
      return a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
    })
  }
  const handleSortDescByField = (array, field) => {
    setAscendant(false)
    array.sort(function (a, b) {
      return a[field] > b[field] ? -1 : a[field] < b[field] ? 1 : 0;
    })
  }
  const handleTeamMemberDialogOpen = (tableMeta, option) => {
    const memberData = [];
    memberData.push(
      tableMeta.id,
      tableMeta.name,
      tableMeta.email,
      tableMeta.team_id,
      tableMeta.team,
      tableMeta.regionId,
      tableMeta.areaId,
    );
    setIsTeamMemberDialogOpen(true)
    setTeamMemberDialogOptions([memberData, option])
  };

  const handleTeamMemberDialogClose = () => {
    dispatch(getTeamMembersAction(companyId))
    setIsTeamMemberDialogOpen(false)
  }

  const isTeam360 = () => {
    return history.location.pathname.includes("stakeholders");
  }
  return (
    <div>
      <MemberDialog
        open={isTeamMemberDialogOpen}
        onClose={handleTeamMemberDialogClose}
        options={teamMemberDialogOptions}
        isTeam360={isTeam360()}
      />
      <table className='review-table'>
        <tbody>
          <tr className='review-table__header'>
            {HEADER.map((value, i) =>
              <th key={i} onClick={() =>
                ascendant
                  ? handleSortDescByField(data, value.toLowerCase())
                  : handleSortAscByField(data, value.toLowerCase())}>
                <div style={{ display: 'flex' }}><span>{value}</span><i className="fa fa-sort"></i></div>
              </th>)}
            <th>
              <div style={{ display: 'flex' }}><span>Options</span></div>
            </th>
          </tr>
          {data.map((teamMember) =>
            <tr key={teamMember.id} className='review-table__body'>
              <td>{teamMember.name}</td>
              <td>{teamMember.email}</td>
              <td>{teamMember.team}</td>
              <td>{teamMember.area}</td>
              <td>{teamMember.region}</td>
              <td>
                <i className="fa fa-paper-plane" onClick={() => handleTeamMemberDialogOpen(teamMember, 'send')}></i>
                <i onClick={() => handleTeamMemberDialogOpen(teamMember, 'edit')} className="fa fa-edit"></i>
                <i onClick={() => handleTeamMemberDialogOpen(teamMember, "delete")} className="fa fa-trash"></i>
              </td>
            </tr>)}
        </tbody>
      </table>
    </div>

  )
}

TeamReviewTable.propTypes = {
  data: PropTypes.array
}

export default TeamReviewTable