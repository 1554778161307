import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/Button";
import clsx from 'clsx'

import "./TeamReviewFooter.scss";

function TeamReviewFooter(props) {
  const { title, onClick, buttonColor, buttonTitle, buttonIcon, disabled, isLastStep, backButtonColor, backButtonTitle, backButtonIcon, backButtonOnClick } =
    props;
  return (
    <div className={clsx('team-review-footer', { 'last-step': isLastStep })}>
      {isLastStep && 
        <Button
          className="custom-button--grey"
          onClick={backButtonOnClick}
          color={backButtonColor}
          disabled={disabled}
          title={backButtonTitle}
          icon={backButtonIcon} />}
        <span className="wizard-footer_title">{title}</span>
        <Button
          onClick={onClick}
          color={buttonColor}
          disabled={disabled}
          title={buttonTitle}
          icon={buttonIcon} />
    </div>
  );
}

TeamReviewFooter.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonColor: PropTypes.string,
  disabled: PropTypes.bool,
  isLastStep: PropTypes.bool,
  backButtonOnClick: PropTypes.func,
  backButtonTitle: PropTypes.string,
  backButtonIcon: PropTypes.string,
  backButtonColor: PropTypes.string,

};

export default TeamReviewFooter;