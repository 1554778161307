import { authHeader } from "../helpers/authHeader";
import { handleErrors } from "../helpers/errorHandlers";
import { notifySuccess, notifyError } from "./../../utils/notifications";
import { methods } from "../helpers/HttpHelper";

import {
  setGlobalLoadingOn,
  setGlobalLoadingOff,
} from "./globalLoading.action";

export const actions = {
  FETCH_PLAN_STATUS_REQUEST: "FETCH_PLAN_STATUS_REQUEST",
  FETCH_PLAN_STATUS_SUCCESS: "FETCH_PLAN_STATUS_SUCCESS",
  FETCH_PLAN_STATUS_FAILURE: "FETCH_PLAN_STATUS_FAILURE",

  FETCH_PLAN_TYPES_REQUEST: "FETCH_PLAN_TYPES_REQUEST",
  FETCH_PLAN_TYPES_SUCCESS: "FETCH_PLAN_TYPES_SUCCESS",
  FETCH_PLAN_TYPES_FAILURE: "FETCH_PLAN_TYPES_FAILURE",

  FETCH_SUBSCRIPTION_KEY_REQUEST: "FETCH_SUBSCRIPTION_KEY_REQUEST",
  FETCH_SUBSCRIPTION_KEY_SUCCESS: "FETCH_SUBSCRIPTION_KEY_SUCCESS",
  FETCH_SUBSCRIPTION_KEY_FAILURE: "FETCH_SUBSCRIPTION_KEY_FAILURE",

  FETCH_SUBSCRIPTION_VALIDATION_REQUEST:
    "FETCH_SUBSCRIPTION_VALIDATION_REQUEST",
  FETCH_SUBSCRIPTION_VALIDATION_SUCCESS:
    "FETCH_SUBSCRIPTION_VALIDATION_SUCCESS",
  FETCH_SUBSCRIPTION_VALIDATION_FAILURE:
    "FETCH_SUBSCRIPTION_VALIDATION_FAILURE",

  UPDATE_ACTIVATION_KEY_REQUEST: "UPDATE_ACTIVATION_KEY_REQUEST",
  UPDATE_ACTIVATION_KEY_SUCCESS: "UPDATE_ACTIVATION_KEY_SUCCESS",
  UPDATE_ACTIVATION_KEY_FAILURE: "UPDATE_ACTIVATION_KEY_FAILURE",
};

export function fetchPlanStatus() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/subscription/my-plan-status`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchPlanStatusRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchPlanStatusSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchPlanStatusFailure(error)));
  };

  function fetchPlanStatusRequest() {
    return { type: actions.FETCH_PLAN_STATUS_REQUEST };
  }
  function fetchPlanStatusSuccess(payload) {
    return {
      type: actions.FETCH_PLAN_STATUS_SUCCESS,
      payload,
    };
  }
  function fetchPlanStatusFailure(error) {
    return {
      type: actions.FETCH_PLAN_STATUS_FAILURE,
      error,
    };
  }
}

export function fetchPlanTypes() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/subscription/plans`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchPlanTypesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const result = [{ id: 0, description: "Please Select..." }];
        json.map((option) => {
          return result.push(option);
        });
        dispatch(fetchPlanTypesSuccess(result));
        return json;
      })
      .catch((error) => dispatch(fetchPlanTypesFailure(error)));
  };

  function fetchPlanTypesRequest() {
    return { type: actions.FETCH_PLAN_TYPES_REQUEST };
  }
  function fetchPlanTypesSuccess(payload) {
    return {
      type: actions.FETCH_PLAN_TYPES_SUCCESS,
      payload,
    };
  }
  function fetchPlanTypesFailure(error) {
    return {
      type: actions.FETCH_PLAN_TYPES_FAILURE,
      error,
    };
  }
}

export function fetchSubscriptionKey() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/subscription`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchSubscriptionKeyRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchSubscriptionKeySuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchSubscriptionKeyFailure(error)));
  };

  function fetchSubscriptionKeyRequest() {
    return { type: actions.FETCH_SUBSCRIPTION_KEY_REQUEST };
  }
  function fetchSubscriptionKeySuccess(payload) {
    return {
      type: actions.FETCH_SUBSCRIPTION_KEY_SUCCESS,
      payload,
    };
  }
  function fetchSubscriptionKeyFailure(error) {
    return {
      type: actions.FETCH_SUBSCRIPTION_KEY_FAILURE,
      error,
    };
  }
}

export function fetchSubscriptionValidation() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/subscription/validate`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchSubscriptionValidationRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchSubscriptionValidationSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchSubscriptionValidationFailure(error)));
  };

  function fetchSubscriptionValidationRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_SUBSCRIPTION_VALIDATION_REQUEST,
      });
      dispatch(setGlobalLoadingOn());
    };
  }
  function fetchSubscriptionValidationSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_SUBSCRIPTION_VALIDATION_SUCCESS,
        payload,
      });
      dispatch(setGlobalLoadingOff());
    };
  }
  function fetchSubscriptionValidationFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_SUBSCRIPTION_VALIDATION_FAILURE,
        error,
      });
      dispatch(setGlobalLoadingOff());
    };
  }
}

export function updateActivationKey(data) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/subscription`;
  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.PUT,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(updateActivationKeyRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(updateActivationKeySuccess(json));
        return json;
      })
      .catch((error) => dispatch(updateActivationKeyFailure(error)));
  };

  function updateActivationKeyRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ACTIVATION_KEY_REQUEST,
      });
      dispatch(setGlobalLoadingOn());
    };
  }
  function updateActivationKeySuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ACTIVATION_KEY_SUCCESS,
        payload,
      });
      dispatch(fetchPlanStatus());
      dispatch(fetchSubscriptionKey());
      dispatch(setGlobalLoadingOff());
      notifySuccess("Your activation key was updated with success");
    };
  }
  function updateActivationKeyFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ACTIVATION_KEY_FAILURE,
        error,
      });
      dispatch(setGlobalLoadingOff());
      notifyError(error.message || error);
    };
  }
}
