/*eslint no-magic-numbers: 0*/
import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authActions } from "../../../../store/actions/auth.action";
import queryString from "query-string";

function LoginAs(props) {
  setAuthToken(props);

  return <Redirect to="/dashboard" />;
}

function setAuthToken(props) {
  const token = getTokenFromQueryString(props.location.search);

  if (!token) {
    return;
  }

  authActions.getUserTokenForLoginAs(token).then((res) => {
    if (!res) {
      props.checkAuthentication();
      return;
    }
    localStorage.setItem("user", JSON.stringify(res));
    props.checkAuthentication();
  });
}

function getTokenFromQueryString(search) {
  var query = queryString.parse(search);

  return query && query.token ? query.token : null;
}

LoginAs.propTypes = {
  checkAuthentication: PropTypes.func,
};

export default connect(null, {
  checkAuthentication: () => authActions.checkAuthentication(),
})(LoginAs);
