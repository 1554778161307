import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import Dropdown from "../../../../../../components/Dropdown";
import PageLoading from "../../../../../../components/PageLoading";
import ReportsFooter from "../../../../components/ReportsFooter";
import ScoreLabel from "../../../../components/ScoreLabel";
import QuestionDetailCompare from "./components/QuestionDetailCompare";

//actions
import { fetchCategoryCompare } from "../../../../../../store/actions/benchmarking.action";
import { setNavigationAssets } from "../../../../../../store/actions/navigation.action";

//utils
import { getValueColor } from "../../../../../../store/helpers/uiHelper";

//scss
import PageContent from "../../../../../../components/PageContent";
import PageTitle from "../../../../../../components/PageTitle";
import "./CategoryDetailCompare.scss";

class CategoryDetailCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
    };
    this.orderList = [
      { id: "", description: "Order" },
      { id: "high", description: "High to Low" },
      { id: "low", description: "Low to High" },
      { id: "disparity", description: "Greatest disparity" },
    ];
  }

  componentDidMount() {
    const navigation = {
      title: "COMPARE AND BENCHMARK",
      subtitle: "COMPARE AND BENCHMARK",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchCategoryCompare(
      this.props.match.params.categoryId,
      this.props.reportsToCompare,
      this.state.order
    );
  }

  updateFormFieldValue = (_, fieldValue) => {
    this.setState({ order: fieldValue }, () => {
      this.props.fetchCategoryCompare(
        this.props.match.params.categoryId,
        this.props.reportsToCompare,
        this.state.order
      );
    });
  };

  render() {
    const { isLoadingCategory, comparedCategory } = this.props;

    const paragraphs = [
      <span key={comparedCategory && comparedCategory[0].category_name}>
        Your six
        <>
          {comparedCategory && (
            <span
              style={{
                fontFamily: "Omnes-bold, sans-serif, Montserrat",
                letterSpacing: "0.0em",
              }}
            >
              {" "}
              {comparedCategory[0].category_name}{" "}
            </span>
          )}
        </>
        questions are shown below. Click any of the bars to reveal the disparity
        of how your team scored each of them. Scroll down to show the category
        explanation, observations, and suggested actions.
      </span>,
    ];

    return (
      <PageLoading
        isLoading={isLoadingCategory}
        render={() => (
          <div className="category-details-compare-page">
            <div className="category-details-compare-page__header">
              <button
                onClick={() => this.props.history.goBack()}
                className="category-details-compare-page__header-button"
              >
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span>Back to Summary</span>
              </button>
            </div>
            <PageTitle
              title={`Category: ${
                comparedCategory && comparedCategory[0].category_name
              }`}
              paragraphs={[paragraphs]}
            >
              <div className="header-select">
                <div className="category-details-compare-page__content-score">
                  <div
                    className={`category-details-compare-page__content-score-value ${getValueColor(
                      comparedCategory && comparedCategory[0].average.toFixed(1)
                    )}`}
                  >
                    <span>
                      {comparedCategory &&
                        comparedCategory[0].average.toFixed(1)}
                    </span>
                  </div>
                  <div className="category-details-compare-page__content-score-icon">
                    <img
                      src={comparedCategory?.[0]?.icon_url}
                      alt={comparedCategory && comparedCategory[0].title}
                    />
                  </div>
                  <div
                    className={`category-details-compare-page__content-score-value ${getValueColor(
                      comparedCategory && comparedCategory[1].average.toFixed(1)
                    )}`}
                  >
                    <span>
                      {comparedCategory &&
                        comparedCategory[1].average.toFixed(1)}
                    </span>
                  </div>
                </div>

                <Dropdown
                  data={{
                    list: this.orderList,
                    name: "order",
                    customClass:
                      "category-details-compare-page__content-options-actions",
                    value: this.state.order,
                  }}
                  onSelect={this.updateFormFieldValue}
                />
              </div>
            </PageTitle>
            <PageContent>
              <ScoreLabel />
              {comparedCategory &&
                comparedCategory[0].questions.map((question, index) => (
                  <Fragment key={question.question_id}>
                    <QuestionDetailCompare
                      answer={question.question_description}
                      initialData={{
                        score: question.average.toFixed(1),
                        teamName: this.props.location.state.team1,
                      }}
                      lastData={{
                        score:
                          comparedCategory[1].questions[index].average.toFixed(
                            1
                          ),
                        teamName: this.props.location.state.team2,
                      }}
                      number={question.question_number}
                    />
                  </Fragment>
                ))}
              <ReportsFooter />
            </PageContent>
          </div>
        )}
      />
    );
  }
}

CategoryDetailCompare.propTypes = {
  isLoadingCategory: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  fetchCategoryCompare: PropTypes.func,
  setNavigationAssets: PropTypes.func,
  reportsToCompare: PropTypes.object,
  comparedCategory: PropTypes.array,
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  reportsToCompare: state.benchmarking.reportsToCompare,
  isLoadingCategory: state.reports.isLoadingCategory,
  comparedCategory: state.benchmarking.comparedCategory,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCategoryCompare: (id, reportsToCompare, order) => {
      return dispatch(fetchCategoryCompare(id, reportsToCompare, order));
    },
    setNavigationAssets: (navigation) =>
      dispatch(setNavigationAssets(navigation)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryDetailCompare);
