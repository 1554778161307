import {
  handleConnection,
  handleServiceErrors,
} from "../helpers/errorHandlers";

import { methods } from "../helpers/HttpHelper";

export const questionnaireService = {
  fetchQuestionnaire,
  submitQuestionnaire,
};

function fetchQuestionnaire(assessmentId, code) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment/${assessmentId}/member/${code}`;

  const requestOptions = {
    method: methods.GET,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .then((questionnaire) => {
      return questionnaire;
    })
    .catch((error) => {
      return Promise.reject(handleConnection(error));
    });
}

function submitQuestionnaire(questionnaire, assessmentId, code) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment/${assessmentId}/member/${code}`;

  const requestOptions = {
    method: methods.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(questionnaire),
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .catch((error) => Promise.reject(handleConnection(error)));
}
