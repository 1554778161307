import { actions } from "../actions/general.action";

const initialState = {
  content: {},
  isLoading: false,
  isLoadingAccountProgress: false,
  isLoadingCountries: false,
  isLoadingCurrencies: false,
  isLoadingDepartments: false,
  isLoadingIndustries: false,
  isLoadingJobs: false,
  error: null,
  countries: [],
  currencies: [],
  departments: [],
  industries: [],
  jobLevels: [],
  progress: [],
  achievements: {},
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ACCOUNT_PROGRESS_REQUEST:
      return {
        ...state,
        isLoadingAccountProgress: true,
        error: null,
      };
    case actions.FETCH_ACCOUNT_PROGRESS_SUCCESS:
      return {
        ...state,
        isLoadingAccountProgress: false,
        progress: action.payload,
      };
    case actions.FETCH_ACCOUNT_PROGRESS_FAILURE:
      return {
        ...state,
        isLoadingAccountProgress: false,
        error: action.payload,
        progress: [],
      };
    case actions.FETCH_ACCOUNT_CONTENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actions.FETCH_ACCOUNT_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: action.payload,
      };
    case actions.FETCH_ACCOUNT_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        content: [],
      };
    case actions.FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoadingCountries: true,
        error: null,
      };
    case actions.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoadingCountries: false,
        countries: action.payload,
      };
    case actions.FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoadingCountries: false,
        error: action.payload,
        countries: [],
      };

    case actions.FETCH_CURRENCIES_REQUEST:
      return {
        ...state,
        isLoadingCurrencies: true,
        error: null,
      };
    case actions.FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        isLoadingCurrencies: false,
        currencies: action.payload,
      };
    case actions.FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        isLoadingCurrencies: false,
        error: action.payload,
        currencies: [],
      };

    case actions.FETCH_INDUSTRIES_REQUEST:
      return {
        ...state,
        isLoadingIndustries: true,
        error: null,
      };
    case actions.FETCH_INDUSTRIES_SUCCESS:
      return {
        ...state,
        isLoadingIndustries: false,
        industries: action.payload,
      };
    case actions.FETCH_INDUSTRIES_FAILURE:
      return {
        ...state,
        isLoadingIndustries: false,
        error: action.payload,
        industries: [],
      };

    case actions.FETCH_JOB_LEVELS_REQUEST:
      return {
        ...state,
        isLoadingJobs: true,
        error: null,
      };
    case actions.FETCH_JOB_LEVELS_SUCCESS:
      return {
        ...state,
        isLoadingJobs: false,
        jobLevels: action.payload,
      };
    case actions.FETCH_JOB_LEVELS_FAILURE:
      return {
        ...state,
        isLoadingJobs: false,
        error: action.payload,
        jobLevels: [],
      };

    case actions.FETCH_DEPARTMENTS_REQUEST:
      return {
        ...state,
        isLoadingDepartments: true,
        error: null,
      };
    case actions.FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        isLoadingDepartments: false,
        departments: action.payload,
      };
    case actions.FETCH_DEPARTMENTS_FAILURE:
      return {
        ...state,
        isLoadingDepartments: false,
        error: action.payload,
        departments: [],
      };
    case actions.FETCH_ACCOUNT_ACHIEVEMENTS_REQUEST:
      return {
        ...state,
        isLoadingAchievements: true,
        error: null,
      };
    case actions.FETCH_ACCOUNT_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        isLoadingAchievements: false,
        achievements: action.payload,
      };
    case actions.FETCH_ACCOUNT_ACHIEVEMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingAchievements: false,
        achievements: [],
      };
    case actions.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isAccountUpdated: false,
        error: null,
      };
    case actions.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isAccountUpdated: true,
      };
    case actions.UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        isAccountUpdated: false,
        error: action.payload,
      };
    case actions.UPDATE_PROFILE_PHOTO_REQUEST:
      return {
        ...state,
        isPhotoUpdated: false,
        error: null,
      };
    case actions.UPDATE_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        isPhotoUpdated: true,
      };
    case actions.UPDATE_PROFILE_PHOTO_FAILURE:
      return {
        ...state,
        isPhotoUpdated: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
