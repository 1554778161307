import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchQuestionAverages } from "../../../../store/actions/reports.action";

//components
import { Link } from "react-router-dom";

//utils
import { getValueColor } from "../../../../store/helpers/uiHelper";

//scss
import { INDICATORS } from "../../../../constants/metrics";
import { SECTIONS } from "../../../../constants/sections";
import "./ReportsTabbar.scss";
class ReportsTabbar extends Component {
  getSelectedTab = (title) => {
    return this.props.title?.toUpperCase() === title.toUpperCase();
  };

  componentDidMount() {
    !this.props.questionAverages && this.props.fetchQuestionAverages();
  }

  render() {
    const { options } = this.props;

    return (
      <div className="reports-tabbar">
        <ul>
          {options.map((option) => (
            <li
              key={option.id}
              className={`${this.getSelectedTab(option.title) ? "active" : ""}`}
            >
              <Link to={option.route} params={{ content: option.title }}>
                <span>{option.subTitle}</span>
                {option.icon ? (
                  <i className={option.icon} />
                ) : (
                  <div
                    className={`metric ${getValueColor(
                      option.value,
                      option.isStress
                    )}`}
                  >
                    <span>
                      {option.value ? (
                        option.value
                      ) : (
                        <i className="fa fa-question-circle" />
                      )}
                    </span>
                  </div>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, navigation, benchmarking }, ownProps) => {
  const getHighestAverage = (type) => {
    const highestAverage = benchmarking.comparedQuestionAverages?.reduce(
      (prev, current) => {
        return prev[type] > current[type] ? prev : current;
      }
    );
    if (!highestAverage) return 0;
    return highestAverage[type].toFixed(1);
  };

  const convertToDecimal = (average) => average.toFixed(1);

  return {
    title: navigation && navigation.selection.subtitle,
    questionAverages: reports.questionAverages,
    options: [
      {
        id: 1,
        route: `/reports/team-synergy${ownProps.compare ? `/compare` : ``}`,
        title: SECTIONS.TEAM_SYNERGY,
        subTitle: "Team Synergy",
        value: ownProps.compare
          ? getHighestAverage(INDICATORS.TEAM_SYNERGY)
          : convertToDecimal(reports.questionAverages?.ci || 0),
      },
      {
        id: 4,
        route: `/reports/top-scoring${ownProps.compare ? `/compare` : ``}`,
        title: SECTIONS.TOP_SCORING,
        subTitle: "Top Scores",
        icon: "fa fa-thumbs-up",
      },
      {
        id: 5,
        route: `/reports/urgent${ownProps.compare ? `/compare` : ``}`,
        title: SECTIONS.URGENT_ACTIONS,
        subTitle: "Urgent Actions",
        icon: "fa fa-exclamation-triangle",
      },
      {
        id: 6,
        route: `/reports/comments${ownProps.compare ? `/compare` : ``}`,
        title: SECTIONS.COMMENTS,
        subTitle: "Comments",
        icon: "fa fa-comments",
      },
    ],
  };
};

ReportsTabbar.propTypes = {
  compare: PropTypes.bool,
  fetchQuestionAverages: PropTypes.func,
  options: PropTypes.array,
  questionAverages: PropTypes.object,
  title: PropTypes.string,
  comparedQuestionAverages: PropTypes.array,
};

export default connect(mapStateToProps, { fetchQuestionAverages })(
  ReportsTabbar
);
