import { authHeader } from "../helpers/authHeader";
import { handleErrors } from "../helpers/errorHandlers";
import { notifyError, notifySuccess } from "./../../utils/notifications";

import { methods } from "../helpers/HttpHelper";
import { fetchTeam } from "./team.action";

import {
  _mapReportsWithDescription
} from "../helpers/mappings";

import { downloadFile } from "../../utils/download-file";
import {
  setGlobalLoadingOff,
  setGlobalLoadingOn,
} from "./globalLoading.action";

export const actions = {
  FETCH_MEMBER_STATUS_REQUEST: "FETCH_MEMBER_STATUS_REQUEST",
  FETCH_MEMBER_STATUS_SUCCESS: "FETCH_MEMBER_STATUS_SUCCESS",
  FETCH_MEMBER_STATUS_FAILURE: "FETCH_MEMBER_STATUS_FAILURE",

  FETCH_CATEGORY_REQUEST: "FETCH_CATEGORY_REQUEST",
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
  FETCH_CATEGORY_FAILURE: "FETCH_CATEGORY_FAILURE",

  FETCH_QUESTION_AVERAGES_REQUEST: "FETCH_QUESTION_AVERAGES_REQUEST",
  FETCH_QUESTION_AVERAGES_SUCCESS: "FETCH_QUESTION_AVERAGES_SUCCESS",
  FETCH_QUESTION_AVERAGES_FAILURE: "FETCH_QUESTION_AVERAGES_FAILURE",

  FETCH_CATEGORY_AVERAGES_REQUEST: "FETCH_CATEGORY_AVERAGES_REQUEST",
  FETCH_CATEGORY_AVERAGES_SUCCESS: "FETCH_CATEGORY_AVERAGES_SUCCESS",
  FETCH_CATEGORY_AVERAGES_FAILURE: "FETCH_CATEGORY_AVERAGES_FAILURE",

  FETCH_REPORTS_TOP10_REQUEST: "FETCH_REPORTS_TOP10_REQUEST",
  FETCH_REPORTS_TOP10_SUCCESS: "FETCH_REPORTS_TOP10_SUCCESS",
  FETCH_REPORTS_TOP10_FAILURE: "FETCH_REPORTS_TOP10_FAILURE",

  FETCH_URGENT_ACTIONS_REQUEST: "FETCH_URGENT_ACTIONS_REQUEST",
  FETCH_URGENT_ACTIONS_SUCCESS: "FETCH_URGENT_ACTIONS_SUCCESS",
  FETCH_URGENT_ACTIONS_FAILURE: "FETCH_URGENT_ACTIONS_FAILURE",

  FETCH_ASSESSMENT_COMMENTS_REQUEST: "FETCH_ASSESSMENT_COMMENTS_REQUEST",
  FETCH_ASSESSMENT_COMMENTS_SUCCESS: "FETCH_ASSESSMENT_COMMENTS_SUCCESS",
  FETCH_ASSESSMENT_COMMENTS_FAILURE: "FETCH_ASSESSMENT_COMMENTS_FAILURE",

  FETCH_REPORT_STATUS_REQUEST: "FETCH_REPORT_STATUS_REQUEST",
  FETCH_REPORT_STATUS_SUCCESS: "FETCH_REPORT_STATUS_SUCCESS",
  FETCH_REPORT_STATUS_FAILURE: "FETCH_REPORT_STATUS_FAILURE",

  FETCH_ALL_REPORTS_REQUEST: "FETCH_ALL_REPORTS_REQUEST",
  FETCH_ALL_REPORTS_SUCCESS: "FETCH_ALL_REPORTS_SUCCESS",
  FETCH_ALL_REPORTS_FAILURE: "FETCH_ALL_REPORTS_FAILURE",

  FETCH_REPORTS_REQUEST: "FETCH_REPORTS_REQUEST",
  FETCH_REPORTS_SUCCESS: "FETCH_REPORTS_SUCCESS",
  FETCH_REPORTS_FAILURE: "FETCH_REPORTS_FAILURE",

  UPDATE_SELECTED_REPORT_REQUEST: "UPDATE_SELECTED_REPORT_REQUEST",
  UPDATE_SELECTED_REPORT_SUCCESS: "UPDATE_SELECTED_REPORT_SUCCESS",
  UPDATE_SELECTED_REPORT_FAILURE: "UPDATE_SELECTED_REPORT_FAILURE",

  GET_SELECTED_REPORT_REQUEST: "GET_SELECTED_REPORT_REQUEST",
  GET_SELECTED_REPORT_SUCCESS: "GET_SELECTED_REPORT_SUCCESS",
  GET_SELECTED_REPORT_FAILURE: "GET_SELECTED_REPORT_FAILURE",

  FETCH_COHESION_INDICATOR_COMMENTS_REQUEST:
    "FETCH_COHESION_INDICATOR_COMMENTS_REQUEST",
  FETCH_COHESION_INDICATOR_COMMENTS_SUCCESS:
    "FETCH_COHESION_INDICATOR_COMMENTS_SUCCESS",
  FETCH_COHESION_INDICATOR_COMMENTS_FAILURE:
    "FETCH_COHESION_INDICATOR_COMMENTS_FAILURE",

  FETCH_MORALE_INDICATOR_COMMENTS_REQUEST:
    "FETCH_MORALE_INDICATOR_COMMENTS_REQUEST",
  FETCH_MORALE_INDICATOR_COMMENTS_SUCCESS:
    "FETCH_MORALE_INDICATOR_COMMENTS_SUCCESS",
  FETCH_MORALE_INDICATOR_COMMENTS_FAILURE:
    "FETCH_MORALE_INDICATOR_COMMENTS_FAILURE",

  FETCH_STRESS_INDICATOR_COMMENTS_REQUEST:
    "FETCH_STRESS_INDICATOR_COMMENTS_REQUEST",
  FETCH_STRESS_INDICATOR_COMMENTS_SUCCESS:
    "FETCH_STRESS_INDICATOR_COMMENTS_SUCCESS",
  FETCH_STRESS_INDICATOR_COMMENTS_FAILURE:
    "FETCH_STRESS_INDICATOR_COMMENTS_FAILURE",

  SUBMIT_REPORT_CODE_REQUEST: "SUBMIT_REPORT_CODE_REQUEST",
  SUBMIT_REPORT_CODE_SUCCESS: "SUBMIT_REPORT_CODE_SUCCESS",
  SUBMIT_REPORT_CODE_FAILURE: "SUBMIT_REPORT_CODE_FAILURE",

  RESET_REPORT_REQUEST: "RESET_REPORT_REQUEST",
  RESET_REPORT_SUCCESS: "RESET_REPORT_SUCCESS",
  RESET_REPORT_FAILURE: "RESET_REPORT_FAILURE",

  FETCH_PDF_REPORT_REQUEST: "FETCH_PDF_REPORT_REQUEST",
  FETCH_PDF_REPORT_SUCCESS: "FETCH_PDF_REPORT_SUCCESS",
  FETCH_PDF_REPORT_FAILURE: "FETCH_PDF_REPORT_FAILURE",
  FETCH_ASSESSMENT_COMMENT_TYPES_REQUEST: "FETCH_ASSESSMENT_COMMENT_TYPES_REQUEST",
  FETCH_ASSESSMENT_COMMENT_TYPES_SUCCESS: "FETCH_ASSESSMENT_COMMENT_TYPES_SUCCESS",
  FETCH_ASSESSMENT_COMMENT_TYPES_FAILURE: "FETCH_ASSESSMENT_COMMENT_TYPES_FAILURE",
};

export function fetchMemberStatus() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/member-status`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchMemberStatusRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchMemberStatusSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchMemberStatusFailure(error)));
  };

  function fetchMemberStatusRequest() {
    return { type: actions.FETCH_MEMBER_STATUS_REQUEST };
  }
  function fetchMemberStatusSuccess(payload) {
    return {
      type: actions.FETCH_MEMBER_STATUS_SUCCESS,
      payload,
    };
  }
  function fetchMemberStatusFailure(error) {
    return {
      type: actions.FETCH_MEMBER_STATUS_FAILURE,
      error,
    };
  }
}

export function fetchCategory(id, order) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/category/${id}/${order}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCategoryRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchCategorySuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchCategoryFailure(error)));
  };

  function fetchCategoryRequest() {
    return {
      type: actions.FETCH_CATEGORY_REQUEST,
    };
  }
  function fetchCategorySuccess(payload) {
    return {
      type: actions.FETCH_CATEGORY_SUCCESS,
      payload,
    };
  }
  function fetchCategoryFailure(error) {
    return {
      type: actions.FETCH_CATEGORY_FAILURE,
      error,
    };
  }
}

export function fetchCategoryAverages() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/category-averages`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCategoryAveragesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchCategoryAveragesSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchCategoryAveragesFailure(error)));
  };

  function fetchCategoryAveragesRequest() {
    return {
      type: actions.FETCH_CATEGORY_AVERAGES_REQUEST,
    };
  }
  function fetchCategoryAveragesSuccess(payload) {
    return {
      type: actions.FETCH_CATEGORY_AVERAGES_SUCCESS,
      payload,
    };
  }
  function fetchCategoryAveragesFailure(error) {
    return {
      type: actions.FETCH_CATEGORY_AVERAGES_FAILURE,
      error,
    };
  }
}

export function fetchQuestionAverages() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/question-averages`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchQuestionAveragesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchQuestionAveragesSuccess(json));

        return json;
      })
      .catch((error) => dispatch(fetchQuestionAveragesFailure(error)));
  };

  function fetchQuestionAveragesRequest() {
    return {
      type: actions.FETCH_QUESTION_AVERAGES_REQUEST,
    };
  }
  function fetchQuestionAveragesSuccess(payload) {
    return {
      type: actions.FETCH_QUESTION_AVERAGES_SUCCESS,
      payload,
    };
  }
  function fetchQuestionAveragesFailure(error) {
    return {
      type: actions.FETCH_QUESTION_AVERAGES_FAILURE,
      error,
    };
  }
}

export function fetchReportsTop10() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/top10`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchReportsTop10Request());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchReportsTop10Success(json));
        return json;
      })
      .catch((error) => dispatch(fetchReportsTop10Failure(error)));
  };

  function fetchReportsTop10Request() {
    return {
      type: actions.FETCH_REPORTS_TOP10_REQUEST,
    };
  }
  function fetchReportsTop10Success(payload) {
    return {
      type: actions.FETCH_REPORTS_TOP10_SUCCESS,
      payload,
    };
  }
  function fetchReportsTop10Failure(error) {
    return {
      type: actions.FETCH_REPORTS_TOP10_FAILURE,
      error,
    };
  }
}

export function fetchUrgentActions() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/urgent-actions`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchUrgentActionsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchUrgentActionsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchUrgentActionsFailure(error)));
  };

  function fetchUrgentActionsRequest() {
    return {
      type: actions.FETCH_URGENT_ACTIONS_REQUEST,
    };
  }
  function fetchUrgentActionsSuccess(payload) {
    return {
      type: actions.FETCH_URGENT_ACTIONS_SUCCESS,
      payload,
    };
  }
  function fetchUrgentActionsFailure(error) {
    return {
      type: actions.FETCH_URGENT_ACTIONS_FAILURE,
      error,
    };
  }
}

export function fetchReportStatus() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/status`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchReportStatusRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchReportStatusSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchReportStatusFailure(error)));
  };

  function fetchReportStatusRequest() {
    return { type: actions.FETCH_REPORT_STATUS_REQUEST };
  }
  function fetchReportStatusSuccess(payload) {
    return {
      type: actions.FETCH_REPORT_STATUS_SUCCESS,
      payload,
    };
  }
  function fetchReportStatusFailure(error) {
    return {
      type: actions.FETCH_REPORT_STATUS_FAILURE,
      error,
    };
  }
}

export function fetchReports() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/all`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return async (dispatch) => {
    dispatch(fetchReportsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchReportsSuccess(_mapReportsWithDescription(json)));
        return json;
      })
      .catch((error) => dispatch(fetchReportsFailure(error)));
  };

  function fetchReportsRequest() {
    return { type: actions.FETCH_REPORTS_REQUEST };
  }
  function fetchReportsSuccess(payload) {
    return {
      type: actions.FETCH_REPORTS_SUCCESS,
      payload,
    };
  }
  function fetchReportsFailure(error) {
    return {
      type: actions.FETCH_REPORTS_FAILURE,
      error,
    };
  }
}

export function fetchAllReports() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAllReportsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchAllReportsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchAllReportsFailure(error)));
  };

  function fetchAllReportsRequest() {
    return { type: actions.FETCH_ALL_REPORTS_REQUEST };
  }
  function fetchAllReportsSuccess(payload) {
    return {
      type: actions.FETCH_ALL_REPORTS_SUCCESS,
      payload,
    };
  }
  function fetchAllReportsFailure(error) {
    return {
      type: actions.FETCH_ALL_REPORTS_FAILURE,
      error,
    };
  }
}

export function getSelectedReport(reportId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/${reportId}/to-compare`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(getSelectedReportRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((json) => {
        dispatch(getSelectedReportSuccess(json))
        return json;
      })
      .catch((error) => dispatch(getSelectedReportFailure(error)));
  }
  function getSelectedReportRequest() {
    return { type: actions.GET_SELECTED_REPORT_REQUEST };
  }
  function getSelectedReportSuccess(payload) {
    return {
      type: actions.GET_SELECTED_REPORT_SUCCESS,
      payload,
    };
  }
  function getSelectedReportFailure(error) {
    return {
      type: actions.GET_SELECTED_REPORT_FAILURE,
      error,
    };
  }
}

export function updateSelectedReport(reportId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/select/${reportId}`;

  const requestOptions = {
    method: methods.PUT,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(updateSelectedReportRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then(() => dispatch(fetchReports()))
      .then((json) => {
        dispatch(updateSelectedReportSuccess(json));
      })
      .catch((error) => dispatch(updateSelectedReportFailure(error)))
  }

  function updateSelectedReportRequest() {
    return { type: actions.UPDATE_SELECTED_REPORT_REQUEST };
  }
  function updateSelectedReportSuccess(payload) {
    return {
      type: actions.UPDATE_SELECTED_REPORT_SUCCESS,
      payload,
    };
  }
  function updateSelectedReportFailure(error) {
    return {
      type: actions.UPDATE_SELECTED_REPORT_FAILURE,
      error,
    };
  }
}

export function submitReportCode(activation_key, assessmentId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment/${assessmentId}/buy`;
  const json = { activation_key };

  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
    body: JSON.stringify(json),
  };

  return (dispatch) => {
    dispatch(submitReportCodeRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(submitReportCodeSuccess(json));
        return json;
      })
      .catch((error) => dispatch(submitReportCodeFailure(error)));
  };

  function submitReportCodeRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.SUBMIT_REPORT_CODE_REQUEST,
      });
      dispatch(setGlobalLoadingOn());
    };
  }
  function submitReportCodeSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.SUBMIT_REPORT_CODE_SUCCESS,
        payload,
      });
      dispatch(setGlobalLoadingOff());
      notifySuccess("Your report is now fully available!");
    };
  }
  function submitReportCodeFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.SUBMIT_REPORT_CODE_FAILURE,
        error,
      });
      dispatch(setGlobalLoadingOff());
      notifyError(error.message || error);
    };
  }
}

export function fetchCommentTypes() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/comment-type`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCommentTypesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((json) => {
        dispatch(fetchCommentTypesSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchCommentTypesFailure(error)));
  }
}

function fetchCommentTypesRequest() {
  return {
    type: actions.FETCH_ASSESSMENT_COMMENT_TYPES_REQUEST,
  };
}

function fetchCommentTypesSuccess(payload) {
  return {
    type: actions.FETCH_ASSESSMENT_COMMENT_TYPES_SUCCESS,
    payload,
  };
}

function fetchCommentTypesFailure(error) {
  return {
    type: actions.FETCH_ASSESSMENT_COMMENT_TYPES_FAILURE,
    error,
  };
}

export function fetchAssessmentComments() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/comments`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAssessmentCommentsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(
          fetchAssessmentCommentsSuccess(json)
        );
        return json;
      })
      .catch((error) => dispatch(fetchAssessmentCommentsFailure(error)));
  };

  function fetchAssessmentCommentsRequest() {
    return {
      type: actions.FETCH_ASSESSMENT_COMMENTS_REQUEST,
    };
  }
  function fetchAssessmentCommentsSuccess(payload) {
    return {
      type: actions.FETCH_ASSESSMENT_COMMENTS_SUCCESS,
      payload,
    };
  }
  function fetchAssessmentCommentsFailure(error) {
    return {
      type: actions.FETCH_ASSESSMENT_COMMENTS_FAILURE,
      error,
    };
  }
}

export function fetchCohesionIndicatorComments() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/indicator-comment/cohesion`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCohesionIndicatorCommentsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchCohesionIndicatorCommentsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchCohesionIndicatorCommentsFailure(error)));
  };

  function fetchCohesionIndicatorCommentsRequest() {
    return {
      type: actions.FETCH_COHESION_INDICATOR_COMMENTS_REQUEST,
    };
  }
  function fetchCohesionIndicatorCommentsSuccess(payload) {
    return {
      type: actions.FETCH_COHESION_INDICATOR_COMMENTS_SUCCESS,
      payload,
    };
  }
  function fetchCohesionIndicatorCommentsFailure(error) {
    return {
      type: actions.FETCH_COHESION_INDICATOR_COMMENTS_FAILURE,
      error,
    };
  }
}

export function fetchMoraleIndicatorComments() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/indicator-comment/morale`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchMoraleIndicatorCommentsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchMoraleIndicatorCommentsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchMoraleIndicatorCommentsFailure(error)));
  };

  function fetchMoraleIndicatorCommentsRequest() {
    return {
      type: actions.FETCH_MORALE_INDICATOR_COMMENTS_REQUEST,
    };
  }
  function fetchMoraleIndicatorCommentsSuccess(payload) {
    return {
      type: actions.FETCH_MORALE_INDICATOR_COMMENTS_SUCCESS,
      payload,
    };
  }
  function fetchMoraleIndicatorCommentsFailure(error) {
    return {
      type: actions.FETCH_MORALE_INDICATOR_COMMENTS_FAILURE,
      error,
    };
  }
}

export function fetchStressIndicatorComments() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/indicator-comment/stress`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchStressIndicatorCommentsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchStressIndicatorCommentsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchStressIndicatorCommentsFailure(error)));
  };

  function fetchStressIndicatorCommentsRequest() {
    return {
      type: actions.FETCH_STRESS_INDICATOR_COMMENTS_REQUEST,
    };
  }
  function fetchStressIndicatorCommentsSuccess(payload) {
    return {
      type: actions.FETCH_STRESS_INDICATOR_COMMENTS_SUCCESS,
      payload,
    };
  }
  function fetchStressIndicatorCommentsFailure(error) {
    return {
      type: actions.FETCH_STRESS_INDICATOR_COMMENTS_FAILURE,
      error,
    };
  }
}

export function fetchPdfReport() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/document/report`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchPdfReportRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.blob())
      .then((blob) => {
        downloadFile(blob, "report.pdf");
        dispatch(fetchPdfReportSuccess(blob));
      })
      .catch((error) => dispatch(fetchPdfReportFailure(error)));
  };

  function fetchPdfReportRequest() {
    return {
      type: actions.FETCH_PDF_REPORT_REQUEST,
    };
  }
  function fetchPdfReportSuccess(payload) {
    return {
      type: actions.FETCH_PDF_REPORT_SUCCESS,
      payload,
    };
  }
  function fetchPdfReportFailure(error) {
    return {
      type: actions.FETCH_PDF_REPORT_FAILURE,
      error,
    };
  }
}

export function resetReport() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment`;

  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(resetReportRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(resetReportSuccess(json));
        return json;
      })
      .catch((error) => dispatch(resetReportFailure(error)));
  };

  function resetReportRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.RESET_REPORT_REQUEST,
      });
    };
  }
  function resetReportSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.RESET_REPORT_SUCCESS,
        payload,
      });
      dispatch(fetchTeam());
      notifySuccess("Your report has been reseted!");
    };
  }
  function resetReportFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.RESET_REPORT_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}
