import React from "react";
import PropTypes from 'prop-types';

import "./TextInput.scss";


export const TextInput = ({ id, name, value, onChange }) => {
  return (
    <div className="text-input">
      <input
        type="text"
        name={name}
        id={id || name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

TextInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
}