export const reportsData = {
  id: 1,
  name: "allReports",
  label: "Choose your report"
};

export const TabsTitles = {
   POSITIVE_COMMENTS: { title: "Positive comments", filter: "positive_comments" },
   AREAS_TO_IMPROVE: { title: "Areas to improve", filter: "to_improve_comments" },
   ADDITIONAL_COMMENTS: { title: "Three quick wins", filter: "additional_comments" },
  };