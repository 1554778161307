import PropTypes from "prop-types";
import React, { Component } from "react";

//utils
import { getValueColor } from "../../../../../../../store/helpers/uiHelper";

// scss
import clsx from "clsx";
import "./QuestionDetailCompare.scss";

class QuestionDetailCompare extends Component {
  render() {
    const { answer, initialData, lastData, number } = this.props;

    const calculateBarWidth = (score) => {
      return `${score * 10}%`;
    };

    const calculateGhostBarWidth = (type) => {
      const value =
        type === "min"
          ? Math.min(initialData.score, lastData.score)
          : Math.max(initialData.score, lastData.score);

      return `${value * 10}%`;
    };

    return (
      <div className="mt-4 question-detail-compare-graph-container">
        <div className="mb-1">
          <h6>
            Q{number}. {answer}
          </h6>
        </div>
        <div className="comparison-container">
          <div className="graph-bar-container">
            <h6 className="mb-2">{initialData.teamName}</h6>
            <div className="graph-bar up-bar">
              <span
                className={clsx("bar", getValueColor(initialData.score))}
                style={{ width: calculateBarWidth(initialData.score) }}
              />
              <span
                className="line"
                style={{ left: calculateBarWidth(initialData.score) }}
              />
            </div>
            <div className="graph-bar-compare-divisor">
              <span
                className="ghost-bar-start"
                style={{ width: calculateGhostBarWidth("min") }}
              />
              <span className="line-between" />
              <span
                className="ghost-bar-end"
                style={{
                  width: `calc(100% - ${calculateGhostBarWidth("max")})`,
                }}
              />
            </div>
            <div className="graph-bar bottom-bar">
              <span
                className={clsx("bar", getValueColor(lastData.score))}
                style={{ width: calculateBarWidth(lastData.score) }}
              />
              <span
                className="line"
                style={{ left: calculateBarWidth(lastData.score) }}
              />
            </div>
            <h6 className="mt-2">{lastData.teamName}</h6>
          </div>
          <div className="score-container">
            <span className={getValueColor(initialData.score)}>
              {initialData.score}
            </span>
            <span className={getValueColor(lastData.score)}>
              {lastData.score}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

QuestionDetailCompare.propTypes = {
  answer: PropTypes.string,
  initialData: PropTypes.shape({
    score: PropTypes.number,
    teamName: PropTypes.string,
  }),
  lastData: PropTypes.shape({
    score: PropTypes.number,
    teamName: PropTypes.string,
  }),
  number: PropTypes.number,
};

export default QuestionDetailCompare;
