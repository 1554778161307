import React from "react";
import PropTypes from 'prop-types';

import { WizardFormBody, WizardFormHeader } from "./components";

import "./WizardFormTable.scss";

export const WizardFormTable = ({
  initialRows,
  columns,
  data,
  onChange
}) => {
  const handleFormTableChange = (data) => onChange && onChange(data);

  return (
    <>
      <table className="wizard-form-table" cellPadding={0} cellSpacing={0}>
        <WizardFormHeader columns={columns} />
        <WizardFormBody
          columns={columns}
          data={data}
          initialRows={initialRows}
          onChange={handleFormTableChange}
        />
      </table>
    </>
  );
};

WizardFormTable.propTypes = {
  initialRows: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({
      hidden: PropTypes.bool,
      icon: PropTypes.string,
      name: PropTypes.string,
      defaultValue: PropTypes.string
  })).isRequired,
  data: PropTypes.array,
  onChange: PropTypes.func
}

export default WizardFormTable;