import { authHeader } from "../helpers/authHeader";
import { handleErrors } from "../helpers/errorHandlers";
import { methods } from "../helpers/HttpHelper";

export const actions = {
  FETCH_REPORTS_TO_COMPARE_REQUEST: "FETCH_REPORTS_TO_COMPARE_REQUEST",
  FETCH_REPORTS_TO_COMPARE_SUCCESS: "FETCH_REPORTS_TO_COMPARE_SUCCESS",
  FETCH_REPORTS_TO_COMPARE_FAILURE: "FETCH_REPORTS_TO_COMPARE_FAILURE",

  COMPARE_CATEGORY_REQUEST: "COMPARE_CATEGORY_REQUEST",
  COMPARE_CATEGORY_SUCCESS: "COMPARE_CATEGORY_SUCCESS",
  COMPARE_CATEGORY_FAILURE: "COMPARE_CATEGORY_FAILURE",

  COMPARE_QUESTION_AVERAGES_REQUEST: "COMPARE_QUESTION_AVERAGES_REQUEST",
  COMPARE_QUESTION_AVERAGES_SUCCESS: "COMPARE_QUESTION_AVERAGES_SUCCESS",
  COMPARE_QUESTION_AVERAGES_FAILURE: "COMPARE_QUESTION_AVERAGES_FAILURE",

  COMPARE_TOP10_REQUEST: "COMPARE_TOP10_REQUEST",
  COMPARE_TOP10_SUCCESS: "COMPARE_TOP10_SUCCESS",
  COMPARE_TOP10_FAILURE: "COMPARE_TOP10_FAILURE",

  COMPARE_URGENT_ACTIONS_REQUEST: "COMPARE_URGENT_ACTIONS_REQUEST",
  COMPARE_URGENT_ACTIONS_SUCCESS: "COMPARE_URGENT_ACTIONS_SUCCESS",
  COMPARE_URGENT_ACTIONS_FAILURE: "COMPARE_URGENT_ACTIONS_FAILURE",

  COMPARE_COMMENTS_REQUEST: "COMPARE_COMMENTS_REQUEST",
  COMPARE_COMMENTS_SUCCESS: "COMPARE_COMMENTS_SUCCESS",
  COMPARE_COMMENTS_FAILURE: "COMPARE_COMMENTS_FAILURE",

  COMPARE_CATEGORY_AVERAGES_REQUEST: "COMPARE_CATEGORY_AVERAGES_REQUEST",
  COMPARE_CATEGORY_AVERAGES_SUCCESS: "COMPARE_CATEGORY_AVERAGES_SUCCESS",
  COMPARE_CATEGORY_AVERAGES_FAILURE: "COMPARE_CATEGORY_AVERAGES_FAILURE",

  UPDATE_ASSESSMENT_COMPARE: "UPDATE_ASSESSMENT_COMPARE",
};

export function compareCategoryAverages(reportId1, reportId2) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/category-averages/compare?assessmentMomentId1=${reportId1}&assessmentMomentId2=${reportId2}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(compareCategoryAveragesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(compareCategoryAveragesSuccess(json));
        return json;
      })
      .catch((error) => dispatch(compareCategoryAveragesFailure(error)));
  };

  function compareCategoryAveragesRequest() {
    return {
      type: actions.COMPARE_CATEGORY_AVERAGES_REQUEST,
    };
  }
  function compareCategoryAveragesSuccess(payload) {
    return {
      type: actions.COMPARE_CATEGORY_AVERAGES_SUCCESS,
      payload,
    };
  }
  function compareCategoryAveragesFailure(error) {
    return {
      type: actions.COMPARE_CATEGORY_AVERAGES_FAILURE,
      error,
    };
  }
}

export function compareQuestionAverages(reportId1, reportId2) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/question-averages/compare?assessmentMomentId1=${reportId1}&assessmentMomentId2=${reportId2}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(compareQuestionAveragesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(compareQuestionAveragesSuccess(json));
        return json;
      })
      .catch((error) => dispatch(compareQuestionAveragesFailure(error)));
  };

  function compareQuestionAveragesRequest() {
    return {
      type: actions.COMPARE_QUESTION_AVERAGES_REQUEST,
    };
  }
  function compareQuestionAveragesSuccess(payload) {
    return {
      type: actions.COMPARE_QUESTION_AVERAGES_SUCCESS,
      payload,
    };
  }
  function compareQuestionAveragesFailure(error) {
    return {
      type: actions.COMPARE_QUESTION_AVERAGES_FAILURE,
      error,
    };
  }
}

export function compareTop10(reportFrom, reportTo) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/top10/compare?assessmentMomentId1=${reportFrom}&assessmentMomentId2=${reportTo}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(compareTop10Request());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(compareTop10Success(json));
        return json;
      })
      .catch((error) => dispatch(compareTop10Failure(error)));
  };

  function compareTop10Request() {
    return {
      type: actions.COMPARE_TOP10_REQUEST,
    };
  }
  function compareTop10Success(payload) {
    return {
      type: actions.COMPARE_TOP10_SUCCESS,
      payload,
    };
  }
  function compareTop10Failure(error) {
    return {
      type: actions.COMPARE_TOP10_FAILURE,
      error,
    };
  }
}

export function compareUrgentActions(reportFrom, reportTo) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/urgent-actions/compare?assessmentMomentId1=${reportFrom}&assessmentMomentId2=${reportTo}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(compareUrgentActionsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(compareUrgentActionsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(compareUrgentActionsFailure(error)));
  };

  function compareUrgentActionsRequest() {
    return {
      type: actions.COMPARE_URGENT_ACTIONS_REQUEST,
    };
  }
  function compareUrgentActionsSuccess(payload) {
    return {
      type: actions.COMPARE_URGENT_ACTIONS_SUCCESS,
      payload,
    };
  }
  function compareUrgentActionsFailure(error) {
    return {
      type: actions.COMPARE_URGENT_ACTIONS_FAILURE,
      error,
    };
  }
}

export function compareComments(reportFrom, reportTo) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/assessment-moment/comments/compare?assessmentMomentId1=${reportFrom}&assessmentMomentId2=${reportTo}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(compareCommentsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(compareCommentsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(compareCommentsFailure(error)));
  };

  function compareCommentsRequest() {
    return {
      type: actions.COMPARE_COMMENTS_REQUEST,
    };
  }
  function compareCommentsSuccess(payload) {
    return {
      type: actions.COMPARE_COMMENTS_SUCCESS,
      payload,
    };
  }
  function compareCommentsFailure(error) {
    return {
      type: actions.COMPARE_COMMENTS_FAILURE,
      error,
    };
  }
}

export function updateAssessmentCompare(reports) {
  return (dispatch) => {
    dispatch(updateAssessmentCompareObj(reports));
  };

  function updateAssessmentCompareObj(payload) {
    return {
      type: actions.UPDATE_ASSESSMENT_COMPARE,
      payload,
    };
  }
}

export function fetchCategoryCompare(id, reportsToCompare, order) {
  const hasOrder = order !== null && order !== undefined && order !== "";

  const apiUrl = `${
    process.env.REACT_APP_TEAMMETRICS_CORE_API_URL
  }/api/assessment-moment/category/${id}/${
    hasOrder ? `${order}/` : ""
  }compare?assessmentMomentId1=${
    reportsToCompare.reportFrom.id
  }&assessmentMomentId2=${reportsToCompare.reportTo.id}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCompareCategoryRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchCompareCategorySuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchCompareCategoryFailure(error)));
  };

  function fetchCompareCategoryRequest() {
    return {
      type: actions.COMPARE_CATEGORY_REQUEST,
    };
  }
  function fetchCompareCategorySuccess(payload) {
    return {
      type: actions.COMPARE_CATEGORY_SUCCESS,
      payload,
    };
  }
  function fetchCompareCategoryFailure(error) {
    return {
      type: actions.COMPARE_CATEGORY_FAILURE,
      error,
    };
  }
}
