import { combineReducers } from "redux";
import reducers from "./reducers";

const appReducer = combineReducers(reducers);

// clean redux on logout
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
