export const emailFieldData = {
  value: "",
  name: "email",
  label: "",
  placeholder: "Email address",
  type: "email",
  required: true,
  validate: true,
  disabled: false,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "regex",
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      priority: 2,
      message: "Invalid email"
    },
    {
      validationName: "minLength",
      value: 8,
      priority: 3,
      message: `Minimum required length is 8 characters`
    },
    {
      validationName: "maxLength",
      value: 50,
      priority: 4,
      message: `Maximum allowed length is 50 characters`
    }
  ]
};

export const passwordFieldData = {
  value: "",
  name: "password",
  label: "",
  placeholder: "Password",
  type: "password",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`
    },
    {
      validationName: "maxLength",
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`
    },
    {
      validationName: "regex",
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z]).*$/,
      priority: 4,
      message:
        "Password must contain at least one uppercase letter and one lower case letter"
    }
  ]
};

export const errors = {
  invalid_username_or_password: "Your username or password are invalid."
};
