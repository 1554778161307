import { toast } from "react-toastify";

/**
 * this can be changed to always apply a set of default options
 * so all the notifications have the same pattern
 *
 * refer to: https://www.npmjs.com/package/react-toastify
 */
export function notify(text, type, options) {
  // an example of how we can have pre-defined options
  // options = {
  //   hideProgressBar: true,
  //   ...options,
  // };

  toast[type](text, options);
}

export function notifyInfo(text) {
  notify(text, "info");
}

export function notifyWarn(text) {
  notify(text, "warn");
}

export function notifySuccess(text) {
  notify(text, "success");
}

export function notifyError(text) {
  notify(text, "error");
}
