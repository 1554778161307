import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import TextField from "../../../../components/TextField";
import PageLoading from "../../../../components/PageLoading";

//utils
import * as data from "./data";
import * as services from "../../../../store/services/validation.service";
import { notifyError } from "./../../../../utils/notifications";

// actions
import { setNavigationAssets } from "../../../../store/actions/navigation.action";
import { authActions } from "../../../../store/actions/auth.action";

import { hasPermission } from "../../../../store/helpers/userHelper";

// scss
import "./Security.scss";

class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        { data: data.oldPasswordData },
        { data: data.newPasswordData },
        { data: data.confirmPasswordData },
      ],
      isFormSubmitted: false,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    const navigation = {
      title: "PROFILE",
      subtitle: "PASSWORD",
      tab: "PASSWORD",
    };
    this.props.setNavigationAssets(navigation);
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  renderComponentForField = (field) => {
    return (
      <TextField
        data={{ ...field.data }}
        recordSelection={this.updateFormFieldValue}
        updateFormsStatus={this.updateFormsStatus}
        delay={500}
        isFormSubmitted={this.state.isFormSubmitted}
      />
    );
  };

  submit = () => {
    const isFormValid = services.getFormsValidityStatus(this.formStatus);

    if (isFormValid) {
      this.setState({ errorMessage: "" });
      const { current_password, new_password, confirm_new_password } =
        this.state;

      this.props.changePassword(
        current_password,
        new_password,
        confirm_new_password
      );
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Form is invalid, please review your information.",
      });
      notifyError("Form is invalid, please review your information.");
    }
  };

  render() {
    const { isLoading, role } = this.props;

    return (
      <PageLoading
        isLoading={isLoading}
        render={() => (
          <div className="security-page">
            <div className="security-page__title">Your Password</div>
            <div className="security-page__form">
              <div className="security-page__form-title">
                <span>
                  Use this option if you want to update your password.
                </span>
              </div>

              {this.state.fields.map((field) => (
                <div key={field.data.id}>
                  {this.renderComponentForField(field)}
                </div>
              ))}
            </div>
            <div className="security-page__footer">
              <div className="security-page__footer-label">
                <span>
                  Once you entered your password information, remember to hit
                  the save button!
                </span>
              </div>

              <div className="security-page__footer-button">
                <button
                  className="save-button"
                  disabled={!hasPermission(role)}
                  onClick={this.submit}
                >
                  Save Changes
                  <i className="fa fa-check" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  const { isPasswordChanged, isLoading, changePasswordError } = state.auth;
  const { role } = state.general.content;

  return {
    role,
    isPasswordChanged,
    isLoading,
    changePasswordError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePassword: (
      userId,
      current_password,
      new_password,
      confirm_new_password
    ) =>
      dispatch(
        authActions.changePassword(
          userId,
          current_password,
          new_password,
          confirm_new_password
        )
      ),
    setNavigationAssets: (navigation) =>
      dispatch(setNavigationAssets(navigation)),
  };
}

Security.propTypes = {
  isLoading: PropTypes.bool,
  role: PropTypes.string,
  setNavigationAssets: PropTypes.func,
  changePassword: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
