import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import TextField from "../../../../components/TextField";
import { Link, Redirect } from "react-router-dom";

//redux actions
import { authActions } from "../../../../store/actions/auth.action";

//utils
import * as data from "./data";
import * as services from "../../../../store/services/validation.service";

//images
import Logo from "../../../../assets/images/logos/tm_logo_blue.svg";

// scss
import "../../Auth.scss";
import { Footer, RedirectPanel } from "../../components";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailFieldData: data.emailFieldData,
      passwordFieldData: data.passwordFieldData,
      isFormSubmitted: false,
      emailConfirmed: this.checkEmailValidation()
    };
    this.formStatus = {};
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  removeFormsStatusFlag = formStatusFlag => {
    if (formStatusFlag) delete this.formStatus[formStatusFlag];
  };

  handleSubmit = e => {
    e.preventDefault();

    const isFormValid = services.getFormsValidityStatus(this.formStatus);
    const { email, password } = this.state;

    if (isFormValid) {
      this.setState({ errorMessage: "" });
      this.props.login(email, password);
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Form is invalid, please review your information."
      });
    }
  };

  checkEmailValidation() {
    const query = new URLSearchParams(this.props.location.search);
    const emailConfirmed = query.get("email-confirmed");
    if (emailConfirmed === "False") return false;
    if (emailConfirmed === "True") return true;
    return emailConfirmed;
  }

  renderButtons() {
    if (this.props.isLoading) {
      return <div className="loader" />;
    } else {
      return (
        <button type="submit" className="submit-button">
          <span>Login</span>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </button>
      );
    }
  }

  renderErrors() {
    const { loginError } = this.props;
    const { errorMessage, emailConfirmed } = this.state;

    if (errorMessage) return errorMessage;

    if (loginError) {
      if (data.errors[loginError]) {
        return data.errors[loginError];
      }
      return loginError;
    }

    if (emailConfirmed !== null) {
      if (emailConfirmed)
        return "Email confirmation successful! You can login now.";
      if (!emailConfirmed)
        return "Email confirmation failed! Please register again.";
    }

    return "";
  }

  render() {
    const { isAuthenticated } = this.props;
    const {
      emailFieldData,
      passwordFieldData,
      isFormSubmitted,
      emailConfirmed
    } = this.state;

    if (isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="auth-page">
        <div className="auth-page__form">
          <div className="auth-page__form-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="auth-page__form-fields">
            <form onSubmit={this.handleSubmit}>
              <div className="auth-page__form-fields-title">
                <span>Login to your account:</span>
              </div>
              <div className="auth-page__form-fields-subtitle">
                <span>Please enter your account details below</span>
              </div>
              <TextField
                data={{
                  ...emailFieldData
                }}
                recordSelection={this.updateFormFieldValue}
                updateFormsStatus={this.updateFormsStatus}
                removeFormsStatusFlag={this.removeFormsStatusFlag}
                isFormSubmitted={isFormSubmitted}
              />
              <TextField
                data={{
                  ...passwordFieldData
                }}
                recordSelection={this.updateFormFieldValue}
                updateFormsStatus={this.updateFormsStatus}
                removeFormsStatusFlag={this.removeFormsStatusFlag}
                isFormSubmitted={isFormSubmitted}
              />
              <div
                className="error-message"
                style={
                  emailConfirmed !== null && emailConfirmed
                    ? { color: "green" }
                    : {}
                }
              >
                {this.renderErrors()}
              </div>
              <div className="auth-page__form-buttons">
                {this.renderButtons()}
              </div>
            </form>
            <div className="auth-page__form-recover-label">
              <div style={{ display: "inline-block" }}>
                <span>Forgotten you password? </span>
              </div>{" "}
              <div style={{ display: "inline-block", color: "#1392d3" }}>
                <Link to="/forgot-password">Recover it here.</Link>
              </div>
            </div>
          </div>
          <RedirectPanel label="New to TeamLytica?" linkLabel="Register" linkTo="/" /> 
          <Footer />
        </div>
        <div className="auth-page__image">
          <div className="auth-page__image-container" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isAuthenticated, isLoading, loginError } = state.auth;
  return {
    isAuthenticated,
    isLoading,
    loginError
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(authActions.login(email, password))
  };
}

Login.propTypes = {
  location: PropTypes.object,
  login: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loginError: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
