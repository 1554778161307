import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TextField, Grid, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { updateAssessmentCompare } from "../../../../../../store/actions/benchmarking.action";

import ReportsCard from "../../../../components/ReportsCard";

import "./CompareTable.scss";

function CompareTable({ reports, updateAssessmentCompare, reportsToCompare }) {
  const [firstFilter, setFirstFilter] = React.useState("");
  const [secondFilter, setSecondFilter] = React.useState("");

  const selectFirstComparison = React.useCallback(
    (selectedReport) => {
      let reportFrom = null;
      if (selectedReport.id !== reportsToCompare?.reportFrom?.id) reportFrom = selectedReport;
      updateAssessmentCompare({ reportFrom });
    },
    [reportsToCompare?.reportFrom]
  );

  const selectSecondComparison = React.useCallback(
    (selectedReport) => {
      let reportTo = null;
      if (selectedReport.id !== reportsToCompare?.reportTo?.id) reportTo = selectedReport;
      updateAssessmentCompare({ reportTo });
    },
    [reportsToCompare?.reportTo]
  );

  return (
    <div>
      <div className="comparison-header">
        <div className="item">
          <h5>Compare this report...</h5>
        </div>
        <div className="item">
          <h5>...to this report</h5>
        </div>
      </div>
      <div className="comparison-table">
        <div className="line">
          <div className="cel">
            <Grid
              container
              direction="column"
              component="form"
              mb={2}
              sx={{
                border: `1px solid ${grey[100]}`,
                borderRadius: 2,
                p: 2,
              }}
            >
              <Grid item sm={12} mb={2}>
                <TextField
                  required
                  placeholder="Type here..."
                  id="outlined-required"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFirstFilter(e.target.value)}
                  fullWidth
                />
              </Grid>

              {reports.map(
                (report, index) =>
                  report.description.includes(firstFilter) &&
                  reportsToCompare?.reportTo?.id !== report.id &&
                  report.canSeeReport && (
                    <Grid item key={report.id} sm={12}>
                      <ReportsCard
                        key={report.id}
                        title={`${report.isTeam360 ? "Team 360" : "Benchmark"} REPORT #${
                          report.description.split(" - ")[0].split("#")[1]
                        }`}
                        subtitle={report.description.split(" - ")[1]}
                        icon="fa-chart-pie"
                        actionIcon="fa-check-circle"
                        onClick={() => selectFirstComparison(report)}
                        selected={reportsToCompare?.reportFrom?.id === report.id}
                        noBorder
                      />
                      {index !== reports.length - 1 && <Divider />}
                    </Grid>
                  )
              )}
            </Grid>
          </div>
          <div className="cel">
            <Grid
              container
              direction="column"
              component="form"
              mb={2}
              sx={{
                border: `1px solid ${grey[100]}`,
                borderRadius: 2,
                p: 2,
              }}
            >
              <Grid item sm={12} mb={2}>
                <TextField
                  required
                  placeholder="Type here..."
                  id="outlined-required"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setSecondFilter(e.target.value)}
                  fullWidth
                />
              </Grid>

              {reports.map(
                (report, index) =>
                  report.description.includes(secondFilter) &&
                  reportsToCompare?.reportFrom?.id !== report.id &&
                  report.canSeeReport && (
                    <Grid item key={report.id} sm={12}>
                      <ReportsCard
                        key={report.id}
                        title={`${report.isTeam360 ? "Team 360" : "Benchmark"} REPORT #${
                          report.description.split(" - ")[0].split("#")[1]
                        }`}
                        subtitle={report.description.split(" - ")[1]}
                        icon="fa-chart-pie"
                        actionIcon="fa-check-circle"
                        onClick={() => selectSecondComparison(report)}
                        selected={reportsToCompare?.reportTo?.id === report.id}
                        noBorder
                      />
                      {index !== reports.length - 1 && <Divider />}
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

CompareTable.propTypes = {
  reports: PropTypes.array,
  updateAssessmentCompare: PropTypes.func,
  reportsToCompare: PropTypes.object,
};

const mapStateToProps = ({ reports, benchmarking }) => {
  return {
    reports: reports.reports,
    reportsToCompare: benchmarking.reportsToCompare,
  };
};

export default connect(mapStateToProps, { updateAssessmentCompare })(CompareTable);
