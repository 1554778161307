import React from "react";
import ReactDOM from "react-dom";
import store from "./utils/store";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// fetch polyfill
import "whatwg-fetch";

import * as serviceWorker from "./serviceWorker";

import "./assets/_shared.scss";
import "./index.css";

import App from "./App";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
