import { INDICATORS } from "../../../constants/metrics";
export const REPORT_TEXTS = {
  default: {
    [INDICATORS.TEAM_SYNERGY]: {
      title: "Your Team’s Synergy Score",
      subtitle: [
        "Welcome to your TeamLytica dashboard. It’s packed full of really useful information generated through the responses of your team. Click any of the bars below to explore your category scores.",
        "Each tab give you access to further detailed insights into how the team feel they work together including the open text comments from the team.",
      ],
    },
    [INDICATORS.STRESS]: {
      title: "Your TeamLytica Stress Index",
      subtitle: [
        "Stress is a critical driver of how teams and individuals work together. This section provides you with a stress indicator for your team and is based on their responses within the questionnaire.",
        "The commentary bellow will give you an indication of where focus should be given to help the team manage its stress levels.",
      ],
    },
    [INDICATORS.MORALE]: {
      title: "Your TeamLytica Morale Index",
      subtitle: [
        "This page gives you a Morale indicator for your team which has been graded as either negative, neutral or positive. Use the details and commentary below to work out a plan on how to support your team and boost its morale to boost team cohesion and performance.",
      ],
    },
  },
  benchmark: {
    [INDICATORS.TEAM_SYNERGY]: {
      title: "Your Team’s Synergy Score",
      subtitle: [
        "Welcome to your TeamLytica benchmark dashboard. It’s packed full of really useful information generated through the responses of your team across two reports. Click any of the bars below to explore and compare your category, disparity and question scores for each report.",
        "You’ll see dated scores that either show improvements or, where elements have slipped back. Scroll down beneath the question scores to see the system-generated commentary and actions which will relate to the latest report. ",
      ],
    },
    top_10: {
      title: "Your Top Scoring Questions",
      subtitle: [
        "These are your six highest-scoring questions from the team taken from your first report within the date range. We've then compared these against your latest results and compared the two scores. This will give you a good indication of what the team thinks it does well and how it's progressed these scores over time. Results may have remained static or dipped which could steer you towards where your focus needs to be placed.",
      ],
    },
    urgent_actions: {
      title: "Urgent Actions",
      subtitle: [
        "The scores below represent the three six lowest-scoring questions from your first compared to how they've been scored in the latest version. The scores are colour-coded for ease of understanding and will show they have either improved stayed roughly the same or dipped below the first score. Clearly, you should be looking for progress and should celebrate where this has happened. When the team scores have slipped back, this might be an area to focus on going forward.",
      ],
    },
    comments: {
      title: "Comments",
      subtitle: [
        "Within the questionnaire are three questions that ask your stakeholders to comment on your team. We ask them what they feel the team does well, what it can improve upon and to suggest three ‘quick wins’ that the team could implement to improve team working or performance.",
        "The comments are written verbatim as the platform does not edit or amend what’s been written in any way. It may include errors, spelling mistakes and very occasionally, inappropriate language.",
        "Use the date range toggle button top right to select which reports you’d like to compare and contrast.",
      ],
    },
  },
  team360: {
    [INDICATORS.TEAM_SYNERGY]: {
      title: "Your Team360 Cohesion Index",
      subtitle: [
        "Welcome to your TeamLytica Team360 report. This report is shown is isolation to your other reports to give you an overview of what your stakeholders think of how your team operates. At any time you can use the benchmarking function to compare these results with any of your own team cohesion reports.",
      ],
    },
    [INDICATORS.STRESS]: {
      title: "Your Team360 Stress Indicator",
      subtitle: [
        "Stress is a critical driver of how teams and individuals work together. By measuring and understanding stress you can adjust your approach, delegation and working practices to get the best from your team. Managing stress to the right level will ensure you get optimum performance from your team.",
      ],
    },
    [INDICATORS.MORALE]: {
      title: "Your Team360 Morale Indicator",
      subtitle: [
        "Team morale means that that there is an abundance of drive, self-esteem, assurance and a generally positive outlook in the team. This will be enhanced through self-belief and positive humour that enables the team to enjoy what they do and be confident in their abilities.",
        "When a team has good morale, there will be an excitement and enthusiasm for their work and performance should be high.",
      ],
    },
  },
  notfullReport: {
    [INDICATORS.TEAM_SYNERGY]: {
      title: "Your Free TeamLytica Summary Report",
      subtitle: [
        "Welcome to your TeamLytica dashboard. This is the free version of the platform so contains only your Cohesion score and the nine category scores shown below. If you require a more detailed report, then click on any of the upgrade buttons or pages for full access. You can download a copy of the summary report using the PDF button on the top right of this page.",
      ],
    },
  },
};
