/*eslint no-magic-numbers: 0*/
import { questionnaireService } from "../services/questionnaire.service";

import {
  setGlobalLoadingOff,
  setGlobalLoadingOn
} from "./globalLoading.action";

export const actions = {
  FETCH_QUESTIONNAIRE_REQUEST: "FETCH_QUESTIONNAIRE_REQUEST",
  FETCH_QUESTIONNAIRE_SUCCESS: "FETCH_QUESTIONNAIRE_SUCCESS",
  FETCH_QUESTIONNAIRE_FAILURE: "FETCH_QUESTIONNAIRE_FAILURE",
  SUBMIT_QUESTIONNAIRE_REQUEST: "SUBMIT_QUESTIONNAIRE_REQUEST",
  SUBMIT_QUESTIONNAIRE_SUCCESS: "SUBMIT_QUESTIONNAIRE_SUCCESS",
  SUBMIT_QUESTIONNAIRE_FAILURE: "SUBMIT_QUESTIONNAIRE_FAILURE"
};

export const questionnaireActions = {
  fetchQuestionnaire,
  submitQuestionnaire
};

const openTextTitles = [
  "What should we stop doing?",
  "What should we start doing?",
  "What should we continue doing?"
];

function fetchQuestionnaire(assessmentId, code) {
  return dispatch => {
    dispatch(fetchQuestionnaireRequest());

    questionnaireService.fetchQuestionnaire(assessmentId, code).then(
      questionnaire => {
        dispatch(
          fetchQuestionnaireSuccess(
            _getQuestionsFromQuestionnaire(questionnaire)
          )
        );
      },
      error => {
        dispatch(fetchQuestionnaireFailure(error));
      }
    );
  };

  function fetchQuestionnaireRequest() {
    return { type: actions.FETCH_QUESTIONNAIRE_REQUEST };
  }

  function fetchQuestionnaireSuccess(questionnaire) {
    return {
      type: actions.FETCH_QUESTIONNAIRE_SUCCESS,
      questionnaire
    };
  }

  function fetchQuestionnaireFailure(error) {
    return {
      type: actions.FETCH_QUESTIONNAIRE_FAILURE,
      error
    };
  }
}

function submitQuestionnaire(questionnaire, assessmentId, code) {
  return dispatch => {
    dispatch(submitQuestionnaireRequest());
    dispatch(setGlobalLoadingOn());

    const questions = questionnaire.slice(1, questionnaire.length);

    questionnaireService
      .submitQuestionnaire(questions, assessmentId, code)
      .then(
        success => {
          dispatch(submitQuestionnaireSuccess(success));
          dispatch(setGlobalLoadingOff());
        },
        error => {
          dispatch(submitQuestionnaireFailure(error));
          dispatch(setGlobalLoadingOff());
        }
      );
  };

  function submitQuestionnaireRequest() {
    return { type: actions.SUBMIT_QUESTIONNAIRE_REQUEST };
  }

  function submitQuestionnaireSuccess(questionnaire) {
    return {
      type: actions.SUBMIT_QUESTIONNAIRE_SUCCESS,
      questionnaire
    };
  }

  function submitQuestionnaireFailure(error) {
    return {
      type: actions.SUBMIT_QUESTIONNAIRE_FAILURE,
      error
    };
  }
}


function _getQuestionsFromQuestionnaire(questionnaire) {
  var openText = 0;
  if (!questionnaire) return {};

  var categoryQuestions = questionnaire.categories.flatMap(category => category.questions.map(question => ({
    category_question_description: category.category_question_description,
    category_name: category.category_name,
    category_order: category.category_order,
    question_custom_title:
      question.question_answer_type === "openText" || question.question_answer_type === "threeInputs"
        ? openTextTitles[openText++]
        : null,
    ...question
  })));

  var openTextQuestions = categoryQuestions.filter(question => ["openText", "threeInputs"].includes(question.question_answer_type));

  var nonOpenTextQuestions = categoryQuestions.filter(question => !["openText", "threeInputs"].includes(question.question_answer_type));

  const questions = [...nonOpenTextQuestions, ...openTextQuestions];

  return {
    assessment_id: questionnaire.assessment_id,
    member_name: questionnaire.member_name,
    member_id: questionnaire.member_id,
    team_name: questionnaire.team_name,
    questions: [
      { question_answer_type: "start" },
      ...questions,
      { question_answer_type: "end" }
    ]
  };
}
