import { actions } from "../actions/teamReview.action";

const initialState = {
  isLoading: false,
  teamMembers: undefined,
  areasByRegions: [],
  teamsByAreas: []
};

export default function teamReviewReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TEAM_MEMBERS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembers: [...action.payload],
        isLoading: false,
      };
    case actions.FETCH_AREAS_BY_REGIONS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_AREAS_BY_REGIONS_SUCCESS:
      return {
        ...state,
        areasByRegions: action.payload,
        isLoading: false,
      };
    case actions.FETCH_TEAMS_BY_AREAS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_TEAMS_BY_AREAS_SUCCESS:
      return {
        ...state,
        areasByRegions: action.payload,
        isLoading: false,
      };
    case actions.RETURN_TEAM_MEMBERS_TO_UNDEFINED:
      return {
        ...state,
        teamMembers: undefined,
        isLoading: false,
      };
    default:
      return state;
  }
}
