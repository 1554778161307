import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

//components
import Checkbox from "../../../../components/Checkbox";
import Dropdown from "../../../../components/Dropdown";
import PageLoading from "../../../../components/PageLoading";
import TextField from "../../../../components/TextField";
import ProfileProgress from "../../components/ProfileProgress";

//utils
import { getFormsValidityStatus } from "../../../../store/services";
import { notifyError } from "../../../../utils/notifications";
import * as data from "./data";

// actions
import { fetchCountries, updateAccount } from "../../../../store/actions/general.action";
import { setNavigationAssets } from "../../../../store/actions/navigation.action";
import {
  addReseller,
  deleteReseller,
  fetchReseller,
} from "../../../../store/actions/reseller.action";

import { hasPermission } from "../../../../store/helpers/userHelper";

// scss
import PageContent from "../../../../components/PageContent";
import PageTitle from "../../../../components/PageTitle";
import "./Profile.scss";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountFields: [
        { data: data.firstNameData },
        { data: data.surnameData },
        { data: data.emailData },
        { data: data.companyNameData },
        { data: data.emailOffersData },
      ],

      locationFields: [
        { data: data.countryData },
        { data: data.townCityData },
        { data: data.postCodeData },
      ],

      resellerField: { data: data.resellerData },
      isResellerSubmitted: false,
      isFormSubmitted: false,
      reseller: props.reseller && props.reseller.email ? props.reseller.email : "",
      account: this.props.content,
      progress: 0,
    };
    this.formStatus = {};
    this.resellerStatus = {};
  }

  componentDidMount() {
    const navigation = {
      title: "PROFILE",
      subtitle: "YOUR PROFILE",
      tab: "YOUR PROFILE",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchCountries();
    this.props.fetchReseller();
  }

  componentWillReceiveProps(nextProps) {
    const { reseller } = nextProps;

    if (reseller && reseller.email) {
      if (this.state.reseller !== reseller.email) {
        this.setState({ reseller: reseller.email });
      }
    } else {
      this.setState({ reseller: "" });
    }
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    fieldName === "reseller"
      ? this.setState({ reseller: fieldValue })
      : this.setState((prevState) => ({
          account: { ...prevState.account, [fieldName]: fieldValue },
        }));
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    fieldName === "reseller"
      ? (this.resellerStatus[fieldName] = fieldStatus)
      : (this.formStatus[fieldName] = fieldStatus);
  };

  validateFields() {
    const { country_id } = this.state.account;
    return !(!country_id || country_id === "0");
  }

  handleAddReseller = () => {
    const isFormValid = getFormsValidityStatus(this.resellerStatus);
    const { reseller } = this.state;

    if (isFormValid) {
      this.props.addReseller(reseller);
      this.setState({ errorMessage: "" });
    } else {
      this.setState({
        isResellerSubmitted: true,
        errorMessage: "Please review your information.",
      });
    }
  };

  handleDeleteReseller = () => {
    const isFormValid = getFormsValidityStatus(this.resellerStatus);
    const resellerId = this.props.reseller.user_reseller_id;

    if (isFormValid) {
      this.props.deleteReseller(resellerId).then(() => this.props.fetchReseller());
    }
  };

  handleSubmit = () => {
    const isFormValid = getFormsValidityStatus(this.formStatus);

    if (isFormValid && this.validateFields()) {
      this.setState({ error: "" });
      const { account } = this.state;
      this.props.updateAccount(account);
      return;
    }
    this.setState({
      isFormSubmitted: true,
      error: "Form is invalid, please review your information.",
    });
    notifyError("Form is invalid, please review your information.");
  };

  renderComponentForField = (field) => {
    switch (field.data.name) {
      case "email_update_offers":
        return (
          <Checkbox
            data={{
              ...field.data,
              checked: this.state.account[field.data.name]
                ? this.state.account[field.data.name]
                : this.props.content[field.data.name]
                ? this.props.content[field.data.name]
                : !!this.props.content[field.data.name],
              disabled: !hasPermission(this.props.content.role),
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      case "country_id":
        return (
          <Dropdown
            data={{
              ...field.data,
              list: this.props.countries ? this.props.countries : field.data.list,
              value: this.state.account[field.data.name]
                ? this.state.account[field.data.name]
                : this.props.content[field.data.name]
                ? this.props.content[field.data.name]
                : field.data.value,
              disabled: !hasPermission(this.props.content.role),
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      default:
        return (
          <TextField
            data={{
              ...field.data,
              value: this.state.account[field.data.name]
                ? this.state.account[field.data.name]
                : this.props.content[field.data.name]
                ? this.props.content[field.data.name]
                : "",
              disabled: !hasPermission(this.props.content.role),
            }}
            customStyleClass="profile-page__forms-form_input"
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
            style={field.data.name === "post_code" ? { width: "35%" } : {}}
          />
        );
    }
  };

  render() {
    return (
      <PageLoading
        isLoading={this.props.isLoadingPage}
        render={() => (
          <div className="profile-page">
            <ProfileProgress value={this.props.content.progress} total={100} />
            <PageTitle title="Your Profile" />
            <PageContent>
              <div className="profile-page__forms">
                <div className="profile-page__forms-form">
                  <div className="profile-page__forms-form-title">
                    <span>Your TeamLytica Account Details:</span>
                  </div>
                  {this.state.accountFields.map((field) => (
                    <div key={field.data.id}>{this.renderComponentForField(field)}</div>
                  ))}
                </div>
                <div className="profile-page__forms-form">
                  <div className="profile-page__forms-form-title">
                    <span>Your Primary Work Location:</span>
                  </div>
                  {this.state.locationFields.map((field) => (
                    <div key={field.data.id}>{this.renderComponentForField(field)}</div>
                  ))}
                </div>
              </div>

              <div className="profile-page__footer">
                <div className="profile-page__footer-label">
                  <span>
                    Once you have updated your company information, remember to hit the save button!
                  </span>
                </div>

                <div className="profile-page__footer-button">
                  <button
                    className="save-button"
                    disabled={!hasPermission(this.props.content.role)}
                    onClick={this.handleSubmit}
                  >
                    Save Changes
                    <i className="fa fa-check" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </PageContent>
          </div>
        )}
      />
    );
  }
}

Profile.propTypes = {
  error: PropTypes.string,
  content: PropTypes.object,
  countries: PropTypes.array,
  isUpdated: PropTypes.bool,
  isLoadingCountries: PropTypes.bool,
  isLoadingPage: PropTypes.bool,
  setNavigationAssets: PropTypes.func,
  fetchCountries: PropTypes.func,
  updateAccount: PropTypes.func,
  reseller: PropTypes.object,
  addReseller: PropTypes.func,
  deleteReseller: PropTypes.func,
  fetchReseller: PropTypes.func,
};

const mapStateToProps = ({ general }) => {
  const isLoadingPage = general.isLoadingCountries || general.isLoadingReseller;

  return {
    content: general.content,
    countries: general.countries,
    isLoadingPage,
    isUpdated: general.isUpdated,
    error: general.error,
    reseller: general.reseller,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  fetchCountries,
  updateAccount,
  addReseller,
  deleteReseller,
  fetchReseller,
})(Profile);
