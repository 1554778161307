import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//components
import Dropdown from "../../../../components/Dropdown";
import PageLoading from "../../../../components/PageLoading";
import ProfileProgress from "../../components/ProfileProgress";
import TextField from "../../../../components/TextField";

//utils
import * as data from "./data";
import { getFormsValidityStatus } from "../../../../store/services";
import { notifyError } from "../../../../utils/notifications";

// actions
import { setNavigationAssets } from "../../../../store/actions/navigation.action";
import {
  fetchIndustries,
  fetchJobLevels,
  fetchDepartments,
  updateAccount
} from "../../../../store/actions/general.action";

import { hasPermission } from "../../../../store/helpers/userHelper";

// scss
import "./Company.scss";

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        { data: data.companyNameData },
        { data: data.industriesData },
        { data: data.departmentsData },
        { data: data.jobLevelsData }
      ],
      user: this.props.content,
      isFormSubmitted: false
    };
    this.formStatus = {};
  }

  componentDidMount() {
    const navigation = {
      title: "PROFILE",
      subtitle: "YOUR COMPANY",
      tab: "YOUR COMPANY"
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchIndustries();
    this.props.fetchJobLevels();
    this.props.fetchDepartments();
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState(prevState => ({
      user: { ...prevState.user, [fieldName]: fieldValue }
    }));
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  getDropdownList = field => {
    switch (field.data.name) {
      case "industry_id":
        return this.props.industries;
      case "department_id":
        return this.props.departments;
      case "job_level_id":
        return this.props.jobLevels;
      default:
        return [];
    }
  };

  validateFields() {
    const { industry_id, job_level_id, department_id } = this.state.user;

    if (
      !industry_id ||
      industry_id === "0" ||
      !job_level_id ||
      job_level_id === "0" ||
      !department_id ||
      department_id === "0"
    ) {
      return false;
    }
    return true;
  }

  handleSubmit = () => {
    const isFormValid = getFormsValidityStatus(this.formStatus);

    if (isFormValid) {
      if (this.validateFields()) {
        this.setState({ error: "" });
        const { user } = this.state;
        this.props.updateAccount(user);
        return;
      }
    }
    this.setState({
      isFormSubmitted: true,
      error: "Form is invalid, please review your information."
    });
    notifyError("Form is invalid, please review your information.");
  };

  renderComponentForField = field => {
    const { user } = this.state;

    switch (field.data.component) {
      case "textfield":
        return (
          <TextField
            data={{
              ...field.data,
              value:
                user && user[field.data.name]
                  ? user[field.data.name]
                  : field.data.value,
              disabled: !hasPermission(user.role)
            }}
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            data={{
              ...field.data,
              list: this.getDropdownList(field),
              value:
                user && user[field.data.name]
                  ? user[field.data.name]
                  : field.data.value,
              disabled: !hasPermission(user.role)
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { user } = this.state;

    return (
      <PageLoading
        isLoading={this.props.isLoading}
        render={() => (
          <div className="company-page">
            <ProfileProgress value={user.progress} total={100} />
            <div className="company-page__content">
              <div className="company-page__title">Your Company</div>
              <div className="company-page__form">
                <div className="company-page__form-title">
                  <span>Your Company Details:</span>
                </div>

                {this.state.fields.map(field => (
                  <div key={field.data.id}>
                    {this.renderComponentForField(field)}
                  </div>
                ))}
              </div>
              <div className="company-page__footer">
                <div className="company-page__footer-label">
                  <span>
                    Once you have updated your company information, remember to
                    hit the save button!
                  </span>
                </div>

                <div className="company-page__footer-button">
                  <button
                    className="save-button"
                    disabled={!hasPermission(user.role)}
                    onClick={this.handleSubmit}
                  >
                    Save Changes
                    <i className="fa fa-check" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

Company.propTypes = {
  content: PropTypes.object,
  isLoading: PropTypes.bool,
  industries: PropTypes.array,
  departments: PropTypes.array,
  jobLevels: PropTypes.array,
  setNavigationAssets: PropTypes.func,
  fetchIndustries: PropTypes.func,
  fetchJobLevels: PropTypes.func,
  fetchDepartments: PropTypes.func,
  updateAccount: PropTypes.func
};

function mapStateToProps(state) {
  const {
    content,
    departments,
    industries,
    jobLevels,
    isLoadingDepartments,
    isLoadingIndustries,
    isLoadingJobs
  } = state.general;

  const isLoadingFetches =
    isLoadingIndustries && isLoadingDepartments && isLoadingJobs;

  return {
    content,
    departments,
    industries,
    jobLevels,
    isLoadingFetches
  };
}

export default connect(
  mapStateToProps,
  {
    setNavigationAssets,
    fetchIndustries,
    fetchJobLevels,
    fetchDepartments,
    updateAccount
  }
)(Company);
