import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// scss
import "../../Questionnaire.scss";

class QuestionnaireStartPage extends Component {
  render() {
    const { memberName } = this.props;

    return (
      <Fragment>
        <div className="questionnaire-page__content-header">
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-title-pre">
              Hi {memberName}, Let&apos;s get started...
            </span>
          </div>
          <div className="questionnaire-page__content-header-sub-title">
            <p>This simple questionnaire takes about 20 minutes to complete.</p>
            <p>
              There are <b>9 sections</b> each with <b>6 questions</b>. Your
              progress will be shown at the bottom of the page.
            </p>
            <p className="mb-4_5">
              Please answer as honestly as possible and remember, there are no
              wrong answers and everything is completely confidential.
            </p>
          </div>
        </div>
        <div className="questionnaire-page__content-start">
          <div className="questionnaire-page__content-start-label">
            <span>Start Questionnaire</span>
          </div>
          <div className="questionnaire-page__content-start-actions">
            <button
              className="questionnaire-page__content-start-actions-yes"
              onClick={() => this.props.onNext()}
            >
              <span>Let&apos;s go</span>
              <i className="fa fa-thumbs-up" aria-hidden="true" />
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

QuestionnaireStartPage.propTypes = {
  onNext: PropTypes.func,
  memberName: PropTypes.string,
};

export default QuestionnaireStartPage;
