/*eslint no-magic-numbers: 0*/
import history from "../../utils/history";

import { authHeader } from "../helpers/authHeader";
import { handleErrors } from "../helpers/errorHandlers";

import { notifySuccess, notifyError } from "./../../utils/notifications";

import { authActions } from "./auth.action";
import { fetchReportStatus, fetchReports } from "./reports.action";
import { methods } from "../helpers/HttpHelper";

import { setGlobalLoadingOn, setGlobalLoadingOff } from "./globalLoading.action";

export const actions = {
  FETCH_ACCOUNT_CONTENT_REQUEST: "FETCH_ACCOUNT_CONTENT_REQUEST",
  FETCH_ACCOUNT_CONTENT_SUCCESS: "FETCH_ACCOUNT_CONTENT_SUCCESS",
  FETCH_ACCOUNT_CONTENT_FAILURE: "FETCH_ACCOUNT_CONTENT_FAILURE",

  FETCH_COUNTRIES_REQUEST: "FETCH_COUNTRIES_REQUEST",
  FETCH_COUNTRIES_SUCCESS: "FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_FAILURE: "FETCH_COUNTRIES_FAILURE",

  FETCH_CURRENCIES_REQUEST: "FETCH_CURRENCIES_REQUEST",
  FETCH_CURRENCIES_SUCCESS: "FETCH_CURRENCIES_SUCCESS",
  FETCH_CURRENCIES_FAILURE: "FETCH_CURRENCIES_FAILURE",

  FETCH_INDUSTRIES_REQUEST: "FETCH_INDUSTRIES_REQUEST",
  FETCH_INDUSTRIES_SUCCESS: "FETCH_INDUSTRIES_SUCCESS",
  FETCH_INDUSTRIES_FAILURE: "FETCH_INDUSTRIES_FAILURE",

  FETCH_JOB_LEVELS_REQUEST: "FETCH_JOB_LEVELS_REQUEST",
  FETCH_JOB_LEVELS_SUCCESS: "FETCH_JOB_LEVELS_SUCCESS",
  FETCH_JOB_LEVELS_FAILURE: "FETCH_JOB_LEVELS_FAILURE",

  FETCH_DEPARTMENTS_REQUEST: "FETCH_DEPARTMENTS_REQUEST",
  FETCH_DEPARTMENTS_SUCCESS: "FETCH_DEPARTMENTS_SUCCESS",
  FETCH_DEPARTMENTS_FAILURE: "FETCH_DEPARTMENTS_FAILURE",

  FETCH_ACCOUNT_PROGRESS_REQUEST: "FECTH_ACCOUNT_PROGRESS_REQUEST",
  FETCH_ACCOUNT_PROGRESS_SUCCESS: "FECTH_ACCOUNT_PROGRESS_SUCCESS",
  FETCH_ACCOUNT_PROGRESS_FAILURE: "FECTH_ACCOUNT_PROGRESS_FAILURE",

  FETCH_ACCOUNT_ACHIEVEMENTS_REQUEST: "FETCH_ACCOUNT_ACHIEVEMENTS_REQUEST",
  FETCH_ACCOUNT_ACHIEVEMENTS_SUCCESS: "FETCH_ACCOUNT_ACHIEVEMENTS_SUCCESS",
  FETCH_ACCOUNT_ACHIEVEMENTS_FAILURE: "FETCH_ACCOUNT_ACHIEVEMENTS_FAILURE",

  UPDATE_ACCOUNT_REQUEST: "UPDATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_FAILURE: "UPDATE_ACCOUNT_FAILURE",

  UPDATE_PROFILE_PHOTO_REQUEST: "UPDATE_PROFILE_PHOTO_REQUEST",
  UPDATE_PROFILE_PHOTO_SUCCESS: "UPDATE_PROFILE_PHOTO_SUCCESS",
  UPDATE_PROFILE_PHOTO_FAILURE: "UPDATE_PROFILE_PHOTO_FAILURE",
};

export function fetchAccountProgress() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts/progress`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAccountProgressRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchAccountProgressSuccess(mapProgress(json)));
        return json;
      })
      .catch((error) => {
        dispatch(fetchAccountProgressFailure(error));
      });
  };

  function fetchAccountProgressRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_PROGRESS_REQUEST,
      });
    };
  }

  function fetchAccountProgressSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_PROGRESS_SUCCESS,
        payload,
      });
    };
  }

  function fetchAccountProgressFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_PROGRESS_FAILURE,
        error,
      });
      dispatch(authActions.logout(() => history.push("/login")));
    };
  }
}

export function fetchAccountContent() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAccountContentRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem("userId", json.id);
        localStorage.setItem("companyId", json.company_id);
        json = addAccountProgress(json);
        dispatch(fetchAccountContentSuccess(json));
        dispatch(fetchReportStatus());
        dispatch(fetchReports());
        return json;
      })
      .catch((error) => {
        dispatch(fetchAccountContentFailure(error));
      });
  };

  function fetchAccountContentRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_CONTENT_REQUEST,
      });
    };
  }

  function fetchAccountContentSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_CONTENT_SUCCESS,
        payload,
      });
    };
  }

  function fetchAccountContentFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_CONTENT_FAILURE,
        error,
      });
      dispatch(authActions.logout(() => history.push("/login")));
    };
  }

  function addAccountProgress(json) {
    if (json.country_id) {
      json.progress = 100;
      return json;
    }
    json.progress = 50;
    return json;
  }
}

export function fetchCountries() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/countries`;

  const requestOptions = {
    method: methods.GET,
  };

  return (dispatch) => {
    dispatch(fetchCountriesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const result = [{ id: 0, description: "Please select your country*" }];
        json.map((option) => {
          return result.push(option);
        });
        dispatch(fetchCountriesSuccess(result));
        return json;
      })
      .catch((error) => dispatch(fetchCountriesFailure(error)));
  };

  function fetchCountriesRequest() {
    return { type: actions.FETCH_COUNTRIES_REQUEST };
  }
  function fetchCountriesSuccess(payload) {
    return {
      type: actions.FETCH_COUNTRIES_SUCCESS,
      payload,
    };
  }
  function fetchCountriesFailure(error) {
    return {
      type: actions.FETCH_COUNTRIES_FAILURE,
      error,
    };
  }
}

export function fetchCurrencies() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/currencies`;

  const requestOptions = {
    method: methods.GET,
  };

  return (dispatch) => {
    dispatch(fetchCurrenciesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const result = [
          {
            id: null,
            country_id: null,
            description: "Please select your currency...",
            code: "",
          },
        ];
        json.map((option) => {
          return result.push(option);
        });
        dispatch(fetchCurrenciesSuccess(result));
        return json;
      })
      .catch((error) => dispatch(fetchCurrenciesFailure(error)));
  };

  function fetchCurrenciesRequest() {
    return { type: actions.FETCH_CURRENCIES_REQUEST };
  }
  function fetchCurrenciesSuccess(payload) {
    return {
      type: actions.FETCH_CURRENCIES_SUCCESS,
      payload,
    };
  }
  function fetchCurrenciesFailure(error) {
    return {
      type: actions.FETCH_CURRENCIES_FAILURE,
      error,
    };
  }
}

export function fetchIndustries() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/industries`;

  const requestOptions = {
    method: methods.GET,
  };

  return (dispatch) => {
    dispatch(fetchIndustriesRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const result = [
          {
            id: 0,
            description: "What does your company do?*",
          },
        ];
        json.map((option) => {
          return result.push(option);
        });
        dispatch(fetchIndustriesSuccess(result));
        return json;
      })
      .catch((error) => dispatch(fetchIndustriesFailure(error)));
  };

  function fetchIndustriesRequest() {
    return { type: actions.FETCH_INDUSTRIES_REQUEST };
  }
  function fetchIndustriesSuccess(payload) {
    return {
      type: actions.FETCH_INDUSTRIES_SUCCESS,
      payload,
    };
  }
  function fetchIndustriesFailure(error) {
    return {
      type: actions.FETCH_INDUSTRIES_FAILURE,
      error,
    };
  }
}

export function fetchJobLevels() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/job-levels`;

  const requestOptions = {
    method: methods.GET,
  };

  return (dispatch) => {
    dispatch(fetchJobLevelsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const result = [
          {
            id: 0,
            description: "What is your level within the business?*",
          },
        ];
        json.map((option) => {
          return result.push(option);
        });
        dispatch(fetchJobLevelsSuccess(result));
        return json;
      })
      .catch((error) => dispatch(fetchJobLevelsFailure(error)));
  };

  function fetchJobLevelsRequest() {
    return { type: actions.FETCH_JOB_LEVELS_REQUEST };
  }
  function fetchJobLevelsSuccess(payload) {
    return {
      type: actions.FETCH_JOB_LEVELS_SUCCESS,
      payload,
    };
  }
  function fetchJobLevelsFailure(error) {
    return {
      type: actions.FETCH_JOB_LEVELS_FAILURE,
      error,
    };
  }
}

export function fetchDepartments() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/departments`;

  const requestOptions = {
    method: methods.GET,
  };

  return (dispatch) => {
    dispatch(fetchDepartmentsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const result = [
          {
            id: 0,
            description: "What does your team do within the business?*",
          },
        ];
        json.map((option) => {
          return result.push(option);
        });
        dispatch(fetchDepartmentsSuccess(result));
        return json;
      })
      .catch((error) => dispatch(fetchDepartmentsFailure(error)));
  };

  function fetchDepartmentsRequest() {
    return { type: actions.FETCH_DEPARTMENTS_REQUEST };
  }
  function fetchDepartmentsSuccess(payload) {
    return {
      type: actions.FETCH_DEPARTMENTS_SUCCESS,
      payload,
    };
  }
  function fetchDepartmentsFailure(error) {
    return {
      type: actions.FETCH_DEPARTMENTS_FAILURE,
      error,
    };
  }
}

export function updateAccount(data) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts`;

  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.PUT,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(updateAccountRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(updateAccountSuccess(json));
        dispatch(fetchAccountContent());
        return json;
      })
      .catch((error) => dispatch(updateAccountFailure(error)));
  };

  function updateAccountRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ACCOUNT_REQUEST,
      });
      dispatch(setGlobalLoadingOn());
    };
  }
  function updateAccountSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ACCOUNT_SUCCESS,
        payload,
      });
      dispatch(setGlobalLoadingOff());
      notifySuccess("Your account was updated with success");
    };
  }
  function updateAccountFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ACCOUNT_FAILURE,
        error,
      });
      dispatch(setGlobalLoadingOff());
      notifyError(error.message || error);
    };
  }
}

export function updateProfilePhoto(photo) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts/update-photo`;

  const data = { photo_path: photo };
  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(updateProfilePhotoRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(updateProfilePhotoSuccess(json));
        dispatch(fetchAccountContent());
        return json;
      })
      .catch((error) => dispatch(updateProfilePhotoFailure(error)));
  };

  function updateProfilePhotoRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PROFILE_PHOTO_REQUEST,
      });
      dispatch(setGlobalLoadingOn());
    };
  }
  function updateProfilePhotoSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PROFILE_PHOTO_SUCCESS,
        payload,
      });
      dispatch(setGlobalLoadingOff());
      notifySuccess("Your profile photo was updated with success");
    };
  }
  function updateProfilePhotoFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PROFILE_PHOTO_FAILURE,
        error,
      });
      dispatch(setGlobalLoadingOff());
      notifyError(error.message || error);
    };
  }
}

export function fetchAccountAchievements() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts/achievements`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAccountAchievementsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchAccountAchievementsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchAccountAchievementsFailure(error));
      });
  };

  function fetchAccountAchievementsRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_ACHIEVEMENTS_REQUEST,
      });
    };
  }

  function fetchAccountAchievementsSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_ACHIEVEMENTS_SUCCESS,
        payload,
      });
    };
  }

  function fetchAccountAchievementsFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_ACCOUNT_ACHIEVEMENTS_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

function mapProgress(progress) {
  const myProgress = [];

  Object.entries(progress).map((keyValue) => {
    switch (keyValue[0]) {
      case "setup_team":
        return myProgress.push({
          order: 1,
          state: keyValue[1],
        });
      case "questionnaire_results":
        return myProgress.push({
          order: 2,
          state: keyValue[1],
        });
      case "summary_report":
        return myProgress.push({
          order: 3,
          state: keyValue[1],
        });
      default:
        return null;
    }
  });

  myProgress.sort((a, b) => {
    return a.order - b.order;
  });

  myProgress.map((step, key) => {
    if (step.state) return (step.color = "green");
    else if ((myProgress[key - 1] && myProgress[key - 1].state) || !myProgress[key - 1])
      return (step.color = "blue");
    else return (step.color = "grey");
  });

  return myProgress;
}
