import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import DeleteButton from "../../../../../components/DeleteButton/DeleteButton";

const TableOptions = ({
  tableMeta,
  handleTeamMemberDialogOpen,
  allMembers,
}) => {
  const isCompleted = tableMeta.rowData[5] === 3;
  const memberId = tableMeta.rowData[0];
  const member = allMembers.find((member) => member.id === memberId);

  const canRecieveQuestionnaire = !isCompleted && member.is_stock_available;

  return (
    <div className="team-page__table-options">
      <button
        disabled={!canRecieveQuestionnaire}
        title="Send"
        className="team-page__table-options-send"
        onClick={() => handleTeamMemberDialogOpen(tableMeta.rowData, "send")}
      >
        <i className="fa fa-paper-plane" />
      </button>
      <button
        disabled={isCompleted}
        title="Edit"
        className="team-page__table-options-edit"
        onClick={() => handleTeamMemberDialogOpen(tableMeta.rowData, "edit")}
      >
        <i className="fa fa-edit" aria-hidden="true" />
      </button>
      <DeleteButton
        disabled={isCompleted}
        title="Delete"
        onClick={() => handleTeamMemberDialogOpen(tableMeta.rowData, "delete")}
      ></DeleteButton>
    </div>
  );
};

TableOptions.propTypes = {
  tableMeta: PropTypes.object,
  handleTeamMemberDialogOpen: PropTypes.func,
  teamMemberSlots: PropTypes.number,
  allMembers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  teamMemberSlots: state.team.teamMemberSlots,
  allMembers: state.team.allMembers,
});

export default connect(mapStateToProps)(TableOptions);
