export const companyNameData = {
  id: 1,
  value: "",
  name: "company_name",
  label: "",
  placeholder: "Company name*",
  type: "text",
  required: true,
  validate: true,
  component: "textfield",
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const industriesData = {
  id: 2,
  value: 0,
  name: "industry_id",
  list: [],
  component: "dropdown",
  required: true,
  validate: true
};

export const departmentsData = {
  id: 3,
  value: 0,
  name: "department_id",
  list: [],
  component: "dropdown",
  required: true,
  validate: true
};

export const jobLevelsData = {
  id: 4,
  value: 0,
  name: "job_level_id",
  list: [],
  component: "dropdown",
  required: true,
  validate: true
};
