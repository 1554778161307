export const firstNameData = {
  id: 1,
  value: "",
  name: "first_name",
  label: "First name*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const surnameData = {
  id: 2,
  value: "",
  name: "surname",
  label: "Surname*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const emailData = {
  id: 3,
  value: "",
  name: "email",
  label: "Email Address*",
  type: "email",
  placeholder: "",
  required: true,
  validate: false,
  disabled: true
};

export const companyNameData = {
  id: 4,
  value: "",
  name: "company_name",
  label: "Company name*",
  placeholder: "",
  type: "text",
  required: false,
  validate: true,
  disabled: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const townCityData = {
  id: 5,
  value: "",
  name: "town_city",
  label: "Town/City*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const postCodeData = {
  id: 6,
  value: "",
  name: "post_code",
  label: "Postcode/Zipcode*",
  placeholder: "",
  type: "text",
  helpDescription:
    "Use the post code most relevant to the team. If the team works remotely then use your location as manager.",
  helpButton: true,
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const countryData = {
  id: 7,
  value: 0,
  name: "country_id",
  label: "Country*",
  list: [],
  component: "dropdown",
  required: true,
  validate: true
};

export const emailOffersData = {
  id: 8,
  checked: true,
  name: "email_update_offers",
  label: "TeamLytica Insights",
  component: "checkbox"
};

export const resellerData = {
  id: 9,
  value: "",
  name: "reseller",
  label: "",
  placeholder: "",
  type: "text",
  component: "textfield",
  validate: true,
  required: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "regex",
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      priority: 2,
      message: "Invalid email"
    },
    {
      validationName: "minLength",
      value: 8,
      priority: 3,
      message: `Minimum required length is 8 characters`
    },
    {
      validationName: "maxLength",
      value: 50,
      priority: 4,
      message: `Maximum allowed length is 50 characters`
    }
  ]
};
