import PropTypes from "prop-types";
import React from "react";

import "./DeleteButton.scss";

const DeleteButton = (props) => (
  <button className="delete-button" {...props}>
    {props.children}
    <i className="fa fa-trash" aria-hidden="true" />
  </button>
);

DeleteButton.propTypes = {
  children: PropTypes.node,
};

export default DeleteButton;
