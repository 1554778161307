export function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.access_token) {
    var header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.access_token}`,
    };
    if (user.role) header.role = user.role;
    return header;
  } else {
    return {};
  }
}
