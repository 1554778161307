import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

//components
import MemberDialog from "../../components/MemberDialog";
import PageLoading from "../../components/PageLoading";

// actions
import { setNavigationAssets } from "../../store/actions/navigation.action";
import {
  createTeamMember,
  fetchTeam,
  getTeamName,
  updateTeamName,
} from "../../store/actions/team.action";

//utils
import * as data from "./data";

// scss
import { withRouter } from "react-router-dom";
import PageContent from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import "./Team.scss";
import TeamForm from "./components/TeamForm";
import TeamTable from "./components/TeamTable/TeamTable";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fields: [{ data: data.nameData }, { data: data.emailData }],
      searchField: { data: data.searchData },
      teamNameField: { data: data.teamData },
      isFormSubmitted: false,
      isAddMemberOpen: true,
      isTeamMemberDialogOpen: false,
      teamMemberDialogOptions: [],
      team: this.props.team,
      subtitle: data.SUBTITLE,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    const navigation = {
      title: this.isTeam360() ? "STAKEHOLDERS" : "YOUR TEAM",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchTeam(this.isTeam360());
  }

  componentWillReceiveProps(nextProps) {
    const { allMembers } = nextProps;
    const handledData = this.handleData(allMembers);
    if (this.state.data !== handledData) {
      this.setState({ data: handledData, email: "", name: "" });
    }
  }

  isTeam360() {
    return this.props.history.location.pathname.includes("stakeholders");
  }

  handleData = (data) => {
    const handledData = [];

    data &&
      data.map((member) => {
        const memberData = [];
        memberData.push(
          member.id,
          member.name,
          member.email,
          member.status,
          member.added_on,
          member.status_id,
          member.team_id
        );
        return handledData.push(memberData);
      });

    return handledData;
  };

  handleTeamMemberDialogClose = () => {
    this.setState({ isTeamMemberDialogOpen: false });
  };

  handleTeamMemberDialogOpen = (tableMeta, option) => {
    this.setState({
      isTeamMemberDialogOpen: true,
      teamMemberDialogOptions: [tableMeta, option],
    });
  };

  getTableTitle() {
    if (this.isTeam360()) {
      return "Stakeholders";
    }

    return `Team Members`;
  }

  render() {
    const { isTeamMemberDialogOpen, data, teamMemberDialogOptions } =
      this.state;

    const { isLoadingPage } = this.props;

    return (
      <PageLoading
        isLoading={isLoadingPage}
        render={() => (
          <>
            <PageTitle
              title={`${
                data?.length > 0 ? "Set up - Edit" : "Set up - Add Your"
              } ${this.isTeam360() ? " Stakeholders" : " Team Members"}`}
              subtitle={
                this.isTeam360()
                  ? this.state.subtitle.t360
                  : this.state.subtitle.noT360
              }
            >
              <MemberDialog
                open={isTeamMemberDialogOpen}
                onClose={this.handleTeamMemberDialogClose}
                options={teamMemberDialogOptions}
                isTeam360={this.isTeam360()}
              />
            </PageTitle>
            <PageContent>
              <TeamForm
                teamId={this.props.teamId}
                team={this.props.team}
                updateTeamName={this.props.updateTeamName}
                handleTeamMemberDialogOpen={this.handleTeamMemberDialogOpen}
              />
              <TeamTable
                title={this.getTableTitle()}
                teamId={this.props.teamId}
                teamMembers={data}
                handleTeamMemberDialogOpen={this.handleTeamMemberDialogOpen}
              />
            </PageContent>
          </>
        )}
      />
    );
  }
}

Team.propTypes = {
  allMembers: PropTypes.array,
  createTeamMember: PropTypes.func,
  updateTeamName: PropTypes.func,
  getTeamName: PropTypes.func,
  error: PropTypes.string,
  fetchTeam: PropTypes.func,
  isLoadingPage: PropTypes.bool,
  user: PropTypes.object,
  reports: PropTypes.array,
  teamId: PropTypes.number,
  reportStatus: PropTypes.object,
  setNavigationAssets: PropTypes.func,
  history: PropTypes.object,
  team: PropTypes.string,
  teamMemberSlots: PropTypes.number,
};

const mapStateToProps = ({ team, reports, general }) => {
  return {
    allMembers: team.allMembers,
    teamId: team.team_id,
    team: team.team,
    teamMemberSlots: team.teamMemberSlots,
    isLoadingPage: team.isLoadingTeam,
    error: team.error,
    user: general.content,
    reports: reports.reports,
    reportStatus: reports.reportStatus,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setNavigationAssets,
    createTeamMember,
    fetchTeam,
    updateTeamName,
    getTeamName,
  })(Team)
);
