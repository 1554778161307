import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import WizardFooter from "../../../WizardFooter";
import WizardFormTable from "../../../WizardFormTable/WizardFormTable";
import { useSelector, useDispatch } from 'react-redux'

import './TeamMemberCreation.scss'
import { getTeamsAction } from "../../../../../../store/actions/wizard.action";
import { stepEnum } from "../../../../../../store/helpers/teamWizardHelper";

const TeamMemberCreation = ({
  teamMemberAmount,
  onChange,
  data,
  onNextStepClick,
  onBackStepClick,
}) => {
  const dispatch = useDispatch()
  const teams = useSelector((state) => state.teamWizard.teams);
  const companyId = useSelector((state) => state.teamWizard.companyId)
  const [savedTeams, setSavedTeams] = useState(teams)

  useEffect(() => {
    dispatch(getTeamsAction(companyId))
  }, [companyId])

  useEffect(() => {
    if (teams && teams.length > 0) {
      setSavedTeams(
        teams.map((team) => ({
          value: team.id,
          label: team.name,
          area: team.areaId,
          region: team.regionId
        }))
      );
    }
  }, [teams]);
  return (
    <div className="team-member-creation">
      <WizardFormTable
        initialRows={Number(teamMemberAmount)}
        columns={[
          { hidden: true },
          {
            hidden: false,
            icon: "fa-user",
            name: "Team Member Name",
            type: "textInput",
            property: "name",
            validate: (value) => {
              return value.length > 2;
            }
          },
          {
            hidden: false,
            icon: "fa-at",
            name: "E-mail Address",
            type: "textInput",
            property: "email",
            validate: (value) => {
              return value.length >= 1;
            }
          },
          {
            hidden: false,
            type: "label",
            property: "company",
            defaultValue: "@company.com"
          },
          {
            hidden: false,
            icon: "fa-users",
            name: "Team",
            type: "select",
            property: "team",
            options: savedTeams || [],
          },
        ]}
        data={data}
        onChange={(e) => {
          onChange([...e]);
        }}
      />
      <WizardFooter
        className="__last-step"
        title="Well done. Now you can review your Teams and then send your TeamLytica Questionnaires."
        buttonTitle="Review Teams"
        buttonIcon="fa-check"
        buttonColor="green"
        isFirstStep={false}
        backButtonColor="grey"
        backButtonOnClick={() => onBackStepClick(stepEnum.TeamMembersCreation)}
        backButtonTitle="Back"
        backButtonIcon="fa-solid fa-angle-left"
        onClick={onNextStepClick}
      />
    </div>
  );
};

TeamMemberCreation.propTypes = {
  teamMemberAmount: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
  onNextStepClick: PropTypes.func,
  teams: PropTypes.array,
  onBackStepClick: PropTypes.func,
};

export default TeamMemberCreation;
