import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PageLoading from "../../../../components/PageLoading";
import BottomInfo from "../../../../components/BottomInfo";
import CompareTable from "./components/CompareTable";

import { setNavigationAssets } from "../../../../store/actions/navigation.action";
import {
  compareCategoryAverages,
  compareQuestionAverages,
} from "../../../../store/actions/benchmarking.action";
import { useHistory } from "react-router-dom";
import PageContent from "../../../../components/PageContent";
import PageTitle from "../../../../components/PageTitle";

import * as data from "./data";

function CompareAndBenchmark({
  isLoadingPage,
  setNavigationAssets,
  reportsToCompare,
  compareCategoryAverages,
  compareQuestionAverages,
}) {
  const history = useHistory();

  const fetchReportsComparison = React.useCallback(async () => {
    compareCategoryAverages(
      reportsToCompare?.reportFrom?.id,
      reportsToCompare?.reportTo?.id
    );
    compareQuestionAverages(
      reportsToCompare?.reportFrom?.id,
      reportsToCompare?.reportTo?.id
    );
    history.push("/reports/team-synergy/compare");
  }, [reportsToCompare?.reportFrom?.id, reportsToCompare?.reportTo?.id]);

  React.useEffect(() => {
    const navigation = {
      title: "COMPARE AND BENCHMARK",
      subtitle: "COMPARE AND BENCHMARK",
      tab: "COMPARE AND BENCHMARK",
    };
    setNavigationAssets(navigation);
  }, []);

  const paragraphs = useMemo(() => data.PARAGRAPHS, [data]);

  return (
    <PageLoading
      isLoading={isLoadingPage}
      render={() => (
        <>
          <PageTitle
            title="Choose reports to compare"
            paragraphs={paragraphs}
          />
          <PageContent>
            <CompareTable />
            <BottomInfo
              buttonColor="lightBlue"
              buttonTitle="Compare"
              buttonIcon="fa-files-o"
              onClick={fetchReportsComparison}
              title="Choose two reports to compare from the menus above. Then click compare."
              disabled={
                !reportsToCompare.reportFrom || !reportsToCompare.reportTo
              }
            />
          </PageContent>
        </>
      )}
    />
  );
}

CompareAndBenchmark.propTypes = {
  setNavigationAssets: PropTypes.func,
  isLoadingPage: PropTypes.bool,
  reportsToCompare: PropTypes.object,
  compareCategoryAverages: PropTypes.func,
  compareQuestionAverages: PropTypes.func,
};

const mapStateToProps = ({ reports, benchmarking }) => {
  const isLoadingPage =
    reports.isLoadingCategoryAverages ||
    reports.isLoadingReports ||
    reports.isLoadingComments ||
    reports.isGeneratingPdf;

  return {
    isLoadingPage,
    reportsToCompare: benchmarking.reportsToCompare,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  compareCategoryAverages,
  compareQuestionAverages,
})(CompareAndBenchmark);
