import React from 'react';
import PropTypes  from 'prop-types';

import "./Button.scss";

const Button = ({onClick, color, disabled, title, icon, children}) => {
    return (
        <button onClick={onClick} className={`custom-button custom-button--${color}`} disabled={disabled}>
            <span>
                {title || children}
            </span>
            {icon && <i className={`fa ml-2 ${icon}`} />}
        </button>
    );
};

Button.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node
}

export default Button;