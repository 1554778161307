/*eslint no-magic-numbers: 0*/
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dialog as MuiDialog, DialogContent as MuiDialogContent } from "@mui/material";
import { withStyles } from '@mui/styles'

//components
import TextField from "../TextField";

// actions
import { deleteViewOnlyUser } from "../../store/actions/reseller.action";

//utils
import * as data from "../../pages/Team/data.js";

// scss
import "./ViewOnlyUserDialog.scss";


const Dialog = withStyles(() => ({
  root: {
    marginTop: "20px",
    backgroundColor: "rgba(0, 0, 0, 0)"
  },

  paper: {
    margin: "0px 10px 0px 10px",
    maxWidth: "800px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    boxShadow: "none"
  }
}))(MuiDialog);

const DialogContent = withStyles(() => ({
  root: {
    margin: "0px",
    padding: "0px",
    backgroundColor: "rgb(255, 255, 255)",
    border: "2px solid rgb(19,146,211)",
    borderRadius: "20px 0 20px 20px"
  }
}))(MuiDialogContent);

const HOCviewOnlyUserDialog = (props) => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  return <ViewOnlyUserDialog {...props} dispatch={dispatch} state={state} />
}

class ViewOnlyUserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [{ data: data.nameData }, { data: data.emailData }, {data: data.teamData}],
      isFormSubmitted: false,
      teams: [],
      viewOnlyUser: {}
    }
    this.formStatus = {};
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  handleDialogClose = () => {
    const { error, onClose } = this.props;
    if (!error) {
      onClose();
    }
  };

  handleDeleteViewOnlyUser = () => {
    const {  deleteViewOnlyUser,options, } = this.props;
    deleteViewOnlyUser(options[0][0], this.props.teamId).then(this.handleDialogClose);
  };

  handleOptionRendering = options => {
    switch (options[1]) {
      case "delete":
        return this.renderDeleteViewOnlyUser(options[0]);
      default:
        return <div>OOPS</div>;
    }
  };

  renderComponentForField = (field, data) => {
    return (
      <Fragment>
        {field.data.name === "team" ?
          <div >
            <div className="dropdown-label">Team</div> 
            <select
              onChange={(event) => this.setState({...this.state, team: event.target.value})}
              className="select"
              defaultValue={this.props.options[0][3]}
            >
              {this.state.teams.map(option => {
                return (
                  <option value={option.id} key={option.id}>
                    {option.description}
                  </option>
                );
              })}
            </select>
          </div> 
          :
          <TextField
            data={{
              ...field.data,
              value: field.data.name === "name" ? data[1] : data[2]
            }}
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
          />}

      </Fragment>
    );
  };


  renderDeleteViewOnlyUser = data => {
    return (
      <Fragment>
        <div className="view-only-user-dialog__content-title">
          <span>Delete View-only user &apos;{data[1]}&apos;</span>
        </div>
        <div className="view-only-user-dialog__content-sub-title">
          <span>Are you sure you want to delete this view-only user?</span>
        </div>
        <div className="view-only-user-dialog__content-actions">
          <button
            className="view-only-user-dialog__content-actions-cancel"
            onClick={this.props.onClose}
          >
            <i className="fa fa-ban" aria-hidden="true" />
            <span>No</span>
          </button>
          <button
            className="view-only-user-dialog__content-actions-danger"
            onClick={this.handleDeleteViewOnlyUser}
          >
            <span>Yes, Delete</span>
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </div>
      </Fragment>
    );
  };

  renderCloseButton = () => {
    return (
      <div className="view-only-user-dialog__close-container">
        <button
          className="view-only-user-dialog__close-button"
          onClick={this.props.onClose}
        >
          <span>
            Cancel <i className="fa fa-times-circle" />
          </span>
        </button>
      </div>
    );
  };

  render() {
    const { open, onClose, options } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        BackdropProps={{
          className: "view-only-user-dialog__backdrop"
        }}
      >
        {this.renderCloseButton()}
        <DialogContent>
          <div className="view-only-user-dialog__container">
            <div className="view-only-user-dialog__content">
              {this.handleOptionRendering(options)}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const TYPES = {
  deleteViewOnlyUser: PropTypes.func,
  error: PropTypes.string,
  history: PropTypes.object,
  isTeam360: PropTypes.bool,
  options: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.number,
  tableMeta: PropTypes.array,
}
HOCviewOnlyUserDialog.propTypes = TYPES
ViewOnlyUserDialog.propTypes = {
  ...TYPES,
  state: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  error: state.team.error,
  teamId: state.team.team_id && state.team.team_id,
});

export default connect(
  mapStateToProps,
  {deleteViewOnlyUser}
)(withRouter(HOCviewOnlyUserDialog));

