import { apiResource, apiResourceSegment } from "../../constants/ApiResources";

import { Get } from "../helpers/HttpHelper";
import { authHeader } from '../helpers/authHeader';
import { handleErrors } from '../helpers/errorHandlers';
import { notifySuccess, notifyError } from './../../utils/notifications';

export const actions = {
  FETCH_TEAM_MEMBERS_STARTED: 'FETCH_TEAM_MEMBERS_STARTED',
  FETCH_TEAM_MEMBERS_SUCCESS: 'FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_AREAS_BY_REGIONS_STARTED: 'FETCH_AREAS_BY_REGIONS_STARTED',
  FETCH_AREAS_BY_REGIONS_SUCCESS: 'FETCH_AREAS_BY_REGIONS_SUCCESS',
  FETCH_TEAMS_BY_AREAS_STARTED: 'FETCH_TEAMS_BY_AREAS_STARTED',
  FETCH_TEAMS_BY_AREAS_SUCCESS: 'FETCH_TEAMS_BY_AREAS_SUCCESS',

  DELETE_TEAM_MEMBER_REQUEST: 'DELETE_TEAM_MEMBER_REQUEST',
  DELETE_TEAM_MEMBER_SUCCESS: 'DELETE_TEAM_MEMBER_SUCCESS',
  DELETE_TEAM_MEMBER_FAILURE: 'DELETE_TEAM_MEMBER_FAILURE',

  RETURN_TEAM_MEMBERS_TO_UNDEFINED: 'RETURN_TEAM_MEMBERS_TO_UNDEFINED',
};


export function getTeamMembersAction(companyId) {
  return async (dispatch) =>  { 
    dispatch({ type: actions.FETCH_TEAM_MEMBERS_STARTED}); 
    const result = await Get(apiResource.companies, companyId, apiResourceSegment.teamMembers);
    dispatch({ type: actions.FETCH_TEAM_MEMBERS_SUCCESS, payload: result }); 
  };
}

export function getAreasByRegions(arrayRegions, filterName) {
  return async (dispatch) => {
    //dispatch({type: actions.FETCH_AREAS_BY_REGIONS_STARTED});
    const result = await Get(apiResource.areasFilter, null, null, arrayRegions, filterName )
    dispatch({ type: actions.FETCH_AREAS_BY_REGIONS_SUCCESS, payload: result });
  }
}

export function getTeamsByAreas(arrayAreas, filterName) {
  return async (dispatch) => {
    //dispatch({type: actions.FETCH_TEAMS_BY_AREAS_STARTED});
    const result = await Get(
      apiResource.teamsFilter,
      null,
      null,
      arrayAreas,
      filterName,
    );
    dispatch({ type: actions.FETCH_TEAMS_BY_AREAS_SUCCESS, payload: result });
  };
}

export function deleteTeamMember(id, teamId, team360 = false) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}/members/${id}?team360=${team360}`;

  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(deleteTeamMemberRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(deleteTeamMemberSuccess(json));
       // dispatch(fetchTeam(team360));
        return json;
      })
      .catch((error) => dispatch(deleteTeamMemberFailure(error)));
  };

  function deleteTeamMemberRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_TEAM_MEMBER_REQUEST,
      });
    };
  }
  function deleteTeamMemberSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_TEAM_MEMBER_SUCCESS,
        payload,
      });
      notifySuccess('Your team member was deleted with success');
    };
  }
  function deleteTeamMemberFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_TEAM_MEMBER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}
