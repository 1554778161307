import { authBody } from "../helpers/authBody";
import { authHeader } from "../helpers/authHeader";
import { methods } from "../helpers/HttpHelper";

import {
  handleConnection,
  handleServiceErrors,
} from "../helpers/errorHandlers";

export const authService = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  changePassword,
  refreshToken,
};

function login(username, password) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_AUTH_API_URL}/connect/token`;

  var params = {
    ...authBody(),
    grant_type: "password",
    refresh_token: "refresh_token",
    username,
    password,
  };

  const body = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  const requestOptions = {
    method: methods.POST,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body,
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    })
    .catch((error) => {
      return Promise.reject(handleConnection(error));
    });
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("userId");
}

function register(user) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts`;

  const requestOptions = {
    method: methods.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .catch((error) => {
      return Promise.reject(handleConnection(error));
    });
}

// FORGOT-PASSWORD
function forgotPassword(email) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_AUTH_API_URL}/account/ForgotPassword`;

  const params = {
    ...authBody(),
    email,
  };

  const requestOptions = {
    method: methods.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .catch((error) => Promise.reject(handleConnection(error)));
}

// RESET-PASSWORD
function resetPassword(userId, code, password, confirm_password) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_AUTH_API_URL}/account/ResetPassword`;

  const decodedCode = decodeURIComponent(decodeURIComponent(code));

  var params = {
    ...authBody(),
    userId,
    code: decodedCode,
    password,
    confirm_password,
  };

  const requestOptions = {
    method: methods.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .catch((error) => Promise.reject(handleConnection(error)));
}

// CHANGE-PASSWORD
function changePassword(current_password, new_password, confirm_new_password) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_AUTH_API_URL}/change-password`;

  var params = {
    ...authBody(),
    current_password,
    new_password,
    confirm_new_password,
  };

  const requestOptions = {
    method: methods.PUT,
    headers: authHeader(),
    body: JSON.stringify(params),
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .catch((error) => Promise.reject(handleConnection(error)));
}

// REFRESH-TOKEN
function refreshToken() {
  const user = JSON.parse(localStorage.getItem("user"));
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_AUTH_API_URL}/connect/token`;

  var params = {
    ...authBody(),
    grant_type: "refresh_token",
    refresh_token: user && user.refresh_token ? user.refresh_token : "",
  };

  const body = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  const requestOptions = {
    method: methods.POST,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body,
  };

  return fetch(apiUrl, requestOptions)
    .then(handleServiceErrors)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    })
    .catch((error) => Promise.reject(handleConnection(error)));
}
