/*eslint no-magic-numbers: 0*/
/*eslint react/no-deprecated: 0*/
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Dropdown from "../../../../components/Dropdown";
import PageContent from "../../../../components/PageContent";
import PageLoading from "../../../../components/PageLoading";
import PageTitle from "../../../../components/PageTitle";
import TabbarV2 from "../../../../components/TabbarV2";
import { SECTIONS } from "../../../../constants/sections";
import { tabColors } from "../../../../constants/tabs";
import ReportsFooter from "../../components/ReportsFooter";
import ReportsTabbar from "../../components/ReportsTabbar";

import {
  getSelectedReport,
  getVisibleReports,
} from "../../../../store/helpers/userHelper";

// actions
import { setNavigationAssets } from "../../../../store/actions/navigation.action";
import {
  fetchAssessmentComments,
  fetchCommentTypes,
  fetchReportStatus,
  fetchReports,
  updateSelectedReport,
} from "../../../../store/actions/reports.action";

//utils
import * as data from "./data";

//scss
import "./Comments.scss";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: "",
      comments: [],
      filteredComments: [],
      reportDropdown: { data: data.reportsData },
    };
  }

  componentDidMount() {
    const navigation = {
      title: "METRIC REPORTS",
      subtitle: SECTIONS.COMMENTS,
      tab: "YOUR REPORTS",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchCommentTypes();
    this.props.fetchAssessmentComments();
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue }, () => {
      this.props.updateSelectedReport(fieldValue).then(() => {
        this.props.fetchReports();
        this.props.fetchReportStatus();
        this.props.fetchAssessmentComments();
      });
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        comments: nextProps.comments ?? [],
        commentTypes: nextProps.commentTypes ?? this.props.commentTypes ?? [],
      },
      () => this.applyFilter("")
    );
  }

  isActive(filter) {
    return this.state.currentFilter === filter;
  }

  applyFilter(commentTypeIdFilter) {
    if (commentTypeIdFilter === this.state.currentFilter)
      this.setState({
        currentFilter: "",
        filteredComments: this.state.comments,
      });
    else {
      const filteredComments = this.state.comments.filter((comment) => {
        return comment.commentTypeId === commentTypeIdFilter;
      });
      this.setState({ currentFilter: commentTypeIdFilter, filteredComments });
    }
  }

  render() {
    const { isPageLoading, isTeam360, reports, commentTypes } = this.props;
    const { reportDropdown } = this.state;

    const options = commentTypes
      .sort((a, b) => a.order - b.order)
      .map((commentType, index) => {
        return {
          id: commentType.id,
          title: commentType.type,
          onClick: () => this.applyFilter(commentType.id),
          active: this.isActive(commentType.id) ? "active" : "",
          color: tabColors[index],
        };
      });

    var firstParagraph = "";
    var secondParagraph = "";
    let paragraphs = [];

    if (isTeam360) {
      firstParagraph = data.Paragraphs.IS_TEAM_360.firstParagraph;
      paragraphs.push(firstParagraph);
      secondParagraph = data.Paragraphs.IS_TEAM_360.secondParagraph;
      paragraphs.push(secondParagraph);
    } else {
      paragraphs.push(...data.Paragraphs.NOT_TEAM_360);
    }

    return (
      <PageLoading
        isLoading={isPageLoading}
        render={() => (
          <Fragment>
            <PageTitle
              title="Comments"
              paragraphs={paragraphs}
              paragraphsClassNames="parapgh-min-height"
              icon={
                <span className="paragraphs__icon">
                  <i className="fa fa-comments" />
                </span>
              }
            >
              {reports && reports.length > 0 && (
                <div className="header-select">
                  <Dropdown
                    data={{
                      ...reportDropdown.data,
                      list: getVisibleReports(reports) || [],
                      value: reports && getSelectedReport(reports),
                    }}
                    onSelect={this.updateFormFieldValue}
                  />
                </div>
              )}
            </PageTitle>
            <PageContent>
              <ReportsTabbar />
              <div className="mt-4_5">
                <TabbarV2 options={options} />
              </div>
              <div className="comment-wrapper">
                {this.state.filteredComments &&
                  this.state.filteredComments.map((comment, key) => (
                    <div className="comment" key={key}>
                      <span className="comment__text">{comment.answer}</span>
                    </div>
                  ))}
              </div>
              <ReportsFooter />
            </PageContent>
          </Fragment>
        )}
      />
    );
  }
}

Comments.propTypes = {
  setNavigationAssets: PropTypes.func,
  fetchAssessmentComments: PropTypes.func,
  fetchReportStatus: PropTypes.func,
  fetchReports: PropTypes.func,
  updateSelectedReport: PropTypes.func,
  fetchCommentTypes: PropTypes.func,
  isLoadingComments: PropTypes.bool,
  isLoadingReports: PropTypes.bool,
  isPageLoading: PropTypes.bool,
  comments: PropTypes.array,
  commentTypes: PropTypes.array,
  isTeam360: PropTypes.bool,
  reports: PropTypes.array,
};

const mapStateToProps = ({ reports }) => {
  const isPageLoading =
    reports.isLoadingCommentTypes ||
    reports.isLoadingComments ||
    reports.isLoadingReports;
  return {
    isPageLoading,
    comments: reports.comments ?? [],
    isTeam360:
      reports && reports.reportStatus && reports.reportStatus.is_team_360,
    reports: reports.reports,
    commentTypes: reports.commentTypes,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  updateSelectedReport,
  fetchReports,
  fetchReportStatus,
  fetchAssessmentComments,
  fetchCommentTypes,
})(Comments);
