import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  fetchCategoryAverages,
  fetchQuestionAverages,
  fetchReportStatus,
  fetchReports,
  getSelectedReport,
  updateSelectedReport,
} from "../../../../../../store/actions/reports.action";

import PageLoading from "../../../../../../components/PageLoading";
import ReportsCard from "../../../../components/ReportsCard";

import { setNavigationAssets } from "../../../../../../store/actions/navigation.action";

import PageTitle from "../../../../../../components/PageTitle";
import "./ReportList.scss";

import PageContent from "../../../../../../components/PageContent";
import * as data from "./data";

function ReportsList({
  isLoadingPage,
  reports,
  updateSelectedReport,
  fetchCategoryAverages,
  fetchQuestionAverages,
  fetchReportStatus,
  fetchReports,
  setNavigationAssets,
  getSelectedReport,
}) {
  const history = useHistory();
  const handleOnChooseReport = React.useCallback(async (id) => {
    await updateSelectedReport(id);
    await getSelectedReport(id);
    await fetchReportStatus();
    await fetchReports();
    await fetchCategoryAverages();
    await fetchQuestionAverages();
    history.push(`/reports/team-synergy`);
  }, []);

  React.useEffect(() => {
    const navigation = {
      title: "METRIC REPORTS",
      subtitle: "YOUR REPORTS",
      tab: "YOUR REPORTS",
    };
    setNavigationAssets(navigation);
  }, []);

  const paragraphs = useMemo(() => data.PARAGRAPHS, [data]);
  console.log("reports", reports);

  return (
    <PageLoading
      isLoading={isLoadingPage}
      render={() => (
        <>
          <PageTitle paragraphs={paragraphs} title="Your Teamlytica Reports" />
          <PageContent>
            <h5 className="divisor">All reports</h5>
            <div className="all-reports-column ">
              {reports.map(
                (report) =>
                  report.canSeeReport && (
                    <ReportsCard
                      key={report.id}
                      title={report.description.split(" - ")[0]}
                      subtitle={report.description.split(" - ")[1]}
                      icon="fa-chart-pie"
                      onClick={() => handleOnChooseReport(report.id)}
                    />
                  )
              )}
            </div>
          </PageContent>
        </>
      )}
    />
  );
}

ReportsList.propTypes = {
  isLoadingPage: PropTypes.bool,
  reports: PropTypes.array,
  updateSelectedReport: PropTypes.func,
  fetchCategoryAverages: PropTypes.func,
  fetchReportStatus: PropTypes.func,
  fetchReports: PropTypes.func,
  fetchQuestionAverages: PropTypes.func,
  setNavigationAssets: PropTypes.func,
  getSelectedReport: PropTypes.func,
};

const mapStateToProps = ({ reports }) => {
  const isLoadingPage =
    reports.isLoadingCategoryAverages ||
    reports.isLoadingReports ||
    reports.isLoadingComments ||
    reports.isGeneratingPdf;

  return {
    isLoadingPage,
    reports: reports.reports,
  };
};

export default connect(mapStateToProps, {
  updateSelectedReport,
  fetchCategoryAverages,
  fetchReportStatus,
  fetchReports,
  fetchQuestionAverages,
  setNavigationAssets,
  getSelectedReport,
})(ReportsList);
