import reducer from "../store";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";

import requestMiddleware from "../store/middleware/requestMiddleware";

// const logger = createLogger();
const store = createStore(
  reducer,
  // applyMiddleware(requestMiddleware, thunk, logger) // add logger in development only
  applyMiddleware(requestMiddleware, thunk)
);

export default store;
