import React from "react";
import PropTypes from "prop-types";
import "./PageContent.scss";
import clsx from "clsx";

const PageContent = ({ children, className }) => {
  return <div className={clsx("page-content", className)}>{children}</div>;
};

PageContent.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.node,
  paragraphs: PropTypes.array,
  className: PropTypes.string,
};

export default PageContent;
