import { renderDate } from "./uiHelper";

export function _mapReportsWithDescriptionAndSubDescription(reports) {
  const reportsList = [];

  reports.forEach(report => {
    const latest = report.completed ? "(latest)" : "";
    const description = `TLci Report${latest} #${report.id}`;
    const subDescription = `Created ${renderDate(report.date, "numeric")}`;

    reportsList.push({
      id: report.id,
      description,
      subDescription,
      selected: report.selected
    });
  });

  return reportsList;
}

export function _mapReportsWithDescription(reports) {
  const reportsList = [];

  reports.map(report => {
    const description = `${report.is_team_360 ? "TEAM360" : "REPORT"}#${report.id
      } - ${renderDate(report.date, "numeric")}`;

    return reportsList.push({
      id: report.id,
      description,
      selected: report.selected,
      completed: report.completed,
      isTeam360: report.is_team_360,
      canSeeReport: report.can_see_report
    });
  });

  return reportsList;
}

export function _mapCommentsWithCategory(comments) {
  const mappedComments = [];

  Object.keys(comments).map(key => {
    return comments[key].map(comment =>
      mappedComments.push({
        comment,
        type: key
      })
    );
  });

  return mappedComments;
}
