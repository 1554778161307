import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({
  layout: Layout,
  component: Component,
  isAuthenticated = false,
  options,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated ? (
          Layout ? (
            <Layout options={options}>
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    isAuthenticated: auth.isAuthenticated
  };
};

PrivateRoute.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  options: PropTypes.array
};

export default connect(mapStateToProps, null)(PrivateRoute);
