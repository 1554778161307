import React, { Component } from "react";
import PropTypes from "prop-types";

//utils
import { getProgress, getValueColor } from "../../store/helpers/uiHelper";

// scss
import "./ProgressBar.scss";

class ProgressBar extends Component {
  render() {
    const { label, value, total, color } = this.props;

    return (
      <div className="progress-bar">
        {label && (
          <label className="progress-bar__label-left">
            <span>{`${getProgress(value, total)}%`}</span> Completed
          </label>
        )}
        <div className="progress-bar__bar">
          <div
            className={`progress-bar__bar-progress ${color &&
              getValueColor(value)}`}
            style={{ width: `${getProgress(value, total)}%` }}
          />
          <div className="progress-bar__bar-mask">
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
            <div className="progress-bar__bar-mask-gap" />
          </div>
        </div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  label: PropTypes.bool,
  color: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ProgressBar;
