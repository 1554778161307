import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import "./ReportsCard.scss";

function ReportsCard(props) {
  const {
    selected = false,
    onClick,
    noBorder = false,
    icon,
    title,
    subtitle,
    actionIcon,
  } = props;

  const [isSelected, setIsSelected] = React.useState(selected || false);

  React.useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <div
      onClick={() => {
        setIsSelected(!isSelected);
        if (onClick) onClick();
      }}
      className={clsx("reports-card", { "no-border": noBorder })}
    >
      <div className="reports-card__body">
        <div className="mr-3">
          <i className={clsx("fa", icon, { active: isSelected })} />
        </div>
        <div>
          <h6>{title}</h6>
          <p>{subtitle}</p>
        </div>
      </div>
      {actionIcon && (
        <div>
          <i
            className={clsx("fa", {
              "fa-check-circle selected": isSelected,
              [actionIcon]: !isSelected,
            })}
          />
        </div>
      )}
    </div>
  );
}
ReportsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  actionIcon: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  noBorder: PropTypes.bool,
};

export default ReportsCard;
