export class FormTableRow {
    constructor(state = {}) {
      this._state = state;
      this._isDirty = false;
      this._isNew = true;
    }
  
    get state() {
      return this.getState();
    }
  
    get isDirty() {
      return this._isDirty;
    }
  
    set(property, value) {
      const currentValue = this._state[property];
  
      if (currentValue === value) return this;
  
      this.setIsDirty(true);
  
      this._state = {
        ...this._state,
        [property]: value
      };
  
      return this;
    }
  
    setIsNew(value) {
      this._isNew = value;
      return this;
    }
  
    setIsDirty(value = false) {
      this._isDirty = value;
  
      return this;
    }
  
    getState() {
      return this._state;
    }
  
    getData() {
      const { _isDirty, _isNew } = this;
  
      return {
        ...this.toJSON(),
        _isDirty,
        _isNew
      };
    }
  
    toJSON() {
      return this._state;
    }
  
    static create(data, isNew) {
      if (data instanceof FormTableRow) return data;
  
      const { _isDirty = false, _isNew = isNew, ...state } = data;
  
      const row = new FormTableRow(state);
  
      row.setIsDirty(_isDirty);
  
      if (_isNew != null) {
        row.setIsNew(_isNew);
      }
  
      return row;
    }
  
    static createFromArray(arr) {
      return arr.map((e) => FormTableRow.create(e, false));
    }
  }
  