export const reportsData = {
  id: 1,
  name: "allReports",
  label: "Choose your report",
};

export const Paragraphs = {
  IS_TEAM_360: {
    firstParagraph:
      "Within the questionnaire are three questions that ask the team for their comments about the team. We’ve asked them what they feel the team does well, what it can improve upon and anything else they feel would help the team develop further.",
    secondParagraph:
      "The comments are written verbatim as the platform does not edit or amend what’s been written by the team in any way. It may include errors, spelling mistakes and very occasionally, inappropriate language.",
  },
  NOT_TEAM_360: [
    "Within the questionnaire there were three open-text questions for the team to answer. We asked you what they should STOP doing, What they should START doing and what they should CONTINUE to do that will help the team develop further. The comments are written verbatim as the platform does not edit or amend what’s been written in any way. It may include errors, spelling mistakes and very occasionally, inappropriate language.",
    "Click on each tab below to see the appropriate comments."
  ],
};
