import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/Button";

import "./WizardFooter.scss";

function WizardFooter(props) {
  const { title, onClick, buttonColor, buttonTitle, buttonIcon, disabled, isFirstStep, backButtonColor, backButtonTitle, backButtonIcon, backButtonOnClick } =
    props;
  return (
    <div className={`wizard-footer ${isFirstStep ? '' : 'first-step'}`}>
      {!isFirstStep && 
        <Button
          className="custom-button--grey"
          onClick={backButtonOnClick}
          color={backButtonColor}
          disabled={disabled}
          title={backButtonTitle}
          icon={backButtonIcon} />}
        <span className="wizard-footer_title">{" "}{title}{" "}</span>
        <Button
          onClick={onClick}
          color={buttonColor}
          disabled={disabled}
          title={buttonTitle}
          icon={buttonIcon} />
    </div>
  );
}

WizardFooter.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonColor: PropTypes.string,
  disabled: PropTypes.bool,
  isFirstStep: PropTypes.bool,
  backButtonOnClick: PropTypes.func,
  backButtonTitle: PropTypes.string,
  backButtonIcon: PropTypes.string,
  backButtonColor: PropTypes.string,

};

export default WizardFooter;