import React from 'react'
import Search from '../../../../components/Search/Search'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'

import './TeamReviewHeader.scss'
import DropdownMultiple from '../../../../components/Dropdown/DropdownMultiple'
import { useEffect } from 'react';

const TeamReviewHeader = (props) => {
  const { searchValue,
    regionsValues,
    onSearchChange,
    onRegionsChange,
    onAreasChange,
    onTeamsChange,
    areasValues,
    teamsValues,
  } = props
  const regions = useSelector(state => state.teamWizard.regions)
  
  useEffect(() => {
    if (regions && regions.length) {
      const newRegionsList = regions.map(region => {
        return {
          id: region.id,
          value: region.name,
          selected: true
        }
      })
      onRegionsChange(newRegionsList)
    }
  }, [regions])

  return (
    <div className="team-review__title">
      <div><div className="show">Show</div></div>
      <div className="team-review__header">
        <div className="team-review__filters">
          <DropdownMultiple label={'Regions'} options={regionsValues} icon={'fa fa-globe'} onChange={onRegionsChange} />
          <DropdownMultiple label={'Areas'} options={areasValues} icon={'fa fa-map'} onChange={onAreasChange} />
          <DropdownMultiple label={'Teams'} options={teamsValues} icon={'fa fa-users'} onChange={onTeamsChange} />
        </div>
        <div className="team-review__search">
          <Search
            value={searchValue}
            onChange={onSearchChange} />
        </div>
      </div>
    </div>

  )
}

TeamReviewHeader.propTypes = {
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  regionsValues: PropTypes.array,
  onRegionsChange: PropTypes.func,
  onAreasChange: PropTypes.func,
  onTeamsChange: PropTypes.func,
  areasValues: PropTypes.array,
  teamsValues: PropTypes.array,
}

export default TeamReviewHeader