import React from 'react';
import PropTypes from 'prop-types';

import StepSizeSelector from '../../../StepSizeSelector/StepSizeSelector';

const RegionAmountSelector = ({onSubmit, regionAmount}) => {
    return (
        <StepSizeSelector 
        headerProps={{
            title : 'How many Regions are in your Organisation?', 
            subtitle : "(you can always add more later)",
            icon: 'fa-globe'
        }}
        
        bodyProps={{
            buttonText: "Add Regions",
            regionAmount: regionAmount,
            onClick: onSubmit
        }} />
    );
};

RegionAmountSelector.propTypes = {
    onSubmit: PropTypes.func,
    regionAmount: PropTypes.string
  }

export default RegionAmountSelector;