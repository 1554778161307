import { actions } from "../actions/content.action"

const initialState = {
  dashboard: {
    isLoading: false,
    content: []
  },
}

const reducers = {
  [actions.FETCH_DASHBOARD_REQUEST]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      isLoading: true

    }
  }),
  [actions.FETCH_DASHBOARD_SUCCESS]: (state, action) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      isLoading: false,
      content: action.payload
    }

  }),
  [actions.FETCH_DASHBOARD_FAILURE]: (state, action) => ({

    ...state,
    dashboard: {
      ...state.dashboard,
      isLoading: false,
      error: action.error
    }
  })

}

export default function content(state = initialState, action) {
  return reducers[action.type] ? reducers[action.type](state, action) : state
}
