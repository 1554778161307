import React from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "react-redux";

function useComparison() {
  const history = useHistory();
  const store = useStore();

  const validateComparison = () => {
    const { reportFrom, reportTo } =
      store.getState().benchmarking.reportsToCompare;
    if (!reportFrom || !reportTo) history.replace("/reports/compare");
  };

  React.useEffect(() => {
    validateComparison();
  }, []);
}

export default useComparison;
