import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="auth-page__form-footer">
      <span className="terms-link">@2019 TeamLytica Ltd</span>
      <Link className="terms-link" to="/static/termsconditions">
        Terms & Conditions
      </Link>
      <Link className="terms-link" to="/static/cookiepolicy">
        Privacy Policy
      </Link>
    </div>
  );
};
