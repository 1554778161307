import { actions } from "../actions/questionnaire.action";

const defaultState = {
  questionnaire: null,
  success: false,
  error: null
};

export default function questionnaire(state = defaultState, action) {
  switch (action.type) {
    case actions.FETCH_QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actions.FETCH_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionnaire: action.questionnaire
      };
    case actions.FETCH_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case actions.SUBMIT_QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ""
      };
    case actions.SUBMIT_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true
      };
    case actions.SUBMIT_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.error
      };
    default:
      return state;
  }
}
