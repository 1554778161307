import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//components
import SidebarButton from "./components/SidebarButton";

//helpers
import { isAdminOrReseller } from "../../store/helpers/userHelper";

//scss
import "./Sidebar.scss";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSelectedTab = (title) => {
    if (
      this.props.title === title.toUpperCase() ||
      this.props.subtitle === title.toUpperCase()
    ) {
      return true;
    }
    return false;
  };

  getButtonVisibility = (menu) => {
    return this.props.history.location.pathname.includes(menu);
  };

  render() {
    const { hasReports } = this.props;

    const buttons = [
      {
        id: 1,
        route: "/dashboard",
        title: "Dashboard",
        icon: "fa-dashboard",
        show: true,
        subMenu: false,
      },
      {
        id: 2,
        route: "/reports/all",
        title: "Your Reports",
        icon: "fa-bar-chart-o",
        show: hasReports,
        subMenu: false,
      },
      {
        id: 3,
        route: "/reports/compare",
        title: "Compare Reports",
        icon: "fa fa-sliders",
        show: true,
        subMenu: false,
      },
      {
        id: 5,
        route: "/team",
        title: "Your Team",
        icon: "fa-users",
        show: true,
        subMenu: false,
      },
      {
        id: 6,
        route: "/view-only-user",
        title: "View-only users",
        icon: "fa-eye",
        show: true,
        subMenu: false,
      },
      {
        id: 8,
        route: "/settings/profile",
        title: "Profile",
        icon: "fa-cog",
        show: true,
        subMenu: false,
      },
    ];

    if (isAdminOrReseller(this.props.user.role)) {
      buttons.push({
        id: 4,
        route: "/buy-new-report",
        title: "Buy New Reports",
        icon: "fa fa-undo",
        show: true,
        subMenu: false,
      });
      buttons.sort((a, b) => a.id - b.id);
    }

    return (
      <div>
        <nav className="s-sidebarleft__nav">
          <ul>
            {buttons.sort().map((button) => (
              <SidebarButton
                key={button.id}
                route={button.route}
                title={button.title}
                icon={button.icon}
                active={this.getSelectedTab(button.title)}
                subMenu={button.subMenu}
                show={button.show}
              />
            ))}
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  title: state.navigation && state.navigation.selection.title,
  subtitle: state.navigation && state.navigation.selection.subtitle,
  hasReports:
    state.reports &&
    state.reports.reportStatus &&
    state.reports.reportStatus.can_see_report,
  isTeam360:
    state.reports &&
    state.reports.reportStatus &&
    state.reports.reportStatus.is_team_360,
  isCurrent:
    state.reports &&
    state.reports.reportStatus &&
    state.reports.reportStatus.is_current,
  user: state.general.content,
});

Sidebar.propTypes = {
  hasReports: PropTypes.bool,
  isTeam360: PropTypes.bool,
  isCurrent: PropTypes.bool,
  history: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  location: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps, null)(withRouter(Sidebar));
