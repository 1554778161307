import auth from "./auth.reducer";
import benchmarking from "./benchmarking.reducer";
import content from './content.reducer';
import general from "./general.reducer";
import globalLoading from "./globalLoading.reducer";
import motivator from './motivator.reducer';
import navigation from "./navigation.reducer";
import payment from "./payment.reducer";
import questionnaire from "./questionnaire.reducer";
import reports from "./reports.reducer";
import reseller from './reseller.reducer';
import subscriptions from "./subscription.reducer";
import team from "./team.reducer";
import teamReview from './teamReview.reducer';
import teamWizard from "./teamWizard.reducer";

export default {
  auth,
  benchmarking,
  general,
  globalLoading,
  navigation,
  payment,
  reports,
  subscriptions,
  team,
  questionnaire,
  teamWizard,
  teamReview,
  motivator,
  reseller,
  content,
};
