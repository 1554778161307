import { API } from "../../constants/api";
import { HttpClient } from "../../utils/http-client";

export const actions = {
  FETCH_DASHBOARD_REQUEST: 'FETCH_DASHBOARD_REQUEST',
  FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',
  FETCH_DASHBOARD_FAILURE: 'FETCH_DASHBOARD_FAILURE',
}

export const fetchDashboardContent = () => {
  var apiUrl = API.content.dashboard();

  return (dispatch) => {
    dispatch(fetchDashboardRequest());

    HttpClient.get(apiUrl)
      .then((response) => {
        dispatch(fetchDashboardSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(fetchDashboardFailure(error));
      })
  }
}

function fetchDashboardRequest() {
  return dispatch => dispatch({ type: actions.FETCH_DASHBOARD_REQUEST })
}

function fetchDashboardSuccess(payload) {
  return dispatch => dispatch({ type: actions.FETCH_DASHBOARD_SUCCESS, payload })
}

function fetchDashboardFailure(payload) {
  return dispatch => dispatch({ type: actions.FETCH_DASHBOARD_FAILURE, payload })
}
