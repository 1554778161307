import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PageLoading from "../../../../../components/PageLoading";
import {
  fetchCoupon,
  fetchProductPrice,
} from "../../../../../store/actions/payment.action";
import { getCurrency } from "../../../../../store/helpers/uiHelper";

const ContentOrder = ({
  isLoadingProductPrice,
  couponError,
  couponDiscount,
  couponVAT,
  couponPrice,
  currentProduct,
  productTotalPrice,
  productQuantity,
  vat,
  fetchProductPrice,
  onCouponSubmit,
}) => {
  const [coupon, setCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  const currency = useMemo(
    () => getCurrency(currentProduct.currency),
    [currentProduct]
  );

  const totalPrice = couponPrice ?? productTotalPrice ?? 0;

  const handleCouponSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setAppliedCoupon(coupon);
      onCouponSubmit(coupon);
    },
    [coupon]
  );

  const handleCouponChange = useCallback((event) => {
    setCoupon(event.target.value);
  }, []);

  useEffect(() => {
    fetchProductPrice(
      currentProduct.subscription_type_id,
      productQuantity,
      appliedCoupon
    );
  }, [currentProduct, productQuantity, appliedCoupon]);

  return (
    <div className="content__order">
      <PageLoading
        message="Calculating price..."
        isLoading={isLoadingProductPrice}
        render={() => (
          <div className="content__order_price_container">
            <div className="content__order_header">
              <span className="content__order_title">Order Value</span>
              <span className="content__order_value">
                {currency}
                {totalPrice}
              </span>
            </div>
            <div className="content__order_form">
              <div className="content__order_label">
                <span>Discount Code (if applicable)</span>
              </div>
              <form
                className="content__order_coupon"
                onSubmit={handleCouponSubmit}
              >
                <input
                  className="content__order_coupon-input"
                  onChange={handleCouponChange}
                  value={coupon}
                  type="text"
                />
                <button
                  className="content__order_coupon-submit"
                  type="submit"
                  onClick={handleCouponSubmit}
                >
                  <span>Apply</span>
                  <i className="fas fa-chevron-right" />
                </button>
              </form>
              {couponError && (
                <div className="content__order_coupon-error">
                  <span>{couponError}</span>
                </div>
              )}
            </div>
            <div className="content__order_applies">
              {!!couponDiscount && (
                <div className="content__order_applies-value">
                  <span>Discount </span>
                  <span>
                    {currency}
                    {couponDiscount}
                  </span>
                </div>
              )}
              <div className="content__order_applies-value">
                <span>VAT </span>
                <span>
                  {currency}
                  {couponVAT || couponVAT === 0 ? couponVAT : vat}
                </span>
              </div>
              <div className="content__order_applies-value">
                <span>Total Amount </span>
                <span>
                  {currency}
                  {totalPrice}
                </span>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

ContentOrder.propTypes = {
  productTotalPrice: PropTypes.number,
  currentProduct: PropTypes.object,
  isLoadingProductPrice: PropTypes.bool,
  couponError: PropTypes.string,
  couponDiscount: PropTypes.number,
  couponVAT: PropTypes.number,
  couponPrice: PropTypes.number,
  vat: PropTypes.number,
  productQuantity: PropTypes.number,
  fetchProductPrice: PropTypes.func,
  onCouponSubmit: PropTypes.func,
};

const mapStateToProps = ({ payment }) => {
  return {
    productTotalPrice: payment.currentPrice.price,
    currentProduct: payment.currentProduct,
    isLoadingProductPrice: payment.isLoadingProductPrice,
    couponPrice: payment.currentPrice.price,
    couponError: payment.couponError,
    couponDiscount: payment.couponDiscount,
    couponVAT: payment.couponVAT,
    vat: payment?.currentPrice.vat,
  };
};

export default connect(mapStateToProps, {
  fetchCoupon,
  fetchProductPrice,
})(ContentOrder);
