export const nameData = {
  id: 1,
  value: "",
  name: "name",
  label: "Name",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field",
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`,
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`,
    },
  ],
};

export const emailData = {
  id: 2,
  value: "",
  name: "email",
  label: "Email Address",
  placeholder: "",
  type: "email",
  required: true,
  validate: true,
  component: "textfield",
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field",
    },
    {
      validationName: "regex",
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      priority: 2,
      message: "Invalid email",
    },
    {
      validationName: "minLength",
      value: 8,
      priority: 3,
      message: `Minimum required length is 8 characters`,
    },
    {
      validationName: "maxLength",
      value: 50,
      priority: 4,
      message: `Maximum allowed length is 50 characters`,
    },
  ],
};

export const searchData = {
  id: 3,
  value: "",
  name: "searchValue",
  label: "",
  placeholder: "Search",
  type: "text",
  component: "textfield",
};

export const teamData = {
  id: 4,
  value: "",
  name: "team",
  label: "Team Name",
  placeholder: "",
  type: "text",
};

export const SUBTITLE = {
  t360: "Here you can Add, Edit or Delete Stakeholders before sending your TeamLytica Questionnaires",
  noT360:
    "If your team has changed since you last completed the TeamLytica questionnaire, then you can add, delete or edit details here.",
};
