import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectStripe } from "react-stripe-elements";

import { createPaymentIntent } from "../../../../store/actions/payment.action";

import { getCurrency } from "../../../../store/helpers/uiHelper";
import { PRODUCT_TYPES } from "../../constants";

import Stripe from "../../../../assets/images/logos/powered_by_stripe.svg";
import Logo from "../../../../assets/images/logos/tm_logo_blue.svg";

// scss
import "./CheckoutForm.scss";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      errorMessage: null,
      cardElement: null,
    };
  }

  componentWillMount() {
    if (this.props.couponPrice !== 0) {
      this.setState({ elements: this.props.stripe.elements() }, () => {
        this.setState(
          {
            cardNumber: this.state.elements.create("cardNumber"),
            cardExpiry: this.state.elements.create("cardExpiry"),
            cardCvc: this.state.elements.create("cardCvc"),
          },
          () => {
            this.state.cardNumber.mount("#card-number");
            this.state.cardExpiry.mount("#card-expiry");
            this.state.cardCvc.mount("#card-cvc");
          }
        );
      });
    }
  }

  componentDidMount() {
    const { customer, quantity, appliedCoupon, teamId } =
      this.props.location.state || {};
    const { currentProduct, couponPrice, createPaymentIntent } = this.props;

    if (!customer || !currentProduct) {
      this.props.history.goBack();
    }

    if (couponPrice === 0) {
      createPaymentIntent(
        {
          subscription_type_id: this.props.currentProduct.subscription_type_id,
          quantity,
          coupon: appliedCoupon,
          customer,
          team_id: teamId,
        },
        this.props.stripe,
        this.state.cardNumber
      );
    }
  }

  componentDidUpdate() {
    if (this.props.isPaymentSuccessful) {
      const routeMapper = {
        "team-360": () => this.props.history.push("/stakeholders"),
        "full-report": () => this.props.history.push("/team"),
        "re-test": () => this.props.history.push("/team"),
        "team-member-credit": () => this.props.history.push("/team"),
      };
      if (routeMapper[this.props.currentProduct.key]) {
        routeMapper[this.props.currentProduct.key]();
        return;
      }
      this.props.history.push("/dashboard");
    }
  }

  handleChange = ({ error }) => {
    this.setState({
      errorMessage: error && error.message ? error.message : "",
    });
  };

  handlePurchase = (ev) => {
    ev.preventDefault();

    const { customer, appliedCoupon, quantity, teamId } =
      this.props.location.state;

    this.props.createPaymentIntent(
      {
        subscription_type_id: this.props.currentProduct.subscription_type_id,
        quantity,
        coupon: appliedCoupon,
        customer,
        team_id: teamId,
      },
      this.props.stripe,
      this.state.cardNumber
    );
  };

  render() {
    const { report } = this.props.match.params;

    const { isLoading, paymentError, currentProduct, couponPrice } = this.props;
    const { quantity } = this.props.location.state;

    const totalPrice =
      couponPrice || couponPrice === 0
        ? couponPrice
        : currentProduct.total_price;

    const productType = PRODUCT_TYPES[report];

    return (
      <div className="checkout-form">
        <div className="header">
          <button
            onClick={() => this.props.history.goBack()}
            className="header__button"
          >
            <i className="fa fa-angle-left" aria-hidden="true" />
            <span>Go Back</span>
          </button>
        </div>
        <div className="checkout-form__info">
          <div className="checkout-form__info-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="checkout-form__info-content">
            <div>
              <p>TeamLytica {productType}</p>
              <p className="value">
                {currentProduct &&
                  `${getCurrency(currentProduct.currency)}${totalPrice}`}
              </p>
              <p className="description">
                {quantity} TeamLytica {productType}
              </p>
            </div>
          </div>
          <div className="checkout-form__info-footer">
            <div>
              <img
                src={Stripe}
                alt="Logo"
                onClick={() => window.open("https://stripe.com/", "_blank")}
              />
              <hr />
              <div className="terms-privacy">
                <p
                  onClick={() =>
                    window.open("https://stripe.com/legal", "_blank")
                  }
                >
                  Terms
                </p>
                <p
                  onClick={() =>
                    window.open("https://stripe.com/privacy", "_blank")
                  }
                >
                  Privacy
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="checkout-form__card">
          <form onSubmit={this.handlePurchase}>
            <p className="checkout-form__card-title">Complete the purchase</p>
            <div className="container">
              {this.props.couponPrice !== 0 && (
                <>
                  <label>Card information</label>
                  <div className="card-details">
                    <div id="card-number" />
                    <div className="card-subdetails">
                      <div id="card-expiry" />
                      <div id="card-cvc" />
                    </div>
                  </div>
                  <div className="error-message">
                    {this.state.errorMessage ? (
                      <span>{this.state.errorMessage}</span>
                    ) : paymentError ? (
                      <span>{paymentError}</span>
                    ) : null}
                  </div>
                </>
              )}
              {isLoading ? (
                <span>Processing payment...</span>
              ) : (
                <button className="submit-button" type="submit">
                  <span>
                    Pay
                    {currentProduct &&
                      ` ${getCurrency(currentProduct.currency)}${totalPrice}`}
                  </span>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  couponPrice: PropTypes.number,
  createPaymentIntent: PropTypes.func,
  currentProduct: PropTypes.object,
  isPaymentSuccessful: PropTypes.bool,
  isLoading: PropTypes.bool,
  paymentError: PropTypes.string,
  stripe: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = ({ payment }) => {
  const isLoading =
    (payment && payment.isLoadingPayment) ||
    (payment && payment.isLoadingIntent);

  return {
    isLoading,
    isPaymentSuccessful: payment && payment.isPaymentSuccessful,
    paymentError: payment && payment.paymentError,
    currentProduct: payment && payment.currentProduct,
    couponPrice: payment && payment.currentPrice && payment.currentPrice.price,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    createPaymentIntent,
  })(injectStripe(CheckoutForm))
);
