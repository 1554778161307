import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//scss
import "../../Sidebar.scss";
class SidebarButton extends Component {
  render() {
    const { active, icon, route, subMenu, show, title } = this.props;
    return show ? (
      <li>
        <Link
          to={route}
          className={`s-sidebarleft__nav-link ${active ? "active" : ""}`}
        >
          <i className={`fa ${icon} ${subMenu && `sub-menu`}`} />
          <em>{title}</em>
        </Link>
      </li>
    ) : null;
  }
}

SidebarButton.propTypes = {
  active: PropTypes.bool,
  history: PropTypes.object,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  route: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  subMenu: PropTypes.bool,
};

export default SidebarButton;
