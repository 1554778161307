/*eslint no-magic-numbers: 0*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { questionnaireActions } from "../../store/actions/questionnaire.action";

//components
import Navbar from "../../components/Navbar";
import ProgressBar from "../../components/ProgressBar";
import QuestionnaireEndPage from "./pages/QuestionnaireEndPage";
import QuestionnaireStartPage from "./pages/QuestionnaireStartPage";
import QuestionnaireQuestion from "./pages/QuestionnaireQuestion";
import QuestionnaireOpenText from "./pages/QuestionnaireOpenText";
import QuestionnaireSingleChoice from "./pages/QuestionnaireSingleChoice";

// scss
import "./Questionnaire.scss";
import QuestionnaireInputs from "./pages/QuestionnaireInputs";
class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: [],
      questions: [],
      currentQuestion: 0,
      progress: 0,
      anim: true
    };
  }

  componentDidMount() {
    const { assessmentId, code } = this.props.match.params;
    this.props.fetchQuestionnaire(assessmentId, code);
  }

  componentDidUpdate = () => {
    window.onbeforeunload = () => true;
  };

  handleSelection = (question_id, answer) => {
    const { currentQuestion, responses } = this.state;

    const responseArray = responses.slice();
    responseArray[currentQuestion] = { question_id, answer };

    this.setState({
      responses: responseArray,
    });
  };

  handleNextQuestion = () => {
    const { responses } = this.state;
    const progress = responses.length / (this.props.questionnaire.questions.length - 1);

    this.setState((prevState) => ({
      currentQuestion: prevState.currentQuestion + 1,
      progress,
      anim: true
    }));
  };

  handleBackQuestion = () => {
    this.setState((prevState) => ({
      currentQuestion: prevState.currentQuestion - 1,
      anim: true
    }));
  };

  handleSubmit = () => {
    const { assessmentId, code } = this.props.match.params;
    this.props.submitQuestionnaire(this.state.responses, assessmentId, code);
  };

  renderQuestion = () => {
    const { currentQuestion, responses } = this.state;
    const { isLoading, error, questionnaire } = this.props;
    if (questionnaire && questionnaire.questions && questionnaire.questions.length > 0) {
      var section = questionnaire.questions[currentQuestion].category_order;
      var sectionNumber = section === 1 ? null : section - 1;
      switch (questionnaire.questions[currentQuestion].question_answer_type) {
        case "openText":
          return (
            <QuestionnaireOpenText
              onNext={this.handleNextQuestion}
              onBack={this.handleBackQuestion}
              onTextChange={this.handleSelection}
              response={responses[currentQuestion]}
              section={sectionNumber}
              title={questionnaire.questions[currentQuestion].question_custom_title}
              subTitle={questionnaire.questions[currentQuestion].category_description}
              question={questionnaire.questions[currentQuestion].question_description}
              number={questionnaire.questions[currentQuestion].question_number}
              questionId={questionnaire.questions[currentQuestion].question_id}
              explanation={questionnaire.questions[currentQuestion].question_explanation || ""}
            />
          );
        case "threeInputs":
          return (
            <QuestionnaireInputs
              onNext={this.handleNextQuestion}
              onBack={this.handleBackQuestion}
              onTextChange={this.handleSelection}
              response={responses[currentQuestion]}
              section={sectionNumber}
              title={questionnaire.questions[currentQuestion].question_custom_title}
              subTitle={questionnaire.questions[currentQuestion].category_description}
              question={questionnaire.questions[currentQuestion].question_description}
              number={questionnaire.questions[currentQuestion].question_number}
              questionId={questionnaire.questions[currentQuestion].question_id}
              explanation={questionnaire.questions[currentQuestion].question_explanation || ""}
            />
          );
        case "numberScoring":
          return (
            <QuestionnaireQuestion
              onNext={this.handleNextQuestion}
              onBack={this.handleBackQuestion}
              onItemClick={this.handleSelection}
              response={responses[currentQuestion]}
              section={sectionNumber}
              number={questionnaire.questions[currentQuestion].question_number}
              title={questionnaire.questions[currentQuestion].category_name}
              subTitle={questionnaire.questions[currentQuestion].category_description}
              question={questionnaire.questions[currentQuestion].question_description}
              questionId={questionnaire.questions[currentQuestion].question_id}
              explanation={questionnaire.questions[currentQuestion].question_explanation || ""}
            />
          );
        case "yesOrNo":
        case "workplace":
        case "schedule":
        case "monthly":
          return (
            <QuestionnaireSingleChoice
              onNext={this.handleNextQuestion}
              onBack={this.handleBackQuestion}
              onItemChange={this.handleSelection}
              number={questionnaire.questions[currentQuestion].question_number}
              response={responses[currentQuestion]}
              section={sectionNumber}
              title={questionnaire.questions[currentQuestion].category_name}
              subTitle={questionnaire.questions[currentQuestion].category_description}
              question={questionnaire.questions[currentQuestion].question_description}
              questionAnswerType={questionnaire.questions[currentQuestion].question_answer_type}
              questionId={questionnaire.questions[currentQuestion].question_id}
              explanation={questionnaire.questions[currentQuestion].question_explanation || ""}
            />
          );
        case "start":
          return (
            <QuestionnaireStartPage
              onNext={this.handleNextQuestion}
              memberName={questionnaire.member_name}
            />
          );
        case "end":
          return (
            <QuestionnaireEndPage onBack={this.handleBackQuestion} onSubmit={this.handleSubmit} />
          );
        default:
          return (
            <div className="no-content">
              <span>OOPS</span>
            </div>
          );
      }
    } else if (error) {
      return (
        <div className="no-content">
          <span>{error}</span>
        </div>
      );
    } else if (isLoading) {
      return (
        <div className="no-content">
          <span>LOADING...</span>
        </div>
      );
    } else {
      return (
        <div className="no-content">
          <span>THIS QUESTIONNAIRE HAS ALREADY BEEN COMPLETED</span>
        </div>
      );
    }
  };

  handleAnim (bool) {
    bool ? this.setState({anim: true}) : this.setState({anim: false})
  }  
  render() {
    const { success } = this.props;
    const { progress, currentQuestion } = this.state;

    return (
      <div className="questionnaire-page">
        <Navbar user={false} />
        {!success && (
          <div className="questionnaire-page__sub-header">
            <span>
              {currentQuestion < 2 && `Please complete the following questionnaire for your team`}
            </span>
          </div>
        )}
        <div id="animated" ref={this.questionRef} onAnimationEnd={() => this.handleAnim(false)} className={`questionnaire-page__content ${this.state.anim ? 'anim' : ''}`}>
          {this.renderQuestion()}
        </div>
        {!success && (
          <div className="questionnaire-page__footer">
            <ProgressBar label={true} value={progress} total={1} />
          </div>
        )}
      </div>
    );
  }
}

Questionnaire.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  questionnaire: PropTypes.object,
  fetchQuestionnaire: PropTypes.func,
  submitQuestionnaire: PropTypes.func,
  assessmentId: PropTypes.number,
  code: PropTypes.number,
  match: PropTypes.object,
};

function mapStateToProps(state) {
  const { error, questionnaire, isLoading, success } = state.questionnaire;
  return {
    error,
    questionnaire,
    isLoading,
    success,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuestionnaire: (assessmentId, code) =>
      dispatch(questionnaireActions.fetchQuestionnaire(assessmentId, code)),
    submitQuestionnaire: (responses, assessmentId, code) =>
      dispatch(questionnaireActions.submitQuestionnaire(responses, assessmentId, code)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
