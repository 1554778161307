import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import "./PageTitle.scss";

const PageTitle = ({
  title,
  subtitle,
  children,
  paragraphs,
  icon,
  paragraphsClassNames,
}) => {
  return (
    <div
      className={clsx("page-title", {
        "page-title__height": children && paragraphs,
      })}
    >
      <div className="title-box">
        <div className="page-title__title">
          <span>{title}</span>
        </div>
        {subtitle && (
          <div className="page-title__sub-title mt-1">
            <span>{subtitle}</span>
          </div>
        )}
        <div className="page-title_box">
          {paragraphs && paragraphs.length > 0 ? (
            <div className={clsx("paragraphs", paragraphsClassNames)}>
              {icon ? (
                <div className="icon-box">
                  <span>{icon ? icon : null}</span>
                  <span>
                    {paragraphs.map((paragraph, index) => (
                      <h6
                        key={paragraph.id ? paragraph.id : index}
                        className="mt-2"
                      >
                        {paragraph.text ? paragraph.text : paragraph}
                      </h6>
                    ))}
                  </span>
                </div>
              ) : (
                <span>
                  {paragraphs.map((paragraph, index) => (
                    <h6
                      key={paragraph.id ? paragraph.id : index}
                      className="mt-2"
                    >
                      {paragraph.text ? paragraph.text : paragraph}
                    </h6>
                  ))}
                </span>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="sub-box">
        <div className="page-title_box__title"></div>
        {children}
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  paragraphs: PropTypes.array,
  icon: PropTypes.node,
  discussParagraph: PropTypes.node,
  paragraphsClassNames: PropTypes.string,
};

export default PageTitle;
