/*eslint no-magic-numbers: 0*/
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Dropdown from "../../../../components/Dropdown";
import PageLoading from "../../../../components/PageLoading";
import QuestionDetail from "../../components/QuestionDetail";
import ReportsFooter from "../../components/ReportsFooter";
import ReportsTabbar from "../../components/ReportsTabbar";

import {
  getSelectedReport,
  getVisibleReports,
} from "../../../../store/helpers/userHelper";

// actions
import {
  fetchReportStatus,
  fetchReports,
  fetchUrgentActions,
  updateSelectedReport,
} from "../../../../store/actions/reports.action";

import { setNavigationAssets } from "../../../../store/actions/navigation.action";

//utils
import * as data from "./data";

//scss
import PageContent from "../../../../components/PageContent";
import PageTitle from "../../../../components/PageTitle";
import "./UrgentActions.scss";

class UrgentActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportDropdown: { data: data.reportsData },
    };
    this.formStatus = {};
  }
  componentDidMount() {
    const navigation = {
      title: "METRIC REPORTS",
      subtitle: "URGENT ACTIONS",
      tab: "YOUR REPORTS",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchUrgentActions();
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue }, () => {
      this.props.updateSelectedReport(fieldValue).then(() => {
        this.props.fetchReports();
        this.props.fetchReportStatus();
        this.props.fetchUrgentActions();
      });
    });
  };

  render() {
    const { isPageLoading, lowestScoring, isTeam360, reports } = this.props;

    const { reportDropdown } = this.state;

    let paragraphs = [];
    if (isTeam360) {
      paragraphs.push(
        "This section gives you the ten lowest scoring questions from your stakeholders. We have included the comparison scores from how your own team rated themselves against the same questions.",
        "If your own team has scored any of the questions significantly higher than your stakeholders, then there is clearly a disconnect that needs to be addressed."
      );
    } else {
      paragraphs.push(
        `Your urgent actions include the team’s lowest scoring questions which will give you a strong indication of where the team feels it’s weakest.`
      );
    }

    return (
      <PageLoading
        isLoading={isPageLoading}
        render={() => (
          <Fragment>
            <PageTitle
              title="Urgent Actions"
              paragraphs={paragraphs}
              paragraphsClassNames="parapgh-min-height"
            >
              {reports && reports.length > 0 && (
                <div className="urgent-actions-page__header-title_select">
                  <Dropdown
                    data={{
                      ...reportDropdown.data,
                      list: getVisibleReports(reports) || [],
                      value: reports && getSelectedReport(reports),
                    }}
                    onSelect={this.updateFormFieldValue}
                  />
                </div>
              )}
            </PageTitle>
            <PageContent>
              <ReportsTabbar />
              <div className="urgent-actions-page__content">
                <div className="urgent-actions-page__content-subtitle">
                  <span>Lowest scoring questions</span>
                </div>
                {lowestScoring &&
                  lowestScoring.map((question) => (
                    <QuestionDetail
                      description={question.question_description}
                      average={question.average}
                      answers={question.answer_count}
                      answersTotal={question.total_answers}
                      number={question.question_number}
                      key={question.question_id}
                    />
                  ))}
              </div>
              <ReportsFooter />
            </PageContent>
          </Fragment>
        )}
      />
    );
  }
}

UrgentActions.propTypes = {
  fetchUrgentActions: PropTypes.func,
  setNavigationAssets: PropTypes.func,
  fetchReports: PropTypes.func,
  fetchReportStatus: PropTypes.func,
  updateSelectedReport: PropTypes.func,
  greatestDisparity: PropTypes.array,
  lowestScoring: PropTypes.array,
  isLoadingUrgentActions: PropTypes.bool,
  isLoadingReports: PropTypes.bool,
  isPageLoading: PropTypes.bool,
  isTeam360: PropTypes.bool,
  reports: PropTypes.array,
};

const mapStateToProps = ({ reports }) => {
  const isPageLoading =
    reports.isLoadingReports || reports.isLoadingUrgentActions;
  return {
    greatestDisparity:
      reports.urgentActions &&
      reports.urgentActions.greatest_disparity_questions,
    lowestScoring:
      reports.urgentActions && reports.urgentActions.lowest_scoring_questions,
    isLoadingUrgentActions: reports.isLoadingUrgentActions,
    isLoadingReports: reports.isLoadingReports,
    isPageLoading,
    isTeam360:
      reports && reports.reportStatus && reports.reportStatus.is_team_360,
    reports: reports.reports,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  updateSelectedReport,
  fetchReports,
  fetchReportStatus,
  fetchUrgentActions,
})(UrgentActions);
