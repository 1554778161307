import React, { Component, Fragment } from "react";
import Router from "./router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import { withRouter } from "react-router-dom";
import history from "./utils/history";

// components
import GlobalLoading from "./components/GlobalLoading";

//redux actions
import { authActions } from "./store/actions/auth.action";

// toastr css
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material";

import theme from "./theme";
class App extends Component {
  componentDidMount() {
    this.props.checkAuthentication();
  }

  render() {
    if (this.props.isAuthenticated === null) {
      return <div className="app__loading" />;
    }

    return (
      <Fragment>
        <ThemeProvider theme={theme}>
          <Router history={history} />
          <ToastContainer />
          <GlobalLoading />
        </ThemeProvider>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

function mapDispatchToProps(dispatch) {
  return {
    checkAuthentication: () => dispatch(authActions.checkAuthentication()),
  };
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  checkAuthentication: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
