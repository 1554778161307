import PropTypes from "prop-types";
import React from 'react';
import clsx from 'clsx';

const TeamWizardStep = ({ icon, fieldName, active = false }) => {
    return (
        <li className={clsx('team-wizard-step', {
            'team-wizard-step--active': active
        })}>
            <div>
                <i className={`fa fa-${icon}`}></i>
                <span>{fieldName}</span>
            </div>
            <i className='team-wizard-step-arrow fa fa-solid fa-chevron-right' />
        </li>
    );
};

TeamWizardStep.propTypes = {
    icon: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    active: PropTypes.bool
}


export default TeamWizardStep;