import LayoutDefault from "./components/LayoutDefault";
import LayoutTabbar from "./components/LayoutTabbar";
import Achievements from "./pages/Achievements";
import ForgotPassword from "./pages/Auth/pages/ForgotPassword";
import Login from "./pages/Auth/pages/Login";
import LoginAs from "./pages/Auth/pages/LoginAs";
import RegisterCompany from "./pages/Auth/pages/Register/pages/RegisterCompany";
import RegisterSignUp from "./pages/Auth/pages/Register/pages/RegisterSignUp/RegisterSignUp";
import RegisterSuccess from "./pages/Auth/pages/Register/pages/RegisterSuccess";
import RegisterUser from "./pages/Auth/pages/Register/pages/RegisterUser";
import ResetPassword from "./pages/Auth/pages/ResetPassword";
import BuyNewReport from "./pages/BuyNewReport";
import Dashboard from "./pages/Dashboard";
import Product from "./pages/Payment/pages/Product";
import StripePayment from "./pages/Payment/pages/StripePayment";
import Questionnaire from "./pages/Questionnaire";
import CategoryDetail from "./pages/Reports/pages/Cohesion/pages/CategoryDetail";
import CohesionCompare from "./pages/Reports/pages/CohesionCompare";
import CategoryDetailCompare from "./pages/Reports/pages/CohesionCompare/pages/CategoryDetailCompare/CategoryDetailCompare";
import Comments from "./pages/Reports/pages/Comments";
import CommentsCompare from "./pages/Reports/pages/CommentsCompare";
import CompareAndBrenchmark from "./pages/Reports/pages/CompareAndBrenchmark";
import ShowReports from "./pages/Reports/pages/Show";
import ReportsView from "./pages/Reports/pages/Show/pages/View";
import Top10 from "./pages/Reports/pages/Top10";
import Top10Compare from "./pages/Reports/pages/Top10Compare";
import UrgentActions from "./pages/Reports/pages/UrgentActions";
import UrgentActionsCompare from "./pages/Reports/pages/UrgentActionsCompare";
import Company from "./pages/Settings/pages/Company";
import Profile from "./pages/Settings/pages/Profile";
import Security from "./pages/Settings/pages/Security";
import PrivacyPolicy from "./pages/Static/pages/PrivacyPolicy";
import TermsConditions from "./pages/Static/pages/TermsConditions";
import SupportVideos from "./pages/SupportVideos";
import Team from "./pages/Team";
import TeamReview from "./pages/TeamReview";
import TeamWizard from "./pages/TeamWizard";
import ViewOnlyUser from "./pages/ViewOnlyUser";

const settingsOptions = [
  {
    id: 1,
    route: "/settings/profile",
    title: "Your Profile",
    icon: "fa fa-user",
  },
  {
    id: 2,
    route: "/settings/company",
    title: "Your Company",
    icon: "fa fa-briefcase",
  },
  {
    id: 3,
    route: "/settings/password",
    title: "Password",
    icon: "fa fa-lock",
  },
];

const reportsOptions = [
  {
    id: 1,
    route: "/reports/cohesion",
    title: "Your Reports",
    icon: "fa fa-user",
  },
  {
    id: 2,
    route: "/reports/cohesion/compare",
    title: "Compare and Benchmark",
    icon: "fa fa-briefcase",
  },
];

const routes = [
  { path: "/", exact: true, component: RegisterUser },
  { path: "/signup", exact: true, component: RegisterSignUp },
  {
    path: "/achievements",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: Achievements,
  },
  {
    path: "/dashboard",
    isPrivate: true,
    layout: LayoutDefault,
    component: Dashboard,
  },
  {
    path: "/buy-new-report",
    isPrivate: true,
    layout: LayoutDefault,
    component: BuyNewReport,
  },
  {
    path: "/explainer-videos",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: SupportVideos,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/login-as",
    exact: true,
    component: LoginAs,
  },
  {
    path: "/product/:report",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: Product,
  },
  {
    path: "/product/:report/payment",
    exact: true,
    isPrivate: true,
    component: StripePayment,
  },
  {
    path: "/questionnaire/:assessmentId/member/:code",
    component: Questionnaire,
  },
  {
    path: "/reset-password/:userId/:code",
    component: ResetPassword,
  },
  { path: "/register-company", exact: true, component: RegisterCompany },
  { path: "/register-success", exact: true, component: RegisterSuccess },
  {
    path: "/reports/team-synergy",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: ReportsView,
    //options: reportsOptions
  },
  {
    path: "/reports/all",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: ShowReports,
    //options: reportsOptions
  },
  {
    path: "/reports/compare",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: CompareAndBrenchmark,
    //options: reportsOptions
  },
  {
    path: "/reports/team-synergy/compare",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: CohesionCompare,
  },
  {
    path: "/reports/cohesion/category-detail/:categoryId",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: CategoryDetail,
  },
  {
    path: "/reports/cohesion/category-detail/:categoryId/compare",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: CategoryDetailCompare,
  },
  {
    path: "/reports/top-scoring",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: Top10,
    //options: reportsOptions
  },
  {
    path: "/reports/top-scoring/compare",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: Top10Compare,
  },
  {
    path: "/reports/urgent",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: UrgentActions,
    //options: reportsOptions
  },
  {
    path: "/reports/urgent/compare",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: UrgentActionsCompare,
    options: reportsOptions,
  },
  {
    path: "/reports/comments",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: Comments,
  },
  {
    path: "/reports/comments/compare",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: CommentsCompare,
  },
  {
    path: "/settings/company",
    exact: true,
    isPrivate: true,
    layout: LayoutTabbar,
    component: Company,
    options: settingsOptions,
  },
  {
    path: "/settings/password",
    exact: true,
    isPrivate: true,
    layout: LayoutTabbar,
    component: Security,
    options: settingsOptions,
  },
  {
    path: "/settings/profile",
    exact: true,
    isPrivate: true,
    layout: LayoutTabbar,
    component: Profile,
    options: settingsOptions,
  },
  {
    path: "/stakeholders",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: Team,
  },
  {
    path: "/static/privacypolicy",
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: "/static/termsconditions",
    exact: true,
    component: TermsConditions,
  },
  {
    path: "/team",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: Team,
  },
  {
    path: "/view-only-user",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: ViewOnlyUser,
  },
  {
    path: "/team-wizard",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: TeamWizard,
  },
  {
    path: "/team-review",
    exact: true,
    isPrivate: true,
    layout: LayoutDefault,
    component: TeamReview,
  },
];

export default routes;
