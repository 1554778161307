/*eslint no-magic-numbers: 0*/
import { authHeader } from "../helpers/authHeader";
import { handleErrors } from "../helpers/errorHandlers";

import { notifySuccess, notifyError } from "../../utils/notifications";

import { methods } from "../helpers/HttpHelper";

export const actions = {
  ADD_RESELLER_REQUEST: "ADD_RESELLER_REQUEST",
  ADD_RESELLER_SUCCESS: "ADD_RESELLER_SUCCESS",
  ADD_RESELLER_FAILURE: "ADD_RESELLER_FAILURE",

  ADD_VIEW_ONLY_USER_REQUEST: "ADD_VIEW_ONLY_USER_REQUEST",
  ADD_VIEW_ONLY_USER_SUCCESS: "ADD_VIEW_ONLY_USER_SUCCESS",
  ADD_VIEW_ONLY_USER_FAILURE: "ADD_VIEW_ONLY_USER_FAILURE",

  DELETE_RESELLER_REQUEST: "DELETE_RESELLER_REQUEST",
  DELETE_RESELLER_SUCCESS: "DELETE_RESELLER_SUCCESS",
  DELETE_RESELLER_FAILURE: "DELETE_RESELLER_FAILURE",

  DELETE_VIEW_ONLY_USER_REQUEST: "DELETE_VIEW_ONLY_USER_REQUEST",
  DELETE_VIEW_ONLY_USER_SUCCESS: "DELETE_VIEW_ONLY_USER_SUCCESS",
  DELETE_VIEW_ONLY_USER_FAILURE: "DELETE_VIEW_ONLY_USER_FAILURE",

  FETCH_RESELLER_REQUEST: "FETCH_RESELLER_REQUEST",
  FETCH_RESELLER_SUCCESS: "FETCH_RESELLER_SUCCESS",
  FETCH_RESELLER_FAILURE: "FETCH_RESELLER_FAILURE",

  FETCH_VIEW_ONLY_USER_REQUEST: "FETCH_VIEW_ONLY_USER_REQUEST",
  FETCH_VIEW_ONLY_USER_SUCCESS: "FETCH_VIEW_ONLY_USER_SUCCESS",
  FETCH_VIEW_ONLY_USER_FAILURE: "FETCH_VIEW_ONLY_USER_FAILURE",
};

export function addViewOnlyUser(email, firstName, lastName, teamId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}/view-only-users`;

  const data = { email, firstName, lastName };
  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(addViewOnlyUserRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(addViewOnlyUserSuccess(json));
        fetchViewOnlyUser(teamId);
      })
      .catch((error) => dispatch(addViewOnlyUserFailure(error)));
  };

  function addViewOnlyUserRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_VIEW_ONLY_USER_REQUEST,
      });
    };
  }
  function addViewOnlyUserSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_VIEW_ONLY_USER_SUCCESS,
        payload,
      });

      dispatch(fetchViewOnlyUser(teamId)).then(notifySuccess("View-only user added with success"));
    };
  }
  function addViewOnlyUserFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_VIEW_ONLY_USER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function addReseller(email) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts/reseller`;

  const data = { reseller_email: email };
  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(addResellerRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(addResellerSuccess(json));
        return json;
      })
      .catch((error) => dispatch(addResellerFailure(error)));
  };

  function addResellerRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_RESELLER_REQUEST,
      });
    };
  }
  function addResellerSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_RESELLER_SUCCESS,
        payload,
      });

      dispatch(fetchReseller()).then(notifySuccess("Reseller added with success"));
    };
  }
  function addResellerFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.ADD_RESELLER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function deleteViewOnlyUser(viewOnlyUserId, teamId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}/view-only-users/${viewOnlyUserId}`;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(deleteViewOnlyUserRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(deleteViewOnlyUserSuccess(json));
        dispatch(fetchViewOnlyUser(teamId));
      })
      .catch((error) => dispatch(deleteViewOnlyUserFailure(error)));
  };

  function deleteViewOnlyUserRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_VIEW_ONLY_USER_REQUEST,
      });
    };
  }
  function deleteViewOnlyUserSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_VIEW_ONLY_USER_SUCCESS,
        payload,
      });
      notifySuccess("View-only user removed with success");
    };
  }
  function deleteViewOnlyUserFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_VIEW_ONLY_USER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function deleteReseller(resellerId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts/reseller/${resellerId}`;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(deleteResellerRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(deleteResellerSuccess(json));
        return json;
      })
      .catch((error) => dispatch(deleteResellerFailure(error)));
  };

  function deleteResellerRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_RESELLER_REQUEST,
      });
    };
  }
  function deleteResellerSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_RESELLER_SUCCESS,
        payload,
      });
      notifySuccess("Reseller removed with success");
    };
  }
  function deleteResellerFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_RESELLER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function fetchViewOnlyUser(teamId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}/view-only-users`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchViewOnlyUserRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchViewOnlyUserSuccess(json));
      })
      .catch((error) => {
        dispatch(fetchViewOnlyUserFailure(error));
      });
  };

  function fetchViewOnlyUserRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_VIEW_ONLY_USER_REQUEST,
      });
    };
  }
  function fetchViewOnlyUserSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_VIEW_ONLY_USER_SUCCESS,
        payload,
      });
    };
  }
  function fetchViewOnlyUserFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_VIEW_ONLY_USER_FAILURE,
        error,
      });
    };
  }
}

export function fetchReseller() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts/reseller`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchResellerRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.text())
      .then((text) => (text.length ? JSON.parse(text) : {}))
      .then((json) => {
        dispatch(fetchResellerSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchResellerFailure(error));
      });
  };

  function fetchResellerRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_RESELLER_REQUEST,
      });
    };
  }
  function fetchResellerSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_RESELLER_SUCCESS,
        payload,
      });
    };
  }
  function fetchResellerFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.FETCH_RESELLER_FAILURE,
        error,
      });
    };
  }
}
