import React from "react";
import TextField from "../../../../components/TextField";
import PropTypes from "prop-types";

import * as data from "../../data";

const SearchField = ({ onChange }) => {
  return (
    <div className="team-page__table-header-search">
      <TextField
        data={{ ...data.searchData }}
        recordSelection={(_, value) => onChange(value)}
        delay={500}
      />
    </div>
  );
};

SearchField.propTypes = {
  onChange: PropTypes.func,
};

export default SearchField;
