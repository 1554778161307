export const downloadFile = (blob, filename) => {
  const TIMEOUT = 100;
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  a.remove();
  setTimeout(() => window.URL.revokeObjectURL(url), TIMEOUT);
};
