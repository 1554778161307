export const emailFieldData = {
  id: 3,
  value: "",
  name: "email",
  disabled: false,
  label: "",
  placeholder: "Work email address*",
  type: "email",
  required: true,
  validate: true,
  component: "textfield",
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field",
    },
    {
      validationName: "regex",
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      priority: 2,
      message: "Invalid email",
    },
    {
      validationName: "minLength",
      value: 8,
      priority: 3,
      message: `Minimum required length is 8 characters`,
    },
    {
      validationName: "maxLength",
      value: 50,
      priority: 4,
      message: `Maximum allowed length is 50 characters`,
    },
  ],
};

export const passwordFieldData = {
  id: 5,
  value: '',
  name: 'password',
  label: '',
  placeholder: 'Password*',
  type: 'password',
  required: true,
  validate: true,
  component: 'textfield',
  validations: [
    {
      validationName: 'required',
      priority: 1,
      message: 'This is required field',
    },
    {
      validationName: 'minLength',
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`,
    },
    {
      validationName: 'maxLength',
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`,
    },
    {
      validationName: 'regex',
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
      priority: 4,
      message:
        "Password must contain upper and lowercase letters and a special character (except _ ).",
    },
  ],
};

export const confirmPasswordFieldData = {
  id: 6,
  value: '',
  name: 'confirm_password',
  label: '',
  placeholder: 'Confirm password*',
  type: 'password',
  required: true,
  validate: true,
  component: 'textfield',

  validations: [
    {
      validationName: 'required',
      priority: 1,
      message: 'This is required field',
    },
    {
      validationName: 'minLength',
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`,
    },
    {
      validationName: 'maxLength',
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`,
    },
    {
      validationName: 'regex',
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
      priority: 4,
      message:
        'Password must contain upper and lowercase letters and a special character (except _ ).',
    },
  ],
};

export const acceptTermsFieldData = {
  id: 7,
  checked: false,
  name: "accept_terms",
  description: "I agree to the TeamLytica Terms of Use",
  component: "checkbox",
};

export const emailUpdateOffersFieldData = {
  id: 8,
  checked: true,
  name: "email_update_offers",
  description: "Sign up for TeamLytica Insights",
  component: "checkbox",
};
