import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";

import useComparison from "../../utils/validateComparison";

import { setNavigationAssets } from "../../../../store/actions/navigation.action";

import PageLoading from "../../../../components/PageLoading";
import CompareChart from "../../components/CompareChart";
import IndexScore from "../../components/IndexScore";
import ReportsTabbar from "../../components/ReportsTabbar";

import PageContent from "../../../../components/PageContent";
import { INDICATORS } from "../../../../constants/metrics";
import ReportsFooter from "../../components/ReportsFooter";
import "./CohesionCompare.scss";

function CohesionCompare(props) {
  const {
    setNavigationAssets,
    isLoadingPage,
    comparedCategoryAverages,
    reports,
  } = props;

  useComparison();

  React.useEffect(() => {
    const navigation = {
      title: "COMPARE AND BENCHMARK",
      subtitle: "Team Synergy",
      tab: "COMPARE AND BENCHMARK",
    };
    setNavigationAssets(navigation);
  }, []);

  return (
    <PageLoading
      isLoading={isLoadingPage}
      render={() => (
        <Fragment>
          <IndexScore
            questionAveragesType={INDICATORS.TEAM_SYNERGY}
            isBenchmark={true}
            comparableReports={reports}
          />
          <PageContent>
            <ReportsTabbar compare={true} />
            {comparedCategoryAverages &&
              comparedCategoryAverages[0]?.map((category, i) => (
                <div className="mt-4" key={category.category_id}>
                  <CompareChart
                    data={{
                      title: category.category_name,
                      subtitle: category.category_description,
                      icon: category.icon_url,
                      initialData: {
                        score: category.average,
                        date: category.date,
                        teamName: category.team_name,
                      },
                      lastData: {
                        score: comparedCategoryAverages[1][i].average,
                        date: comparedCategoryAverages[1][i].date,
                        teamName: category.team_name,
                      },
                      id: category.category_id,
                      isRedirectable: true,
                    }}
                  />
                </div>
              ))}
            <ReportsFooter showMemberStatus={false} />
          </PageContent>
        </Fragment>
      )}
    />
  );
}

CohesionCompare.propTypes = {
  isLoadingPage: PropTypes.bool,
  setNavigationAssets: PropTypes.func,
  comparedCategoryAverages: PropTypes.array,
  reports: PropTypes.array,
};

const mapStateToProps = ({ benchmarking, reports }) => {
  const isLoadingPage = benchmarking.isLoadingComparedCategoryAverages;

  return {
    comparedCategoryAverages: benchmarking.comparedCategoryAverages,
    isLoadingPage,
    reports: reports.reports,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
})(CohesionCompare);
