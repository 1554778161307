import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { getProgress } from "../../../../store/helpers/uiHelper";

//scss
import "./BarGraph.scss";

class BarGraph extends Component {
  render() {
    const { values, total } = this.props;
    return (
      <div className="bargraph">
        {values.map(question => (
          <Fragment key={question.rating}>
            <div className="bargraph__bar">
              <div className="bargraph__bar-container">
                <div
                  className="bargraph__bar-value"
                  style={{
                    height: `${getProgress(question.count, total)}%`
                  }}
                />
              </div>
              <div className="bargraph__bar-description">{question.rating}</div>
            </div>
          </Fragment>
        ))}
      </div>
    );
  }
}

BarGraph.propTypes = {
  values: PropTypes.array,
  total: PropTypes.number
};

export default BarGraph;
