import { ERROR } from "../../constants/ApiResources";
import { notifyError, notifySuccess } from "../../utils/notifications";
import { authHeader } from "./authHeader";
import { handleErrors } from "./errorHandlers";

const baseUrl = process.env.REACT_APP_TEAMMETRICS_CORE_API_URL;

export const methods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
};

export function Post(resource, body, parameter = null) {
  const apiUrl = UrlBuilder(resource, parameter);
  return fetch(apiUrl, buildRequestOptions(methods.POST, body))
    .then(handleErrors)
    .then((res) => {
      res.ok && notifySuccess("Success saving data!");
    })
    .catch((error) => {
      error && error.toString().includes("SyntaxError") ? null : notifyError(error.toString());
      return ERROR;
    });
}

export function Get(resource, parameter = null, segment = null, filter, filterName) {
  const apiUrl = UrlBuilder(resource, parameter, segment, filter, filterName);
  return fetch(apiUrl, buildRequestOptions(methods.GET))
    .then(handleErrors)
    .then((res) => {
      return res.json();
    })
    .catch((error) =>
      error.toString().includes("SyntaxError") ? null : notifyError(error.toString())
    );
}

function UrlBuilder(resource, parameter = null, segment = null, filter = [], filterName) {
  const url = filterName
    ? `${baseUrl}${resource}${filterName}=${filter.toString()}`
    : `${baseUrl}${resource}${(parameter && `/${parameter}`) || ""}${
        (segment && `/${segment}`) || ""
      }`;
  return url;
}

function buildRequestOptions(method, body = null) {
  return {
    method: method,
    headers: authHeader(),
    body: body,
  };
}
