import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import TextField from "../../../../components/TextField";
import { Link } from "react-router-dom";

//redux actions
import { authActions } from "../../../../store/actions/auth.action";

//utils
import * as data from "./data";
import * as services from "../../../../store/services/validation.service";

//images
import Logo from "../../../../assets/images/logos/tm_logo_blue.svg";

// scss
import "../../Auth.scss";
import { Footer, RedirectPanel } from "../../components";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordFieldData: data.passwordFieldData,
      confirmPasswordFieldData: data.confirmPasswordFieldData,
      isFormSubmitted: false,
      emailConfirmed: this.checkEmailValidation(),
    };
    this.formStatus = {};
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  removeFormsStatusFlag = (formStatusFlag) => {
    if (formStatusFlag) delete this.formStatus[formStatusFlag];
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = services.getFormsValidityStatus(this.formStatus);
    const { password, confirm_password } = this.state;
    const { userId, code } = this.props.match.params;

    if (isFormValid) {
      this.setState({ errorMessage: "" });
      this.props.resetPassword(userId, code, password, confirm_password);
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Form is invalid, please review your information.",
      });
    }
  };

  checkEmailValidation() {
    const query = new URLSearchParams(this.props.location.search);
    const emailConfirmed = query.get("email-confirmed");
    if (emailConfirmed === "False") return false;
    if (emailConfirmed === "True") return true;
    return emailConfirmed;
  }

  renderButtons() {
    if (this.props.isLoading) {
      return <div className="loader" />;
    } else {
      return (
        <button type="submit" className="submit-button">
          <span>CHANGE PASSWORD</span>
        </button>
      );
    }
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="auth-page__form-fields-title">
          <span>Reset your password:</span>
        </div>
        <div className="auth-page__form-fields-subtitle">
          <span>Please enter your new password.</span>
        </div>
        <TextField
          data={{
            ...this.state.passwordFieldData,
          }}
          recordSelection={this.updateFormFieldValue}
          updateFormsStatus={this.updateFormsStatus}
          removeFormsStatusFlag={this.removeFormsStatusFlag}
          delay={500}
          isFormSubmitted={this.state.isFormSubmitted}
        />
        <TextField
          data={{
            ...this.state.confirmPasswordFieldData,
          }}
          recordSelection={this.updateFormFieldValue}
          updateFormsStatus={this.updateFormsStatus}
          removeFormsStatusFlag={this.removeFormsStatusFlag}
          delay={500}
          isFormSubmitted={this.state.isFormSubmitted}
        />
        <div className="auth-page__form-buttons">{this.renderButtons()}</div>
        <div style={{ marginTop: 10 }}>
          <div
            className="error-message"
            style={
              this.state.emailConfirmed !== null && this.state.emailConfirmed
                ? { color: "green" }
                : {}
            }
          >
            {this.state.errorMessage
              ? this.state.errorMessage
              : this.props.resetPasswordError
              ? this.props.resetPasswordError
              : this.state.emailConfirmed !== null && this.state.emailConfirmed
              ? "Email confirmation successful! You can login now."
              : this.state.emailConfirmed !== null && !this.state.emailConfirmed
              ? "Email confirmation failed! Please register again."
              : ""}
          </div>
        </div>
      </form>
    );
  }

  renderSuccess() {
    return (
      <div className="reset-password__success">
        <label className="success__title">Your password has changed!</label>
        <label className="success__message">You have successfully changed your password!</label>
        <Link className="register-button" to="/login">
          Login
        </Link>
      </div>
    );
  }

  render() {
    return (
      <div className="auth-page">
        <div className="auth-page__form">
          <div className="auth-page__form-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="auth-page__form-fields">
            {this.props.isPasswordReset ? this.renderSuccess() : this.renderForm()}
            <div className="auth-page__form-recover-label">
              <div style={{ display: "inline-block" }}>
                <span>Remembered your password? </span>
              </div>{" "}
              <div style={{ display: "inline-block", color: "#1392d3" }}>
                <Link to="/login">Login here.</Link>
              </div>
            </div>
          </div>
          <RedirectPanel label="New to TeamLytica?" linkLabel="Register" linkTo="/" />
          <Footer />
        </div>
        <div className="auth-page__image">
          <div className="auth-page__image-container" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoading, resetPasswordError, isPasswordReset } = state.auth;

  return {
    isLoading,
    resetPasswordError,
    isPasswordReset,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (userId, code, password, confirm_password) =>
      dispatch(authActions.resetPassword(userId, code, password, confirm_password)),
  };
}

ResetPassword.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  isPasswordReset: PropTypes.bool,
  resetPasswordError: PropTypes.object,
  resetPassword: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
