import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          margin: '0 !important',
          minHeight: '20px !important',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
        content: {
          justifyContent: 'flex-end',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
  },
});

export default theme;
