import { actions } from "../actions/reports.action";

const initialState = {
  isLoadingCategory: false,
  isLoadingComments: false,
  isLoadingMemberStatus: false,
  isLoadingQuestionAverages: false,
  isLoadingCategoryAverages: false,
  isLoadingReports: false,
  isLoadingTop10: false,
  isLoadingUrgentActions: false,
  isLoadingCommentTypes: false,
  isGeneratingPdf: false,
  error: null,
  reports: [],
  report: {},
  commentTypes: [],
};

export default function subscriptions(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_MEMBER_STATUS_REQUEST:
      return {
        ...state,
        isLoadingMemberStatus: true,
        error: null,
      };
    case actions.FETCH_MEMBER_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingMemberStatus: false,
        members: action.payload,
      };
    case actions.FETCH_MEMBER_STATUS_FAILURE:
      return {
        ...state,
        isLoadingMemberStatus: false,
        error: action.payload,
      };
    case actions.FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        isLoadingCategory: true,
        error: null,
      };
    case actions.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingCategory: false,
        category: action.payload,
      };
    case actions.FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        isLoadingCategory: false,
        error: action.payload,
      };
    case actions.FETCH_QUESTION_AVERAGES_REQUEST:
      return {
        ...state,
        isLoadingQuestionAverages: true,
        error: null,
      };
    case actions.FETCH_QUESTION_AVERAGES_SUCCESS:
      return {
        ...state,
        isLoadingQuestionAverages: false,
        questionAverages: action.payload,
      };
    case actions.FETCH_QUESTION_AVERAGES_FAILURE:
      return {
        ...state,
        isLoadingQuestionAverages: false,
        error: action.payload,
      };
    case actions.FETCH_CATEGORY_AVERAGES_REQUEST:
      return {
        ...state,
        isLoadingCategoryAverages: true,
        error: null,
      };
    case actions.FETCH_CATEGORY_AVERAGES_SUCCESS:
      return {
        ...state,
        isLoadingCategoryAverages: false,
        categoryAverages: action.payload,
      };
    case actions.FETCH_CATEGORY_AVERAGES_FAILURE:
      return {
        ...state,
        isLoadingCategoryAverages: false,
        error: action.payload,
      };
    case actions.FETCH_REPORTS_TOP10_REQUEST:
      return {
        ...state,
        isLoadingTop10: true,
        error: null,
      };
    case actions.FETCH_REPORTS_TOP10_SUCCESS:
      return {
        ...state,
        isLoadingTop10: false,
        top10: action.payload,
      };
    case actions.FETCH_REPORTS_TOP10_FAILURE:
      return {
        ...state,
        isLoadingTop10: false,
        error: action.payload,
      };
    case actions.FETCH_URGENT_ACTIONS_REQUEST:
      return {
        ...state,
        isLoadingUrgentActions: true,
        error: null,
      };
    case actions.FETCH_URGENT_ACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingUrgentActions: false,
        urgentActions: action.payload,
      };
    case actions.FETCH_URGENT_ACTIONS_FAILURE:
      return {
        ...state,
        isLoadingUrgentActions: false,
        error: action.payload,
      };
    case actions.FETCH_ASSESSMENT_COMMENTS_REQUEST:
      return {
        ...state,
        isLoadingComments: true,
        error: null,
      };
    case actions.FETCH_ASSESSMENT_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoadingComments: false,
        comments: action.payload,
      };
    case actions.FETCH_ASSESSMENT_COMMENTS_FAILURE:
      return {
        ...state,
        isLoadingComments: false,
        error: action.payload,
      };

    case actions.FETCH_COHESION_INDICATOR_COMMENTS_REQUEST:
      return {
        ...state,
        isLoadingComments: true,
        error: null,
      };
    case actions.FETCH_COHESION_INDICATOR_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoadingComments: false,
        cohesionComments: action.payload,
      };
    case actions.FETCH_COHESION_INDICATOR_COMMENTS_FAILURE:
      return {
        ...state,
        isLoadingComments: false,
        error: action.payload,
      };

    case actions.FETCH_MORALE_INDICATOR_COMMENTS_REQUEST:
      return {
        ...state,
        isLoadingComments: true,
        error: null,
      };
    case actions.FETCH_MORALE_INDICATOR_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoadingComments: false,
        moraleComments: action.payload,
      };
    case actions.FETCH_MORALE_INDICATOR_COMMENTS_FAILURE:
      return {
        ...state,
        isLoadingComments: false,
        error: action.payload,
      };

    case actions.FETCH_STRESS_INDICATOR_COMMENTS_REQUEST:
      return {
        ...state,
        isLoadingComments: true,
        error: null,
      };
    case actions.FETCH_STRESS_INDICATOR_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoadingComments: false,
        stressComments: action.payload,
      };
    case actions.FETCH_STRESS_INDICATOR_COMMENTS_FAILURE:
      return {
        ...state,
        isLoadingComments: false,
        error: action.payload,
      };

    case actions.FETCH_REPORT_STATUS_REQUEST:
      return {
        ...state,
        isLoadingReportStatus: true,
        error: null,
      };
    case actions.FETCH_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingReportStatus: false,
        reportStatus: action.payload,
      };
    case actions.FETCH_REPORT_STATUS_FAILURE:
      return {
        ...state,
        isLoadingReportStatus: false,
        error: action.payload,
      };

    case actions.FETCH_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingReports: true,
        error: null,
      };
    case actions.FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        isLoadingReports: false,
        reports: action.payload,
        report: action.payload.find(report => report.selected === true)
      };
    case actions.FETCH_REPORTS_FAILURE:
      return {
        ...state,
        isLoadingReports: false,
        error: action.payload,
      };

    case actions.FETCH_ALL_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingReports: true,
        error: null,
      };
    case actions.FETCH_ALL_REPORTS_SUCCESS:
      return {
        ...state,
        isLoadingReports: false,
        allReports: action.payload,
        reports: action.payload,
        report: action.payload.find(report => report.selected === true)
      };
    case actions.FETCH_ALL_REPORTS_FAILURE:
      return {
        ...state,
        isLoadingReports: false,
        error: action.payload,
      };

    case actions.UPDATE_SELECTED_REPORT_REQUEST:
      return {
        ...state,
        isLoadingReports: true,
        error: null,
      };
    case actions.UPDATE_SELECTED_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReports: false,
        report: action.payload.find(report => report.selected === true)
      };
    case actions.UPDATE_SELECTED_REPORT_FAILURE:
      return {
        ...state,
        isLoadingReports: false,
        error: action.payload,
      };
    case actions.GET_SELECTED_REPORT_REQUEST:
      return {
        ...state,
        isLoadingReports: true,
        error: null,
      };
    case actions.GET_SELECTED_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReports: false,
        report: action.payload.find(report => report.selected === true)
      };
    case actions.GET_SELECTED_REPORT_FAILURE:
      return {
        ...state,
        isLoadingReports: false,
        error: action.payload,
      };
    case actions.FETCH_PDF_REPORT_REQUEST:
      return {
        ...state,
        isGeneratingPdf: true,
        error: null,
      };
    case actions.FETCH_PDF_REPORT_SUCCESS:
      return {
        ...state,
        isGeneratingPdf: false,
        pdf: action.payload,
      };
    case actions.FETCH_PDF_REPORT_FAILURE:
      return {
        ...state,
        isGeneratingPdf: false,
        error: action.payload,
      };

    case actions.SUBMIT_REPORT_CODE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actions.SUBMIT_REPORT_CODE_SUCCESS:
      return {
        ...state,
      };

    case actions.SUBMIT_REPORT_CODE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actions.RESET_REPORT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actions.RESET_REPORT_SUCCESS:
      return {
        ...state,
      };
    case actions.RESET_REPORT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actions.FETCH_ASSESSMENT_COMMENT_TYPES_REQUEST:
      return {
        ...state,
        isLoadingCommentTypes: true,
        error: null,
      };
    case actions.FETCH_ASSESSMENT_COMMENT_TYPES_SUCCESS:
      return {
        ...state,
        commentTypes: action.payload,
        isLoadingCommentTypes: false,
      };
    case action.FETCH_ASSESSMENT_COMMENT_TYPES_FAILURE:
      return {
        ...state,
        isLoadingCommentTypes: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
