import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMemberStatus } from "../../../../store/actions/reports.action";
//scss
import "./ReportsFooter.scss";

class ReportsFooter extends Component {
  componentDidMount() {
    this.props.fetchMemberStatus();
  }

  render() {
    const { members, isCurrent, showMemberStatus = true } = this.props;
    return (
      <div className="reports-footer">
        <div className="reports-footer__users">
          {members && isCurrent && showMemberStatus && (
            <>
              <div className="reports-footer__users-info left">
                Invited: {members.invited}
              </div>
              <div className="reports-footer__users-info">
                Completed: {members.completed}
              </div>
              <div className="reports-footer__users-info right">
                Pending: {members.pending}
              </div>
            </>
          )}
        </div>
        <div className="reports-footer__scale">
          <div className="reports-footer__scale-info">
            <span className="reports-footer__scale-info-dot low" />
            <span className="reports-footer__scale-info-label">
              Score &lt; 6
            </span>
          </div>
          <div className="reports-footer__scale-info">
            <span className="reports-footer__scale-info-dot medium" />
            <span className="reports-footer__scale-info-label">
              Score 6 - 8
            </span>
          </div>
          <div className="reports-footer__scale-info">
            <span className="reports-footer__scale-info-dot high" />
            <span className="reports-footer__scale-info-label">
              Score &gt; 8
            </span>
          </div>
        </div>
      </div>
    );
  }
}

ReportsFooter.propTypes = {
  fetchMemberStatus: PropTypes.func,
  members: PropTypes.object,
  isCurrent: PropTypes.bool,
  showMemberStatus: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  members: state.reports.members,
  isCurrent:
    state.reports &&
    state.reports.reportStatus &&
    state.reports.reportStatus.is_current,
});

export default connect(mapStateToProps, { fetchMemberStatus })(ReportsFooter);
