import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

//components
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Checkbox from "../../../../../../components/Checkbox";
import TextField from "../../../../../../components/TextField";

//redux actions
import { createAccount } from "../../../../../../store/actions/auth.action";

//utils
import * as data from "./data";
import { getFormsValidityStatus } from "../../../../../../store/services";

//images
import Logo from "../../../../../../assets/images/logos/tm_logo_blue.svg";

//scss
import "../../../../Auth.scss";
import { Footer } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";

const RegisterSignUp = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [user, setUser] = useState({
    accept_terms: data.acceptTermsFieldData.checked,
    email_update_offers: data.emailUpdateOffersFieldData.checked,
  });
  const [formStatus, setFormStatus] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { isAuthenticated, registerData, registerError, isLoading } = useSelector(
    (state) => state.auth
  );
  const location = useLocation();
  const history = useHistory();

  const textfields = useMemo(
    () => [
      { data: data.emailFieldData },
      { data: data.passwordFieldData },
      { data: data.confirmPasswordFieldData },
    ],
    [data]
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.email) {
      setUser((prevState) => ({ ...prevState, ["email"]: params.email }));
    }
    if (params.code) {
      setUser((prevState) => ({ ...prevState, ["code"]: params.code }));
    }
  }, []);

  const checkboxes = useMemo(() => [{ data: data.acceptTermsFieldData }], [data]);

  const updateFormFieldValue = (fieldName, fieldValue) => {
    setUser((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const updateFormsStatus = (fieldStatus, fieldName) => {
    setFormStatus((prevState) => ({ ...prevState, [fieldName]: fieldStatus }));
  };

  const validateTerms = () => {
    const { accept_terms } = user;
    return accept_terms;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = getFormsValidityStatus(formStatus);
    const { email, password, code } = user;

    if (isFormValid) {
      if (!validateTerms()) {
        setIsFormSubmitted(true);
        setErrorMessage("Please read and agree with the TeamLytica terms and conditions.");
        return;
      }
      setErrorMessage("");
      dispatch(createAccount(email, password, code)).then(() => history.push('/login'));
    } else {
      setIsFormSubmitted(true);
      setErrorMessage("Form is invalid, please review your information.");
    }
  };

  const renderComponentForField = (field) => {
    switch (field.data.component) {
      case "checkbox":
        return (
          <Checkbox
            data={{
              ...field.data,
              checked:
                user && user[field.data.name] !== undefined
                  ? user[field.data.name]
                  : registerData && registerData[field.data.name]
                  ? registerData[field.data.name]
                  : field.data.checked,
            }}
            onSelect={updateFormFieldValue}
          />
        );
      case "textfield":
        return (
          <TextField
            data={{
              ...field.data,
              value:
                user && user[field.data.name]
                  ? user[field.data.name]
                  : registerData && registerData[field.data.name]
                  ? registerData[field.data.name]
                  : field.data.value,
              disabled: field.data.name === "email" && !!user.email,
            }}
            recordSelection={updateFormFieldValue}
            updateFormsStatus={updateFormsStatus}
            delay={500}
            isFormSubmitted={isFormSubmitted}
          />
        );
      default:
        return null;
    }
  };

  const renderButtons = () => {
    if (isLoading) {
      return <div className="loader" />;
    } else {
      return (
        <button type="submit" className="submit-button">
          <span>Create account</span>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </button>
      );
    }
  };
  if (isAuthenticated) {
    const { from = "/dashboard" } = location.state || {};
    return <Redirect to={from} />;
  }
  return (
    <div className="auth-page">
      <div className="auth-page__form">
        <div className="auth-page__form-logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="auth-page__form-fields">
          <form onSubmit={handleSubmit}>
            <div className="auth-page__form-fields-title">
              <span>Let&apos;s get you signed up:</span>
            </div>
            <div className="auth-page__form-fields-subtitle">
              <span>
                Create your password - it must contain upper and lowercase letters and a special
                character &#40;except _ &#41;.
              </span>
            </div>
            {textfields.map((textfield) => (
              <div key={textfield.data.id}>{renderComponentForField(textfield)}</div>
            ))}
            <div className="error-message">
              {errorMessage ? errorMessage :  registerError ? registerError : ""}
            </div>
            <div className="auth-page__form-buttons">{renderButtons()}</div>
            {checkboxes.map((checkbox) => (
              <div key={checkbox.data.id}>{renderComponentForField(checkbox)}</div>
            ))}
          </form>
        </div>
        <Footer />
      </div>
      <div className="auth-page__image">
        <div className="auth-page__image-container" />
      </div>
    </div>
  );
};

RegisterSignUp.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  checkRegistration: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  registerData: PropTypes.object,
  registerError: PropTypes.string,
  fetchCountries: PropTypes.func,
  countries: PropTypes.array,
  match: PropTypes.object,
};

export default RegisterSignUp;
