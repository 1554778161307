import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PageLoading from "../../components/PageLoading";

import { setNavigationAssets } from "../../store/actions/navigation.action";
import { videoList } from "../../utils/video-list";

// scss
import "./SupportVideos.scss";
import PageTitle from "../../components/PageTitle";
import PageContent from "../../components/PageContent";

class SupportVideos extends Component {
  componentDidMount() {
    const navigation = { title: "EXPLAINER VIDEOS" };
    this.props.setNavigationAssets(navigation);
  }

  render() {
    return (
      <PageLoading
        isLoading={false}
        render={() => (
          <>
            <PageTitle
              title="Help Videos"
              subtitle="Watch our help videos to get the most out of our Team Lytica Reports"
            ></PageTitle>
            <PageContent>
              {videoList.map((section, key) => (
                <>
                  <div className="video-title">
                    <span>{section.title}</span>
                  </div>
                  <div className="video-container" key={key}>
                    {section.videos.map((video, key) => (
                      <div className="video-wrapper" key={key}>
                        <iframe
                          className="video"
                          src={video.source}
                          title={`video${key}`}
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen={true}
                        />
                        <span>{video.description}</span>
                      </div>
                    ))}
                  </div>
                </>
              ))}
            </PageContent>
          </>
        )}
      />
    );
  }
}

SupportVideos.propTypes = {
  setNavigationAssets: PropTypes.func,
};

export default connect(null, { setNavigationAssets })(SupportVideos);
