import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createTeamMembers } from "../../../../store/actions/team.action";
import { INITIAL_NEW_MEMBERS_ROWS } from "../../constants";
import SearchField from "../SearchField/SearchField";
import { useTableColumns, useTableOptions } from "./hooks";

const TeamTable = ({
  title,
  teamId,
  teamMembers: rows,
  user,
  handleTeamMemberDialogOpen,
  reports,
  createTeamMembers,
  allMembers,
}) => {
  const [newRows, setNewRows] = useState(
    Array.from({ length: INITIAL_NEW_MEMBERS_ROWS }, (_, index) => [
      `local_${index}`,
    ])
  );

  const history = useHistory();

  const isTeam360 = useMemo(
    () => history.location.pathname.includes("stakeholders"),
    [history]
  );

  const [searchValue, setSearchValue] = useState("");

  const onSearchChange = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const handleAddNewRow = useCallback(() => {
    setNewRows((prevRows) => {
      return [...prevRows, [`local_${prevRows.length + 1}`]];
    });
  }, []);

  const handleRemoveRow = useCallback(
    (rowId) => {
      setNewRows((prevRows) =>
        prevRows.filter((row) => row[0].toString() !== rowId)
      );
    },
    [newRows]
  );

  const handleInputRowChange = useCallback((rowId, columnIndex, value) => {
    setNewRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.find((row) => row[0] === rowId)[columnIndex] = value;
      return newRows;
    });
  }, []);

  const handlePurchaseNewMembers = useCallback(() => {
    const quantityCreditsNeeded = allMembers.filter(
      (member) => !member.is_stock_available
    ).length;
    history.push("/product/team-member-credit", {
      quantity: quantityCreditsNeeded,
      teamId,
    });
  }, [allMembers, teamId, history]);

  const handleAddNewMembers = useCallback(() => {
    const newMembers = newRows
      .filter((row) => row[1] && row[2])
      .map((row) => {
        return {
          name: row[1],
          email: row[2],
        };
      });

    if (newMembers.length) {
      createTeamMembers(teamId, newMembers);
    }
  }, [newRows, teamId]);

  const canSeeLastReport = useMemo(() => {
    const nonT360Reports = reports?.filter((report) => !report.isTeam360) ?? [];
    const lastReport = nonT360Reports.at(-1);
    return lastReport?.canSeeReport;
  }, [reports]);

  const columns = useTableColumns({
    handleInputRowChange,
    handleRemoveRow,
    handleTeamMemberDialogOpen,
  });

  const options = useTableOptions({
    searchValue,
    user,
    isTeam360,
    canSeeLastReport,
    handleTeamMemberDialogOpen,
    handleAddNewRow,
    handleAddNewMembers,
    handlePurchaseNewMembers,
  });

  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
      fontSize: 15,
      useNextVariants: true,
    },
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          fontWeight: "bold",
          fontSize: 16,
          color: "#173b59",
          borderBottom: "2px solid #1392d3",
        },
      },
    },
  });

  return (
    <div className="team-page__table">
      <div className="team-page__table-header">
        <span className="team-page__table-header-title">Your {title}</span>
        {!!rows.length && <SearchField onChange={onSearchChange} />}
      </div>
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          data={[...rows, ...newRows]}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

TeamTable.propTypes = {
  teamId: PropTypes.number,
  teamMembers: PropTypes.array,
  title: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  handleTeamMemberDialogOpen: PropTypes.func,
  reports: PropTypes.array,
  teamMemberSlots: PropTypes.number,
  createTeamMembers: PropTypes.func,
  allMembers: PropTypes.array,
};

const mapStateToProps = ({ team, reports, general }) => {
  return {
    allMembers: team.allMembers,
    teamId: team.team_id,
    team: team.team,
    teamMemberSlots: team.teamMemberSlots,
    isLoadingPage: team.isLoadingTeam,
    error: team.error,
    user: general.content,
    reports: reports.reports,
    reportStatus: reports.reportStatus,
  };
};

export default connect(mapStateToProps, {
  createTeamMembers,
})(TeamTable);
