import React from "react";
import DeleteButton from "../../../../components/DeleteButton/DeleteButton";
import Input from "../../../../components/Input/Input";
import TableFooter from "../../../../components/TableFooter/TableFooter";
import { renderDate } from "../../../../store/helpers/uiHelper";
import TableOptions from "./TableOptions/TableOptions";

export const useTableOptions = ({
  searchValue,
  user,
  isTeam360,
  canSeeLastReport,
  handleTeamMemberDialogOpen,
  handleAddNewRow,
  handleAddNewMembers,
  handlePurchaseNewMembers,
  canAddNewMembers,
}) => ({
  selectableRows: "none",
  elevation: 0,
  rowsPerPage: 1000,
  pagination: true,
  fixedHeader: true,
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  searchText: searchValue,
  customFooter: (count, page, rowsPerPage, _changeRowsPerPage, changePage) => {
    return (
      <TableFooter
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        user={user}
        isTeam360={isTeam360}
        canSeeLastReport={canSeeLastReport}
        handleTeamMemberDialogOpen={handleTeamMemberDialogOpen}
        onAddNewRow={handleAddNewRow}
        onAddNewMembers={handleAddNewMembers}
        onPurchaseNewMembers={handlePurchaseNewMembers}
        canAddNewMembers={canAddNewMembers}
      />
    );
  },
});

export const useTableColumns = ({
  handleInputRowChange,
  handleTeamMemberDialogOpen,
  handleRemoveRow,
}) => {
  return [
    {
      name: "id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const rowId = row[0];
          const columnData = row[tableMeta.columnIndex];

          if (!rowId.toString().includes("local")) {
            return value;
          }

          return (
            <Input
              placeholder="Name"
              value={columnData || ""}
              onChange={(event) =>
                handleInputRowChange(
                  rowId,
                  tableMeta.columnIndex,
                  event.target.value
                )
              }
            />
          );
        },
      },
    },
    {
      name: "Email",
      options: {
        customBodyRender: (columnValue, tableMeta) => {
          const row = tableMeta.rowData;
          const rowId = row[0];
          const columnData = row[tableMeta.columnIndex];

          if (!rowId.toString().includes("local")) {
            return columnValue;
          }

          return (
            <Input
              placeholder="Email"
              value={columnData || ""}
              onChange={(event) =>
                handleInputRowChange(
                  rowId,
                  tableMeta.columnIndex,
                  event.target.value
                )
              }
            />
          );
        },
      },
    },
    {
      name: "Status",
      options: {
        customBodyRender: (value) => (
          <div className={`team-page__table-status ${value}`}>{value}</div>
        ),
      },
    },
    {
      name: "Added on",
      options: {
        customBodyRender: (value) => {
          return value ? renderDate(value, "long") : "";
        },
      },
    },
    {
      name: "status_id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "team_id",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Options",
      options: {
        sort: false,
        customBodyRender: (_, tableMeta) => {
          const row = tableMeta.rowData;
          const rowId = row[0];

          return !rowId.toString().includes("local") ? (
            <TableOptions
              tableMeta={tableMeta}
              handleTeamMemberDialogOpen={handleTeamMemberDialogOpen}
            />
          ) : (
            <div className="team-page__table-options">
              <DeleteButton onClick={() => handleRemoveRow(rowId)} />
            </div>
          );
        },
      },
    },
  ];
};
