import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import { Redirect } from "react-router-dom";
import Dropdown from "../../../../../../components/Dropdown";
import TextField from "../../../../../../components/TextField";
import PageLoading from "../../../../../../components/PageLoading";

//redux actions
import { authActions } from "../../../../../../store/actions/auth.action";
import {
  fetchIndustries,
  fetchJobLevels,
  fetchDepartments,
} from "../../../../../../store/actions/general.action";

//utils
import * as data from "./data";
import { getFormsValidityStatus } from "../../../../../../store/services";

//images
import Logo from "../../../../../../assets/images/logos/tm_logo_blue.svg";
import Progress2 from "../../../../../../assets/images/other/progress2.svg";

//scss
import "../../../../Auth.scss";
import { Footer, RedirectPanel } from "../../../../components";

class RegisterCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        { data: data.companyNameData },
        { data: data.industriesData },
        { data: data.departmentsData },
        { data: data.jobLevelsData },
      ],
      user: {
        industry_id: 0,
        department_id: 0,
        job_level_id: 0,
        ...(this.props.registerData ? this.props.registerData : {}),
      },
      isFormSubmitted: false,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    this.props.fetchIndustries();
    this.props.fetchJobLevels();
    this.props.fetchDepartments();
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState((prevState) => ({
      user: { ...prevState.user, [fieldName]: fieldValue },
    }));
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  getDropdownList = (field) => {
    switch (field.data.name) {
      case "industry_id":
        return this.props.industries;
      case "department_id":
        return this.props.departments;
      case "job_level_id":
        return this.props.jobLevels;
      default:
        return [];
    }
  };

  validateFields() {
    const { industry_id, job_level_id, department_id } = this.state.user;

    if (
      !industry_id ||
      industry_id === "0" ||
      !job_level_id ||
      job_level_id === "0" ||
      !department_id ||
      department_id === "0"
    ) {
      return false;
    }
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = getFormsValidityStatus(this.formStatus);

    if (isFormValid) {
      if (this.validateFields()) {
        this.setState({ errorMessage: "" });
        this.props.register(this.state.user);
        return;
      }
    }
    this.setState({
      isFormSubmitted: true,
      errorMessage: "Form is invalid, please fill all the fields.",
    });
  };

  renderComponentForField = (field) => {
    const { user } = this.state;
    const { registerData } = this.props;

    switch (field.data.component) {
      case "textfield":
        return (
          <TextField
            data={{
              ...field.data,
              value:
                user && user[field.data.name]
                  ? user[field.data.name]
                  : registerData && registerData[field.data.name]
                  ? registerData[field.data.name]
                  : field.data.value,
            }}
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            data={{
              ...field.data,
              list: this.getDropdownList(field),
              value:
                user && user[field.data.name]
                  ? user[field.data.name]
                  : registerData && registerData[field.data.name]
                  ? registerData[field.data.name]
                  : field.data.value,
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      default:
        return null;
    }
  };

  renderButtons() {
    if (this.props.isLoading) {
      return <div className="loader" />;
    } else {
      return (
        <div>
          <button type="submit" className="submit-button">
            <span>Register</span>
          </button>
        </div>
      );
    }
  }

  render() {
    const {
      isAuthenticated,
      location,
      registerData,
      registerError,
      isRegistered,
      isLoadingFetches,
    } = this.props;
    const { fields, errorMessage } = this.state;

    if (isAuthenticated) {
      const { from = "/dashboard" } = location.state || {};
      return <Redirect to={from} />;
    }

    if (registerError || !registerData) {
      const { from = "/" } = location.state || {};
      return <Redirect to={from} />;
    }

    if (isRegistered) {
      this.props.history.push(`/register-success`);
    }

    return (
      <div className="auth-page">
        <div className="auth-page__form">
          <div className="auth-page__form-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="auth-page__form-fields">
            <PageLoading
              isLoading={isLoadingFetches}
              render={() => (
                <Fragment>
                  <form onSubmit={this.handleSubmit}>
                    <div className="auth-page__form-fields-title">
                      <span>Step 2. About your team:</span>
                    </div>
                    <div className="auth-page__form-fields-subtitle">
                      <span>
                        Almost there. Could you tell us a few more details about your organisation?
                      </span>
                    </div>
                    {fields.map((field) => (
                      <div key={field.data.id}>{this.renderComponentForField(field)}</div>
                    ))}
                    <div className="error-message">{errorMessage ? errorMessage : ""}</div>
                    <div className="auth-page__form-buttons">{this.renderButtons()}</div>
                  </form>
                  <div className="auth-page__form-progress">
                    <img src={Progress2} alt="Progress" />
                  </div>
                </Fragment>
              )}
            />
          </div>
          <RedirectPanel label="Already registered?" linkLabel="Log in" linkTo="/login" />
          <Footer />
        </div>
        <div className="auth-page__image">
          <div
            className="auth-page__image-container"
            style={{
              backgroundImage: `url(${require(`../../../../../../assets/images/background/background_image_2.jpg`)})`,
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isAuthenticated, isLoading, registerData, registerError, isRegistered } = state.auth;

  const {
    departments,
    industries,
    jobLevels,
    isLoadingDepartments,
    isLoadingIndustries,
    isLoadingJobs,
  } = state.general;

  const isLoadingFetches = isLoadingIndustries && isLoadingDepartments && isLoadingJobs;

  return {
    departments,
    industries,
    jobLevels,
    isAuthenticated,
    isLoading,
    isLoadingFetches,
    isRegistered,
    registerData,
    registerError,
  };
}

RegisterCompany.propTypes = {
  location: PropTypes.object,
  fetchIndustries: PropTypes.func,
  fetchJobLevels: PropTypes.func,
  fetchDepartments: PropTypes.func,
  industries: PropTypes.object,
  departments: PropTypes.object,
  jobLevels: PropTypes.object,
  history: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingDepartments: PropTypes.bool,
  isLoadingFetches: PropTypes.bool,
  isLoadingIndustries: PropTypes.bool,
  isLoadingJobs: PropTypes.bool,
  isRegistered: PropTypes.bool,
  register: PropTypes.func,
  registerData: PropTypes.object,
  registerError: PropTypes.string,
};

export default connect(mapStateToProps, {
  fetchIndustries,
  fetchJobLevels,
  fetchDepartments,
  register: (user) => authActions.register(user),
})(RegisterCompany);
