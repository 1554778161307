import React from "react";
import PropTypes from "prop-types";

const StepSizeSelectorHeader = ({ headerProps }) => {
  return (
    <div className="step-size-selector-header">
      <div className="step-size-selector-header__title">
        <i className={`fa ${headerProps.icon}`}></i>
        <span>{headerProps.title}</span>
      </div>
      <div className="step-size-selector-header__subTitle">
        <span>{headerProps.subtitle}</span>
      </div>
    </div>
  );
};

StepSizeSelectorHeader.propTypes = {
  headerProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
};

export default StepSizeSelectorHeader;
