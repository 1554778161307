export const videoList = [
  {
    title: "Setting up your team",
    videos: [
      {
        source: "https://www.youtube.com/embed/k7BlkklpM2k?rel=0",
        description: "Edit Profile."
      },
      {
        source: "https://www.youtube.com/embed/26etH8Nmpao?rel=0",
        description: "Adding or Deleting Team Members."
      }
    ]
  },
  {
    title: "Using the Platform",
    videos: [
      {
        source: "https://www.youtube.com/embed/uSpopLJEovQ?rel=0",
        description: "Cohesion scores explained."
      },
      {
        source: "https://www.youtube.com/embed/2JaRiz2VFHs?rel=0",
        description: "Category scores explained."
      },
      {
        source: "https://www.youtube.com/embed/VzI5axhLDuI?rel=0",
        description: "Morale Indicators explained."
      },
      {
        source: "https://www.youtube.com/embed/cWVRmMon4Ao?rel=0",
        description: "Stress Indicators explained."
      },
      {
        source: "https://www.youtube.com/embed/VXirq7H_R_I?rel=0",
        description: "Question Scores explained."
      },
      {
        source: "https://www.youtube.com/embed/89wsXCv_ACY?rel=0",
        description: "Disparity scores explained."
      },
      {
        source: "https://www.youtube.com/embed/SMKdRO93KZY?rel=0",
        description: "Understanding team comments."
      },
      {
        source: "https://www.youtube.com/embed/xRILOdKTbx4?rel=0",
        description: "Selecting and Viewing my Team Reports."
      }
    ]
  },
  {
    title: "Additional Services",
    videos: [
      {
        source: "https://www.youtube.com/embed/cWuUhMUi2WE?rel=0",
        description: "Team 360."
      },
      {
        source: "https://www.youtube.com/embed/gGZWdt9YIJI?rel=0",
        description: "Consulting Hour explained."
      }
    ]
  },
  {
    title: "Product Videos",
    videos: [
      {
        source: "https://www.youtube.com/embed/colYbQzXh0Q?rel=0",
        description: "Free Team Summary Report."
      },
      {
        source: "https://www.youtube.com/embed/NSbr3uQJGdU?rel=0",
        description: "Team 360 Report."
      },
      {
        source: "https://www.youtube.com/embed/t0rVw60tfp8?rel=0",
        description: "Re Test Benchmarking."
      },
      {
        source: "https://www.youtube.com/embed/q0O73vCrCCM?rel=0",
        description: "Full Report."
      },
      {
        source: "https://www.youtube.com/embed/ndFM8-0hgZI?rel=0",
        description: "Paid Consulting Hour."
      }
    ]
  }
];
