import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ProgressBar from "../../../../components/ProgressBar";

//utils
import { getValueColor } from "../../../../store/helpers/uiHelper";

//scss
import "./ReportsTopic.scss";

class ReportsTopic extends Component {
  render() {
    const { id, title, subtitle, value, iconUrl } = this.props;

    return (
      <div className="reports-topic">
        <div className="reports-topic__container">
          <Link
            to={`/reports/cohesion/category-detail/${id}`}
            className="reports-topic__content"
          >
            <div className="reports-topic__header">
              <div className="reports-topic__header-text">
                <div className="reports-topic__header-text-title">{title}</div>
                <div className="reports-topic__header-text-subtitle">
                  {subtitle}
                </div>
              </div>
              <div className="reports-topic__header-container">
                <div className="reports-topic__header-container-icon">
                  <img src={iconUrl} alt={title} />
                </div>
                <div
                  className={`reports-topic__header-container-value ${getValueColor(
                    value
                  )}`}
                >
                  <span>{value}</span>
                </div>
              </div>
            </div>
            <div className="reports-topic__progress">
              <ProgressBar value={value} total="10" color={true} />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

ReportsTopic.propTypes = {
  answers: PropTypes.array,
  answersTotal: PropTypes.number,
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.string,
  canExpand: PropTypes.bool,
  iconUrl: PropTypes.string,
};

export default connect(null, null)(ReportsTopic);
