export const reportsData = {
  id: 1,
  name: "allReports",
  label: "Choose you report:",
};

export const PARAGRAPHS = [
  {
    id: 1,
    title: "bm",
    text: "Retest your team with a second (or more) report to find out what progress has been made against every metric.",
  }
];
