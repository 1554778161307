import './TeamReview.scss'

import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useHistory} from 'react-router-dom'
import PageLoading from "../../components/PageLoading";
import PageTitle from "../../components/PageTitle";
import MemberDialog from "../../components/MemberDialog";
import TeamReviewFooter from './components/TeamReviewFooter/TeamReviewFooter';
import TeamReviewHeader from './components/TeamReviewHeader';
import TeamReviewTable from './components/TeamReviewTable';
import {
  actions,
  fetchCompanyId,
  getAreasAction,
  getRegionsAction,
  getTeamsAction,
} from '../../store/actions/wizard.action';
import {actions as actionsTeamReview} from '../../store/actions/teamReview.action'
import { getTeamMembersAction } from '../../store/actions/teamReview.action';
import { useEffect } from 'react';
import TeamReviewTableFooter from './components/TeamReviewTableFooter/TeamReviewTableFooter';

const TeamReview = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLoading, teamMembers } = useSelector((state) => state.teamReview);
  const {companyId, regions, areas, teams } = useSelector((state) => state.teamWizard)
  const [searchValue, setSearchValue] = useState('')
  const [regionsValues, setRegionsValues] = useState([])
  const [areasValues, setAreasValues] = useState([])
  const [teamsValues, setTeamsValues] = useState([])
  const [countSufix, setCountSufix] = useState('')
  const [isTeamMemberDialogOpen, setIsTeamMemberDialogOpen] = useState(false)
  const [teamMemberDialogOptions, setTeamMemberDialogOptions] = useState([])

  const handleSearchChange = (value) => setSearchValue(value)
  const handleRegionsChange = (array) => {
    const result = array.filter(region => region.selected)
    result.length === 1 ?
    setCountSufix(`of ${result[0].value}`) : setCountSufix('')
    setRegionsValues(array)
    const selectedRegions = result.map(region => region.id)
    const _areas = (areas || []).filter(area => {
      if (!selectedRegions.includes(area.regionId)) return false;
      return true
    }).map(area => ({
      id: area.id,
      value: area.name,
      selected: true,
      regionId: area.regionId
    }))
    handleAreasChange(_areas)
  }
  const handleAreasChange = (array) => {
    const result = array.filter(area => area.selected)
    result.length === 1 ?
      setCountSufix(`of ${result[0].value}`) : null
    setAreasValues(array)
    const selectedAreas = result.map(area => area.id)
    const _teams = (teams || []).filter(team => {
      if (!selectedAreas.includes(team.areaId)) return false;
      return true
    }).map(team => ({
      id: team.id,
      value: team.name,
      selected: true,
      areaId: team.areaId
    }))
    handleTeamsChange(_teams)
  }
  const handleTeamsChange = (array) => {
    const result = array.filter(team => team.selected)
    result.length === 1 ?
      setCountSufix(`of ${result[0].value}`) : null
    setTeamsValues(array)
  }
  const handleClearFilters = () => {
    setSearchValue('')
    const newRegionsList = regions.map(region => {
      return {
        id: region.id,
        value: region.name,
        selected: true
      }
    })
    handleRegionsChange(newRegionsList)
    setCountSufix('')
  }
  useEffect(() => {
    dispatch(fetchCompanyId())
  }, [])

  useEffect(() => {
    dispatch(getRegionsAction(companyId))
    dispatch(getAreasAction(companyId))
    dispatch(getTeamsAction(companyId))
  }, [companyId])

  useEffect(() => {
    if (companyId !== null) {
      dispatch(getTeamMembersAction(companyId));
    }
  }, [companyId])
 
  useEffect(() => {
    if(teamMembers && teamMembers.length === 0) {
      dispatch({type: actions.GO_BACK_TEAM_MEMBER_WIZARD})
      dispatch({ type: actionsTeamReview.RETURN_TEAM_MEMBERS_TO_UNDEFINED })
      history.push('/team-wizard')
    }
  }, [teamMembers])

  const isTeam360 = () => {
    return history.location.pathname.includes("stakeholders");
  }
  const handleTeamMemberDialogOpen = (tableMeta, option) => {
    const memberData = [];
    if(tableMeta !== null) {
      memberData.push(
        tableMeta.id,
        tableMeta.name,
        tableMeta.email,
        tableMeta.team_id,
        tableMeta.team,
        tableMeta.regionId,
        tableMeta.areaId,
      );
    }
    setIsTeamMemberDialogOpen(true)
    setTeamMemberDialogOptions([memberData, option])
  };
  const handleTeamMemberDialogClose = () => {
    dispatch(getTeamMembersAction(companyId))
    setIsTeamMemberDialogOpen(false)
  }

  const filteredTeamMembers = useMemo(() => {
    const selectedRegions = regionsValues.filter(region => region.selected).map(region => region.value);
    const selectedAreas = areasValues.filter(area => area.selected).map(area => area.value);
    const selectedTeams = teamsValues.filter(team => team.selected).map(team => team.id)

    return (teamMembers || []).filter(
      (member) => {
        const { name, email } = member;

        // Check for members with the search value
        if (searchValue && searchValue.length && !`${name} ${email}`.includes(searchValue)) {
          return false;
        }

        // Check for members with the selected regions
        if (selectedRegions.length && !selectedRegions.includes(member.region)) {
          return false;
        }

        // Check for members with the selected areas
        if (selectedAreas.length && !selectedAreas.includes(member.area)) {
          return false;
        }

        // Check for members with the selected teams
        if (selectedTeams.length && !selectedTeams.includes(member.team_id)) {
          return false;
        }

        return true;
      }
    );
  }, [teamMembers, searchValue, regionsValues, areasValues]);



  return (
    <PageLoading
      isLoading={isLoading}
      render={() => (
        <div className="team-review">
          <PageTitle
            title="Team Members"
            subtitle="Here you can review your Team Members before sending your TeamLytica Questionnaires"
          />
          <MemberDialog
            open={isTeamMemberDialogOpen}
            onClose={handleTeamMemberDialogClose}
            options={teamMemberDialogOptions}
            isTeam360={isTeam360()}
          />
          <TeamReviewHeader
            onSearchChange={handleSearchChange}
            onRegionsChange={handleRegionsChange}
            onAreasChange={handleAreasChange}
            onTeamsChange={handleTeamsChange}
            searchValue={searchValue}
            regionsValues={regionsValues}
            areasValues={areasValues}
            teamsValues={teamsValues}
          />
          <TeamReviewTable data={filteredTeamMembers} onClearFilters={handleClearFilters} />
          <TeamReviewTableFooter teamMembers={filteredTeamMembers} countSufix={countSufix} onClearFilters={handleClearFilters} />
          <TeamReviewFooter className="region-creation__footer"
            title="Once you've added all your Team Members, you can send your TeamLytica Questionnaires."
            buttonTitle="Send Questionnaires"
            buttonIcon="fa-paper-plane"
            buttonColor="green"
            onClick={() => handleTeamMemberDialogOpen(null, "all")} />
        </div>
      )}
    />
  );
};

export default TeamReview;
