import React from "react";
import PropTypes from "prop-types";
import WizardFooter from "../../../WizardFooter";
import WizardFormTable from "../../../WizardFormTable/WizardFormTable";

import "./AreaCreation.scss";
import { stepEnum } from "../../../../../../store/helpers/teamWizardHelper";

const AreaCreation = ({
  areaAmount,
  onChange,
  data,
  onBackStepClick,
  onNextStepClick,
  regions,
}) => {
  return (
    <div className="area-creation">
      <WizardFormTable
        initialRows={Number(areaAmount)}
        columns={[
          { hidden: true },
          {
            hidden: false,
            icon: "fa-map",
            name: "Area",
            type: "textInput",
            property: "name",
            validate: (value) => {
              return value.length > 2;
            }
          },
          {
            hidden: false,
            icon: "fa-globe",
            name: "Region",
            type: "select",
            property: "region",
            options: regions || [],
          },
        ]}
        data={data}
        onChange={(e) => {
          onChange([...e]);
        }}
      />
      <WizardFooter
        className="area-creation__footer"
        title="Once you've added all your teams' Areas, move on to Teams."
        buttonTitle="Add Teams"
        buttonIcon="fa-users"
        buttonColor="green"
        isFirstStep={false}
        backButtonColor="grey"
        backButtonOnClick={() => onBackStepClick(stepEnum.AreaCreation)}
        backButtonTitle="Back"
        backButtonIcon="fa-solid fa-angle-left"
        onClick={onNextStepClick}
      />
    </div>
  );
};

AreaCreation.propTypes = {
  areaAmount: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
  onBackStepClick: PropTypes.func,
  onNextStepClick: PropTypes.func,
  regions: PropTypes.array,
};

export default AreaCreation;
