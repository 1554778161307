export const apiResource = {
  user: '/api/user',
  companies: '/api/companies',
  regions: '/api/regions',
  areas: '/api/areas',
  teams: '/api/team',
  teamMembersAddMultiple: '/api/team/members/addMultiple',
  teamMembers: '/api/team/members/all',
  areasFilter: '/api/areas/filter?',
  teamsFilter: '/api/team/filter?'
};

export const apiResourceSegment = {
  regions: 'regions',
  areas: 'areas',
  teams: 'teams',
  teamMembers: 'members',
};

export const filterName = {
  regions: 'regionIds',
  areas: 'areaIds'
}

export const ERROR = 'error'
