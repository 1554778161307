import { Get, Post } from "../helpers/HttpHelper";
import { stepEnum } from "../helpers/teamWizardHelper";
import { apiResource, apiResourceSegment, ERROR } from "../../constants/ApiResources";

export const actions = {
  FETCH_COMPANYID_STARTED: "FETCH_COMPANYID_STARTED",
  FETCH_COMPANYID_SUCCESS: "FETCH_COMPANYID_SUCCESS",

  AMOUNT_REGIONS_SET: "AMOUNT_REGIONS_SET",
  SAVE_REGIONS_STARTED: "SAVE_REGIONS_STARTED",
  SAVE_REGIONS_SUCCESS: "SAVE_REGIONS_SUCCESS",
  SAVE_REGIONS_FAIL: "SAVE_REGIONS_FAIL",
  FETCH_REGIONS_STARTED: "FETCH_REGIONS_STARTED",
  FETCH_REGIONS_SUCCESS: "FETCH_REGIONS_SUCCESS",

  AMOUNT_AREAS_SET: "AMOUNT_AREAS_SET",
  FETCH_AREAS_STARTED: "FETCH_AREAS_STARTED",
  FETCH_AREAS_SUCCESS: "FETCH_AREAS_SUCCESS",
  SAVE_AREAS_STARTED: "SAVE_AREAS_STARTED",
  SAVE_AREAS_SUCCESS: "SAVE_AREAS_SUCCESS",
  SAVE_AREAS_FAIL: "SAVE_AREAS_FAIL",

  AMOUNT_TEAMS_SET: "AMOUNT_TEAMS_SET",
  SAVE_TEAMS_STARTED: "SAVE_TEAMS_STARTED",
  SAVE_TEAMS_SUCCESS: "SAVE_TEAMS_SUCCESS",
  SAVE_TEAMS_FAIL: "SAVE_TEAMS_FAIL",
  FETCH_TEAMS_STARTED: "FETCH_TEAMS_STARTED",
  FETCH_TEAMS_SUCCESS: "FETCH_TEAMS_SUCCESS",

  AMOUNT_TEAM_MEMBER_SET: "AMOUNT_TEAM_MEMBER_SET",
  SAVE_TEAM_MEMBER_STARTED: "SAVE_TEAM_MEMBER_STARTED",
  SAVE_TEAM_MEMBER_SUCCESS: "SAVE_TEAM_MEMBER_SUCCESS",
  SAVE_TEAM_MEMBER_FAIL: "SAVE_TEAM_MEMBER_FAIL",

  BACK_REGIONS_CREATION: "BACK_REGIONS_CREATION",
  BACK_TEAMS_CREATION: "BACK_TEAMS_CREATION",
  BACK_AREAS_CREATION: "BACK_AREAS_CREATION",

  GO_BACK_TEAM_MEMBER_WIZARD: "GO_BACK_TEAM_MEMBER_WIZARD",
};

export function fetchCompanyId() {
  const userId = localStorage.getItem("userId");
  return async (dispatch) => {
    dispatch({ type: actions.FETCH_COMPANYID_STARTED });
    const result = await Get(apiResource.user, userId);
    dispatch({
      type: actions.FETCH_COMPANYID_SUCCESS,
      payload: result.companyId,
    });
  };
}

export function saveRegionsAction(regionData) {
  const json = JSON.stringify(regionData);
  return async (dispatch) => {
    dispatch({ type: actions.SAVE_REGIONS_STARTED });
    const result = await Post(apiResource.regions, json);
    result === ERROR
      ? dispatch({ type: actions.SAVE_REGIONS_FAIL })
      : dispatch({ type: actions.SAVE_REGIONS_SUCCESS, payload: result });
  };
}

export function getRegionsAction(companyId) {
  return async (dispatch) => {
    dispatch({ type: actions.FETCH_REGIONS_STARTED });
    const result = await Get(apiResource.companies, companyId, apiResourceSegment.regions);
    dispatch({ type: actions.FETCH_REGIONS_SUCCESS, payload: (result || []).regions });
  };
}

export function getAreasAction(companyId) {
  return async (dispatch) => {
    dispatch({ type: actions.FETCH_AREAS_STARTED });
    const result = await Get(apiResource.companies, companyId, apiResourceSegment.areas);
    dispatch({ type: actions.FETCH_AREAS_SUCCESS, payload: result });
  };
}

export function getTeamsAction(companyId) {
  return async (dispatch) => {
    //dispatch({ type: actions.FETCH_TEAMS_STARTED });
    const result = await Get(apiResource.companies, companyId, apiResourceSegment.teams);
    dispatch({
      type: actions.FETCH_TEAMS_SUCCESS,
      payload: (result || []).data,
    });
  };
}

export function saveAreasAction(areasData) {
  const json = JSON.stringify(areasData);
  return async (dispatch) => {
    dispatch({ type: actions.SAVE_AREAS_STARTED });
    const result = await Post(apiResource.areas, json);
    result === ERROR
      ? dispatch({ type: actions.SAVE_AREAS_FAIL })
      : dispatch({ type: actions.SAVE_AREAS_SUCCESS, payload: result });
  };
}

export function saveTeamsAction(teamsData) {
  const json = JSON.stringify(teamsData);
  return async (dispatch) => {
    dispatch({ type: actions.SAVE_TEAMS_STARTED });
    const result = await Post(apiResource.teams, json);
    result === ERROR
      ? dispatch({ type: actions.SAVE_TEAMS_FAIL })
      : dispatch({ type: actions.SAVE_TEAMS_SUCCESS, payload: result });
  };
}

export function saveTeamMemberAction(teamMemberData) {
  const json = JSON.stringify(teamMemberData);
  return async (dispatch) => {
    dispatch({ type: actions.SAVE_TEAM_MEMBER_STARTED });
    const result = await Post(apiResource.teamMembersAddMultiple, json);
    result === ERROR
      ? dispatch({ type: actions.SAVE_TEAM_MEMBER_FAIL })
      : dispatch({ type: actions.SAVE_TEAM_MEMBER_SUCCESS, payload: result });
  };
}

export function handleAmounts(amountFunction, amount, step) {
  amountFunction(amount);
  return (dispatch) => {
    switch (step) {
      case stepEnum.RegionAmount:
        dispatch({ type: actions.AMOUNT_REGIONS_SET });
        break;
      case stepEnum.AreaAmount:
        dispatch({ type: actions.AMOUNT_AREAS_SET });
        break;
      case stepEnum.TeamsAmount:
        dispatch({ type: actions.AMOUNT_TEAMS_SET });
        break;
      case stepEnum.TeamMembersAmount:
        dispatch({ type: actions.AMOUNT_TEAM_MEMBER_SET });
        break;
      default:
        break;
    }
  };
}
