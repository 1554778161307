import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import * as services from "../../store/services";

import "./styles.scss";
import clsx from "clsx";

export default class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.data || {};
    this.state.value = props.data.value || "";
    this.state.parentValue = props.data.value;
    this.state.isFormSubmitted = props.isFormSubmitted || false;
    this.state.isTextFieldDirty = false;
    this.updateParentFormWithDebouncedFieldValue = _.debounce(
      this.updateParentFormFieldValue,
      this.props.delay || 0
    );
  }

  componentDidMount() {
    this.validateTextField();
  }

  componentWillReceiveProps(nextProps) {
    let value;

    const isParentValueChanged =
      this.state.parentValue !== nextProps.data.value;
    const isFormSubmittedChanged =
      this.state.isFormSubmitted !== nextProps.isFormSubmitted;

    if (isParentValueChanged) {
      value = nextProps.data.value;
      this.props.recordSelection(this.state.name, value);
    } else {
      value = this.state.value;
    }

    this.setState(
      {
        ...nextProps.data,
        value: value ?? "",
        parentValue: isParentValueChanged ? value : this.state.parentValue,
        isFormSubmitted: nextProps.isFormSubmitted,
      },
      () => {
        if (isFormSubmittedChanged || isParentValueChanged) {
          this.validateTextField();
        }
      }
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isRerenderRequired =
      this.state.value !== nextState.value ||
      this.state.disabled !== nextProps.data.disabled ||
      this.state.validationMessage !== nextState.validationMessage ||
      this.state.isFormSubmitted !== nextProps.isFormSubmitted;

    return isRerenderRequired;
  }

  componentWillUnmount() {
    if (this.props.removeFormsStatusFlag) {
      this.props.removeFormsStatusFlag(this.state.name);
    }
  }

  validateTextField = () => {
    const {
      value,
      validations,
      isFormSubmitted,
      validate,
      name,
      isTextFieldDirty,
    } = this.state;

    if (!validate) {
      return;
    }

    const { validationMessage, shouldErrorMessageShown } =
      services.validateTextField(
        value,
        validations,
        isFormSubmitted,
        validate,
        name,
        isTextFieldDirty
      );
    if (validationMessage) {
      this.props.updateFormsStatus(false, this.state.name);
    } else {
      this.props.updateFormsStatus(true, this.state.name);
    }
    this.displayValidationMessage(shouldErrorMessageShown, validationMessage);
  };

  handleChange = ({ target }) => {
    const value = target.value;
    this.setState({ isTextFieldDirty: true, value }, () => {
      this.validateTextField();
    });
    this.updateParentFormWithDebouncedFieldValue(this.state.name, value);
  };

  updateParentFormFieldValue = (name, value) => {
    this.props.recordSelection(name, value);
  };

  displayValidationMessage = (shouldErrorMessageBeShown, validationMessage) => {
    const message =
      shouldErrorMessageBeShown && validationMessage ? validationMessage : null;
    this.setState({ validationMessage: message });
  };

  render() {
    const customStyle = {
      ...this.props.style,
    };

    const customStyleClass = this.props.customStyleClass;

    return (
      <div>
        {this.state.label ? <label>{this.state.label}</label> : null}
        <div style={{ display: "inline" }}>
          <input
            className={clsx("text-field form-control", customStyleClass)}
            style={customStyle}
            type={this.state.type}
            onChange={this.handleChange}
            name={this.state.name}
            required={this.state.required}
            placeholder={this.state.placeholder}
            value={this.state.value}
            disabled={this.state.disabled}
          />
        </div>
        {this.state.description ? (
          <label className="description-message">
            {this.state.description}
          </label>
        ) : null}
        {this.state.helpButton ? (
          <div style={{ display: "inline-block", verticalAlign: "middle" }}>
            {this.state.helpDescription ? (
              <div className="tooltip">
                <span className="tooltiptext">
                  {this.state.helpDescription}
                </span>
                <button className="help-button" type="button">
                  <i className="fa fa-question" />
                </button>
              </div>
            ) : (
              <button className="help-button" type="button">
                <i className="fa fa-question" />
              </button>
            )}
            {this.state.helpLabel ? (
              <label
                className="description-message"
                style={{ display: "block", marginLeft: "10px" }}
              >
                {this.state.helpLabel}
              </label>
            ) : null}
          </div>
        ) : null}

        <div className="validation-message">{this.state.validationMessage}</div>
      </div>
    );
  }
}

TextField.propTypes = {
  customStyleClass: PropTypes.string,
  isFormSubmitted: PropTypes.bool,
  data: PropTypes.object,
  delay: PropTypes.number,
  onHelpClick: PropTypes.func,
  recordSelection: PropTypes.func,
  removeFormsStatusFlag: PropTypes.func,
  style: PropTypes.object,
  updateFormsStatus: PropTypes.func,
};
