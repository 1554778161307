export const emailFieldData = {
  value: "",
  name: "email",
  label: "",
  placeholder: "Email address",
  type: "email",
  required: true,
  validate: true,
  disabled: false,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "regex",
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      priority: 2,
      message: "Invalid email"
    },
    {
      validationName: "minLength",
      value: 8,
      priority: 3,
      message: `Minimum required length is 8 characters`
    },
    {
      validationName: "maxLength",
      value: 50,
      priority: 4,
      message: `Maximum allowed length is 50 characters`
    }
  ]
};
