import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../components/Dropdown";
import PageLoading from "../../components/PageLoading";
import { fetchAccountProgress } from "../../store/actions/general.action";
import { setNavigationAssets } from "../../store/actions/navigation.action";
import {
  fetchReportStatus,
  fetchReports,
  updateSelectedReport,
} from "../../store/actions/reports.action";

import * as data from "./data";

import PageContent from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import ReportScore from "../../components/ReportScore";

import { isAdminOrReseller } from "../../store/helpers/userHelper";
import "./BuyNewReport.scss";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const BuyNewReport = () => {
  const dispatch = useDispatch();
  const { isLoadingAccountProgress, content: user } = useSelector(
    (state) => state.general
  );

  const { isLoadingReports, reports, report } = useSelector(
    (state) => state.reports
  );

  const reportDropdown = useMemo(() => ({ data: data.reportsData }), [data]);
  const paragraphs = useMemo(() => data.PARAGRAPHS, [data]);

  if (!isAdminOrReseller(user.role)) {
    return <Redirect to="/dashboard" />;
  }

  useEffect(() => {
    const navigation = {
      title: "BUYNEWREPORT",
      subtitle: "BUY NEW REPORT",
      tab: "BUY NEW REPORT",
    };
    dispatch(setNavigationAssets(navigation));
    dispatch(fetchAccountProgress());
    dispatch(fetchReports());
  }, []);

  const onSelect = (_fieldName, fieldValue) => {
    dispatch(updateSelectedReport(fieldValue));
    dispatch(fetchReportStatus());
  };

  const dashboardLinkTitles = ["bm", "t360"];

  return (
    <PageLoading
      isLoading={isLoadingReports || isLoadingAccountProgress}
      render={() => (
        <>
          <PageTitle
            title="Buy New Report"
            paragraphs={[
              {
                text: "Choose which report you wish to buy and click on Purchase which allows you to make a payment for the report.",
              },
              {
                text: "If you bought a benchmark report you will then be directed to make any edits or amendments to your existing team",
              },
            ]}
          >
            {reports && reports.length > 0 && (
              <Dropdown
                data={{
                  ...reportDropdown.data,
                  list: reports || [],
                  value: report?.id,
                }}
                onSelect={onSelect}
              />
            )}
          </PageTitle>
          <PageContent>
            <div className="flex-container">
              {paragraphs && paragraphs.length > 0
                ? paragraphs.map((paragraph) => {
                    return (
                      <div key={paragraph.id}>
                        <ReportScore
                          average={null}
                          averageType={paragraph.title}
                          updateFormFieldValue={onSelect}
                        />
                        {dashboardLinkTitles.find(
                          (title) => title === paragraph.title
                        ) ? (
                          <h6 className="mt-4 text-box">{paragraph.text}</h6>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </PageContent>
        </>
      )}
    />
  );
};

export default BuyNewReport;
