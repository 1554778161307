import React from "react";
import PropTypes from "prop-types";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import PageLoading from "../PageLoading";

import { connect } from "react-redux";

const Container = props => {
  const { children, isLoading } = props;
  return (
    <div className="app">
      <Navbar user={true} />
      <div className="app__content">
        <Sidebar />
        <div className="layout__content">
          <PageLoading isLoading={isLoading} render={() => children} />
        </div>
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
};

const mapStateToProps = ({ general }) => ({
  isLoading: general && general.isLoading,
});

export default connect(
  mapStateToProps,
  null
)(Container);
