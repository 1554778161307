import './TeamWizardSteps.scss';

import PropTypes from 'prop-types';
import React from 'react';
import TeamWizardStep from "./TeamWizardStep";

const TeamWizardSteps = ({ steps }) => {
    return (
        <ul className="team-wizard-steps">
            {steps.map((step, index) => {
                return <TeamWizardStep key={index} fieldName={step.name} icon={step.icon} active={step.active}  />;
            })}
        </ul>
    );
};

TeamWizardSteps.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        active: PropTypes.bool,
    })).isRequired
}

export default TeamWizardSteps;
