import React from "react";
import PropTypes from "prop-types";

import { TextInput } from "../../../../../components/TextInput/TextInput";

export const WizardFormCell = ({ column, row, onChange }) => {
  const value = row.state[column.property];

  if (column.render) {
    return column.render({ value, column, row, onChange });
  }

  if (column.type === "textInput") {
    return (
      <TextInput
        value={value}
        onChange={(v) => {
          onChange && onChange(v);
        }}
      />
    );
  }

  if (column.type === "label") {
    return <span>{value}</span>;
  }

  if (column.type === "select") {
    return (
      <select
        defaultValue={value}
        onChange={(event) => {
          onChange && onChange(event.target.value);
        }}
      >
        <option>Select an option</option>
        {column.options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    );
  }

  return (
    <div style={{ color: "red" }}>
      {!column.type && (
        <pre>
          No <strong>{`"type"`}</strong> or <strong>{`"render"`}</strong>{" "}
          property defined.
        </pre>
      )}
      {column.type && (
        <pre>
          No handler for type <strong>{`"{column.type}"`}</strong>
        </pre>
      )}
      <pre>{JSON.stringify(column, null, 2)}</pre>
    </div>
  );
};

WizardFormCell.propTypes = {
  column: PropTypes.object,
  row: PropTypes.object,
  onChange: PropTypes.func,
};
