/*eslint no-magic-numbers: 0*/
/*eslint react/no-deprecated: 0*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import PageLoading from "../../components/PageLoading";
import TextField from "../../components/TextField";
import TableFooter from "../../components/TableFooter";
import MUIDataTable from "mui-datatables";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// actions
import { setNavigationAssets } from "../../store/actions/navigation.action";
import { createTeamMember, fetchTeam } from "../../store/actions/team.action";
import {
  fetchCountries,
  updateAccount,
} from "../../store/actions/general.action";
import {
  addReseller,
  addViewOnlyUser,
  fetchViewOnlyUser,
  deleteReseller,
  fetchReseller,
} from "../../store/actions/reseller.action";
import { hasPermission } from "../../store/helpers/userHelper";

//utils
import * as data from "./data";
import * as services from "../../store/services/validation.service";

// scss
import "./ViewOnlyUser.scss";
import { withRouter } from "react-router-dom";
import ViewOnlyUserDialog from "../../components/ViewOnlyUserDialog";
import PageTitle from "../../components/PageTitle";
import PageContent from "../../components/PageContent";

class ViewOnlyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fields: [
        { data: data.firstNameData },
        { data: data.lastNameData },
        { data: data.emailData },
      ],
      searchField: { data: data.searchData },
      isFormSubmitted: false,
      isAddViewOnlyUserOpen: true,
      isViewOnlyUserDialogOpen: false,
      viewOnlyUserDialogOptions: [],
      companyId: null,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    const { company_id } = this.props.user;
    if (company_id) this.setState({ companyId: company_id });
    if (this.props.teamId) this.setState({ teamId: this.props.teamId });
    const navigation = {
      title: "VIEW-ONLY USER",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchTeam(this.isTeam360());
  }

  componentWillReceiveProps(nextProps) {
    const { reseller } = nextProps;
    this.setState({ data: reseller });
  }

  canSeeLastReport() {
    const { reports } = this.props;
    const lastReport = reports && reports.slice(-1)[0];
    return lastReport && lastReport.canSeeReport;
  }

  isTeam360() {
    return this.props.history.location.pathname.includes("stakeholders");
  }

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  };

  handleAddViewOnlyUser = () => {
    const isFormValid = services.getFormsValidityStatus(this.formStatus);
    const { email, firstName, lastName } = this.state;

    if (isFormValid) {
      this.props.addViewOnlyUser(email, firstName, lastName, this.props.teamId);
      this.setState({ errorMessage: "" });
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Please review your information.",
      });
    }
  };

  handleHideViewOnlyUserForm = () => {
    this.setState({
      isAddViewOnlyUserOpen: !this.state.isAddViewOnlyUserOpen,
    });
  };

  handleViewOnlyUserDialogClose = () => {
    this.setState({ isViewOnlyUserDialogOpen: false });
  };

  handleViewOnlyUserDialogOpen = (tableMeta, option) => {
    this.setState({
      isViewOnlyUserDialogOpen: true,
      viewOnlyUserDialogOptions: [tableMeta, option],
    });
  };

  renderComponentForField = (field) => {
    return (
      <TextField
        data={{ ...field.data, value: this.state[field.data.name] }}
        recordSelection={this.updateFormFieldValue}
        updateFormsStatus={this.updateFormsStatus}
        delay={500}
        isFormSubmitted={this.state.isFormSubmitted}
      />
    );
  };

  renderOptions = (tableMeta) => {
    if (tableMeta.rowData) {
      return (
        <div className="view-only-user-page__table-options">
          <button
            title="Delete"
            className="view-only-user-page__table-options-delete"
            onClick={() =>
              this.handleViewOnlyUserDialogOpen(tableMeta.rowData, "delete")
            }
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </div>
      );
    }
  };

  render() {
    const {
      isAddViewOnlyUserOpen,
      isViewOnlyUserDialogOpen,
      data,
      searchField,
      viewOnlyUserDialogOptions,
    } = this.state;

    const { isLoadingTeam, isLoadingReseller, user } = this.props;

    const table = {
      columns: [
        {
          name: "id",
          options: {
            display: "excluded",
          },
        },
        {
          name: "displayName",
          label: "Name",
          options: {
            sortDirection: "asc",
          },
        },
        {
          name: "email",
          label: "Email",
          options: {
            sortDirection: "asc",
          },
        },
        hasPermission(user.role) && {
          name: "Options",
          options: {
            sort: false,
            customBodyRender: (value, tableMeta) => {
              return this.renderOptions(tableMeta);
            },
          },
        },
      ],
      options: {
        selectableRows: "none",
        elevation: 0,
        rowsPerPage: 1000,
        pagination: true,
        fixedHeader: true,
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        searchText: this.state.searchValue,

        customFooter: (count, page, rowsPerPage, changePage) => {
          return (
            <TableFooter
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              changePage={changePage}
              user={user}
              isTeam360={this.isTeam360()}
              canSeeLastReport={this.canSeeLastReport()}
              isViewOnlyUser
              handleTeamMemberDialogOpen={this.handleViewOnlyUserDialogOpen}
            />
          );
        },
      },
      theme: createTheme({
        typography: {
          fontFamily: "Montserrat",
          fontSize: 15,
          useNextVariants: true,
        },
        overrides: {
          MUIDataTableHeadCell: {
            root: {
              fontWeight: "bold",
              fontSize: 16,
              color: "#173b59",
              borderBottom: "2px solid #1392d3",
            },
          },
        },
      }),
    };

    const { columns, options, theme } = table;

    return (
      <PageLoading
        isLoading={isLoadingReseller || isLoadingTeam}
        render={() => (
          <>
            <PageTitle
              title="Set up - Add Your view-only users"
              subtitle="If your view-only users has changed since you last completed the TeamLytica questionnaire, then you can add, delete or edit details here."
            >
              <ViewOnlyUserDialog
                open={isViewOnlyUserDialogOpen}
                onClose={this.handleViewOnlyUserDialogClose}
                options={viewOnlyUserDialogOptions}
                isTeam360={this.isTeam360()}
              />
            </PageTitle>
            <PageContent>
              <div className="view-only-user-page__form">
                <div className="view-only-user-page__button-container">
                  <button
                    className="view-only-user-page__hide-button"
                    onClick={this.handleHideViewOnlyUserForm}
                  >
                    <span>Add New View-only users &nbsp;&nbsp;&nbsp;</span>
                    <i className="fa fa-navicon" aria-hidden="true" />
                  </button>
                </div>
                {isAddViewOnlyUserOpen ? (
                  <div className="view-only-user-page__container">
                    <div className="view-only-user-page__container-form">
                      <div className="view-only-user-page__container-form-title">
                        <span>Add view-only user</span>
                      </div>
                      <div className="view-only-user-page__container-form-content">
                        {this.state.fields.map((field) => (
                          <div key={field.data.id}>
                            {this.renderComponentForField(field)}
                          </div>
                        ))}
                      </div>
                      <div className="view-only-user-page__container-form-submit">
                        <button
                          className="view-only-user-page__container-form-submit-button"
                          onClick={this.handleAddViewOnlyUser}
                          disabled={!hasPermission(this.props.content.role)}
                        >
                          <span>Add view-only user</span>
                          <i className="fa fa-plus" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="view-only-user-page__table">
                <div className="view-only-user-page__table-header">
                  <span className="view-only-user-page__table-header-title">
                    Your view-only users
                  </span>
                  {data?.length > 0 && (
                    <div className="view-only-user-page__table-header-search">
                      <TextField
                        data={{ ...searchField.data }}
                        recordSelection={this.updateFormFieldValue}
                        updateFormsStatus={this.updateFormsStatus}
                        delay={500}
                        isFormSubmitted={this.state.isFormSubmitted}
                      />
                    </div>
                  )}
                </div>

                {data?.length > 0 ? (
                  <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                ) : (
                  <div className="view-only-user-page__table-no-content">
                    <p>
                      View-only users will appear here when you add them using
                      the form above.
                    </p>
                  </div>
                )}
              </div>
            </PageContent>
          </>
        )}
      />
    );
  }
}

ViewOnlyUser.propTypes = {
  allMembers: PropTypes.array,
  createTeamMember: PropTypes.func,
  error: PropTypes.string,
  fetchTeam: PropTypes.func,
  isLoadingReseller: PropTypes.bool,
  isLoadingTeam: PropTypes.bool,
  user: PropTypes.object,
  reports: PropTypes.array,
  teamId: PropTypes.number,
  setNavigationAssets: PropTypes.func,
  history: PropTypes.object,
  content: PropTypes.object,
  countries: PropTypes.array,
  isUpdated: PropTypes.bool,
  isLoadingCountries: PropTypes.bool,
  fetchCountries: PropTypes.func,
  updateAccount: PropTypes.func,
  reseller: PropTypes.object,
  addReseller: PropTypes.func,
  deleteReseller: PropTypes.func,
  fetchReseller: PropTypes.func,
  fetchViewOnlyUser: PropTypes.func,
  addViewOnlyUser: PropTypes.func,
};

const mapStateToProps = ({ team, reports, general, reseller }) => {
  return {
    allMembers: team.allMembers && team.allMembers,
    teamId: team.team_id && team.team_id,
    isLoadingTeam: team.isLoadingTeam,
    error: team.error || general.error,
    user: general.content,
    reports: reports.reports,
    content: general.content,
    countries: general.countries,
    isUpdated: general.isUpdated,
    reseller: reseller.reseller,
    isLoadingReseller: reseller.isLoadingReseller,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setNavigationAssets,
    createTeamMember,
    fetchTeam,
    fetchCountries,
    updateAccount,
    addReseller,
    deleteReseller,
    fetchReseller,
    fetchViewOnlyUser,
    addViewOnlyUser,
  })(ViewOnlyUser)
);
