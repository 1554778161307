import { actions } from "../actions/motivator.action";

const initialState = {
  isShowing: true,
  report: "",
};

export default function motivator(state = initialState, action) {
  switch (action.type) {
    case actions.SET_MOTIVATOR_REPORT:
      return {
        ...state,
        report: action.payload,
        isShowing: true,
      };
    default:
      return state;
  }
}
