/*eslint no-magic-numbers: 0*/
import faceHigh from "../../assets/images/icons/face_high.svg";
import faceLow from "../../assets/images/icons/face_low.svg";
import faceMed from "../../assets/images/icons/face_med.svg";

import stress1 from "../../assets/images/other/stress1.svg";
import stress2 from "../../assets/images/other/stress2.svg";
import stress3 from "../../assets/images/other/stress3.svg";
import stress4 from "../../assets/images/other/stress4.svg";
import stress5 from "../../assets/images/other/stress5.svg";
import stress6 from "../../assets/images/other/stress6.svg";

export function getCurrency(currency) {
  switch (currency) {
    case "gbp":
      return "£";
    case "eur":
      return "€";
    default:
      return "$";
  }
}

export function getValueColor(value, isStress = false) {
  if (value) {
    if (value > 8) return isStress ? "low" : "high";
    else if (value > 5.9) return "medium";
    else return isStress ? "high" : "low";
  }
  return "";
}

const LOW = 6
const MED = 8

export function getFaceTransparency(score, face) {
  if (
    (face === "low" && score < LOW) ||
    (face === "med" && score >= LOW && score < MED) ||
    (face === "high" && score >= MED)
  )
    return "";
  return "transparent";
}

export function getCircleColor(value, isStress = false) {
  if (value) {
    if (value > 8) return isStress ? "orange" : "green";
    else if (value > 5.9) return "yellow";
    return isStress ? "green" : "orange";
  }
  return "";
}

export function getColor(value, isStress = false) {
  const valueColor = getValueColor(value);

  switch (valueColor) {
    case "low":
      return isStress ? "#0ac467" : "#ff4900";
    case "high":
      return isStress ? "#ff4900" : "#0ac467";
    case "medium":
      return "#ffa200";

    default:
      return "";
  }
}

export function getGraphSource(score) {
  if (score) {
    if (score > 0 && score <= 1.66) return stress6;
    if (score > 1.66 && score <= 3.32) return stress5;
    if (score > 3.32 && score <= 4.98) return stress4;
    if (score > 4.98 && score <= 6.64) return stress3;
    if (score > 6.64 && score <= 8.3) return stress2;
    if (score > 8.3) return stress1;
  }
}

export function getSmileySource(score) {
  if (score && score > 7.9) {
    return faceHigh;
  } else if (score && score > 4.9) {
    return faceMed;
  } else if (score) {
    return faceLow;
  }
}

export function getProgress(value, total) {
  let progress = (value / total) * 100;
  if (progress > 100) progress = 100;
  progress = Math.round(progress);
  return progress;
}

export function renderDate(value, month) {
  const data = new Date(value);
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month,
    day: "2-digit",
  }).format(data);
}
