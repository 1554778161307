/*eslint no-magic-numbers: 0*/
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// scss
import "../../Questionnaire.scss";

const MAX_CHAR = 250;
const FIRST = "first";
const SECOND = "second";
const THIRD = "third";

class QuestionnaireInputs extends Component {
  constructor(props) {
    super(props);
    const answerJson = props.response && JSON.parse(props.response.answer)
    this.state = answerJson || {
      textOne: "",
      textTwo: "",
      textThree: "",
    };
  }

  componentDidUpdate() {
    const { response } = this.props;
    if (response) {
      if (
        this.state.textOne === undefined ||
        this.state.textTwo === undefined ||
        this.state.textThree === undefined
      ) {
        this.setState({
          textOne: response.answer["textOne"],
          textTwo: response.answer["textTwo"],
          textThree: response.answer["textThree"],
        });
      }
    }
  }

  handleChange = (e, text)=> {
    const keyMap = {
      [FIRST]: 'textOne',
      [SECOND]: 'textTwo',
      [THIRD]: 'textThree'
    }
  
    const keyToUpdate = keyMap[text];

    if (!keyToUpdate) return;
  
    this.setState({
      [keyToUpdate]: e.target.value,
      error: null
    });
  }

  handleOnBack = () => {
    this.setState({
      error: null,
    });
    this.props.onBack();
  };

  handleOnNext = () => {
    this.props.onTextChange(
      this.props.questionId,
      JSON.stringify(this.state)
    );
    const { textOne } = this.state;
    if (textOne && textOne.length <= MAX_CHAR && textOne.length !== 0) {
      this.props.onNext();
      this.setState({
        textOne: "",
        error: null,
      });
      return;
    }
    if (textOne && textOne.length > MAX_CHAR) {
      this.setState({
        error: "You exceeded the maximum number of characters.",
      });
      return;
    }
    this.setState({
      error: "You need to answer this question in order to continue.",
    });
  };

  renderTextAreaTip(text) {
    return (
      <Fragment>
        {text && text.length >= MAX_CHAR ? (
          <div className="questionnaire-page__content-body-threeinputs-tip">
            <span style={{ color: "#ff4900" }}>Characters exceeded</span>
          </div>
        ) : text ? (
          <div className="questionnaire-page__content-body-threeinputs-tip">
            <span>{MAX_CHAR - text.length} characters left</span>
          </div>
        ) : null}
      </Fragment>
    );
  }

  render() {
    const { textOne, textTwo, textThree, error } = this.state;
    const { subTitle, question, explanation, title, number } = this.props;

    return (
      <Fragment>
        <div className="questionnaire-page__content-header">
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-title-main">{`${title}`}</span>
          </div>
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-sub-title">{subTitle}</span>
          </div>
        </div>
        <div className="questionnaire-page__content-body">
          <div className="questionnaire-page__content-body-question">
            <i className="fa fa-question-circle" />
            <span>{`Q${number}.  `}</span>
            <span>{question}</span>
          </div>
          <div className="questionnaire-page__content-body-question">
            <span>{explanation && `*${explanation}`}</span>
          </div>
          <div className="questionnaire-page__content-body-threeinputs">
            <input
              name="response"
              type="text"
              id="1"
              placeholder="First quick win..."
              onChange={(e) => this.handleChange(e, FIRST)}
              value={textOne}
            />
            {this.renderTextAreaTip(textOne)}
            <input
              name="response"
              type="text"
              id="2"
              placeholder="Second quick win..."
              onChange={(e) => this.handleChange(e, SECOND)}
              value={textTwo}
            />
            {this.renderTextAreaTip(textTwo)}
            <input
              name="response"
              type="text"
              id="3"
              placeholder="Third quick win..."
              onChange={(e) => this.handleChange(e, THIRD)}
              value={textThree}
            />
            {this.renderTextAreaTip(textThree)}
          </div>
          <div className="questionnaire-page__content-body-actions">
            <button
              className="questionnaire-page__content-body-actions-back"
              onClick={() => this.handleOnBack()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" />
              <span>Back</span>
            </button>
            <div className="questionnaire-page__content-body-actions-separator">
              <hr />
            </div>
            <button
              className="questionnaire-page__content-body-actions-next"
              onClick={() => this.handleOnNext()}
            >
              <span>Next</span>
              <i className="fa fa-arrow-right" aria-hidden="true" />
            </button>
          </div>
          <div className="questionnaire-page__content-body-error">
            {error && error ? error : ""}
          </div>
          <div className="questionnaire-page__content-body-description">
            *Please note, your comments are completely confidential. However, the report includes
            exactly what you’ve written so please be mindful what you write and include.
          </div>
        </div>
      </Fragment>
    );
  }
}

QuestionnaireInputs.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onTextChange: PropTypes.func,
  response: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  question: PropTypes.string,
  number: PropTypes.string,
  questionId: PropTypes.number,
  explanation: PropTypes.string,
};

export default QuestionnaireInputs;
