/*eslint no-magic-numbers: 0*/
import { authActions } from "../actions/auth.action";
import history from "../../utils/history";

export default ({ dispatch }) => next => action => {
  if (action.error && action.error === "Unauthorized") {
    dispatch(authActions.logout(() => history.push("/login")));
  } else if (
    action.type === "LOGIN_SUCCESS" ||
    action.type === "REFRESH_TOKEN_SUCCESS"
  ) {
    const user = JSON.parse(localStorage.getItem("user"));
    const tokenTime = new Date().getTime() + user.expires_in * 1000;

    localStorage.setItem("tokenTime", tokenTime);
  } else {
    const tokenTime = localStorage.getItem("tokenTime");
    const refreshThreshold = new Date().getTime() + 300000;

    if (tokenTime && tokenTime < refreshThreshold) {
      localStorage.removeItem("tokenTime");
      dispatch(authActions.refreshToken());
    }
  }

  return next(action);
};
