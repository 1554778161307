import PropTypes from "prop-types";
import React, { Component } from "react";
import clsx from "clsx";

//components
import { Link } from "react-router-dom";

// the last import should always be the scss
import "./TabbarV2.scss";

class TabbarV2 extends Component {
  render() {
    return (
      <div className="tabbar-v2">
        <ul>
          {this.props.options?.map((option) => (
            <li key={option.id} className={clsx(option.active, option.color)}>
              <Link
                to={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  option.onClick();
                }}
              >
                <span>{option.title}</span>
                <i className={option.icon} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

TabbarV2.propTypes = {
  options: PropTypes.array,
};

export default TabbarV2;
