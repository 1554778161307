import { methods } from "../store/helpers/HttpHelper";
import { authHeader } from "../store/helpers/authHeader";
import { handleErrors } from "../store/helpers/errorHandlers";

const get = (url, options) => {
  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
    ...options
  };

  return fetch(url, {
    ...requestOptions,
  }).then(handleErrors)
    .then(response => response.json())
}

const post = (url, body, options) => {
  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
    body: JSON.stringify(body),
    ...options
  };
  return fetch(url, {
    ...requestOptions,
  }).then(handleErrors)
}

export const HttpClient = {
  get,
  post
}
