export const passwordFieldData = {
  value: "",
  name: "password",
  label: "",
  placeholder: "Password",
  type: "password",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`
    },
    {
      validationName: "maxLength",
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`
    },
    {
      validationName: "regex",
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
      priority: 4,
      message:
        "Password must contain at least one uppercase letter and a number"
    }
  ]
};

export const confirmPasswordFieldData = {
  value: "",
  name: "confirm_password",
  label: "",
  placeholder: "Confirm Password",
  type: "password",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 6,
      priority: 2,
      message: `Minimum required length is 6 characters`
    },
    {
      validationName: "maxLength",
      value: 20,
      priority: 3,
      message: `Maximum allowed length is 20 characters`
    },
    {
      validationName: "regex",
      value: /^(?=.{6,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
      priority: 4,
      message:
        "Password must contain at least one uppercase letter and a number"
    }
  ]
};
