import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

function BottomInfo(props) {
  const { title, onClick, buttonColor, buttonTitle, buttonIcon, disabled } =
    props;
  return (
    <div className="bottom-info-container">
      <h6>{title}</h6>
      <button onClick={onClick} className={buttonColor} disabled={disabled}>
        <div className="button-content">
          {buttonTitle}
          {buttonIcon && <i className={`fa ml-2 ${buttonIcon}`} />}
        </div>
      </button>
    </div>
  );
}

BottomInfo.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonColor: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BottomInfo;
