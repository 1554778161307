import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { getValueColor } from "../../../../store/helpers/uiHelper";
import "./CompareChart.scss";

const DATE_FORMAT = "MMM YYYY";

function CompareChart(props) {
  const { data, style } = props;

  const calculateBarWidth = React.useCallback((score) => {
    return `${score * 10}%`;
  }, []);

  const calculateGhostBarWidth = React.useCallback((type) => {
    const value =
      type === "min"
        ? Math.min(data.initialData.score, data.lastData.score)
        : Math.max(data.initialData.score, data.lastData.score);

    return `${value * 10}%`;
  }, []);

  const Chart = () => (
    <>
      <div className="mb-1 compare-graph-header">
        <h6>{data.title}</h6>
        <h6 className="subtitle">{data.subtitle}</h6>
        {data.icon && <img src={data.icon} width={36} />}
      </div>
      <div className="comparison-container">
        <div className="graph-bar-container">
          <h6 className="mb-2">
            {moment(data.initialData.date).format(DATE_FORMAT)}
          </h6>
          <div className="graph-bar up-bar">
            <span
              className={`${getValueColor(data.initialData.score)} bar`}
              style={{ width: calculateBarWidth(data.initialData.score) }}
            />
            <span
              className="line"
              style={{ left: calculateBarWidth(data.initialData.score) }}
            />
          </div>
          <div className="graph-bar-compare-divisor">
            <span
              className="ghost-bar-start"
              style={{ width: calculateGhostBarWidth("min") }}
            />
            <span className="line-between" />
            <span
              className="ghost-bar-end"
              style={{
                width: `calc(100% - ${calculateGhostBarWidth("max")})`,
              }}
            />
          </div>
          <div className="graph-bar bottom-bar">
            <span
              className={`${getValueColor(data.lastData.score)} bar`}
              style={{ width: calculateBarWidth(data.lastData.score) }}
            />
            <span
              className="line"
              style={{ left: calculateBarWidth(data.lastData.score) }}
            />
          </div>
          <h6 className="mt-2">
            {moment(data.lastData.date).format(DATE_FORMAT)}
          </h6>
        </div>
        <div className="score-container">
          <span className={getValueColor(data.initialData.score)}>
            {data.initialData.score.toFixed(1)}
          </span>
          <span className={getValueColor(data.lastData.score)}>
            {data.lastData.score.toFixed(1)}
          </span>
        </div>
      </div>
    </>
  );

  return (
    <div className="compare-graph-container" style={style}>
      {data.isRedirectable ? (
        <Link
          to={{
            pathname: `/reports/cohesion/category-detail/${data.id}/compare`,
            state: {
              team1: data.initialData.teamName,
              team2: data.lastData.teamName,
            },
          }}
        >
          <Chart />
        </Link>
      ) : (
        <Chart />
      )}
    </div>
  );
}

CompareChart.propTypes = {
  style: PropTypes.any,
  data: PropTypes.exact({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    initialData: PropTypes.shape({
      score: PropTypes.number,
      date: PropTypes.string,
      teamName: PropTypes.string,
    }),
    lastData: PropTypes.shape({
      score: PropTypes.number,
      date: PropTypes.string,
      teamName: PropTypes.string,
    }),
    id: PropTypes.number,
    isRedirectable: PropTypes.bool,
  }),
};

export default CompareChart;
