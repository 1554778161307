import React from 'react';
import PropTypes from 'prop-types';
import StepSizeSelector from '../../../StepSizeSelector/StepSizeSelector';

const AreaAmountSelector = ({onSubmit, areaAmount}) => {
    return (
        <StepSizeSelector 
        headerProps={{
            title : 'How many Areas are in your Organisation?', 
            subtitle : "(you can always add more later)",
            icon: 'fa-map'
        }}
        
        bodyProps={{
            buttonText: "Add Areas",
            areaAmount: areaAmount,
            onClick: onSubmit
        }} />
    );
};

AreaAmountSelector.propTypes = {
    onSubmit: PropTypes.func,
    areaAmount: PropTypes.string
  }

export default AreaAmountSelector;