import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import PageLoading from "../../components/PageLoading";
import Achievement from "./components/Achievement";

//actions
import { fetchAccountAchievements } from "../../store/actions/general.action";
import { setNavigationAssets } from "../../store/actions/navigation.action";

//images
import Achievement_1a from "../../assets/images/icons/achievements/achievement_1a.svg";
import Achievement_1b from "../../assets/images/icons/achievements/achievement_1b.svg";
import Achievement_2a from "../../assets/images/icons/achievements/achievement_2a.svg";
import Achievement_3a from "../../assets/images/icons/achievements/achievement_3a.svg";
import Achievement_3b from "../../assets/images/icons/achievements/achievement_3b.svg";
import Achievement_4a from "../../assets/images/icons/achievements/achievement_4a.svg";
import Achievement_4b from "../../assets/images/icons/achievements/achievement_4b.svg";
import Achievement_5a from "../../assets/images/icons/achievements/achievement_5a.svg";
import Achievement_5b from "../../assets/images/icons/achievements/achievement_5b.svg";
import Achievement_6a from "../../assets/images/icons/achievements/achievement_6a.svg";
import Achievement_6b from "../../assets/images/icons/achievements/achievement_6b.svg";
import Achievement_7a from "../../assets/images/icons/achievements/achievement_7a.svg";
import Achievement_8a from "../../assets/images/icons/achievements/achievement_8a.svg";

//To be used
/*eslint no-unused-vars: 0*/
import Achievement_7b from "../../assets/images/icons/achievements/achievement_7b.svg";
import Achievement_8b from "../../assets/images/icons/achievements/achievement_8b.svg";
import Achievement_2b from "../../assets/images/icons/achievements/achievement_2b.svg";

// scss
import "./Achievements.scss";

class Achievements extends Component {
  componentDidMount() {
    const navigation = { title: "ACHIEVEMENTS" };
    this.props.setNavigationAssets(navigation);
    this.props.fetchAccountAchievements();
  }

  render() {
    const {
      setupAchievements,
      useAchievements,
      shareAchievements,
      isLoadingAchievements,
    } = this.props;

    return (
      <PageLoading
        isLoading={isLoadingAchievements}
        render={() => (
          <div className="page-content">
            <div className="header">
              <span className="header__title">Your Achievements</span>
              <span className="header__title--sub">
                As you complete each of the sections and functions in the
                platform you’ll unlock achievement ‘badges’ shown below. If they
                are greyed out, you’ve not yet used that function.
              </span>
            </div>
            <div className="main-container">
              <div className="achievements">
                <div className="achievements__settings">
                  <span className="group-title">Setting up TeamLytica...</span>
                  <div className="achievements__wrapper">
                    {setupAchievements &&
                      setupAchievements.map((achievement) => {
                        return (
                          <Achievement
                            key={achievement.id}
                            title={achievement.title}
                            image={achievement.image}
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="achievements__small">
                  <div className="achievements__sharing">
                    <span className="group-title">Using up TeamLytica...</span>
                    <div className="achievements__wrapper">
                      {useAchievements &&
                        useAchievements.map((achievement) => {
                          return (
                            <Achievement
                              key={achievement.id}
                              title={achievement.title}
                              image={achievement.image}
                            />
                          );
                        })}
                    </div>
                  </div>
                  <div className="achievements__sharing">
                    <span className="group-title">
                      Sharing up TeamLytica...
                    </span>
                    <div className="achievements__wrapper">
                      {shareAchievements &&
                        shareAchievements.map((achievement) => {
                          return (
                            <Achievement
                              key={achievement.id}
                              title={achievement.title}
                              image={achievement.image}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

Achievements.propTypes = {};

const mapStateToProps = ({ general }) => ({
  setupAchievements: [
    {
      id: 1,
      title: "Complete your Profile",
      image: general.achievements.profile_complete
        ? Achievement_1b
        : Achievement_1a,
    },
    {
      id: 2,
      title: "Prioritise Team Performance Categories",
      image: Achievement_2a,
    },
    {
      id: 3,
      title: "Finish Adding your Team Members",
      image: general.achievements.finish_adding_members
        ? Achievement_3b
        : Achievement_3a,
    },
    {
      id: 4,
      title: "Sent Questionnaire to All Team Members",
      image: general.achievements.sent_questionnaire_all
        ? Achievement_4b
        : Achievement_4a,
    },
  ],

  useAchievements: [
    {
      id: 5,
      title: "Review your Report",
      image: general.achievements.review_report
        ? Achievement_5b
        : Achievement_5a,
    },
    {
      id: 6,
      title: "Review Open Text Comments",
      image: general.achievements.review_open_text
        ? Achievement_6b
        : Achievement_6a,
    },
  ],

  shareAchievements: [
    {
      id: 7,
      title: "Invite People to View Summary Report",
      image: Achievement_7a,
    },
    {
      id: 8,
      title: "Share PDF Report",
      image: Achievement_8a,
    },
  ],

  achievements: general.achievements,
  isLoadingAchievements: general.isLoadingAchievements,
});

Achievements.propTypes = {
  fetchAccountAchievements: PropTypes.func,
  setNavigationAssets: PropTypes.func,
  achievements: PropTypes.object,
  setupAchievements: PropTypes.array,
  useAchievements: PropTypes.array,
  shareAchievements: PropTypes.array,
  isLoadingAchievements: PropTypes.bool,
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  fetchAccountAchievements,
})(Achievements);
