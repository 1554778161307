import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import facebook from "../../../../assets/images/logos/fb-logo.png";
import twitter from "../../../../assets/images/logos/twitter-logo.png";
import linkedin from "../../../../assets/images/logos/linkedin-logo.png";

class QuestionnaireEndPage extends Component {
  renderSuccess = () => {
    const { success } = this.props;
    return (
      <Fragment>
        {success && (
          <div className="questionnaire-page__content-end">
            <span className="questionnaire-page__content-header-title-main-final">
              Thank you for you time today - it&apos;s really appreciated!
              <br />
              We&apos;ll summarize all the responses from your team and combine
              them into the final report.
            </span>

            <ul className="social-icons">
              <li>
                <a href="https://www.facebook.com/teamlytica/" target="__blank">
                  <img src={facebook} alt="fb" />
                </a>
              </li>
              <li>
                <a href="#twitter">
                  <img src={twitter} alt="twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/teamlytica/"
                  target="__blank"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
              </li>
            </ul>
            <div>
              <button className="questionnaire-page__content-start-actions-tm-btn">
                <a
                  href="http://www.teamlytica.com/"
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  <span>www.teamlytica.com</span>
                </a>
              </button>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  renderError = () => {
    const { error } = this.props;
    return (
      <Fragment>
        {error && (
          <div className="questionnaire-page__content-body-error">{error}</div>
        )}
      </Fragment>
    );
  };

  renderButtons = () => {
    const { success } = this.props;
    return (
      <Fragment>
        {!success && (
          <div className="questionnaire-page__content-body-actions">
            <button
              className="questionnaire-page__content-body-actions-back"
              onClick={() => this.props.onBack()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" />
              <span>Back</span>
            </button>
            <div className="questionnaire-page__content-body-actions-separator">
              <hr />
            </div>
            <button
              className="questionnaire-page__content-body-actions-next"
              onClick={() => this.props.onSubmit()}
            >
              <span>Submit</span>
              <i className="fa fa-arrow-right" aria-hidden="true" />
            </button>
          </div>
        )}
      </Fragment>
    );
  };

  render() {
    const { success } = this.props;
    return (
      <Fragment>
        {!success && (
          <div>
            <div className="questionnaire-page__content-header-title">
              <span className="questionnaire-page__content-header-title-pre">
                This is the end...
              </span>
            </div>
            <div className="questionnaire-page__content-header-sub-title">
              <p>
                Thank you for your contribution by completing this
                questionnaire!
              </p>
            </div>
          </div>
        )}
        {this.renderButtons()}
        {this.renderSuccess()}
        {this.renderError()}
      </Fragment>
    );
  }
}

QuestionnaireEndPage.propTypes = {
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.string
};

function mapStateToProps(state) {
  const { success, error } = state.questionnaire;
  return {
    success,
    error
  };
}

export default connect(
  mapStateToProps,
  null
)(QuestionnaireEndPage);
