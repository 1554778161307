import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';

import './DropdownMultiple.scss';

const DropdownMultiple = ({ label, options, icon, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tempOptions, setTempOptions] = useState(options);
  const tempSelectedOptions = tempOptions.filter(
    (option) => option.selected === true,
  );
  const allSelected = tempSelectedOptions.length === tempOptions.length;
  const oneItemSelected = tempSelectedOptions.length === 1
  const noItemSelected = tempSelectedOptions.length === 0
  const moreThenOneItemSelected = 1 < tempSelectedOptions.length < tempOptions.length

  useEffect(() => {
    setTempOptions(options);
  }, [options]);

  const selectLabel = useMemo(() => {
    if (isDropdownOpen) return `Choose ${label}`;
    if (allSelected && !oneItemSelected) return `All ${label}`;
    if (oneItemSelected  || (oneItemSelected && allSelected)) return `${tempSelectedOptions[0].value}`
    if (moreThenOneItemSelected) return `${tempSelectedOptions.length} ${label}`
  }, [label, isDropdownOpen, allSelected, oneItemSelected, noItemSelected, moreThenOneItemSelected]);

  const handleDropdownButtonClick = () => {
    setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
  };

  const handleApplyFilterButtonClick = () => {
    setIsDropdownOpen(false);
    onChange(tempOptions);
  };

  const handleAllSelected = () => {
    setTempOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        selected: !allSelected,
      })),
    );
    onChange(tempOptions);
  };

  useEffect(() => {
    if (!isDropdownOpen && noItemSelected) {
      handleAllSelected()
  } 
  }, [isDropdownOpen, noItemSelected])

  const handleOptionCheckboxChange = (changedIndex) => {
    setTempOptions((prevOptions) =>
      prevOptions.map((option, index) => {
        if (index === changedIndex) {
          return { ...option, selected: !option.selected };
        }
        return option;
      }),
    );
    onChange(tempOptions)
  };

  const renderOptions = () => {
    return (
      tempOptions.length &&
      tempOptions.map((option, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={option.selected}
                onChange={() => handleOptionCheckboxChange(index)}
                icon={<DoneIcon id="icon-filter-unchecked" />}
                checkedIcon={<DoneIcon id="icon-filter-checked" />}
              />
            }
            label={option.value}
            icon={<DoneIcon id="icon-filter-unchecked" />}
          />
        );
      })
    );
  };
  const renderFilteredList = () => {
    return (
      !isDropdownOpen &&
      !allSelected &&
      tempSelectedOptions.length > 1 && (
        <div className="list-container border-radius-bottom">
          <div className="list-top-border">
            {tempSelectedOptions.map((option, index) => (
              <div className="list-item" key={index}>
                - <span className="list-underline">{option.value}</span>
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  return (
    <section>
      <FormControl variant="outlined" style={{ marginLeft: '0.5rem' }}>
        <Button
          className={
            'button-dropdown ' +
            (isDropdownOpen ? 'border border-radius-top' : null)
          }
          onClick={handleDropdownButtonClick}
        >
         <i className={icon} id='icon-filter'/>
          {selectLabel}
          <Icon component={KeyboardArrowDownIcon} id="icon-arrow-down" />
        </Button>
        {isDropdownOpen && (
          <div className="checkbox-container border border-radius-bottom">
            <div className="checkbox-section">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allSelected}
                      onChange={handleAllSelected}
                      icon={<DoneIcon id="icon-filter-unchecked" />}
                      checkedIcon={<DoneIcon id="icon-filter-checked" />}
                    />
                  }
                  label={`All ${label}`}
                  icon={<DoneIcon id="icon-filter-unchecked" />}
                />
                {renderOptions()}
              </FormGroup>
            </div>
            <Button
              className={
                `button-apply-filters ${!tempSelectedOptions.length && !allSelected ? 'disabled' : ''}`
              }
              onClick={handleApplyFilterButtonClick}
              disabled={!tempSelectedOptions.length && !allSelected}
            >
              Apply filters <DoneIcon />
            </Button>
          </div>
        )}
        {oneItemSelected ? <div className="list-top-border"></div> : null}
        {renderFilteredList()}
      </FormControl>
    </section>
  );
};

export default DropdownMultiple;

DropdownMultiple.propTypes = {
  options: PropTypes.array,
  filter: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
};
