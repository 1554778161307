/*eslint no-magic-numbers: 0*/
export function handleServiceErrors(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error =
        (data && data.error_description) ||
        (data && data.error) ||
        (data && data[Object.keys(data)[0]][0]) ||
        response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

export function handleInvoiceErrors(response) {
  if (!response.ok) {
    if (response.status === 401) {
      return Promise.reject(response.statusText);
    }
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (data[0].key === "Limit exceeded" && data[1].message === "True") {
        const error = "freeTrialLimit";
        return Promise.reject(error);
      }
      const error =
        data[Object.keys(data)[0]][0] || data[0].message || response.statusText;
      return Promise.reject(error);
    });
  }
  return response;
}

export function handleErrors(response) {
  if (response.ok) {
    return response;
  }

  if (response.status === 401) {
    return Promise.reject(response.statusText);
  }
  
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    const message = data[0] && data[0].message;

    const error =
      (data[0] && data[Object.keys(data)[0]][0]) ||
      message ||
      response.statusText;
    return Promise.reject(error);
  });
}

export function handleConnection(error) {
  if (typeof error === "object") return "Sorry. We couldn't connect.";
  return error;
}
