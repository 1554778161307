import React from "react";
import PropTypes from 'prop-types';

import { useVisibleColumns } from "../hooks/use-visible-column"
import { FormTableRow } from "../domain";

import { WizardFormCell } from "./WizardFormCell";

const hasBothLastInvalid = (data, validateRow) => {
  if (data.length < 3) return false;

  const last = data[data.length - 1];
  const secondToLast = data[data.length - 2];

  const areInvalid = [last, secondToLast].every((row) => !validateRow(row));

  return areInvalid;
};

export const WizardFormBody = ({ initialRows, columns, data, onChange }) => {
  const visibleColumns = useVisibleColumns(columns);

  const getDefaultRowData = React.useCallback(() => {
    const properties = columns.filter((c) => !!c.property);

    const newRow = properties.reduce(
      (acc, column) => ({
        ...acc,
        [column.property]: column.defaultValue || ""
      }),
      {}
    );

    return newRow;
  }, [columns]);

  const validateRow = React.useCallback(
    (row) => {
      const validators = columns.filter((c) => !!c.validate);

      return (
        validators.length &&
        validators.every(
          (v) => row && v.validate(row.state[v.property], row, v)
        )
      );
    },
    [columns]
  );

  const computedData = React.useMemo(() => {
    const formTableRows = FormTableRow.createFromArray(data);

    if (formTableRows.length >= initialRows) {
      const allRowsAreValid = formTableRows.every((row) => validateRow(row));

      if (allRowsAreValid) {
        return [...formTableRows, FormTableRow.create(getDefaultRowData())];
      }

      if (
        formTableRows.length !== initialRows &&
        hasBothLastInvalid(formTableRows, validateRow)
      ) {
        return formTableRows.slice(0, -1);
      }

      return formTableRows;
    }

    const missingRows = initialRows - formTableRows.length;

    for (let i = 0; i < missingRows; i += 1) {
      formTableRows.push(FormTableRow.create(getDefaultRowData()));
    }

    return formTableRows;
  }, [data, initialRows, getDefaultRowData, validateRow]);

  const update = (value, rowIndex, col) => {
    const newData = computedData.map((row, index) => {
      if (index !== rowIndex) return row.getData();

      return row.set(col.property, value).getData();
    });

    onChange && onChange(newData);
  };

  return (
    <tbody>
      {computedData.map((row, rowIndex) => (
        <tr key={`row-${rowIndex}`}>
          <td>{rowIndex + 1}</td>
          {visibleColumns.map((col, colIndex) => (
            <td key={`row-col-${colIndex}`}>
              <WizardFormCell
                key={colIndex}
                column={col}
                row={row}
                onChange={(value) => update(value, rowIndex, col)}
              />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

WizardFormBody.propTypes = {
  initialRows: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({
      hidden: PropTypes.bool,
      icon: PropTypes.string,
      name: PropTypes.string,
      defaultValue: PropTypes.string
  })).isRequired,
  data: PropTypes.array,
  onChange: PropTypes.func
}