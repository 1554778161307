import React, { Component } from "react";
import PropTypes from "prop-types";

//components
import { Link } from "react-router-dom";
import User from "./components/User";

//images
import HeaderLogo from "../../assets/images/logos/tm_logo_white.svg";

//scss
import "./Navbar.scss";
import "./components/User/User.scss";

class Navbar extends Component {
  handleHelp = () => {
    this.props.history.push("/dashboard");
  };

  render() {
    const { user } = this.props;
    return (
      <div className="navbar">
        <div className="navbar__logo">
          {user ? (
            <Link to="/dashboard">
              <img
                src={HeaderLogo}
                alt="header-logo"
                className="navbar__logo_image"
              />
            </Link>
          ) : (
            <img
              src={HeaderLogo}
              alt="header-logo"
              className="navbar__logo_image"
            />
          )}
        </div>
        {user ? (
          <User />
        ) : (
          <button className="navbar__help" onClick={this.handleHelp}>
            <i className="fa fa-question-circle" />
            <span>Help</span>
          </button>
        )}
      </div>
    );
  }
}

Navbar.propTypes = {
  user: PropTypes.bool,
  history: PropTypes.object
};

export default Navbar;
