import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// actions
import {
  getValueColor
} from "../../store/helpers/uiHelper";

import { submitReportCode } from "../../store/actions/reports.action";

//icons
import t360NotPurchased from "../../assets/images/icons/tl_360_icon_not_purchased.svg";
import t360Purchased from "../../assets/images/icons/tl_360_icon_purchased.svg";
import benchmarkNotPurchased from "../../assets/images/icons/tl_benchmark_grey_not_purchased.svg";
import benchmarkPurchased from "../../assets/images/icons/tl_benchmark_purchased.svg";

// scss
import { INDICATORS } from "../../constants/metrics";
import "./ReportScore.scss";

const T360 = "t360";
const TEAM_360 = "team-360";
const FULL_REPORT = "full-report";
const BENCHMARK = "bm";
const CONSULTING = "co";

class ReportScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      isDisabled: props.isDisabled,
    };
  }

  goToRoute = (route) => {
    this.props.history.push(route);
  };

  selectTeam360 = () => {
    const { team360Id, updateFormFieldValue } = this.props;
    if (team360Id) {
      updateFormFieldValue("allReports", team360Id);
    }
    return;
  };

  renderPaymentButton(report) {
    return (
      <div className="upgrade-btn">
        <Link
          to={`/product/${report}`}
          onClick={(e) => e.stopPropagation()}
          className="sub-btn"
        >
          <span>Purchase</span>
        </Link>
      </div>
    );
  }

  renderConsulting() {
    return (
      <div className="report__content">
        <div className="consulting-upgrade-btn ">
          <div className="consulting-upgrade-btn__icons">
            <i className="fa fa-pie-chart" />
            <i className="fa fa-line-chart" />
            <i className="fa fa-users" />
            <i className="fa fa-shield" />
            <i className="fa fa-handshake" />
            <i className="fa fa-graduation-cap" />
            <i className="fa fa-flash" />
            <i className="fa fa-gavel" />
          </div>
          <div className="consulting-upgrade-btn__description">
            <span>Book a consulting session with a TeamLytica Expert.</span>
          </div>
          <a
            target="__blank"
            href="https://calendly.com/teamlytica/consulting-hour"
          >
            <span>Purchase</span>
          </a>
        </div>
      </div>
    );
  }

  renderScore() {
    const { average } = this.props;

    return (
      <div className="report__content">
        <div className={clsx("__details", getValueColor(average, false))}>
          <span>{average ?? "N/A"}</span>
        </div>
      </div>
    );
  }

  renderImage() {
    const {
      averageType,
      isTeam360,
      hasBenchmarking,
      team360Id,
      disablePurchaseButton,
    } = this.props;

    const { isDisabled } = this.state;

    switch (averageType) {
      case BENCHMARK:
        return (
          <div className={`report__content`}>
            <div className="report__content-title">
              <img
                src={
                  hasBenchmarking && !isDisabled
                    ? benchmarkPurchased
                    : benchmarkNotPurchased
                }
                className={clsx("report__content_image", {
                  "h-full": disablePurchaseButton,
                  "max-h-120": !disablePurchaseButton,
                })}
                alt="Benchmark Icon"
              />
              {!disablePurchaseButton && this.renderPaymentButton(FULL_REPORT)}
            </div>
          </div>
        );
      case T360:
        return (
          <div className="report__content">
            <div className="report__content-title">
              <img
                src={isTeam360 || team360Id ? t360Purchased : t360NotPurchased}
                className={clsx("report__content_image", {
                  "h-full": disablePurchaseButton,
                  "max-h-120": !disablePurchaseButton,
                })}
                alt="Benchmark Icon"
              />
              {this.renderPaymentButton(TEAM_360)}
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  getReportProperties() {
    const { averageType, hasBenchmarking, isTeam360, team360Id } =
      this.props;

    const { isDisabled } = this.state;

    switch (averageType) {
      case INDICATORS.TEAM_SYNERGY:
        return {
          title: "Team Synergy Score",
          route: "/reports/team-synergy",
          icon: "fa fa-users",
          text: "View your team's synergy score",
          content: this.renderScore(),
          disabled: isDisabled,
        };
      case BENCHMARK:
        return {
          title: "Benchmarking",
          route: "/reports/compare",
          icon: "fa fa-line-chart",
          content: this.renderImage(),
          disabled: !hasBenchmarking || isDisabled,
        };
      case T360:
        return {
          title: "Team 360",
          route: "/reports/your-360-reports",
          icon: "fa fa-dot-circle-o",
          text: "Purchase Team 360 reports",
          content: this.renderImage(),
          disabled: isTeam360 && team360Id,
        };
      case CONSULTING:
        return {
          title: "Consulting",
          route: "/",
          icon: "fa fa-bolt",
          text: "",
          content: this.renderConsulting(),
          disabled: false,
        };
      default:
        return {};
    }
  }

  render() {
    const { averageType, team360Id } = this.props;

    const content = this.getReportProperties().content;
    const disabled = this.getReportProperties().disabled;
    const icon = this.getReportProperties().icon;
    const route = this.getReportProperties().route;
    const text = this.getReportProperties().text;
    const title = this.getReportProperties().title;

    return (
      <div
        className={clsx("flex-score-container", {
          pointer: !disabled,
        })}
        onClick={
          disabled ||
          (averageType !== "co" && team360Id && averageType === "t360")
            ? this.selectTeam360
            : () => this.goToRoute(route) ?? null
        }
      >
        <div className={`report `}>
          <i className={`${icon}`} />
          <span className="report__title">{title}</span>
        </div>
        {content}
        <div
          className={clsx("tile-link", {
            grey: disabled,
          })}
        >
          <span>{text}</span>
          {text && averageType !== "co" && (
            <i className="fa fa-chevron-right" />
          )}
        </div>
      </div>
    );
  }
}

ReportScore.propTypes = {
  average: PropTypes.number,
  averageType: PropTypes.string,
  isCurrentReport: PropTypes.bool,
  isTeam360: PropTypes.bool,
  hasBenchmarking: PropTypes.bool,
  history: PropTypes.object,
  userPlan: PropTypes.string,
  scoreType: PropTypes.string,
  submitReportCode: PropTypes.func,
  assessment_id: PropTypes.string,
  report: PropTypes.object,
  team360Id: PropTypes.number,
  updateFormFieldValue: PropTypes.func,
  disablePurchaseButton: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    hasBenchmarking:
      state.general &&
      state.general.content &&
      state.general.content.has_benchmarking,
    isTeam360:
      state.reports &&
      state.reports.reportStatus &&
      state.reports.reportStatus.is_team_360,
    isCurrentReport:
      state.reports &&
      state.reports.reportStatus &&
      state.reports.reportStatus.is_current,
    team360Id:
      state.reports &&
      state.reports.reportStatus &&
      state.reports.reportStatus.team_360_id,
  };
};

export default connect(mapStateToProps, { submitReportCode })(
  withRouter(ReportScore)
);
