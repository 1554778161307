import { actions } from "../actions/auth.action";

const defaultState = {
  isAuthenticated: null,
  isRegistered: false
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        user: action.user,
        loginError: ""
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.user
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        loginError: action.error
      };
    case actions.LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };
    case actions.REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        user: action.user,
        registerError: ""
      };
    case actions.REGISTER_SUCCESS:
      return {
        ...state,
        isRegistered: true,
        isLoading: false,
        user: action.user
      };
    case actions.REGISTER_FAILURE:
      return {
        ...state,
        isRegistered: false,
        isLoading: false,
        registerError: action.error
      };

    case actions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        user: action.user,
        forgotPasswordError: null
      };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotten: true,
        isLoading: false
      };
    case actions.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isForgotten: false,
        isLoading: false,
        forgotPasswordError: action.error
      };
    case actions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        resetPassword: action.resetPassword,
        resetPasswordError: null
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordReset: true,
        isLoading: false
      };
    case actions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isPasswordReset: false,
        isLoading: false,
        resetPasswordError: action.error
      };
    case actions.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        changePassword: action.changePassword,
        changePasswordError: null
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordChanged: true,
        isLoading: false
      };
    case actions.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isPasswordChanged: false,
        isLoading: false,
        changePasswordError: action.error
      };
    case actions.REFRESH_TOKEN_REQUEST:
      return {
        ...state
      };
    case actions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        error: null
      };
    case actions.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case actions.CHECK_AUTHENTICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginError: null
      };
    case actions.CHECK_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        loginError: null
      };
    case actions.CHECK_AUTHENTICATION_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        loginError: action.error
      };

    case actions.CHECK_REGISTRATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        registerError: null
      };
    case actions.CHECK_REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        registerData: action.user,
        registerError: null
      };
    case actions.CHECK_REGISTRATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        registerError: action.payload
      };
      case actions.CREATE_ACCOUNT_REQUEST:
        return {
          ...state,
          isLoading: true,
          registerError: null
        };
      case actions.CREATE_ACCOUNT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          registerData: action.payload,
          isRegistered: true,
          registerError: null
        };
      case actions.CREATE_ACCOUNT_FAILURE:
        return {
          ...state,
          isLoading: false,
          registerError: action.payload
        };

    default:
      return state;
  }
}
