import React from 'react'
import PropTypes from 'prop-types'
import CancelIcon from '@mui/icons-material/Cancel';

import './TeamReviewTableFooter.scss'

const TeamReviewTableFooter = ({ onClearFilters, teamMembers, countSufix }) => {
  return (
    <section className="table-footer">
      <div className="table-footer__count">{teamMembers.length} Team Members {countSufix}</div>
      <div className="table-footer__clear" onClick={onClearFilters}><CancelIcon /><span>clear filters</span></div>
    </section>

  )
}

TeamReviewTableFooter.propTypes = {
  onClearFilters: PropTypes.func,
  teamMembers: PropTypes.array,
  countSufix: PropTypes.string
}

export default TeamReviewTableFooter