export const allReportsData = {
  id: 1,
  name: "allReport",
  label: "",
};

export const filteredReportsData = {
  id: 2,
  name: "filteredReport",
  label: "",
};

export const selectedReportsData = {
  id: 3,
  name: "selectedReport",
  label: "",
};

export const reportToCompareFrom = {
  id: 4,
  name: "reportFrom",
  label: "",
};

export const reportToCompareTo = {
  id: 5,
  name: "reportTo",
  label: "",
};
