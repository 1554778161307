import { methods } from "../helpers/HttpHelper";
import { authHeader } from "../helpers/authHeader";
import { handleErrors } from "../helpers/errorHandlers";
import { notifyError } from "./../../utils/notifications";
export const actions = {
  CREATE_PAYMENT_INTENT_REQUEST: "CREATE_PAYMENT_INTENT_REQUEST",
  CREATE_PAYMENT_INTENT_SUCCESS: "CREATE_PAYMENT_INTENT_SUCCESS",
  CREATE_PAYMENT_INTENT_FAILURE: "CREATE_PAYMENT_INTENT_FAILURE",

  FETCH_ALL_PRODUCTS_REQUEST: "FETCH_ALL_PRODUCTS_REQUEST",
  FETCH_ALL_PRODUCTS_SUCCESS: "FETCH_ALL_PRODUCTS_SUCCESS",
  FETCH_ALL_PRODUCTS_FAILURE: "FETCH_ALL_PRODUCTS_FAILURE",

  FETCH_COUPON_REQUEST: "FETCH_COUPON_REQUEST",
  FETCH_COUPON_SUCCESS: "FETCH_COUPON_SUCCESS",
  FETCH_COUPON_FAILURE: "FETCH_COUPON_FAILURE",

  FETCH_PRODUCT_REQUEST: "FETCH_PRODUCT_REQUEST",
  FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
  FETCH_PRODUCT_FAILURE: "FETCH_PRODUCT_FAILURE",

  FETCH_PRODUCT_PRICE_REQUEST: "FETCH_PRODUCT_PRICE_REQUEST",
  FETCH_PRODUCT_PRICE_SUCCESS: "FETCH_PRODUCT_PRICE_SUCCESS",
  FETCH_PRODUCT_PRICE_FAILURE: "FETCH_PRODUCT_PRICE_FAILURE",

  MAKE_PAYMENT_REQUEST: "MAKE_PAYMENT_REQUEST",
  MAKE_PAYMENT_SUCCESS: "MAKE_PAYMENT_SUCCESS",
  MAKE_PAYMENT_FAILURE: "MAKE_PAYMENT_FAILURE",

  UPDATE_PAYMENT_ERROR_REQUEST: "UPDATE_PAYMENT_ERROR_REQUEST",
  UPDATE_PAYMENT_ERROR_SUCCESS: "UPDATE_PAYMENT_ERROR_SUCCESS",
  UPDATE_PAYMENT_ERROR_FAILURE: "UPDATE_PAYMENT_ERROR_FAILURE",

  FETCH_CUSTOMER_SUCCESS: "FETCH_CUSTOMER_SUCCESS",
  FETCH_CUSTOMER_REQUEST: "FETCH_CUSTOMER_REQUEST",
  FETCH_CUSTOMER_FAILURE: "FETCH_CUSTOMER_FAILURE",
};

export function fetchCoupon(coupon, subscriptionId, quantity) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/coupon/price?code=${coupon}&subscriptionTypeId=${subscriptionId}&quantity=${quantity}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCouponRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchCouponSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchCouponFailure(error)));
  };

  function fetchCouponRequest() {
    return { type: actions.FETCH_COUPON_REQUEST };
  }
  function fetchCouponSuccess(payload) {
    return {
      type: actions.FETCH_COUPON_SUCCESS,
      payload,
    };
  }
  function fetchCouponFailure(error) {
    return {
      type: actions.FETCH_COUPON_FAILURE,
      error,
    };
  }
}

export const createPaymentIntent =
  (params, stripe, cardElement) => async (dispatch) => {
    var productId = params.subscription_type_id;
    dispatch(createPaymentIntentRequest(productId));
    try {
      const payload = await fetch(
        `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/payment/intent`,
        {
          method: methods.POST,
          headers: authHeader(),
          body: JSON.stringify(params),
        }
      );

      await handleErrors(payload);
      const data = await payload.json();
      dispatch(createPaymentIntentSuccess());
      if (data.already_done) {
        dispatch(
          dispatch({
            type: actions.MAKE_PAYMENT_SUCCESS,
            payload: true,
          })
        );
      } else {
        dispatch(makePayment(stripe, data, cardElement));
      }
    } catch (error) {
      dispatch(
        createPaymentIntentFailure(
          error instanceof Object ? error.message : error,
          productId
        )
      );
    }

    function createPaymentIntentRequest(requestedId) {
      return (dispatch) => {
        dispatch({
          type: actions.CREATE_PAYMENT_INTENT_REQUEST,
          requestedId,
        });
      };
    }

    function createPaymentIntentSuccess() {
      return (dispatch) => {
        dispatch({
          type: actions.CREATE_PAYMENT_INTENT_SUCCESS,
        });
      };
    }

    function createPaymentIntentFailure(error, requestedId) {
      return (dispatch) => {
        dispatch({
          type: actions.CREATE_PAYMENT_INTENT_FAILURE,
          error,
          requestedId,
        });
        if (error === "Failed to fetch") {
          notifyError("Service is unavailable");
        }
      };
    }
  };

const makePayment = (stripe, data, cardElement) => async (dispatch) => {
  dispatch(makePaymentRequest());
  try {
    stripe
      .handleCardPayment(data.clientSecret, cardElement, {
        payment_method_data: {
          billing_details: {
            name: data.name,
            email: data.email,
          },
        },
      })
      .then(function (result) {
        if (result.error) {
          var error = result.error;
          dispatch(makePaymentFailure(error.message));

          dispatch(
            updatePaymentError({
              id: data.paymentToken,
              code: error.code,
              type: error.type,
              message: error.message,
            })
          );
        } else {
          dispatch(makePaymentSuccess());
        }
      });
  } catch (error) {
    dispatch(makePaymentFailure(error));
  }

  function makePaymentRequest() {
    return { type: actions.MAKE_PAYMENT_REQUEST };
  }

  function makePaymentFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.MAKE_PAYMENT_FAILURE,
        error: error instanceof Object ? error.message : error,
      });
    };
  }

  function makePaymentSuccess() {
    return (dispatch) => {
      dispatch({
        type: actions.MAKE_PAYMENT_SUCCESS,
        payload: true,
      });
    };
  }
};

export const updatePaymentError = (param) => async (dispatch) => {
  dispatch(updatePaymentErrorRequest());
  try {
    const payload = await fetch(
      `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/stripe/notify-error`,
      {
        method: methods.POST,
        headers: authHeader(),
        body: JSON.stringify(param),
      }
    );

    await handleErrors(payload);
    dispatch(updatePaymentErrorSuccess(true));
  } catch (error) {
    dispatch(
      updatePaymentErrorFailure(error instanceof Object ? error.message : error)
    );
  }

  function updatePaymentErrorRequest() {
    return { type: actions.UPDATE_PAYMENT_ERROR_REQUEST };
  }

  function updatePaymentErrorFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PAYMENT_ERROR_FAILURE,
        error,
      });
      notifyError(error === "Failed to fetch" ? "Service unavailable" : error);
    };
  }

  function updatePaymentErrorSuccess(intent) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PAYMENT_ERROR_SUCCESS,
        payload: intent,
      });
    };
  }
};

export function fetchAllProducts() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/subscription-type/all`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAllProductsRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchAllProductsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchAllProductsFailure(error)));
  };

  function fetchAllProductsRequest() {
    return { type: actions.FETCH_ALL_PRODUCTS_REQUEST };
  }
  function fetchAllProductsSuccess(payload) {
    return {
      type: actions.FETCH_ALL_PRODUCTS_SUCCESS,
      payload,
    };
  }
  function fetchAllProductsFailure(error) {
    return {
      type: actions.FETCH_ALL_PRODUCTS_FAILURE,
      error,
    };
  }
}

export function fetchProductPrice(subscriptionTypeId, quantity = 1, code = null) {
  const apiUrl = new URL(`${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/coupon/price`);


  apiUrl.search = new URLSearchParams({
    subscriptionTypeId,
    quantity,
    code,
  }).toString();


  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),

  };

  return (dispatch) => {
    dispatch(fetchProductPriceRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchProductPriceSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchProductPriceFailure(error)));
  };

  function fetchProductPriceRequest() {
    return { type: actions.FETCH_PRODUCT_PRICE_REQUEST };
  }
  function fetchProductPriceSuccess(payload) {
    return {
      type: actions.FETCH_PRODUCT_PRICE_SUCCESS,
      payload,
    };
  }
  function fetchProductPriceFailure(error) {
    return {
      type: actions.FETCH_PRODUCT_PRICE_FAILURE,
      error,
    };
  }
}


export function fetchProduct(report) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/subscription-type?key=${report}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchProductRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchProductSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchProductFailure(error)));
  };

  function fetchProductRequest() {
    return { type: actions.FETCH_PRODUCT_REQUEST };
  }
  function fetchProductSuccess(payload) {
    return {
      type: actions.FETCH_PRODUCT_SUCCESS,
      payload,
    };
  }
  function fetchProductFailure(error) {
    return {
      type: actions.FETCH_PRODUCT_FAILURE,
      error,
    };
  }
}

export function fetchCustomer() {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/accounts/customer`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCustomerRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchCustomerSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchCustomerFailure(error)));
  };

  function fetchCustomerRequest() {
    return { type: actions.FETCH_CUSTOMER_REQUEST };
  }
  function fetchCustomerSuccess(payload) {
    return {
      type: actions.FETCH_CUSTOMER_SUCCESS,
      payload,
    };
  }
  function fetchCustomerFailure(error) {
    return {
      type: actions.FETCH_CUSTOMER_FAILURE,
      error,
    };
  }
}
