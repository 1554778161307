/*eslint no-magic-numbers: 0*/
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

// scss
import { connect } from "react-redux";
import { isAdminOrReseller } from "../../store/helpers/userHelper";
import "./TableFooter.scss";

class TableFooter extends Component {
  render() {
    const {
      isTeam360,
      count,
      user,
      handleTeamMemberDialogOpen,
      canSeeLastReport,
      isViewOnlyUser,
      onAddNewRow,
      onAddNewMembers,
      onPurchaseNewMembers,
      members,
    } = this.props;
    const showPurchaseButton =
      isAdminOrReseller(user.role) &&
      members.some((member) => !member.is_stock_available);

    return (
      <Fragment>
        <tfoot>
          <tr className="table-footer">
            <td>
              <span className="table-footer_label">{`Showing ${count} Team Members`}</span>
              <div className="table-footer_actions">
                <div className="table_form_actions">
                  <button className="add" onClick={onAddNewRow}>
                    <span>Add Row</span>
                    <i className="fa fa-plus" aria-hidden="true" />
                  </button>
                  <button className="submit" onClick={onAddNewMembers}>
                    <span>Add New Members</span>
                    <i className="fa fa-user-plus" aria-hidden="true" />
                  </button>
                  {showPurchaseButton && (
                    <button className="payment" onClick={onPurchaseNewMembers}>
                      <span>Pay</span>
                    </button>
                  )}
                  {!isTeam360 && !isViewOnlyUser && canSeeLastReport && (
                    <button
                      className="table-footer__reset"
                      onClick={() => {
                        handleTeamMemberDialogOpen(null, "reset");
                      }}
                    >
                      <span>Start new questionnaire</span>
                    </button>
                  )}
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </Fragment>
    );
  }
}

TableFooter.propTypes = {
  changePage: PropTypes.func,
  count: PropTypes.number,
  canSeeLastReport: PropTypes.bool,
  isTeam360: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  sendAll: PropTypes.func,
  user: PropTypes.object,
  handleTeamMemberDialogOpen: PropTypes.func,
  isViewOnlyUser: PropTypes.bool,
  onAddNewRow: PropTypes.func,
  onAddNewMembers: PropTypes.func,
  canAddNewMembers: PropTypes.bool,
  onPurchaseNewMembers: PropTypes.func,
  members: PropTypes.array,
};

const mapStateToProps = (state) => ({
  members: state.team.allMembers,
});

export default connect(mapStateToProps)(TableFooter);
