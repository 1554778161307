import PropTypes from "prop-types";
import React, { Component } from "react";
import TextField from "../../../components/TextField";

//utils
import * as services from "../../../store/services/validation.service";
import * as data from "../data";

class TeamForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamNameField: { data: data.teamData },
      isFormSubmitted: false,
      isAddMemberOpen: true,
    };
    this.formStatus = {};
  }

  handleToggleMemberForm() {
    this.setState({
      isAddMemberOpen: !this.state.isAddMemberOpen,
    });
  }

  updateFormsStatus(fieldStatus, fieldName) {
    this.formStatus[fieldName] = fieldStatus;
  }

  updateFormFieldValue(fieldName, fieldValue) {
    this.setState({ [fieldName]: fieldValue });
  }

  handleChangeTeamName() {
    const isFormValid = services.getFormsValidityStatus(this.formStatus);
    const { team } = this.state;

    if (isFormValid) {
      this.props.updateTeamName(team, this.props.teamId);
      this.setState({ errorMessage: "" });
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Please review your information.",
      });
    }
  }

  render() {
    const { isAddMemberOpen, teamNameField } = this.state;

    return (
      <div className="team-page__form">
        <div className="team-page__button-container">
          <button
            className="team-page__hide-button"
            onClick={this.handleToggleMemberForm.bind(this)}
          >
            <span>
              {isAddMemberOpen ? "Hide this form" : "Change Team Name"}
              &nbsp;&nbsp;&nbsp;
            </span>
            <i className="fa fa-navicon" aria-hidden="true" />
          </button>
        </div>
        {!!isAddMemberOpen && (
          <div className="team-page__container">
            <div className="team-page__container-form">
              <div className="team-page__container-form-content">
                <TextField
                  data={{
                    ...teamNameField.data,
                    value: this.props.team,
                  }}
                  recordSelection={(field, value) =>
                    this.updateFormFieldValue(field, value)
                  }
                  updateFormsStatus={this.updateFormsStatus.bind(this)}
                  delay={500}
                  isFormSubmitted={this.state.isFormSubmitted}
                />
              </div>
              <div className="team-page__container-form-submit">
                <button
                  className="team-page__container-form-submit-button"
                  onClick={this.handleChangeTeamName.bind(this)}
                >
                  <span>Change name</span>
                  <i className="fa fa-check" aria-hidden="true" />
                </button>
              </div>

              <div className="team-page__container-form-submit">
                <button
                  title="Send questionnaire to all"
                  className="team-page__container-form-submit-all"
                  onClick={() => {
                    this.props.handleTeamMemberDialogOpen(null, "all");
                  }}
                >
                  <span>Send questionnaire to all</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TeamForm.propTypes = {
  handleTeamMemberDialogOpen: PropTypes.func,
  team: PropTypes.string,
  teamId: PropTypes.number,
  updateTeamName: PropTypes.func,
};

export default TeamForm;
