import { ROLES } from '../../constants/roles';

export function hasPermission(role) {
  return !!role;
}

export function isAdminOrReseller(userRole) {
  if (!userRole) {
    return false;
  }
  var normalizedRole = userRole.toUpperCase();
  return ([ROLES.ADMIN, ROLES.RESELLER].some(role => role === normalizedRole));
}

export function getSelectedReport(reports) {
  let selectedReport;
  reports &&
    reports.map(report => {
      return report.selected ? (selectedReport = report) : null;
    });
  return selectedReport && selectedReport.id;
}

export function getVisibleReports(reports) {
  const completedReports = [];
  reports &&
    reports.map(report => {
      if (report.canSeeReport) {
        return completedReports.push(report);
      }
      return null;
    });

  return completedReports;
}

export function getLatestReport(reports) {
  const latestReport = reports.slice(-1).pop();
  return latestReport.id;
}
