import { actions } from "../actions/benchmarking.action";

const initialState = {
  reportsToCompare: {
    reportFrom: null,
    reportTo: null,
  },
};

export default function benchmarking(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_REPORTS_TO_COMPARE_REQUEST:
      return {
        ...state,
        isLoadingComparableReports: true,
        comparableReports: null,
      };
    case actions.FETCH_REPORTS_TO_COMPARE_SUCCESS:
      return {
        ...state,
        isLoadingComparableReports: false,
        comparableReports: action.payload,
      };
    case actions.FETCH_REPORTS_TO_COMPARE_FAILURE:
      return {
        ...state,
        isLoadingComparableReports: false,
        error: action.error,
      };
    case actions.COMPARE_CATEGORY_REQUEST:
      return {
        ...state,
        isLoadingComparedCategory: true,
      };
    case actions.COMPARE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingComparedCategory: false,
        comparedCategory: action.payload,
      };
    case actions.COMPARE_CATEGORY_FAILURE:
      return {
        ...state,
        isLoadingComparedCategory: false,
        error: action.error,
      };
    case actions.COMPARE_CATEGORY_AVERAGES_REQUEST:
      return {
        ...state,
        isLoadingComparedCategoryAverages: true,
      };
    case actions.COMPARE_CATEGORY_AVERAGES_SUCCESS:
      return {
        ...state,
        isLoadingComparedCategoryAverages: false,
        comparedCategoryAverages: action.payload,
      };
    case actions.COMPARE_CATEGORY_AVERAGES_FAILURE:
      return {
        ...state,
        isLoadingComparedCategoryAverages: false,
        error: action.error,
      };

    case actions.UPDATE_ASSESSMENT_COMPARE:
      return {
        ...state,
        reportsToCompare: {
          ...state?.reportsToCompare,
          ...action.payload,
        },
      };

    case actions.COMPARE_QUESTION_AVERAGES_REQUEST:
      return {
        ...state,
        isLoadingComparedQuestionAverages: true,
      };
    case actions.COMPARE_QUESTION_AVERAGES_SUCCESS:
      return {
        ...state,
        isLoadingComparedQuestionAverages: false,
        comparedQuestionAverages: action.payload,
      };
    case actions.COMPARE_QUESTION_AVERAGES_FAILURE:
      return {
        ...state,
        isLoadingComparedQuestionAverages: false,
        error: action.error,
      };
    case actions.COMPARE_TOP10_REQUEST:
      return {
        ...state,
        isLoadingComparedTop10: true,
      };
    case actions.COMPARE_TOP10_SUCCESS:
      return {
        ...state,
        isLoadingComparedTop10: false,
        comparedTop10: action.payload,
      };
    case actions.COMPARE_TOP10_FAILURE:
      return {
        ...state,
        isLoadingComparedTop10: false,
        error: action.error,
      };
    case actions.COMPARE_URGENT_ACTIONS_REQUEST:
      return {
        ...state,
        isLoadingComparedUrgenctActions: true,
      };
    case actions.COMPARE_URGENT_ACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingComparedUrgenctActions: false,
        comparedUrgenctActions: action.payload,
      };
    case actions.COMPARE_URGENT_ACTIONS_FAILURE:
      return {
        ...state,
        isLoadingComparedUrgenctActions: false,
        error: action.error,
      };
    case actions.COMPARE_COMMENTS_REQUEST:
      return {
        ...state,
        isLoadingComparedComments: true,
      };
    case actions.COMPARE_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoadingComparedComments: false,
        comparedComments: action.payload,
      };
    case actions.COMPARE_COMMENTS_FAILURE:
      return {
        ...state,
        isLoadingComparedComments: false,
        error: action.error,
      };
    default:
      return state;
  }
}
