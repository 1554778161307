export const firstNameData = {
  id: 1,
  value: "",
  name: "first_name",
  label: "First name*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const surnameData = {
  id: 2,
  value: "",
  name: "surname",
  label: "Surname*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const companyNameData = {
  id: 3,
  value: "",
  name: "company_name",
  label: "Company name*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const addressData = {
  id: 4,
  value: "",
  name: "address",
  label: "Address*",
  placeholder: "",
  type: "text",
  validate: true,
  required: true,
  validations: [
    {
      validationName: "minLength",
      value: 3,
      priority: 1,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 200,
      priority: 2,
      message: `Maximum allowed length is 200 characters`
    }
  ]
};

export const countryData = {
  id: 5,
  value: 0,
  name: "country_id",
  label: "Country*",
  list: [],
  component: "dropdown",
  required: true,
  validate: true
};

export const townCityData = {
  id: 6,
  value: "",
  name: "town_city",
  label: "Town/City*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const postCodeData = {
  id: 7,
  value: "",
  name: "post_code",
  label: "Postcode/Zipcode*",
  placeholder: "",
  type: "text",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 100 characters`
    }
  ]
};

export const phoneNumberData = {
  id: 8,
  value: "",
  name: "phone_number",
  label: "Telephone",
  placeholder: "",
  type: "tel",
  validate: true,
  validations: [
    {
      validationName: "regex",
      value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
      priority: 1,
      message: "Invalid telephone"
    },
    {
      validationName: "minLength",
      value: 3,
      priority: 2,
      message: `Minimum required length is 3 characters`
    },
    {
      validationName: "maxLength",
      value: 100,
      priority: 3,
      message: `Maximum allowed length is 20 characters`
    }
  ]
};

export const emailData = {
  id: 9,
  value: "",
  name: "email",
  label: "Email Address*",
  type: "email",
  placeholder: "",
  required: true,
  validate: true,
  validations: [
    {
      validationName: "required",
      priority: 1,
      message: "This is required field"
    },
    {
      validationName: "regex",
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      priority: 2,
      message: "Invalid email"
    },
    {
      validationName: "minLength",
      value: 8,
      priority: 3,
      message: `Minimum required length is 8 characters`
    },
    {
      validationName: "maxLength",
      value: 50,
      priority: 4,
      message: `Maximum allowed length is 50 characters`
    }
  ]
};
