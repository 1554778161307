import { actions } from "../actions/reseller.action";

const initialState = {
  isLoadingReseller: false,
  reseller: [],
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_RESELLER_REQUEST:
      return {
        ...state,
        isLoadingReseller: true,
        error: null,
      };
    case actions.ADD_RESELLER_SUCCESS:
      return {
        ...state,
        isLoadingReseller: false,
      };
    case actions.ADD_RESELLER_FAILURE:
      return {
        ...state,
        isLoadingReseller: false,
        error: action.error,
      };
    case actions.ADD_VIEW_ONLY_USER_REQUEST:
      return {
        ...state,
        isLoadingReseller: true,
        error: null,
      };
    case actions.ADD_VIEW_ONLY_USER_SUCCESS:
      return {
        ...state,
        isLoadingReseller: false,
      };
    case actions.ADD_VIEW_ONLY_USER_FAILURE:
      return {
        ...state,
        isLoadingResellerAction: false,
        error: action.error,
      };
    case actions.DELETE_RESELLER_REQUEST:
      return {
        ...state,
        isLoadingResellerAction: true,
        error: null,
      };
    case actions.DELETE_RESELLER_SUCCESS:
      return {
        ...state,
        isLoadingResellerAction: false,
        reseller: {},
      };
    case actions.DELETE_RESELLER_FAILURE:
      return {
        ...state,
        isLoadingResellerAction: false,
        error: action.error,
      };
      case actions.DELETE_VIEW_ONLY_USER_REQUEST:
        return {
          ...state,
          isLoadingResellerAction: true,
          error: null,
        };
      case actions.DELETE_VIEW_ONLY_USER_SUCCESS:
        return {
          ...state,
          isLoadingResellerAction: false,
          reseller: state.reseller.filter((res) => res != action.payload),
        };
      case actions.DELETE_VIEW_ONLY_USER_FAILURE:
        return {
          ...state,
          isLoadingResellerAction: false,
          error: action.error,
        };
    case actions.FETCH_VIEW_ONLY_USER_REQUEST:
      return {
        ...state,
        isLoadingReseller: true,
        error: null,
      };
    case actions.FETCH_VIEW_ONLY_USER_SUCCESS:
      return {
        ...state,
        isLoadingReseller: false,
        reseller: action.payload,
      };
    case actions.FETCH_VIEW_ONLY_USER_FAILURE:
      return {
        ...state,
        isLoadingReseller: false,
        error: action.error,
      };
    case actions.FETCH_RESELLER_REQUEST:
      return {
        ...state,
        isLoadingReseller: true,
        error: null,
      };
    case actions.FETCH_RESELLER_SUCCESS:
      return {
        ...state,
        isLoadingReseller: false,
        reseller: action.payload,
      };
    case actions.FETCH_RESELLER_FAILURE:
      return {
        ...state,
        isLoadingReseller: false,
        error: action.error,
      };
    default:
      return state;
  }
}
