import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import { Redirect } from "react-router-dom";

//images
import Logo from "../../../../../../assets/images/logos/tm_logo_blue.svg";
import Progress3 from "../../../../../../assets/images/other/progress3.svg";

//scss
import "../../../../Auth.scss";
import { Footer, RedirectPanel } from "../../../../components";

class RegisterSuccess extends Component {
  render() {
    const { isRegistered, location } = this.props;

    if (!isRegistered) {
      const { from = "/" } = location.state || {};
      return <Redirect to={from} />;
    }

    return (
      <div className="auth-page">
        <div className="auth-page__form">
          <div className="auth-page__form-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div
            className="auth-page__form-fields"
            style={{ alignItems: "center" }}
          >
            <div
              className="auth-page__form-fields-title"
              style={{ textAlign: "center" }}
            >
              <span>That&apos;s it!</span>
            </div>
            <div
              className="auth-page__form-fields-subtitle"
              style={{ textAlign: "center" }}
            >
              <span>
                Thank you for registering your TeamLytica profile.
                <br />
                Please check your email for your account verification link.
              </span>
            </div>
            <div className="auth-page__form-progress">
              <img src={Progress3} alt="Progress" />
            </div>
          </div>
          <RedirectPanel label="Already registered?" linkLabel="Log in" linkTo="/login" />
         <Footer />
        </div>
        <div className="auth-page__image">
          <div
            className="auth-page__image-container"
            style={{
              backgroundImage: `url(${require(`../../../../../../assets/images/background/background_image_3.jpg`)})`
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isRegistered } = state.auth;
  return {
    isRegistered
  };
}

RegisterSuccess.propTypes = {
  isRegistered: PropTypes.bool,
  location: PropTypes.object
};

export default connect(
  mapStateToProps,
  null
)(RegisterSuccess);
