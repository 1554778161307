import React, { Component } from "react";

class TermsConditions extends Component {
  render() {
    return (
      <div style={{ margin: "5em 10em 5em 10em" }}>
        <ol>
          <li>
            <strong>Introduction</strong>
          </li>
          <p>
            These terms and conditions shall govern your use of the TeamLytica
            service.
          </p>
          <ol type="a">
            <li>
              By using our service, you accept these terms and conditions in
              full; accordingly, if you disagree with these terms and conditions
              or any part of these terms and conditions, you must not use our
              service.
            </li>
            <li>
              If you register with our service, submit any scores, comments,
              feedback or material to our service or use any of our services, we
              will ask you to expressly agree to these terms and conditions.
            </li>
            <li>
              Our service uses cookies; by using our service or agreeing to
              these terms and conditions, you consent to our use of cookies in
              accordance with the terms of our Privacy Policy.
            </li>
          </ol>
        </ol>
        <p>&nbsp;</p>
        <ol start="2">
          <li>
            <strong>Copyright notice</strong>
            <p />
            <ol type="a">
              <li>
                Copyright &copy; 2019 TEAM METRICS Limited, All Rights Reserved.
              </li>
              <li>
                Subject to the express provisions of these terms and conditions:
                <ul>
                  <li>
                    we, together with our licensors, own and control all the
                    copyright and other intellectual property rights in our
                    service and the material on our service
                  </li>
                  <li>
                    all the copyright and other intellectual property rights in
                    our service and the material on our service are reserved and
                    protected by UK Law.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol start="3">
          <li>
            <strong>Licence to use service</strong>
            <p />
            <ol type="a">
              <li>
                You may, subject to the other provisions of these terms and
                conditions:
                <ol type="i">
                  <li>
                    view pages from our service in a web or mobile browser;
                  </li>
                  <li>
                    download pages or PDFs from our service for caching in a web
                    or mobile browser;
                  </li>
                  <li>print pages from our service;</li>
                  <li>stream video files from our service;</li>
                </ol>
                <li>
                  Except as expressly permitted by Section 3a or the other
                  provisions of these terms and conditions, you must not
                  download any material from our service or save any such
                  material to your computer.
                </li>
                <li>
                  Except as expressly permitted by these terms and conditions,
                  you must not edit or otherwise modify any material on our
                  service.
                </li>
                <li>
                  Unless you own or control the relevant rights in the material,
                  you must not:
                  <ol type="i">
                    <li>
                      republish material from our service (including
                      republication on another service);
                    </li>
                    <li>
                      sell, rent or sub-license material from our service;
                    </li>
                    <li> show any material from our service in public;</li>
                    <li>
                      exploit material from our service for a commercial
                      purpose;
                    </li>
                    <li> redistribute material from our service.</li>
                  </ol>
                </li>
                <li>
                  Notwithstanding Section 3g, you may redistribute our
                  newsletter in electronic form to any person.
                </li>
                <li>
                  We reserve the right to restrict access to areas of our
                  service, or indeed our whole service, at our discretion; you
                  must not circumvent or bypass, or attempt to circumvent or
                  bypass, any access restriction measures on our service.
                </li>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol start="4">
          <li>
            <strong>Acceptable use</strong>
            <p />
            You must not:
            <ol type="a">
              <li>
                Use our service in any way or take any action that causes, or
                may cause, damage to the service or impairment of the
                performance, availability or accessibility of the service.
              </li>
              <li>
                Use our service in any way that is unlawful, illegal, fraudulent
                or harmful, or in connection with any unlawful, illegal,
                fraudulent or harmful purpose or activity.
              </li>
              <li>
                Use our service to copy, store, host, transmit, send, use,
                publish or distribute any material which consists of (or is
                linked to) any spyware, computer virus, trojan horse, worm,
                keystroke logger, rootkit or other malicious computer software.
              </li>
              <li>
                Conduct any systematic or automated data collection activities
                (including without limitation scraping, data mining, data
                extraction and data harvesting) on or in relation to our service
                without our express written consent.
              </li>
              <li>
                Access or otherwise interact with our service using any robot,
                spider or other automated means.
              </li>
              <li>
                Violate the directives set out in the robots.txt file for our
                service;
              </li>
              <li>
                Use data collected from our service for any direct marketing
                activity (including without limitation email marketing, SMS
                marketing, telemarketing and direct mailing).
              </li>
              <li>
                You must not use data collected from our service to contact
                individuals, companies or other persons or entities.
              </li>
              <li>
                You must ensure that all the information you supply to us
                through our service, or in relation to our service, is [true,
                accurate, current, complete and non-misleading].
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ol start="5">
          <li>
            <strong>Registration and accounts</strong>
            <p />
            <ol type="a">
              <li>
                You may register for an account with our service by completing
                and submitting the account registration form on our service, and
                clicking on the verification link in the email that the service
                will send to you.
              </li>
              <li>
                You must notify us in writing immediately if you become aware of
                any unauthorised use of your account.
              </li>
              <li>
                You must not use any other person&apos;s account to access the
                service, unless you have that person&apos;s express permission
                to do so.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="6">
          <li>
            <strong>User IDs and passwords</strong>
            <p />
            <ol type="a">
              <li>
                If you register for an account with our service, we will provide
                you with / you will be asked to choose a user ID and password.
              </li>
              <li>
                Your user ID must not be liable to mislead and must comply with
                the content rules set out in Section 9; you must not use your
                account or user ID for or in connection with the impersonation
                of any person.
              </li>
              <li>You must keep your password confidential.</li>
              <li>
                You must notify us in writing immediately if you become aware of
                any disclosure of your password.
              </li>
              <li>
                You are responsible for any activity on our service arising out
                of any failure to keep your password confidential, and may be
                held liable for any losses arising out of such a failure.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="7">
          <li>
            <strong>Cancellation and suspension of account</strong>
            <p />
            <ol type="a">
              <li>
                We may, at any time in our sole discretion without notice or
                explanation:
                <ol type="i">
                  <li>suspend your account;</li>
                  <li>cancel your account; and/or</li>
                  <li>edit your account details</li>
                </ol>
              </li>
              <li>
                You may cancel your account on our service using your account
                control panel on the service.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="8">
          <li>
            <strong>Your content: rules</strong>
            <p />
            <ol type="a">
              <li>
                You warrant and represent that your content will comply with
                these terms and conditions.
              </li>
              <li>
                Your content must not be illegal or unlawful, must not infringe
                any person&apos;s legal rights, and must not be capable of
                giving rise to legal action against any person (in each case in
                any jurisdiction and under any applicable law).
              </li>
              <li>
                Your content, and the use of your content by us in accordance
                with these terms and conditions, must not:
                <ol type="i">
                  <li>be libellous or maliciously false;</li>
                  <li>be obscene or indecent;</li>
                  <li>
                    infringe any copyright, moral right, database right, trade
                    mark right, design right, right in passing off, or other
                    intellectual property right;
                  </li>
                  <li>
                    infringe any right of confidence, right of privacy or right
                    under data protection legislation;
                  </li>
                  <li>
                    constitute negligent advice or contain any negligent
                    statement;
                  </li>
                  <li>
                    constitute an incitement to commit a crime or the promotion
                    of criminal activity;
                  </li>
                  <li>
                    be in contempt of any court, or in breach of any court
                    order;
                  </li>
                  <li>
                    be in breach of racial or religious hatred or discrimination
                    legislation;
                  </li>
                  <li>be blasphemous;</li>
                  <li>be in breach of official secrets legislation;</li>
                  <li>
                    be in breach of any contractual obligation owed to any
                    person;
                  </li>
                  <li>
                    depict violence, in an explicit, graphic or gratuitous
                    manner;
                  </li>
                  <li>
                    be pornographic, lewd, suggestive or sexually explicit;
                  </li>
                  <li>be untrue, false, inaccurate or misleading;</li>
                  <li>
                    consist of or contain any instructions, advice or other
                    information which may be acted upon and could, if acted
                    upon, cause illness, injury or death, or any other loss or
                    damage;
                  </li>
                  <li>constitute spam;</li>
                  <li>
                    be offensive, deceptive, fraudulent, threatening, abusive,
                    harassing, anti-social, menacing, hateful, discriminatory or
                    inflammatory;
                  </li>
                  <li>
                    cause annoyance, inconvenience or needless anxiety to any
                    person.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>

        <p>&nbsp;</p>
        <ol start="9">
          <li>
            <strong>Limited warranties</strong>
            <p />
            <br /> We do not warrant or represent:
            <ol type="a">
              <li>
                The completeness or accuracy of the information published on our
                service.
              </li>
              <li>That the material on the service is up to date;</li>
              <li>
                That the service or any service on the service will remain
                available.
              </li>
              <li>
                We reserve the right to discontinue or alter any or all of our
                service services, and to stop publishing our service, at any
                time in our sole discretion without notice or explanation; and
                save to the extent expressly provided otherwise in these terms
                and conditions, you will not be entitled to any compensation or
                other payment upon the discontinuance or alteration of any
                service services, or if we stop publishing the service.
              </li>
              <li>
                To the maximum extent permitted by applicable law and subject to
                section 10, we exclude all representations and warranties
                relating to the subject matter of these terms and conditions,
                our service and the use of our service.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="10">
          <li>
            <strong>Limitations and exclusions of liability</strong>
            <p />
            <ol type="a">
              <li>
                Nothing in these terms and conditions will:
                <ol type="i">
                  <li>
                    limit or exclude any liability for death or personal injury
                    resulting from negligence;
                  </li>
                  <li>
                    limit or exclude any liability for fraud or fraudulent
                    misrepresentation;
                  </li>
                  <li>
                    limit or exclude any liability for fraud or fraudulent
                    misrepresentation;
                  </li>
                  <li>
                    exclude any liabilities that may not be excluded under
                    applicable law.
                  </li>
                </ol>
              </li>
              <li>
                The limitations and exclusions of liability set out in this
                Section 12 and elsewhere in these terms and conditions:
                <ol type="i">
                  <li>are subject to Section 10a;</li>
                  <li>
                    govern all liabilities arising under these terms and
                    conditions or relating to the subject matter of these terms
                    and conditions, including liabilities arising in contract,
                    in tort (including negligence) and for breach of statutory
                    duty.
                  </li>
                </ol>
              </li>
              <li>
                To the extent that our service and the information and services
                on our service are provided free of charge, we will not be
                liable for any loss or damage of any nature.
              </li>
              <li>
                We will not be liable to you in respect of any losses arising
                out of any event or events beyond our reasonable control.
              </li>
              <li>
                We will not be liable to you in respect of any business losses,
                including (without limitation) loss of or damage to profits,
                income, revenue, use, production, anticipated savings, business,
                contracts, commercial opportunities or goodwill.
              </li>
              <li>
                We will not be liable to you in respect of any loss or
                corruption of any data, database or software.
              </li>
              <li>
                We will not be liable to you in respect of any loss or
                corruption of any data, database or software.
              </li>
              <li>
                You accept that we have an interest in limiting the personal
                liability of our officers and employees and, having regard to
                that interest, you acknowledge that we are a limited liability
                entity; you agree that you will not bring any claim personally
                against our officers or employees in respect of any losses you
                suffer in connection with the service or these terms and
                conditions (this will not, of course, limit or exclude the
                liability of the limited liability entity itself for the acts
                and omissions of our officers and employees).
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="11">
          <li>
            <strong>Breaches of these terms and conditions</strong>
            <p />
            <ol type="a">
              <li>
                Without prejudice to our other rights under these terms and
                conditions, if you breach these terms and conditions in any way,
                or if we reasonably suspect that you have breached these terms
                and conditions in any way, we may:
                <ol type="i">
                  <li>send you one or more formal warnings;</li>
                  <li>temporarily suspend your access to our service;</li>
                  <li>permanently prohibit you from accessing our service;</li>
                  <li>
                    block computers using your IP address from accessing our
                    service;
                  </li>
                  <li>
                    contact any or all your internet service providers and
                    request that they block your access to our service and
                    service;
                  </li>
                  <li>
                    commence legal action against you, whether for breach of
                    contract or otherwise; and/or
                  </li>
                  <li> suspend or delete your account on our service.</li>
                </ol>
              </li>
              <li>
                Where we suspend or prohibit or block your access to our service
                or a part of our service, you must not take any action to
                circumvent such suspension or prohibition or blocking (including
                without limitation creating and/or using a different account).
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="12">
          <li>
            <strong>Variation</strong>
            <p />
            <ol type="a">
              <li>
                We may revise these terms and conditions from time to time.
              </li>
              <li>
                The revised terms and conditions shall apply to the use of our
                service from the date of publication of the revised terms and
                conditions on the service, and you hereby waive any right you
                may otherwise have to be notified of, or to consent to,
                revisions of these terms and conditions. / We will give you
                written notice of any revision of these terms and conditions,
                and the revised terms and conditions will apply to the use of
                our service from the date that we give you such notice; if you
                do not agree to the revised terms and conditions, you must stop
                using our service.
              </li>
              <li>
                If you have given your express agreement to these terms and
                conditions, we will ask for your express agreement to any
                revision of these terms and conditions; and if you do not give
                your express agreement to the revised terms and conditions
                within such period as we may specify, we will disable or delete
                your account on the service, and you must stop using the
                service.
              </li>
            </ol>
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>Assignment</strong>
            <p />
            <ol type="a">
              <li>
                If you have given your express agreement to these terms and
                conditions, we will ask for your express agreement to any
                revision of these terms and conditions; and if you do not give
                your express agreement to the revised terms and conditions
                within such period as we may specify, we will disable or delete
                your account on the service, and you must stop using the
                service.
              </li>
              <li>
                You may not without our prior written consent assign, transfer,
                sub-contract or otherwise deal with any of your rights and/or
                obligations under these terms and conditions.
              </li>
            </ol>
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>Severability</strong>
            <p />
            <ol type="a">
              <li>
                If a provision of these terms and conditions is determined by
                any court or other competent authority to be unlawful and/or
                unenforceable, the other provisions will continue in effect.
              </li>
              <li>
                If any unlawful and/or unenforceable provision of these terms
                and conditions would be lawful or enforceable if part of it were
                deleted, that part will be deemed to be deleted, and the rest of
                the provision will continue in effect.
              </li>
            </ol>
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>Third party rights</strong>
            <p />
            <ol type="a">
              <li>
                These terms and conditions are for our benefit and your benefit,
                and these terms and conditions are not intended to benefit or be
                enforceable by any third party.
              </li>
              <li>
                The exercise of the parties rights under these terms and
                conditions is not subject to the consent of any third party.
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol start="16">
          <li>
            <strong>Entire agreement</strong>
            <p />
            <ol type="a">
              <li>
                Subject to Section 9, these terms and conditions, together with
                our privacy policy, shall constitute the entire agreement
                between you and us in relation to your use of our service and
                shall supersede all previous agreements between you and us in
                relation to your use of our service.
              </li>
            </ol>
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>Law and jurisdiction</strong>
            <p />
            <ol type="a">
              <li>
                These terms and conditions shall be governed by and construed in
                accordance with English law.
              </li>
              <li>
                Any disputes relating to these terms and conditions shall be
                subject to the exclusive jurisdiction of the courts of England.
              </li>
            </ol>
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>Statutory and regulatory disclosures</strong>
            <p />
            <ol type="a">
              <li>
                We are registered at UK Companies House; you can find the online
                version of the register at www.companieshouse.gov.uk, and our
                registration number is 11584075.
              </li>
            </ol>
          </li>
          <p>&nbsp;</p>
          <li>
            <strong>Our details</strong>
            <p />
            <ol type="a">
              <li>
                This TeamLytica service is owned and operated by TEAM METRICS
                Limited.
              </li>
              <li>
                We are registered in England and Wales under registration number
                11584075 and our registered office is 1st Floor, Rowood House,
                Murdock Road, Bicester, OX264PP, Oxfordshire, UK.
              </li>
              <li>
                Our principal place of business is at 1st Floor, Rowood House,
                Murdock Road, Bicester, OX264PP, Oxfordshire, UK.
              </li>
              <li>
                You can contact us by writing to the business address given
                above, by using our contact form, or by email to
                hello&#64;teamlytica.com.
              </li>
              <li>
                We are registered with the Information Commissioner&apos;s
                Office in the UK under registration number A8484991
              </li>
            </ol>
          </li>
        </ol>
        <p>&nbsp;</p>
      </div>
    );
  }
}

export default TermsConditions;
