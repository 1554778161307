// eslint-disable-next-line no-undef
const baseUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api`;

const teamMember = {
  createBulk: (team360) => `${baseUrl}/team/members/bulk?team360=${team360}`,
}

const content = {
  dashboard: () => `${baseUrl}/content?name=dashboard`,
}

export const API = {
  content,
  teamMember
}
