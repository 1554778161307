import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import { Redirect } from "react-router-dom";
import Checkbox from "../../../../../../components/Checkbox";
import TextField from "../../../../../../components/TextField";
import Dropdown from "../../../../../../components/Dropdown";

//redux actions
import { authActions } from "../../../../../../store/actions/auth.action";
import { fetchCountries } from "../../../../../../store/actions/general.action";

//utils
import * as data from "./data";
import { getFormsValidityStatus } from "../../../../../../store/services";

//images
import Logo from "../../../../../../assets/images/logos/tm_logo_blue.svg";
import Progress1 from "../../../../../../assets/images/other/progress1.svg";

//scss
import "../../../../Auth.scss";
import { Footer, RedirectPanel } from "../../../../components";

class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textfields: [
        { data: data.firstNameFieldData },
        { data: data.surnameFieldData },
        { data: data.emailFieldData },
        { data: data.passwordFieldData },
        { data: data.confirmPasswordFieldData },
      ],
      dropdowns: [{ data: data.countryFieldData }],
      checkboxes: [{ data: data.acceptTermsFieldData }, { data: data.emailUpdateOffersFieldData }],
      user: {
        accept_terms: data.acceptTermsFieldData.checked,
        email_update_offers: data.emailUpdateOffersFieldData.checked,
      },

      isFormSubmitted: false,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    this.props.fetchCountries();
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState((prevState) => ({
      user: { ...prevState.user, [fieldName]: fieldValue },
    }));
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  nextStep = () => {
    if (this.props.registerData && !this.props.registerError) {
      this.props.history.push(`/register-company`);
    }
  };

  validateTerms() {
    const { accept_terms } = this.state.user;
    return accept_terms;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = getFormsValidityStatus(this.formStatus);

    if (isFormValid) {
      if (!this.validateTerms()) {
        this.setState({
          isFormSubmitted: true,
          errorMessage: "Please read and agree with the TeamLytica terms and conditions.",
        });
        return;
      }
      this.setState({ errorMessage: "" });
      this.props.checkRegistration(this.state.user).then(() => this.nextStep());
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Form is invalid, please review your information.",
      });
    }
  };

  renderComponentForField = (field) => {
    const { user } = this.state;
    const { registerData } = this.props;

    switch (field.data.component) {
      case "checkbox":
        return (
          <Checkbox
            data={{
              ...field.data,
              checked:
                user && user[field.data.name] !== undefined
                  ? user[field.data.name]
                  : registerData && registerData[field.data.name]
                  ? registerData[field.data.name]
                  : field.data.checked,
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      case "textfield":
        return (
          <TextField
            data={{
              ...field.data,
              value:
                user && user[field.data.name]
                  ? user[field.data.name]
                  : registerData && registerData[field.data.name]
                  ? registerData[field.data.name]
                  : field.data.value,
            }}
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            data={{
              ...field.data,
              list: this.props.countries,
              value:
                user && user[field.data.name]
                  ? user[field.data.name]
                  : registerData && registerData[field.data.name]
                  ? registerData[field.data.name]
                  : field.data.value,
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      default:
        return null;
    }
  };

  renderButtons() {
    if (this.props.isLoading) {
      return <div className="loader" />;
    } else {
      return (
        <button type="submit" className="submit-button">
          <span>Next step</span>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </button>
      );
    }
  }

  render() {
    if (this.props.isAuthenticated) {
      const { from = "/dashboard" } = this.props.location.state || {};
      return <Redirect to={from} />;
    }

    return (
      <div className="auth-page">
        <div className="auth-page__form">
          <div className="auth-page__form-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="auth-page__form-fields">
            <form onSubmit={this.handleSubmit}>
              <div className="auth-page__form-fields-title">
                <span>Step 1. Let&apos;s get you registered:</span>
              </div>
              <div className="auth-page__form-fields-subtitle">
                <span>
                  It’s really quick and simple to register – just two quick screens and then you’re
                  done.
                </span>
              </div>

              {this.state.textfields.map((textfield) => (
                <div key={textfield.data.id}>{this.renderComponentForField(textfield)}</div>
              ))}
              {this.state.dropdowns.map((dropdown) => (
                <div key={dropdown.data.id}>{this.renderComponentForField(dropdown)}</div>
              ))}
              <div className="error-message">
                {this.state.errorMessage
                  ? this.state.errorMessage
                  : this.props.registerError
                  ? this.props.registerError
                  : ""}
              </div>
              <div className="auth-page__form-buttons">{this.renderButtons()}</div>
              {this.state.checkboxes.map((checkbox) => (
                <div key={checkbox.data.id}>{this.renderComponentForField(checkbox)}</div>
              ))}
            </form>
            <div className="auth-page__form-progress">
              <img src={Progress1} alt="Progress" />
            </div>
          </div>
          <RedirectPanel label="Already registered?" linkLabel="Log in" linkTo="/login" />
          <Footer />
        </div>
        <div className="auth-page__image">
          <div className="auth-page__image-container" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isAuthenticated, isLoading, registerData, registerError } = state.auth;

  const { countries } = state.general;

  return {
    isAuthenticated,
    isLoading,
    registerData,
    registerError,
    countries,
  };
}

RegisterUser.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  checkRegistration: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  registerData: PropTypes.object,
  registerError: PropTypes.string,
  fetchCountries: PropTypes.func,
  countries: PropTypes.array,
  match: PropTypes.object,
};

export default connect(mapStateToProps, {
  fetchCountries,
  checkRegistration: (user) => authActions.checkRegistration(user),
})(RegisterUser);
