import React from "react";
import PropTypes from "prop-types";
import WizardFooter from "../../../WizardFooter";
import WizardFormTable from "../../../WizardFormTable/WizardFormTable";

import './TeamCreation.scss'
import { stepEnum } from "../../../../../../store/helpers/teamWizardHelper";

const TeamCreation = (props) => {
  const { teamAmount, onChange, data, onBackStepClick, onNextStepClick, areas, } = props;
  return (
    <div className="team-creation">
      <WizardFormTable
        initialRows={Number(teamAmount)}
        columns={[
          { hidden: true },
          {
            hidden: false,
            icon: "fa-users",
            name: "Team",
            type: "textInput",
            property: "name",
            validate: (value) => {
              return value.length > 2;
            }
          },
          {
            hidden: false,
            icon: "fa-map",
            name: "Area",
            type: "select",
            property: "area",
            options: areas || [],
          },
        ]}
        data={data}
        onChange={onChange}
      />
      <WizardFooter
        className="team-creation__footer"
        title="Once you've added all your teams' Teams, move on to Team Members."
        buttonTitle="Add Teams"
        buttonIcon="fa-user"
        buttonColor="green"
        isFirstStep={false}
        backButtonColor="grey"
        backButtonOnClick={() => onBackStepClick(stepEnum.TeamsCreation)}
        backButtonTitle="Back"
        backButtonIcon="fa-solid fa-angle-left"
        onClick={onNextStepClick}
      />
    </div>
  );
};

TeamCreation.propTypes = {
  teamAmount: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
  onBackStepClick: PropTypes.func,
  onNextStepClick: PropTypes.func,
  areas: PropTypes.array,
};

export default TeamCreation;
