/*eslint react/no-deprecated: 0*/
import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Dropdown.scss";

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = props.data || {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state) {
      this.setState({
        ...nextProps.data,
      });
    }
  }
  onChange = (e) => {
    this.props.onSelect(e.target.name, e.target.value);
  };
  render() {
    const { disabled, label, list, value, name, validate } = this.state;

    return (
      <div className="dropdown">
        {label ? <div className="dropdown-label">{label}</div> : null}
        <select
          name={name}
          disabled={disabled}
          onChange={this.onChange}
          value={value}
          className="select"
        >
          {list.map((option) => {
            return (
              <option value={option.id} key={option.id}>
                {option.description}
              </option>
            );
          })}
        </select>
        {validate ? (
          <div className="validation-message">{value === "0" ? "This field is required" : ""}</div>
        ) : null}
      </div>
    );
  }
}

Dropdown.propTypes = {
  data: PropTypes.object,
  onSelect: PropTypes.func,
};
