import { actions } from "../actions/globalLoading.action";

const initialState = {
  isLoading: false
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case actions.SET_GLOBAL_LOADING_TIMEOUT:
      return {
        ...state,
        timeout: action.payload
      };
    case actions.SET_GLOBAL_LOADING_ON:
      return {
        isLoading: true
      };
    case actions.SET_GLOBAL_LOADING_OFF:
      return {
        isLoading: false
      };
    default:
      return state;
  }
}
