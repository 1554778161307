import { } from "react-router-dom";
import { methods } from "../helpers/HttpHelper";
import { authHeader } from "../helpers/authHeader";
import { handleErrors } from "../helpers/errorHandlers";

import { API } from "../../constants/api";
import { HttpClient } from "../../utils/http-client";
import { notifyError, notifySuccess } from "./../../utils/notifications";
import { fetchViewOnlyUser } from "./reseller.action";

export const actions = {
  FETCH_TEAM_REQUEST: "FETCH_TEAM_REQUEST",
  FETCH_TEAM_SUCCESS: "FETCH_TEAM_SUCCESS",
  FETCH_TEAM_FAILURE: "FETCH_TEAM_FAILURE",

  CREATE_TEAM_MEMBER_REQUEST: "CREATE_TEAM_MEMBER_REQUEST",
  CREATE_TEAM_MEMBER_SUCCESS: "CREATE_TEAM_MEMBER_SUCCESS",
  CREATE_TEAM_MEMBER_FAILURE: "CREATE_TEAM_MEMBER_FAILURE",

  DELETE_TEAM_MEMBER_REQUEST: "DELETE_TEAM_MEMBER_REQUEST",
  DELETE_TEAM_MEMBER_SUCCESS: "DELETE_TEAM_MEMBER_SUCCESS",
  DELETE_TEAM_MEMBER_FAILURE: "DELETE_TEAM_MEMBER_FAILURE",

  UPDATE_TEAM_MEMBER_REQUEST: "UPDATE_TEAM_MEMBER_REQUEST",
  UPDATE_TEAM_MEMBER_SUCCESS: "UPDATE_TEAM_MEMBER_SUCCESS",
  UPDATE_TEAM_MEMBER_FAILURE: "UPDATE_TEAM_MEMBER_FAILURE",

  UPDATE_TEAM_NAME_REQUEST: "UPDATE_TEAM_MEMBER_REQUEST",
  UPDATE_TEAM_NAME_SUCCESS: "UPDATE_TEAM_MEMBER_SUCCESS",
  UPDATE_TEAM_NAME_FAILURE: "UPDATE_TEAM_MEMBER_FAILURE",

  GET_TEAM_NAME_REQUEST: "GET_TEAM_MEMBER_REQUEST",
  GET_TEAM_NAME_SUCCESS: "GET_TEAM_MEMBER_SUCCESS",
  GET_TEAM_NAME_FAILURE: "GET_TEAM_MEMBER_FAILURE",

  SEND_QUESTIONNAIRE_TO_MEMBER_REQUEST: "SEND_QUESTIONNAIRE_TO_MEMBER_REQUEST",
  SEND_QUESTIONNAIRE_TO_MEMBER_SUCCESS: "SEND_QUESTIONNAIRE_TO_MEMBER_SUCCESS",
  SEND_QUESTIONNAIRE_TO_MEMBER_FAILURE: "SEND_QUESTIONNAIRE_TO_MEMBER_FAILURE",

  SEND_QUESTIONNAIRE_TO_TEAM_REQUEST: "SEND_QUESTIONNAIRE_TO_TEAM_REQUEST",
  SEND_QUESTIONNAIRE_TO_TEAM_SUCCESS: "SEND_QUESTIONNAIRE_TO_TEAM_SUCCESS",
  SEND_QUESTIONNAIRE_TO_TEAM_FAILURE: "SEND_QUESTIONNAIRE_TO_TEAM_FAILURE",
};

export function fetchTeam(team360 = false) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/members/all?team360=${team360}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchTeamRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(fetchTeamSuccess(json));
        dispatch(fetchViewOnlyUser(json.team_id))
        return json;
      })
      .catch((error) => dispatch(fetchTeamFailure(error)));
  };

  function fetchTeamRequest() {
    return { type: actions.FETCH_TEAM_REQUEST };
  }
  function fetchTeamSuccess(payload) {
    return {
      type: actions.FETCH_TEAM_SUCCESS,
      payload,
    };
  }
  function fetchTeamFailure(error) {
    return {
      type: actions.FETCH_TEAM_FAILURE,
      error,
    };
  }
}

export function createTeamMember(team_id, email, name, team360 = false) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/members?team360=${team360}`;

  const data = { email, name, team_id };
  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(createTeamMemberRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(createTeamMemberSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createTeamMemberFailure(error));
      });
  };

  function createTeamMemberRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_TEAM_MEMBER_REQUEST,
      });
    };
  }
  function createTeamMemberSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_TEAM_MEMBER_SUCCESS,
        payload,
      });
      dispatch(fetchTeam(team360)).then(notifySuccess("Team member created with success"));
    };
  }
  function createTeamMemberFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_TEAM_MEMBER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function createTeamMembers(teamId, members, team360 = false) {
  const apiUrl = API.teamMember.createBulk(team360);
  const data = { team_id: teamId, members };

  return (dispatch) => {
    dispatch(createTeamMemberRequest());
    HttpClient.post(apiUrl, data)
      .then((json) => {
        dispatch(createTeamMemberSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createTeamMemberFailure(error));
      });
  }

  function createTeamMemberRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_TEAM_MEMBER_REQUEST,
      });
    };
  }
  function createTeamMemberSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_TEAM_MEMBER_SUCCESS,
        payload,
      });
      dispatch(fetchTeam(team360)).then(notifySuccess("Team members were created with success"));
    };
  }
  function createTeamMemberFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.CREATE_TEAM_MEMBER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function deleteTeamMember(id, teamId, team360 = false) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}/members/${id}?team360=${team360}`;
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(deleteTeamMemberRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(deleteTeamMemberSuccess(json));
        dispatch(fetchTeam(team360));
        return json;
      })
      .catch((error) => dispatch(deleteTeamMemberFailure(error)));
  };

  function deleteTeamMemberRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_TEAM_MEMBER_REQUEST,
      });
    };
  }
  function deleteTeamMemberSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_TEAM_MEMBER_SUCCESS,
        payload,
      });
      notifySuccess("Your team member was deleted with success");
    };
  }
  function deleteTeamMemberFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_TEAM_MEMBER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function updateTeamMember(member, teamId, team360 = false) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${member.team}/members/${member.id}?team360=${team360}`;

  const data = { email: member.email, name: member.name, team_id: Number(teamId) };
  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.PUT,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(updateTeamMemberRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(updateTeamMemberSuccess(json));
        dispatch(fetchTeam(team360));
        return json;
      })
      .catch((error) => dispatch(updateTeamMemberFailure(error)));
  };

  function updateTeamMemberRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_TEAM_MEMBER_REQUEST,
      });
    };
  }
  function updateTeamMemberSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_TEAM_MEMBER_SUCCESS,
        payload,
      });
      notifySuccess("Your team member was updated with success");
    };
  }
  function updateTeamMemberFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_TEAM_MEMBER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function sendQuestionnaireToMember(teamId, id, team360 = false) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}/members/${id}/resend?team360=${team360}`;
  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(sendQuestionnaireToMemberRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(sendQuestionnaireToMemberSuccess(json, team360));
        return json;
      })
      .catch((error) => dispatch(sendQuestionnaireToMemberFailure(error)));
  };

  function sendQuestionnaireToMemberRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.SEND_QUESTIONNAIRE_TO_MEMBER_REQUEST,
      });
    };
  }
  function sendQuestionnaireToMemberSuccess(payload, team360) {
    return (dispatch) => {
      dispatch({
        type: actions.SEND_QUESTIONNAIRE_TO_MEMBER_SUCCESS,
        payload,
      });
      dispatch(fetchTeam(team360)).then(notifySuccess("Questionnaire sent with success"));
    };
  }
  function sendQuestionnaireToMemberFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.SEND_QUESTIONNAIRE_TO_MEMBER_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function sendQuestionnaireToTeam(payload, team360 = false) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/members/email?team360=${team360}`;
  const data = {
    company_id: payload.company_id,
    region_ids: payload.region_ids,
    team_ids: payload.team_ids,
    area_ids: payload.area_ids,
  };

  const json = JSON.stringify(data);
  const requestOptions = {
    method: methods.POST,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(sendQuestionnaireToTeamRequest());
    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(sendQuestionnaireToTeamSuccess(json, team360));
        return json;
      })
      .catch((error) => dispatch(sendQuestionnaireToTeamFailure(error)));
  };

  function sendQuestionnaireToTeamRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.SEND_QUESTIONNAIRE_TO_TEAM_REQUEST,
      });
    };
  }
  function sendQuestionnaireToTeamSuccess(payload, team360) {
    return (dispatch) => {
      dispatch({
        type: actions.SEND_QUESTIONNAIRE_TO_TEAM_SUCCESS,
        payload,
      });
      dispatch(fetchTeam(team360)).then(notifySuccess("Questionnaire sent to team with success"));
    };
  }
  function sendQuestionnaireToTeamFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.SEND_QUESTIONNAIRE_TO_TEAM_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function updateTeamName(team, teamId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}`;

  const data = { name: team };
  const json = JSON.stringify(data);

  const requestOptions = {
    method: methods.PUT,
    headers: authHeader(),
    body: json,
  };

  return (dispatch) => {
    dispatch(updateTeamNameRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((json) => {
        dispatch(updateTeamNameSuccess(json));
      })
      .catch((error) => dispatch(updateTeamNameFailure(error)));
  };

  function updateTeamNameRequest() {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_TEAM_NAME_REQUEST,
      });
    };
  }
  function updateTeamNameSuccess(payload) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_TEAM_NAME_SUCCESS,
        payload,
      });
      notifySuccess("Your team was updated with success");
    };
  }
  function updateTeamNameFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_TEAM_NAME_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

export function getTeamName(teamId) {
  const apiUrl = `${process.env.REACT_APP_TEAMMETRICS_CORE_API_URL}/api/team/${teamId}`;

  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(getTeamNameRequest());

    return fetch(apiUrl, requestOptions)
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        dispatch(getTeamNameSuccess(json));
      })
      .catch((error) => dispatch(getTeamNameFailure(error)));
  };

  function getTeamNameFailure(error) {
    return (dispatch) => {
      dispatch({
        type: actions.GET_TEAM_NAME_FAILURE,
        error,
      });
      notifyError(error.message || error);
    };
  }
}

function getTeamNameSuccess(payload) {
  return (dispatch) => {
    dispatch({
      type: actions.GET_TEAM_NAME_SUCCESS,
      payload,
    });
  };
}

function getTeamNameRequest() {
  return (dispatch) => {
    dispatch({
      type: actions.GET_TEAM_NAME_REQUEST,
    });
  };
}
