import React from "react";
import PropTypes from 'prop-types';

import { useVisibleColumns } from "../hooks/use-visible-column";

export const WizardFormHeader = ({ columns }) => {
  const visibleColumns = useVisibleColumns(columns);

  return (
    <thead>
      <tr>
        <th width="0%"></th>
        {visibleColumns.map((col, colIndex) => (
          <th width={col.width} key={`col-${colIndex}`}>
            {col.icon && <i className={`fa fa-solid ${col.icon}`} />}
            {col.name && <span>{col.name}</span>}
          </th>
        ))}
      </tr>
    </thead>
  );
};

WizardFormHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
      hidden: PropTypes.bool,
      icon: PropTypes.string,
      name: PropTypes.string,
      defaultValue: PropTypes.string
  })).isRequired
}