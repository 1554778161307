export const stepEnum = {
  RegionAmount: 'RegionAmount',
  RegionCreation: 'RegionCreation',
  AreaAmount: 'AreaAmount',
  AreaCreation: 'AreaCreation',
  TeamsAmount: 'TeamsAmount',
  TeamsCreation: 'TeamsCreation',
  TeamMembersAmount: 'TeamMembersAmount',
  TeamMembersCreation: 'TeamMembersCreation',
};

export const updateActiveStep = (name, previousSteps) => {
  return previousSteps.map((step) => ({
    ...step,
    active: step.name === name,
  }));
};

export function handleAreaParsing(areas, regions) {
  return (
    areas &&
    areas.map((area) => ({
      value: area.id,
      label: `${area.name} - ${
        regions.find((region) => region.id === area.regionId).name
      }`,
    }))
  );
}
