/*eslint no-magic-numbers: 0*/
/*eslint react/no-deprecated: 0*/
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class QuestionnaireQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scaleValue: props.response ? props.response : 0,
    };
    this.scaleItems = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  }

  componentDidUpdate() {
    const { response } = this.props;

    if (response) {
      if (this.state.scaleValue !== response.answer) {
        this.setState({
          scaleValue: response.answer,
        });
      }
    }
  }

  componentDidMount() {
    const { response } = this.props;

    if (response) {
      if (this.state.scaleValue !== response.answer) {
        this.setState({
          scaleValue: response.answer,
        });
      }
    }
  }

  handleItemClick = (e) => {
    this.props.onItemClick(this.props.questionId, e.currentTarget.value);
    this.setState({
      scaleValue: e.currentTarget.value,
    });
  };

  handleOnBack = () => {
    this.setState({
      error: null,
    });
    this.props.onBack();
  };

  handleOnNext = () => {
    const { scaleValue } = this.state;
    if (scaleValue && scaleValue !== 0) {
      this.props.onNext(scaleValue);
      this.setState({
        scaleValue: 0,
        error: null,
      });
    } else {
      this.setState({
        error: "You need to answer this question in order to continue",
      });
    }
  };

  render() {
    const { scaleValue, error } = this.state;
    const {
      section,
      title,
      subTitle,
      question,
      explanation,
      number,
    } = this.props;
    const formattedQuestion = { __html: question };

    return (
      <Fragment>
        <div className="questionnaire-page__content-header">
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-title-main">
              {`${section ? `Section ${section}. ` : ""}${title}`}
            </span>
          </div>
          <div className="questionnaire-page__content-header-title">
            <span className="questionnaire-page__content-header-sub-title">
              {subTitle}
            </span>
          </div>
        </div>
        <div className="questionnaire-page__content-body">
          <div className="questionnaire-page__content-body-question">
            <i className="fa fa-question-circle" />
            <span>{`Q${number}.  `} </span>
            <span dangerouslySetInnerHTML={formattedQuestion} />
          </div>
          <div className="questionnaire-page__content-body-explanation">
            <span>{explanation && `*${explanation}`}</span>
          </div>
          <div className="questionnaire-page__content-body-options">
            <span className="questionnaire-page__content-body-options-title">
              Please score:
            </span>
            <div className="questionnaire-page__content-body-options-scale">
              {this.scaleItems.map((value) => {
                return (
                  <button
                    onClick={this.handleItemClick}
                    className={`questionnaire-page__content-body-options-scale-item${
                      scaleValue === value ? `-active` : ``
                    }`}
                    value={value}
                    key={value}
                  >
                    <span>{value}</span>
                  </button>
                );
              })}
            </div>
            <div className="questionnaire-page__content-body-options-footer">
              <span>Strongly Disagree</span>
              <span>Strongly Agree</span>
            </div>
          </div>
          <div className="questionnaire-page__content-body-actions">
            <button
              className="questionnaire-page__content-body-actions-back"
              onClick={() => this.handleOnBack()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" />
              <span>Back</span>
            </button>
            <div className="questionnaire-page__content-body-actions-separator">
              <hr />
            </div>
            <button
              className="questionnaire-page__content-body-actions-next"
              onClick={() => this.handleOnNext()}
            >
              <span>Next</span>
              <i className="fa fa-arrow-right" aria-hidden="true" />
            </button>
          </div>
          <div className="questionnaire-page__content-body-error">
            {error && error ? error : ""}
          </div>
        </div>
      </Fragment>
    );
  }
}

QuestionnaireQuestion.propTypes = {
  response: PropTypes.object,
  onBack: PropTypes.func,
  onItemClick: PropTypes.func,
  onNext: PropTypes.func,
  section: PropTypes.number,
  title: PropTypes.string,
  question: PropTypes.string,
  questionId: PropTypes.number,
  number: PropTypes.string,
  subTitle: PropTypes.string,
  explanation: PropTypes.string,
};

export default QuestionnaireQuestion;
