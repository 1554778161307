/*eslint no-magic-numbers: 0*/
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import Dropdown from "../../../../components/Dropdown";

//utils
import { getValueColor } from "../../../../store/helpers/uiHelper";
import {
  getLatestReport,
  getSelectedReport,
  getVisibleReports,
} from "../../../../store/helpers/userHelper";

import * as data from "./data";

//scss
import "./IndexScore.scss";

//actions
import {
  fetchCategoryAverages,
  fetchMemberStatus,
  fetchMoraleIndicatorComments,
  fetchPdfReport,
  fetchQuestionAverages,
  fetchReportStatus,
  fetchReports,
  fetchStressIndicatorComments,
  updateSelectedReport,
} from "../../../../store/actions/reports.action";

import { useLocation } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle";
import { INDICATORS } from "../../../../constants/metrics";
import {
  compareCategoryAverages,
  compareQuestionAverages,
  updateAssessmentCompare,
} from "../../../../store/actions/benchmarking.action";
import { REPORT_TEXTS } from "../../utils/contentTexts";

const HOCIndexScore = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { location } = useLocation();
  return (
    <IndexScore
      {...props}
      dispatch={dispatch}
      state={state}
      location={location}
    />
  );
};

class IndexScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allReportsData: { data: data.allReportsData },
      selectedReportsData: { data: data.selectedReportsData },
      reportToCompareFrom: { data: data.reportToCompareFrom },
      reportToCompareTo: { data: data.reportToCompareTo },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { comparableReports, reports } = nextProps;

    if (comparableReports && comparableReports.length > 0) {
      if (this.state.filteredReport !== getLatestReport(comparableReports)) {
        this.setState({
          filteredReport: getLatestReport(comparableReports),
        });
      }
    }
    if (reports) {
      if (this.state.allReport !== getSelectedReport(reports)) {
        this.setState({
          allReport: getSelectedReport(reports),
        });
      }
    }
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    const {
      questionAveragesType,
      fetchCategoryAverages,
      fetchReportStatus,
      fetchReports,
      fetchQuestionAverages,
      fetchMoraleIndicatorComments,
      fetchStressIndicatorComments,
      updateSelectedReport,
      reports,
      reportsToCompare,
      updateAssessmentCompare,
      compareCategoryAverages,
      compareQuestionAverages,
    } = this.props;

    this.setState({ [fieldName]: fieldValue }, () => {
      if (fieldName === "selectedReport") {
        updateSelectedReport(fieldValue).then(() => {
          fetchQuestionAverages();
          fetchReportStatus();
          fetchReports();
          switch (questionAveragesType) {
            case INDICATORS.TEAM_SYNERGY:
              fetchCategoryAverages();
              break;
            case INDICATORS.STRESS:
              fetchStressIndicatorComments();
              break;
            case INDICATORS.MORALE:
              fetchMoraleIndicatorComments();
              break;
            default:
              break;
          }
        });
      } else if (fieldName === "reportFrom" || fieldName === "reportTo") {
        const selectedReport = reports.find(
          (report) => report.id === Number(fieldValue)
        );
        let newReportsToCompare = reportsToCompare;
        if (
          fieldName === "reportFrom" &&
          reportsToCompare?.reportTo?.id === Number(fieldValue)
        ) {
          updateAssessmentCompare({
            reportFrom: selectedReport,
            reportTo: reportsToCompare?.reportFrom,
          });
        } else if (
          fieldName === "reportTo" &&
          reportsToCompare?.reportFrom?.id === Number(fieldValue)
        ) {
          updateAssessmentCompare({
            reportFrom: reportsToCompare?.reportTo,
            reportTo: selectedReport,
          });
        } else {
          newReportsToCompare = {
            ...reportsToCompare,
            [fieldName]: selectedReport,
          };
          updateAssessmentCompare(newReportsToCompare);
        }

        compareCategoryAverages(
          newReportsToCompare?.reportFrom?.id,
          newReportsToCompare?.reportTo?.id
        );
        compareQuestionAverages(
          newReportsToCompare?.reportFrom?.id,
          newReportsToCompare?.reportTo?.id
        );
      }
    });
  };

  handleGetPdf = () => {
    this.props.fetchPdfReport();
  };

  render() {
    const {
      comparableReports,
      questionAverages,
      questionAveragesType,
      reports,
      isBenchmark,
      isTeam360,
      reportsToCompare,
    } = this.props;

    const {
      reportToCompareFrom,
      reportToCompareTo,
      selectedReportsData,
      selectedReport,
    } = this.state;

    const averages = questionAverages && questionAverages[questionAveragesType];
    let title = "";
    let subtitle = "";
    let paragraphs = [];

    const getTitleType = () => {
      if (isBenchmark) {
        return "benchmark";
      }
      if (isTeam360) {
        return "team360";
      }
      return "default";
    };

    const titleKey = getTitleType();

    if (this.props.title) title = this.props.title;
    else title = REPORT_TEXTS[titleKey][questionAveragesType].title;

    if (this.props.subtitle) subtitle = this.props.subtitle;
    else if (this.props.paragraphs) paragraphs = this.props.paragraphs;
    else paragraphs = REPORT_TEXTS[titleKey][questionAveragesType].subtitle;

    return (
      <Fragment>
        <PageTitle
          title={title}
          subtitle={subtitle}
          paragraphs={paragraphs}
          paragraphsClassNames="parapgh-min-height"
        >
          <div className="index-score__average">
            <div className="index-score__average-container">
              {isBenchmark && this.props.comparedQuestionAverages ? (
                <div
                  className={`index-score__average-value ${getValueColor(
                    this.props.comparedQuestionAverages[0][
                      questionAveragesType
                    ],
                    questionAveragesType === INDICATORS.STRESS
                  )}`}
                >
                  <h1>
                    {
                      this.props.comparedQuestionAverages[0][
                        questionAveragesType
                      ]
                    }
                  </h1>
                </div>
              ) : (
                <div className="index-score__average-tl">
                  <h1>TL</h1>
                </div>
              )}
              <div className="index-score__average-ci">
                <h1>SI</h1>
              </div>
              {isBenchmark && this.props.comparedQuestionAverages ? (
                <div
                  className={`index-score__average-value ${getValueColor(
                    this.props.comparedQuestionAverages[1][
                      questionAveragesType
                    ],
                    questionAveragesType === INDICATORS.STRESS
                  )}`}
                >
                  <h1>
                    {
                      this.props.comparedQuestionAverages[1][
                        questionAveragesType
                      ]
                    }
                  </h1>
                </div>
              ) : (
                <div
                  className={`index-score__average-value ${getValueColor(
                    questionAverages && averages,
                    questionAveragesType === INDICATORS.STRESS
                  )}`}
                >
                  <h1>
                    {questionAverages && averages ? (
                      averages
                    ) : (
                      <i className="fa fa-question-circle" />
                    )}
                  </h1>
                </div>
              )}
            </div>
            <div className="index-score__options">
              {isBenchmark ? (
                reports &&
                reports.length > 0 && (
                  <>
                    <div className="index-score__options-select mb-3">
                      <Dropdown
                        data={{
                          ...reportToCompareFrom.data,
                          list: getVisibleReports(reports) || [],
                          value: reportsToCompare?.reportFrom?.id,
                        }}
                        onSelect={this.updateFormFieldValue}
                      />
                    </div>
                    <div className="index-score__options-select">
                      <Dropdown
                        data={{
                          ...reportToCompareTo.data,
                          list: getVisibleReports(comparableReports) || [],
                          value: reportsToCompare?.reportTo?.id,
                        }}
                        onSelect={this.updateFormFieldValue}
                      />
                    </div>
                  </>
                )
              ) : (
                <>
                  <div className="index-score__options-select">
                    <Dropdown
                      data={{
                        ...selectedReportsData.data,
                        list: getVisibleReports(reports) || [],
                        value: selectedReport
                          ? selectedReport
                          : reports && getSelectedReport(reports),
                      }}
                      onSelect={this.updateFormFieldValue}
                    />
                  </div>
                  <div className="index-score__options-label">
                    <span>Download your Report:</span>
                  </div>
                  <div className="index-score__options-actions">
                    <button onClick={this.handleGetPdf}>
                      <span>Get PDF</span>
                      <i className="fas fa-file-download" />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </PageTitle>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ reports, benchmarking }) => {
  return {
    questionAverages: reports.questionAverages,
    isTeam360:
      reports && reports.reportStatus && reports.reportStatus.is_team_360,
    reports: reports && reports.reports,
    comparedCategoryAverages: benchmarking.comparedCategoryAverages,
    reportsToCompare: benchmarking.reportsToCompare,
    comparedQuestionAverages: benchmarking.comparedQuestionAverages,
  };
};

const TYPES = {
  comparableReports: PropTypes.array,
  isTeam360: PropTypes.bool,
  questionAverages: PropTypes.object,
  questionAveragesType: PropTypes.string,
  fetchCategoryAverages: PropTypes.func,
  fetchMemberStatus: PropTypes.func,
  fetchPdfReport: PropTypes.func,
  fetchQuestionAverages: PropTypes.func,
  fetchReports: PropTypes.func,
  fetchReportStatus: PropTypes.func,
  fetchStressIndicatorComments: PropTypes.func,
  fetchMoraleIndicatorComments: PropTypes.func,
  updateSelectedReport: PropTypes.func,
  reports: PropTypes.array,
  categoryAverages: PropTypes.array,
  comparedCategoryAverages: PropTypes.array,
  updateAssessmentCompare: PropTypes.func,
  reportsToCompare: PropTypes.object,
  compareCategoryAverages: PropTypes.func,
  compareQuestionAverages: PropTypes.func,
  comparedQuestionAverages: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HOCIndexScore.propTypes = TYPES;
IndexScore.propTypes = {
  ...TYPES,
  state: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
};

export default connect(mapStateToProps, {
  compareCategoryAverages,
  compareQuestionAverages,
  fetchCategoryAverages,
  fetchMemberStatus,
  fetchPdfReport,
  fetchQuestionAverages,
  fetchReports,
  fetchReportStatus,
  fetchStressIndicatorComments,
  fetchMoraleIndicatorComments,
  updateSelectedReport,
  updateAssessmentCompare,
})(HOCIndexScore);
