import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//components
import { Link } from "react-router-dom";

// the last import should always be the scss
import "./Tabbar.scss";

class Tabbar extends Component {
  getSelectedTab = (title) => {
    if (this.props.title === title.toUpperCase()) {
      return true;
    }
    return false;
  };

  renderArrow(title) {
    if (this.getSelectedTab(title)) {
      return (
        <div className="arrow-container">
          <div className="arrow-down" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="tabbar">
        <ul>
          {this.props.options?.map((option) => (
            <li
              key={option.id}
              className={`${this.getSelectedTab(option.title) ? "active" : ""}`}
            >
              <Link to={option.route} params={{ content: option.title }}>
                <span>{option.title}</span>
                <i className={option.icon} />
              </Link>
              {this.renderArrow(option.title)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  title: state.navigation && state.navigation.selection.tab,
});

Tabbar.propTypes = {
  options: PropTypes.array,
  title: PropTypes.string,
};

export default connect(mapStateToProps, null)(Tabbar);
