import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";

//actions
import { setNavigationAssets } from "../../../../../../store/actions/navigation.action";
import {
  fetchCategoryAverages,
  fetchCohesionIndicatorComments,
  fetchQuestionAverages,
} from "../../../../../../store/actions/reports.action";

//components
import PageContent from "../../../../../../components/PageContent";
import PageLoading from "../../../../../../components/PageLoading";
import { INDICATORS } from "../../../../../../constants/metrics";
import { SECTIONS } from "../../../../../../constants/sections";
import IndexScore from "../../../../components/IndexScore";
import ReportsFooter from "../../../../components/ReportsFooter";
import ReportsTabbar from "../../../../components/ReportsTabbar";
import ReportsTopic from "../../../../components/ReportsTopic";

function ReportView(props) {
  const {
    isLoadingPage,
    setNavigationAssets,
    categoryAverages,
    fetchCategoryAverages,
    questionAverages,
    fetchQuestionAverages,
    isGeneratingPdf,
  } = props;

  React.useEffect(() => {
    const navigation = {
      title: "METRIC REPORTS",
      subtitle: SECTIONS.TEAM_SYNERGY,
      tab: "YOUR REPORTS",
    };
    setNavigationAssets(navigation);
  }, []);

  React.useEffect(() => {
    if (!categoryAverages) {
      fetchCategoryAverages();
    }
    if (!questionAverages) {
      fetchQuestionAverages();
    }
  }, [
    categoryAverages,
    questionAverages,
    fetchCategoryAverages,
    fetchQuestionAverages,
  ]);

  return (
    <PageLoading
      isLoading={isLoadingPage}
      message={isGeneratingPdf && "We're generating your report..."}
      render={() => (
        <Fragment>
          <IndexScore questionAveragesType={INDICATORS.TEAM_SYNERGY} />
          <PageContent>
            <ReportsTabbar compare={false} />
            {categoryAverages &&
              categoryAverages.map((category) => (
                <div key={category.category_id}>
                  <ReportsTopic
                    answers={category.answer_count}
                    answersTotal={category.total_answers}
                    id={category.category_id}
                    title={category.category_name}
                    subtitle={category.category_description}
                    value={category.average.toFixed(1)}
                    canExpand={true}
                    iconUrl={category.icon_url}
                  />
                </div>
              ))}
            <ReportsFooter showMemberStatus/>
          </PageContent>
        </Fragment>
      )}
    />
  );
}

ReportView.propTypes = {
  fetchCategoryAverages: PropTypes.func,
  fetchCohesionIndicatorComments: PropTypes.func,
  isLoadingCategoryAverages: PropTypes.bool,
  isLoadingReports: PropTypes.bool,
  isPageLoading: PropTypes.bool,
  categoryAverages: PropTypes.array,
  questionAverages: PropTypes.object,
  setNavigationAssets: PropTypes.func,
  isLoadingPage: PropTypes.bool,
  isGeneratingPdf: PropTypes.bool,
  fetchQuestionAverages: PropTypes.func,
};

const mapStateToProps = ({ reports }) => {
  const isLoadingPage =
    reports.isLoadingCategoryAverages ||
    reports.isLoadingReports ||
    reports.isLoadingComments ||
    reports.isGeneratingPdf;

  return {
    canSeeReport:
      reports && reports.reportStatus && reports.reportStatus.can_see_report,
    categoryAverages: reports.categoryAverages,
    error: reports.error,
    isLoadingPage,
    isGeneratingPdf: reports.isGeneratingPdf,
    questionAverages: reports.questionAverages,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  fetchCategoryAverages,
  fetchCohesionIndicatorComments,
  fetchQuestionAverages,
})(ReportView);
