import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import Dropdown from "../../../../components/Dropdown";
import PageLoading from "../../../../components/PageLoading";
import TextField from "../../../../components/TextField";

//actions
import { fetchCountries } from "../../../../store/actions/general.action";
import {
  fetchCoupon,
  fetchCustomer,
  fetchProduct,
} from "../../../../store/actions/payment.action";

//utils
import { getFormsValidityStatus } from "../../../../store/services";
import { notifyError } from "../../../../utils/notifications";
import * as data from "./data";

// scss
import "./Product.scss";
import ContentOrder from "./components/ContentOrder";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productQuantity: 1,
      customerFields: [
        { data: data.firstNameData },
        { data: data.surnameData },
        { data: data.companyNameData },
        { data: data.addressData },
        { data: data.countryData },
        { data: data.townCityData },
        { data: data.postCodeData },
        { data: data.phoneNumberData },
        { data: data.emailData },
      ],
      isFormSubmitted: false,
      customer: this.getAccountInfo(),
      coupon: null,
      appliedCoupon: null,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    const { report } = this.props.match.params;
    this.setState({
      productQuantity: this.props.location.state.quantity,
    });
    this.props.fetchProduct(report);
    this.props.fetchCountries();
    this.props.fetchCustomer();
  }

  componentWillReceiveProps(nextProps) {
    const { account, customer, countries } = nextProps;
    if (customer && customer !== this.state.customer) {
      this.setState(() => ({
        customer,
      }));
    }

    if (
      !customer &&
      countries.length > 0 &&
      !this.state.customer.country_name
    ) {
      this.updateFormFieldValue("country_id", parseInt(account.country_id));
    }
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    if (fieldName === "country_id") {
      this.setState((prevState) => ({
        customer: {
          ...prevState.customer,
          country_name: this.getCountryName(parseInt(fieldValue)),
          country_id: fieldValue,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customer: { ...prevState.customer, [fieldName]: fieldValue },
      }));
    }
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  getAccountInfo() {
    const { account, countries } = this.props;
    const customer = {
      first_name: account && account.first_name,
      surname: account && account.surname,
      town_city: account && account.town_city,
      post_code: account && account.post_code,
      email: account && account.email,
      country_name:
        account &&
        countries &&
        this.getCountryName(parseInt(account.country_id)),
      country_id: account && account.country_id,
      company_name: account && account.company_name,
    };

    return customer;
  }

  validateCountry() {
    const { country_id, country_name } = this.state.customer;

    if (country_id) {
      return !(!country_id || country_id === "0");
    } else if (country_name) {
      return !(
        !this.getCountryId(country_name) ||
        this.getCountryId(country_name) === "0"
      );
    }
  }

  getCountryName(countryId) {
    let countryName;
    this.props.countries.forEach((country) => {
      if (country.id === countryId) {
        countryName = country.description;
      }
    });
    return countryName;
  }

  getCountryId(countryName) {
    let countryId;
    this.props.countries.forEach((country) => {
      if (country.description === countryName) {
        countryId = country.id;
      }
    });
    return countryId;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = getFormsValidityStatus(this.formStatus);

    if (isFormValid && this.validateCountry()) {
      this.setState({ error: "" });
      const { report } = this.props.match.params;
      const { customer, appliedCoupon, productQuantity } = this.state;
      const { teamId } = this.props.location.state;

      this.props.history.push({
        pathname: `/product/${report}/payment`,
        state: { customer, appliedCoupon, quantity: productQuantity, teamId },
      });
      return;
    }
    this.setState({
      isFormSubmitted: true,
      error: "Form is invalid, please review your information.",
    });
    notifyError("Form is invalid, please review your information.");
  };

  renderComponentForField = (field) => {
    switch (field.data.name) {
      case "country_id":
        return (
          <Dropdown
            data={{
              ...field.data,
              list: this.props.countries
                ? this.props.countries
                : field.data.list,
              value:
                this.props.customer && this.props.customer.country_name
                  ? this.getCountryId(this.props.customer.country_name)
                  : this.state.customer && this.state.customer[field.data.name]
                  ? this.state.customer[field.data.name]
                  : field.data.value,
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      default:
        return (
          <TextField
            data={{
              ...field.data,
              value: this.props.customer
                ? this.props.customer[field.data.name]
                : this.state.customer && this.state.customer[field.data.name]
                ? this.state.customer[field.data.name]
                : field.data.value,
            }}
            customStyleClass="content__info-customer_input"
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
          />
        );
    }
  };

  handleCouponSubmit(coupon) {
    this.setState({ appliedCoupon: coupon });
  }

  render() {
    const { currentProduct, isLoadingProduct } = this.props;

    const { productQuantity } = this.state;

    return (
      <PageLoading
        isLoading={isLoadingProduct}
        render={() => (
          <div className="product-page">
            <div className="header">
              <button
                onClick={() => this.props.history.goBack()}
                className="header__button"
              >
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span>Go Back</span>
              </button>
            </div>
            {!currentProduct ? (
              <span>Oops, we couldn&apos;t find your product</span>
            ) : (
              <>
                <div className="title">
                  <span>Order your TeamLytica {currentProduct.name}</span>
                </div>

                <div className="content">
                  <>
                    <div className="content__info">
                      <div className="content__info-product">
                        <div className="content__info-separator">
                          <span>Your chosen report</span>
                        </div>
                        <>
                          <span className="content__info-product-name">
                            {currentProduct.name} - Quantity: {productQuantity}
                          </span>
                        </>
                      </div>
                      <div className="content__info-customer">
                        <div className="content__info-separator">
                          <span>Payment Details</span>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                          {this.state.customerFields.map((field) => (
                            <div key={field.data.id}>
                              {this.renderComponentForField(field)}
                            </div>
                          ))}
                          <div className="error-message">
                            {this.state.errorMessage
                              ? this.state.errorMessage
                              : ""}
                          </div>

                          <div className="content__info-customer_footer">
                            <button type="submit" className="payment-button">
                              <span>Proceed to Payment</span>
                              <i className="fas fa-chevron-right" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <ContentOrder
                      productQuantity={+productQuantity}
                      onCouponSubmit={this.handleCouponSubmit.bind(this)}
                    />
                  </>
                </div>
              </>
            )}
          </div>
        )}
      />
    );
  }
}

Product.propTypes = {
  account: PropTypes.object,
  currentProduct: PropTypes.object,
  customer: PropTypes.object,
  countries: PropTypes.array,
  isLoadingProduct: PropTypes.bool,
  isLoadingProductPrice: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  report: PropTypes.string,
  fetchCoupon: PropTypes.func,
  fetchProduct: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchCustomer: PropTypes.func,
  isTeam360: PropTypes.bool,
  location: PropTypes.object,
};

const mapStateToProps = ({ general, payment, reports }) => {
  return {
    countries: general && general.countries,
    account: general && general.content,
    isLoadingProduct: payment && payment.isLoadingProduct,
    isLoadingProductPrice: payment?.isLoadingProductPrice,
    currentProduct: payment && payment.currentProduct,
    error: payment.error,
    isCustomerLoading: payment.isCustomerLoading,
    customer: payment.customer,
    customerError: payment.customerError,
    isTeam360:
      reports && reports.reportStatus && reports.reportStatus.is_team_360,
  };
};

export default connect(mapStateToProps, {
  fetchCountries,
  fetchCoupon,
  fetchProduct,
  fetchCustomer,
})(Product);
