import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const RedirectPanel = ({ label, linkLabel, linkTo }) => {
  return (
    <div className="auth-page__form-redirect-container">
      <span className="redirect-label">{label}</span>
      <Link className="redirect-button" to={linkTo}>
        {linkLabel}
      </Link>
    </div>
  );
};

RedirectPanel.propTypes = {
  label: PropTypes.string,
  linkLabel: PropTypes.string,
  linkTo: PropTypes.string,
};
