import React from "react";
import PropTypes from "prop-types";

import StepSizeSelector from "../../../StepSizeSelector/StepSizeSelector";

const TeamAmountSelector = ({ onSubmit, teamMemberAmount }) => {
  return (
    <StepSizeSelector
      headerProps={{
        title: "How many Team Members are in your Organisation?",
        subtitle: "(you can always add more later)",
        icon: "fa-users",
      }}
      bodyProps={{
        buttonText: "Add Team Members",
        teamMemberAmount: teamMemberAmount,
        onClick: onSubmit,
      }}
    />
  );
};

TeamAmountSelector.propTypes = {
  onSubmit: PropTypes.func,
  teamMemberAmount: PropTypes.string,
};

export default TeamAmountSelector;
